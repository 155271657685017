import React from 'react';
import { useTable, useFlexLayout, useResizeColumns } from 'react-table';

const initialState = { hiddenColumns: ['actions'] };
function ContactsTableConfig({ columns, data, updateMyData, defaultColumn }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
		updateMyData,
		defaultColumn,
	});

	const rowStyles = (row, rowIndex) => {
		let style = {};

		if (row.new) {
			style.backgroundColor = '#95d095';
		} else if (row.deleted) {
			style.backgroundColor = '#e89996';
		} else if (row.edited) {
			style.backgroundColor = '#5bc0de';
		}
		return style;
	};

	return (
		<table {...getTableProps()}>
			<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th
								{...column.getHeaderProps({
									style: { width: column.width },
								})}>
								<span>{column.render('text')}</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<tr style={rowStyles(row.original)} {...row.getRowProps()}>
							{row.cells.map(cell => {
								return <td {...cell.getCellProps({
									style: { width:  cell.column.width },
								})}>{cell.render('Cell')}</td>;
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}
export default ContactsTableConfig;
