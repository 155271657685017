import React, { Component } from "react";
import { Form } from "react-bootstrap";

export const CheckboxField = (props) => {
  return (
    <Form.Group as={props.as}>
      <Form.Label></Form.Label>
        <Form.Check
          size="sm"
          type="checkbox"
          name={props.name}
          label={props.label}
          value={props.value}
          checked={props.value}
          onChange={props.onChange}
          disabled={props.viewMode}
        />
    </Form.Group>
  );
};
