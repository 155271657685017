import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

export default class NumberInput extends React.Component {
	static defaultProps = {
		value: 0.0,
	};
	state = {
		value: this.props.value,
	};
	getValue() {
		return parseFloat(this.value);
	}

	handleKeyDown = e => {
		const { onEscape } = this.props;
		if (e.keyCode === 27) {
			// ESC
			this.props.onUpdate(this.state.value);
		} else if (e.keyCode === 13) {
			// ENTER
			this.props.onUpdate(this.state.value);
		}
	};

	render() {
		const { value, onUpdate, prefix, suffix, format, mask, stringValue, ...rest } = this.props;
		return (
			<NumberFormat
				className='form-control form-control-sm'
				format={format}
				mask={mask}
				value={this.state.value}
				thousandSeparator={true}
				prefix={prefix ? prefix : ''}
				suffix={suffix ? suffix : ''}
				fixedDecimalScale={true}
				decimalScale={2}
				onBlur={() => onUpdate(this.state.value)}
				onKeyDown={this.handleKeyDown}
				onValueChange={values => {
					const { formattedValue, value } = values;
					this.setState({ value: stringValue ? formattedValue : value });
				}}
			/>
		);
	}
}
