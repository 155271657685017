import * as utility from '../utility.js';
import { roundToTwo as rnd } from '../utility.js';

export const getFeeAmount = x =>
	x.remitted || x.invoiced ? x.remittedFirmFee : rnd((parseFloat(x.firmFeePercentage) * rnd(x.amount)) / 100);

export const getPenaltiesDeducted = (cas, feeAmount) => {
	if (cas.directPayment || (cas.caseLostPolicyGracePeriod && cas.isCaseLostPolicyGracePeriodPassed === false)) return 0;

	const principal = rnd(cas.amount) - rnd(feeAmount);
	const percentage = cas.amount < 0 ? 0 : rnd(cas.penaltiesBalancePercentage / 100);
	return rnd(principal * percentage);
};

export const getPenaltiesBalancePercentage = (cas, feeAmount, penaltiesBalances, totalPenaltiesDeducted) => {
	if (
		(cas.caseLostPolicyGracePeriod && cas.isCaseLostPolicyGracePeriodPassed === false) ||
		cas.directPayment ||
		cas.amount < 0
	)
		return 0.0;

	if (penaltiesBalances[cas.caseId] <= 0) {
		const principal = rnd(cas.amount) - rnd(feeAmount);
		return rnd((totalPenaltiesDeducted * 100) / principal);
	}

	return cas.penaltiesBalancePercentage;
};

// totalPenaltiesDeducted becomes obsolete by new remittance logic
export const getRemittanceAmount = (cas, feeAmount, totalPenaltiesDeducted) => {
	if (cas.directPayment) return 0.0;

	if (cas.caseLostPolicyGracePeriod && cas.isCaseLostPolicyGracePeriodPassed === false)
		return rnd(cas.amount) - rnd(feeAmount);

	return rnd(cas.amount) - rnd(feeAmount) - rnd(totalPenaltiesDeducted);
};
