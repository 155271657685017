import axios from 'axios';
import { env } from '../configs/env';

export function getUserNoteTypes() {
	return axios(env.SERVER_ENV.url + '/api/NoteTypes/userTypes', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function importOldCaseNotes(id) {
	return axios(env.SERVER_ENV.url + '/api/note/case_import/' + id, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getMassInsertData(request) {
	return axios(env.SERVER_ENV.url + '/api/note/mass-insert-page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function massInsertNotes(request) {
	return axios(env.SERVER_ENV.url + '/api/note/mass-insert', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
