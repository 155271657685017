import moment from 'moment';
import { getInvoiceBlob as generate } from '../components/checks/invoiceGenerator';
import * as utility from '../components/checks/remittanceUtility';
import { roundToTwo } from '../components/utility';
import { emailInvoice, uploadInvoiceToDM } from '../services/invoiceServices';

const initialState = {
	checkStatuses: [],
	newCheckStatus: null,
	page: 1,
	pageSize: 25,
	checks: [],
	dataSize: 1,
	invoices: [],
	saveInvoice: false,
	emailInvoice: false,
	outChecks: [],
	providers: [],
	checkedAll: false,
};

const checkReducer = (state = { ...initialState }, action) => {
	var totalPenaltiesDeducted = 0.0;
	var feeAmount = 0.0;

	const calculatedFields = [
		'costBalancePercentage',
		'totalCostDeducted',
		'penaltiesBalancePercentage',
		'totalPenaltiesDeducted',
		'feeAmount',
	];

	const normalizeData = () =>
		calculatedFields.forEach(name => {
			action.payload[name] = action.payload[name] ? parseFloat(action.payload[name]) : 0;
		});

	switch (action.type) {
		case 'CHECK_GET_PAGE_PENDING':
			return Object.assign({}, state, { ...state, checks: [] });
		case 'CHECKS_OUT_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
		case 'CHECK_OUT_PRINT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				outChecks: state.outChecks.filter(x => x.id != action.payload.data.id),
			});
		case 'CHECK_DELETE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				checks: state.checks.filter(x => x.id != action.payload.data),
			});
		case 'CHECK_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				checks: state.checks.map(item => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
		case 'CHECK_OUT_UPDATE_NUMBER':
			return Object.assign({}, state, {
				...state,
				outChecks: [
					...state.outChecks.map(x => {
						if (x.id != action.payload.id) return x;
						return { ...x, checkNumber: action.payload.checkNumber };
					}),
				],
			});
		case 'REMITTANCE_GET_PROVIDERS_PENDING':
			return Object.assign({}, state, {
				...state,
				checkedAll: false,
				providers: [],
			});
			break;
		case 'REMITTANCE_GET_PROVIDERS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'REMITTANCE_GET_PAGE_FULFILLED':
		case 'LLC_GET_PAGE_FULFILLED':
			const { data } = action.payload;

			const shouldIgnore = rem =>
				rem.frozen === 1 ||
				(action.type === 'LLC_GET_PAGE_FULFILLED' && (rem.liveCheckClient != 1 || rem.invoiced)) ||
				(action.type !== 'LLC_GET_PAGE_FULFILLED' && (rem.remitted || rem.liveCheckClient === 1 || rem.invoiced));

			if (data?.checks?.length !== 0) {
				return Object.assign({}, state, {
					...state,
					checkedAll: false,
					providers: state.providers.map(x => {
						if (x.providerId !== data.checks[0].providerId) return x;
						return {
							...x,
							costs: action.payload.data.costs,
							payments: action.payload.data.payments,
							penalties: action.payload.data.penalties,
							remittanceIgnoreSelect: data.checks.filter(rem => shouldIgnore(rem)).map(x => x.checkCaseMappingId),

							remittance: data.checks.map(cas => {
								feeAmount = utility.getFeeAmount(cas);

								return {
									...cas,
									feeAmount,
									costBalancePercentage: 0,
									penaltiesBalancePercentage: 0,
									totalCostDeducted: 0,
									totalPenaltiesDeducted: 0,
									remittanceAmount: utility.getRemittanceAmount(cas, feeAmount, totalPenaltiesDeducted),
								};
							}),
						};
					}),
				});
			}
			break;

		case 'REMITTANCE_GET_PROVIDERS_REJECTED':
			return Object.assign({}, state, {
				...state,
				checkedAll: false,
				providers: [],
			});
			break;

		case 'CHECK_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				checks: action.payload.data.checks.map(x => {
					return {
						...x,
						remittance: [],
						cleared: {
							value: x.isCleared,
							label: x.isCleared ? 'Cleared' : 'Not Cleared',
						},
					};
				}),
			});
			break;
		case 'CHECK_STATUS_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				checkStatuses: [...action.payload.data],
			});
			break;
		case 'CHECK_STATUS_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				checkStatuses: [action.payload.data, ...state.checkStatuses],
				newCheckStatus: action.payload.data,
			});
			break;
		case 'CHECK_STATUS_DELETE_FULFILLED':
			var removeIndex = state.checkStatuses
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.checkStatuses.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				checkStatuses: [...state.checkStatuses],
			});
			break;

		case 'CHECK_STATUS_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				checkStatuses: state.checkStatuses.map(item => {
					if (item.id !== action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;

		case 'REMITTANCE_UPDATE_BALANCE_PERCENTAGE':
			return Object.assign({}, state, {
				...state,
				providers: state.providers.map(item => {
					if (item.providerId === action.payload?.providerId)
						return {
							...item,
							...action.payload,
						};
					return item;
				}),
			});
			break;

		case 'REMITTANCE_ADD_COST':
			const { payload } = action;

			return Object.assign({}, state, {
				...state,
				providers: state.providers.map(item => {
					if (item.providerId === payload.providerId)
						return {
							...item,
							remittance: item.remittance.map(cas => {
								if (cas.checkCaseMappingId === payload.checkCaseMappingId) {
									normalizeData();

									return {
										...payload,
										firmFeePercentage: roundToTwo((payload.feeAmount * 100) / payload.amount),
										feeAmount: payload.feeAmount,
										totalCostDeducted: 0,
										totalPenaltiesDeducted: 0,
										costBalancePercentage: 0,
										penaltiesBalancePercentage: 0,
										remittanceAmount: utility.getRemittanceAmount(
											payload,
											payload.feeAmount,
											payload.totalPenaltiesDeducted
										),
									};
								}
								return cas;
							}),
						};
					return item;
				}),
			});
			break;
		case 'CHECK_REMITT_FULFILLED':
		case 'CHECK_GENERATE_INVOICES_FULFILLED':
			const { data: pData } = action.payload;

			if (state.emailInvoice || state.saveInvoice)
				generate(action.payload.data, blob => {
					if (state.emailInvoice && pData.providerEmail) emailInvoice(blob, pData);
					if (state.saveInvoice) uploadInvoiceToDM(blob, pData);
				});

			return Object.assign({}, state, {
				...state,
				invoices: [...state.invoices, pData],
				providers: [
					...state.providers.map(provider => {
						if (pData.providerId !== provider.providerId) return provider;
						return {
							...provider,
							costBalance: pData.costBalance,
							penaltiesBalance: pData.penaltiesBalance,
							remittanceIgnoreSelect: [
								...provider.remittanceIgnoreSelect,
								...pData.data.map(x => x.checkCaseMappingId),
							],
							costs: [],
							payments: [],
							penalties: [],
							remittance: provider.remittance.map(cas => {
								if (!pData.data.some(x => x.checkCaseMappingId === cas.checkCaseMappingId)) return cas;
								return {
									...cas,
									invoiced: action.payload.type === 'CHECK_GENERATE_INVOICES_FULFILLED',
									remitted: action.payload.type !== 'CHECK_GENERATE_INVOICES_FULFILLED',
								};
							}),
						};
					}),
				],
			});
			break;
		case 'REMITTANCE_TOGGLE_EMAIL':
			return Object.assign({}, state, {
				...state,
				emailInvoice: !state.emailInvoice,
			});
		case 'REMITTANCE_TOGGLE_SAVE':
			return Object.assign({}, state, {
				...state,
				saveInvoice: !state.saveInvoice,
			});
		case 'CHECK_CLEAR_STATE':
			return Object.assign({}, state, {
				...initialState,
			});
		case 'CHECK_UPDATE_VALUE':
			return Object.assign({}, state, {
				...state,
				checks: [
					...state.checks.map(x => {
						if (
							x.id === action.payload.id ||
							(x.checkNumber === action.payload.checkNumber &&
								moment(x.checkDate).isSame(action.payload.checkDate) &&
								x.deposited)
						)
							return {
								...x,
								clearDate: action.payload.clearDate !== null ? new Date(moment.utc(action.payload.clearDate)) : null,
								cleared: action.payload.cleared,
							};
						else return x;
					}),
				],
			});
		case 'CHECK_REMITTANCE_TOGGLE_ALL':
			return Object.assign({}, state, {
				...state,
				checkedAll: !state.checkedAll,
			});
			break;

		default:
			return state;
			break;
	}
};

export default checkReducer;
