import React, { useEffect, useState, useRef } from 'react';
import DynamicSurveyFields from './dynSurveyFields';
import axios from 'axios';
import { env } from '../../configs/env';
import { finalize } from '../../services/surveyServices';
import { saveDynamicDataFields } from '../../actions/utilityActions';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/userActions';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import './survey.css';

const Survey = props => {
	const [state, setState] = useState({});
	const user = useSelector(state => state.userReducer);
	const dispatch = useDispatch();

	const firstGroup = useRef(null);
	const secondGroup = useRef(null);
	const textInput = useRef(null);

	const toast = useRef(null);

	useEffect(() => {
		document.title = 'BabySteps - Anketa';
	});

	const reject = () => {
		toast.current.show({ severity: 'warn', summary: 'Odbijeno', detail: 'Anketa nije predana', life: 3000 });
	};

	const sendEmail = value => {
		if (user.user.KeyId) {
			let formData = new FormData();
			formData.append('to', user.user.KeyId);
			formData.append('bcc', 'emir@softweare.ba');

			formData.append('subject', 'Prijava korupcije');

			var body = 'Zaprimljena prijava o korupciji<br><br>Tekst prijave: <br><i>' + value + '</i><br><br><br>BabySteps';
			formData.append('body', body);

			// documents.forEach(file => {
			// 	formData.append('file', new Blob([file], { type: file.type }), file.name);
			// });

			return axios.post(env.SERVER_ENV.url + '/api/email/code', formData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			});
		}
	};

	const handleOnSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		if (form.checkValidity() === false || firstGroup.current.validate() === false) {
			e.stopPropagation();
		} else {
			setState({ ...state, validated: true });
			confirmDialog({
				acceptLabel: 'Da',
				rejectLabel: 'Ne',
				message: 'Potvrdite predaju ankete?',
				header: 'Predaj anketu',
				icon: 'pi pi-question-circle',
				accept,
				reject,
			});
		}
	};

	const accept = () => {
		var dynamicData = [];
		if (firstGroup.current) dynamicData = [...dynamicData, ...firstGroup.current.getData()];
		if (secondGroup.current) dynamicData = [...dynamicData, ...secondGroup.current.getData()];
		if (textInput.current) {
			let inputData = textInput.current.getData();

			if (inputData[0] && inputData[0].value !== null) sendEmail(inputData[0].value);
			dynamicData = [...dynamicData, ...inputData];
		}

		dynamicData = dynamicData.filter(x => x);
		if (dynamicData.length > 0) dispatch(saveDynamicDataFields('survey', dynamicData));

		finalize(user.user.KeyId);
		dispatch(logout());
	};

	return (
		<div className='container formgrid grid' style={{ paddingTop: '20px', maxWidth: '100vw' }}>
			<Toast ref={toast} />
			<div className='field col'>
				<Panel header={'1. Grupa pitanja'}>
					<label className='mb-3 text-md font-italic'>Odaberite ocjenu koja najbolje opisuje Vaše iskustvo.</label>
					<DynamicSurveyFields
						ref={firstGroup}
						viewMode={false}
						tabName={'1. Grupa pitanja'}
						entityCode={'survey'}
						entityId={user.user.KeyId}
					/>
				</Panel>
			</div>
			<div className='field col'>
				<Panel header={'2. Grupa pitanja'}>
					<label className='mb-3 text-md font-italic'>Odaberite opciju koja najbolje opisuje Vaše iskustvo.</label>
					<DynamicSurveyFields
						ref={secondGroup}
						viewMode={false}
						tabName={'2. Grupa pitanja'}
						entityCode={'survey'}
						entityId={user.user.KeyId}
						columnsForField={12}
					/>
				</Panel>
			</div>
			<div className='field w-full p-2'>
				<Panel header={'3. Prijava korupcije'}>
					<label className='mb-3 text-md font-italic'>
						Želite anonimno prijaviti korupciju koju ste doživjeli u porodilištu? Opišite detalje događaja, uz navođenje
						imena zdravstvenog radnika i godinu poroda. Ukoliko ne znate ime, možete navesti datum i okvirno vrijeme
						kada se događaj desio. Prijava je anonimna i bit će proslijeđena nadležnima. Ukoliko ne želite prijaviti
						korupciju, već samo anonimno podijeliti iskustvo - isto možete uraditi putem linka{' '}
						<a href='https://babysteps.ba/iskustva/podijeli-svoje-iskustvo/' target='_blank'>
							babysteps.ba/iskustva/podijeli-svoje-iskustvo
						</a>
						.
					</label>
					<DynamicSurveyFields
						ref={textInput}
						viewMode={false}
						tabName={'3. Grupa pitanja'}
						entityCode={'survey'}
						entityId={user.user.KeyId}
						columnsForField={12}
					/>
					<div className='flex justify-content-end'>
						<Button
							type='submit'
							form='survey'
							onClick={handleOnSubmit}
							className='p-button-sm'
							label='Pošalji anketu'
							aria-label='Submit'
						/>
					</div>
				</Panel>
			</div>
			<ConfirmDialog />
		</div>
	);
};

export default Survey;
