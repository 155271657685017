import moment from 'moment';
import { calculate, generateColumnTotals, generateRowTotals } from '../components/cases/judgement/judgementUtility';

const initialState = {
	judgement: {
		judgementGroupCasesOpen: [],
		judgementGroupCasesTaken: [],
		interestReferentAmountField: null,
		judgementType: null,
		interestReferentDateField: null,
		attorneyFeeLimit: { value: 5, label: '20%' },
		interestPercentage: 2,
	},
};

const headerFees = {
	statuteCost: 20,
	marshalPoundage: 50,
};

const judgementReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'JUDGEMENT_CREATE_FULFILLED':
		case 'JUDGEMENT_GET_FULFILLED':
			return Object.assign({}, state, {
				judgement: Object.assign({}, state.judgement, {
					...initialState.judgement,
					...action.payload.data,
				}),
			});
			break;
		case 'JUDGEMENT_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				judgement: Object.assign({}, state.judgement, {
					...state.judgement,
					judgementGroupCasesTaken: state.judgement.judgementGroupCasesTaken.map(x =>
						x.id === action.payload.data.id ? { ...x, ...action.payload.data } : x
					),
				}),
			});
			break;
		case 'JUDGEMENT_CLEAR':
			return Object.assign({}, state, {
				judgement: {
					judgementGroupCasesOpen: [],
					judgementGroupCasesTaken: [],
					interestReferentAmountField: null,
					judgementType: null,
					interestReferentDateField: null,
					attorneyFeeLimit: { value: 5, label: '20%' },
					interestPercentage: 2,
				},
			});
			break;
		case 'JUDGEMENT_SET_STATE':
			return Object.assign({}, state, {
				judgement: { ...action.payload },
			});
			break;
		case 'JUDGEMENT_GET_GROUP_CASES_FULFILLED':
			let judgementGroupCasesTaken = action.payload.data.judgementGroupCases
				.map(x => {
					if (x.isTaken)
						return { ...x, interestAccrued: x.interestAmount, interestPercentage: 2, totals: generateRowTotals(x) };
				})
				.filter(x => x);

			var index = 0;
			let judgementGroupCasesOpen = action.payload.data.judgementGroupCases
				.map(x => {
					if (!x.isTaken) {
						var fees = index++ === 0 ? headerFees : { statuteCost: 0, marshalPoundage: 0 };
						return {
							...x,
							toInterestDate: moment(),
							interestAccrued: 0,
							judgementPercentage: 100,
							interestPercentage: 2,
							isActive: true,
							additionalAttorneyFees: 0,
							attorneyFees: 0,
							interestDiscountPercentage: 100,
							...fees,
							totals: generateRowTotals(x),
						};
					}
				})
				.filter(x => x);

			if (state.judgement.caseId === action.payload.data.caseId)
				return Object.assign({}, state, {
					judgement: Object.assign({}, state.judgement, {
						...state.judgement,
						judgementGroupCasesTaken,
						judgementGroupCasesOpen,
						rowTotalsOpen: generateColumnTotals(judgementGroupCasesOpen),
						rowTotalsTaken: generateColumnTotals(judgementGroupCasesTaken, true),
					}),
				});
			break;
		case 'JUDGEMENT_CASE_DELETE_FULFILLED':
			var removeIndex = state.judgement.judgementGroupCasesTaken
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			var elements = state.judgement.judgementGroupCasesTaken.splice(removeIndex, 1);
			elements.id = 0;

			judgementGroupCasesTaken =
				state.judgement.judgementGroupCasesTaken && state.judgement.judgementGroupCasesTaken.length
					? [...state.judgement.judgementGroupCasesTaken]
					: [];
			judgementGroupCasesOpen =
				state.judgement.judgementGroupCasesOpen && state.judgement.judgementGroupCasesOpen.length > 0
					? [...state.judgement.judgementGroupCasesOpen, ...elements]
					: [...elements];

			return Object.assign({}, state, {
				judgement: Object.assign({}, state.judgement, {
					...state.judgement,
					judgementGroupCasesTaken,
					judgementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(judgementGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(judgementGroupCasesTaken, true),
				}),
			});
			break;
		case 'JUDGEMENT_CASE_UPDATE_VALUE':
			judgementGroupCasesOpen = state.judgement.judgementGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId
					? { ...x, totals: generateRowTotals(x) }
					: { ...action.payload, totals: generateRowTotals(action.payload) }
			);

			return Object.assign({}, state, {
				...state,
				judgement: {
					...state.judgement,
					judgementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(judgementGroupCasesOpen),
				},
			});
			break;
		case 'JUDGEMENT_CASE_TOGGLE':
			judgementGroupCasesOpen = state.judgement.judgementGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId ? x : { ...action.payload, isActive: !action.payload.isActive }
			);

			return Object.assign({}, state, {
				...state,
				judgement: {
					...state.judgement,
					judgementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(judgementGroupCasesOpen),
				},
			});
			break;
		case 'JUDGEMENT_CASE_TOGGLE_ALL':
			judgementGroupCasesOpen = state.judgement.judgementGroupCasesOpen.map(x => {
				return { ...x, isActive: action.payload };
			});
			return Object.assign({}, state, {
				...state,
				judgement: {
					...state.judgement,
					judgementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(judgementGroupCasesOpen),
				},
			});
			break;
		case 'JUDGEMENT_CASE_RECALCULATE':
			judgementGroupCasesOpen = [...calculate(action.payload)];
			return Object.assign({}, state, {
				...state,
				judgement: {
					...state.judgement,
					judgementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(judgementGroupCasesOpen),
				},
			});
			break;
		case 'JUDGEMENT_GET_GROUP_CASES_FULFILLED':
			if (state.judgement.caseId === action.payload.data.caseId)
				return Object.assign({}, state, {
					judgement: Object.assign({}, state.judgement, {
						...state.judgement,
						judgementGroupCases: action.payload.data.judgementGroupCases.map(x => {
							return { ...x, interestAccrued: x.interestAmount };
						}),
					}),
				});
			break;
		default:
			return state;
			break;
	}
};

export default judgementReducer;
