import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, NavLink } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { connect, useDispatch } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
import { getAllModules, getReportGroups, getUserPermissions } from '../../actions/moduleActions';
import { getUserReports } from '../../actions/reportActions';
import { logout } from '../../actions/userActions';
import './navigation.css';
import './menuBarButton.css';

import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import BabyStepsImage from './babysteps-logo.png';

const innerLinkStyle = { color: '#212529', padding: '.25rem 1.5rem' };

const Navigation = props => {
	const [state, setState] = useState({
		items: [],
		reportGroups: [],
	});

	const [hideMenuButton, setHideMenuButton] = useState('');
	const profileItems = [
		{
			label: 'Settings',
			icon: 'pi pi-external-link',
			template: (item, options) => {
				return (
					<NavLink as={Link} to='/user-profile'>
						Profil
					</NavLink>
				);
			},
		},
		{
			separator: true,
		},
		{
			label: 'Log Out',
			icon: 'pi pi-sign-out',
			template: (item, options) => {
				return (
					<NavLink onClick={() => dispatch(props.logout)}>
						<FiLogOut /> Odjavi se
					</NavLink>
				);
			},
		},
	];

	const dispatch = useDispatch();

	useEffect(() => {
		const { reportGroups } = props.moduleState;
		if (reportGroups.length === 0) dispatch(getReportGroups());
	}, []);

	useEffect(() => {
		setState({ ...state, reportGroups: props.moduleState.reportGroups });
	}, [props.reportGroups]);

	useEffect(() => {
		window.location.pathname.includes('/login') ? setHideMenuButton(' login') : setHideMenuButton('');
	}, [props.user]);

	const menu = useRef(null);
	const toast = useRef(null);

	const start = (
		<div id='public-navigation'>
			<Image src={BabyStepsImage} alt='BabySteps' width='220px' />
			{props.user.user && props.user.user.sub != 'korisnik' && (
				<div>
					<NavLink className='menu-item inline-block' key={'dashboard'} as={Link} to={'/pocetna'}>
						O porodilištima
					</NavLink>
					<div class='vertical-line d-inline'></div>
				</div>
			)}
		</div>
	);
	const end = (
		<div>
			{props.user.user && !props.user.user.KeyId && (
				<>
					<Menu model={props.user.user ? profileItems : null} popup ref={menu} id='popup-menu' />
					<label id='lbl-logged'>Prijavljen kao: </label>
					<Button
						label={props.user.user ? props.user.user.username : null}
						icon='pi pi-user'
						className='justify-content-center'
						onClick={event => menu.current.toggle(event)}
						aria-controls='popup-menu'
						aria-haspopup
					/>
				</>
			)}
		</div>
	);

	const getModuleNavigation = (items, reports, caseMenu) => {
		return items
			.map(item => {
				if (item.relativePath !== '#' && item.parentId === 0 && item.isCaseMenu === caseMenu) {
					return {
						label: item.name,
						template: (x, options) => {
							return (
								<NavLink
									key={item.name}
									as={Link}
									to={item.isCaseMenu ? item.relativePath + '/' + props.case.id : item.relativePath}>
									{item.name}
								</NavLink>
							);
						},
					};
				} else if (item.relativePath === '#' && item.parentId === 0 && item.isCaseMenu === caseMenu) {
					const tmpItems = getInnerItems(items, item.id, 1).filter(x => x && x !== null);
					var innerItems = [...tmpItems];

					if (item.name === 'Izvještaji') {
						const tmpReports = getReportItems(reports, item).filter(x => x && x !== null);
						innerItems = [...tmpItems, ...tmpReports];
					}
					if (!innerItems.length) return null;

					return {
						label: item.name,
						items: innerItems,
					};
				}
			})
			.filter(x => x && x !== null);
	};

	const getReportItems = (reports, item) => {
		var reportList = [
			...reports
				.map(report => {
					let toLink = report.relativePath + '/' + report.id;
					if (report.parentId == 1 && state.reportGroups.length > 1)
						return {
							template: (x, options) => {
								return (
									<NavLink key={report.name} as={Link} style={innerLinkStyle} to={toLink}>
										{report.name}
									</NavLink>
								);
							},
						};
				})
				.filter(x => x && x !== null),

			...(state.reportGroups.length <= 1
				? reports
						.map(report => {
							let toLink = report.relativePath + '/' + report.id;
							return {
								template: (x, options) => {
									return (
										<NavLink key={report.name} as={Link} to={toLink}>
											{report.name}
										</NavLink>
									);
								},
							};
						})
						.filter(x => x && x !== null)
				: state.reportGroups
						.map(group => {
							if (group.name !== 'Izvještaji' && group.parentId === 1) {
								const tmpGroups = state.reportGroups.filter(x => x.name !== 'Izvještaji' && x.parentId !== 1);
								const innerReports = getInnerReports([...reports, ...tmpGroups], group.id, 1);
								return {
									label: group.name,
									items: innerReports,
								};
							}
						})
						.filter(x => x && x !== null)),
		];
		return reportList;
	};

	const getInnerReports = (items, parentId, depth) => {
		return items.map(item => {
			if (item.parentId === parentId || item.parent === parentId) {
				if (item.relativePath !== '#') {
					let toLink = item.relativePath;
					if (item.isReport) toLink += '/' + item.id;
					return {
						label: item.name,
						template: (x, options) => {
							return (
								<NavLink key={item.name} as={Link} to={toLink}>
									{item.name}
								</NavLink>
							);
						},
					};
				} else {
					const tmpItems = getInnerReports(items, item.id, depth + 1);
					const innerItems = tmpItems.filter(x => x && x !== null);
					if (!innerItems.length) return null;
					return {
						label: item.name,
						items: [innerItems],
					};
				}
			}
		});
	};

	const getInnerItems = (items, parentId, depth) => {
		return items.map(item => {
			if (item.parentId === parentId) {
				if (item.relativePath !== '#') {
					if (depth === 1 && !item.isReport) {
						return {
							label: item.name,
							template: (x, options) => {
								return (
									<NavLink
										key={item.name}
										as={Link}
										to={item.isCaseMenu ? item.relativePath + '/' + props.case.id : item.relativePath}>
										{item.name}
									</NavLink>
								);
							},
						};
					} else {
						let toLink = item.relativePath;
						if (item.isReport) toLink += '/' + item.id;
						else if (item.isCaseMenu) toLink += '/' + props.case.id;
						return {
							label: item.name,
							template: (x, options) => {
								return (
									<NavLink
										key={item.name}
										as={Link}
										to={item.isCaseMenu ? item.relativePath + '/' + props.case.id : item.relativePath}>
										{item.name}
									</NavLink>
								);
							},
						};
					}
				} else {
					const tmpItems = getInnerItems(items, item.id, depth + 1);
					const innerItems = tmpItems.filter(x => x && x !== null);
					const asItem = item.hasChildren ? Dropdown.Item : NavLink;
					if (!innerItems.length) return null;
					return {
						label: item.name,
						template: (x, options) => {
							return (
								<NavLink
									key={item.name}
									as={Link}
									to={item.isCaseMenu ? item.relativePath + '/' + props.case.id : item.relativePath}>
									{item.name}
								</NavLink>
							);
						},
					};
				}
			}
		});
	};

	return (
		<div key={props.user} className={'navigation' + hideMenuButton}>
			<Toast ref={toast}></Toast>
			<Menubar
				className={'bg-primary border-noround border-primary'}
				model={
					props.user.user && props.moduleState.navModules && props.moduleState.reportModules
						? getModuleNavigation([...props.moduleState.navModules], [...props.moduleState.reportModules], false)
						: null
				}
				start={start}
				end={
					props.user.user ? (
						end
					) : (
						<></>
						// <NavLink className={'menu-item'} key={'login'} as={Link} to={'/login'}>
						// 	Prijavi se
						// </NavLink>
					)
				}
			/>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => {
			dispatch(logout());
		},
		getAllModules: (userId, moduleId) => {
			dispatch(getAllModules(userId, moduleId));
		},
		getUserPermissions: () => {
			dispatch(getUserPermissions());
		},
		getUserReports: () => {
			dispatch(getUserReports());
		},
		getReportGroups: () => {
			dispatch(getReportGroups());
		},
	};
};

const mapStateToProps = state => {
	return {
		user: state.userReducer,
		moduleState: state.moduleReducer,
		case: state.caseReducer.case,
		reportState: state.reportReducer,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
