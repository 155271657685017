import axios from 'axios';
import { env } from '../../configs/env';
import React, { useEffect, useState } from 'react';
import SelectField from '../dynamicFields/selectField';
import { Button, Col, Modal, ModalFooter, Card, Row, Form, ProgressBar } from 'react-bootstrap';
import { MdSend, MdError, MdDone } from 'react-icons/md';
import ReactQuill from 'react-quill';
import * as service from '../../services/emailTemplateService';
import 'react-quill/dist/quill.snow.css';
import './email.css';
import { setCharacterSpacing } from 'pdf-lib';

const EmailModal = props => {
	const title = 'Send Email';
	const [documents, setDocuments] = useState([]);

	const [state, setState] = useState({});
	const [chosenTemplate, setChosenTemplate] = useState({});
	const [body, setBody] = useState('');
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [emailLoading, setEmailLoading] = useState(false);
	const [templateCount, setTemplateCount] = useState();

	useEffect(() => {
		if (!props.templateSelectShow) {
			setState(props.data);
			setBody(props.data.body);
		}
		else {
			const req = { page: 1, pageSize: 10 };
			service.getEmailTemplates(req)
				.then(res => {
						setTemplateCount(res.data.dataSize);
						console.log("countTemp", templateCount)
					})
					.catch(e => {
						console.log('ErrorCount - ', e);
					});
		}
	}, [props.data]);

	useEffect(() => {
		console.log("props",props.templateSelectShow)
		console.log("chosenTemplate", chosenTemplate)

		if (props.templateSelectShow) {
			setState(chosenTemplate);
			setBody(chosenTemplate.body);
			if(Object.keys(chosenTemplate)?.length)
			PreviewEmail();
		}
	}, [chosenTemplate]);

	var fonts = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
	// generate code friendly names
	function getFontName(font) {
		return font.toLowerCase().replace(/\s/g, '-');
	}
	var fontNames = fonts.map(font => getFontName(font));
	// add fonts to style
	var fontStyles = '';
	fonts.forEach(function (font) {
		var fontName = getFontName(font);
		fontStyles +=
			'.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=' +
			fontName +
			']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=' +
			fontName +
			']::before {' +
			"content: '" +
			font +
			"';" +
			"font-family: '" +
			font +
			"', sans-serif;" +
			'}' +
			'.ql-font-' +
			fontName +
			'{' +
			" font-family: '" +
			font +
			"', sans-serif;" +
			'}';
	});
	var node = document.createElement('style');
	node.innerHTML = fontStyles;
	document.body.appendChild(node);

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline'],
			['strike', 'blockquote'],
			[{ font: fontNames }],
			[{ color: [] }, { background: [] }],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ indent: '-1' }, { indent: '+1' }],
			['link'],
			['clean'],
		],
	};

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'color',
		'background',
		'font',
	];

	var Font = ReactQuill.Quill.import('formats/font');
	Font.whitelist = fontNames;
	ReactQuill.Quill.register(Font, true);

	const handleOnChange = e => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const onChangeTemplate = e => {
		service
			.getEmailTemplateById(e.value)
			.then(res => {
				setChosenTemplate(res.data);
			})
			.catch(e => {
				console.log('Error - ', e);
			});
	};

	const handleDocuments = async e => {
		e.preventDefault();
		let files = e.target.files;

		if (files && files.length > 0) {
			setDocuments(files);
		}
	};

	const parseEmail = input =>
		input && input.length > 0
			? input
					.replace(/\r?\n|\r/g, '')
					.split(',')
					.map(x => x.trim())
			: [];

	const emailValidation = () => {
		const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		var emailList = [...parseEmail(state.toField), ...parseEmail(state.ccField), ...parseEmail(state.bccField)];
		var valid = true;

		emailList.forEach(element => {
			if (!regex.test(element)) {
				console.log(element);
				valid = false;
			}
		});

		return valid;
	};

	const sendEmail = () => {
		let formData = new FormData();

		var emailTo = parseEmail(state.toField);
		var emailCc = parseEmail(state.ccField);
		var emailBcc = parseEmail(state.bccField);

		emailTo.forEach(x => {
			formData.append('to', x);
		});
		emailCc.forEach(x => {
			formData.append('cc', x);
		});
		emailBcc.forEach(x => {
			formData.append('bcc', x);
		});

		formData.append('subject', state.subject);
		formData.append('body', body);

		documents.forEach(file => {
			formData.append('file', new Blob([file], { type: file.type }), file.name);
		});

		return axios.post(env.SERVER_ENV.url + '/api/email', formData, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	};

	const PreviewEmail = () => {
		if(body != '')
			setEmailLoading(true);
		const req = {
			templateId: chosenTemplate.id,
			body: chosenTemplate.body,
			caseId: props.caseId,
		};
		service
			.getEmailBodyWithoutTags(req)
			.then(res => {
				setBody(res.data.body);
				setEmailLoading(false);
			})
			.catch(e => {
				console.log('Error - ', e);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();

		setLoading(true);

		const form = e.currentTarget;
		if (!form.checkValidity() || !emailValidation()) {
			e.stopPropagation();
			setState({ ...state, validationError: 'E-mail format not correct!' });
		} else {
			setState({ ...state, validationError: '' });
			setValidated(true);

			sendEmail()
				.then(response => {
					console.log(response);
					setState({ ...state, sendingError: 'Sent' });
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					setState({ ...state, sendingError: 'E-mail not sent' });
				});
		}
	};

	return (
		<Modal className={'custom-modal'} show={props.show} onHide={props.onHide}>
			<Modal.Header>
				<h5>{title}</h5>
				{state.sendingError && state.sendingError.length > 0 && (
					<div>
						{state.sendingError !== 'Sent' ? (
							<MdError style={{ verticalAlign: 'baseline' }} className='mr-1 text-danger' />
						) : (
							<MdSend style={{ verticalAlign: 'baseline' }} className='mr-1 text-success' />
						)}
						<strong
							style={{ verticalAlign: 'text-bottom' }}
							className={state.sendingError !== 'Sent' ? 'text-danger' : 'text-success'}>
							{state.sendingError}
						</strong>
					</div>
				)}
			</Modal.Header>
			<Modal.Body>
				{loading && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
				{emailLoading && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
				{state.validationError && state.validationError.length > 0 && (
					<p className='text-danger'>{state.validationError}</p>
				)}
				<Form id='email' onSubmit={handleSubmit} validated={validated}>
					<Row className='m-0'>
						<Col className='py-0 pr-1 pl-0'>
							{props.templateSelectShow && (
								<Form.Group as={Row} className='mb-1 p-0'>
									<Col sm='2'>
										<Form.Label>Template:</Form.Label>
									</Col>
									<Col>
										<SelectField
											isMulti={false}
											name='Email Template'
											onChange={onChangeTemplate}
											viewMode={false}
											dataTable='eEmailTemplates'
											dataField='name'
										/>
									</Col>
								</Form.Group>
							)}
							<Form.Group as={Row} className='mb-1 p-0'>
								<Col sm='2'>
									<Form.Label>To:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										size='sm'
										type='text'
										placeholder='name@example.com'
										name='toField'
										required
										value={state.toField}
										onChange={handleOnChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='mb-1 p-0'>
								<Col sm='2'>
									<Form.Label>CC:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										size='sm'
										type='text'
										placeholder='name@example.com'
										name='ccField'
										value={state.ccField}
										onChange={handleOnChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='mb-1 p-0'>
								<Col sm='2'>
									<Form.Label>BCC:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										size='sm'
										type='text'
										name='bccField'
										placeholder='name@example.com'
										value={state.bccField}
										onChange={handleOnChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='mt-4 mx-0'>
								<Form.Label className='mb-1 mx-0' style={{ width: '100%' }}>
									Attached documents:
								</Form.Label>
								<Form.Control
									type='file'
									style={{ width: 'fit-content' }}
									size='sm'
									multiple
									onChange={handleDocuments}
								/>
							</Form.Group>
						</Col>
						<Col className='py-0 pr-0 pl-1'>
							<Form.Group as={Row} key={chosenTemplate.id} className='mb-1 p-0'>
								<Col sm='2'>
									<Form.Label>Subject: </Form.Label>
								</Col>
								<Col>
									<Form.Control
										size='sm'
										type='text'
										name='subject'
										required
										value={props.templateSelectShow ? chosenTemplate.subject : state.subject}
										onChange={handleOnChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='mb-1 p-0'>
								<Col className='mt-1'>
									<Form.Label>Body: </Form.Label>
									<Card className='mt-1'>
										<Card.Body className='p-0'>
											<ReactQuill
												theme='snow'
												modules={modules}
												formats={formats}
												value={body}
												onChange={e => setBody(e)}
											/>
										</Card.Body>
									</Card>
								</Col>
							</Form.Group>
						</Col>
					</Row>
				</Form>
				{loading && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
			</Modal.Body>
			<ModalFooter>
				<div className='float-right'>
					{console.log("temCount", templateCount)}
					<Button disabled={(props.templateSelectShow && templateCount<1) ? true : false} className='mr-2' size='sm' variant='primary' type='submit' form='email'>
						Send
					</Button>
					<Button
						size='sm'
						variant='secondary'
						onClick={() => {
							props.closeModal();
							setChosenTemplate({});
							setBody('');
							setValidated(false);
						}}>
						Close
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
};
export default EmailModal;
