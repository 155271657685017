import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

export default class DateInput extends React.Component {
  static defaultProps = {
    value: 0.0,
  };
  state = {
    value: this.props.value,
  };
  getValue() {
    return this.state.value;
  }

  handleKeyDown = (e) => {
    const { onEscape } = this.props;
    if (e.keyCode === 27) {
      // ESC
      this.props.onUpdate(this.state.value);
    } else if (e.keyCode === 13) {
      // ENTER
      this.props.onUpdate(this.state.value);
    }
  };

  render() {
    const {
      value,
      onUpdate,
      minDate,
      maxDate,
      showTimeSelect,
      isClearable,
      ...rest
    } = this.props;
    return (
      <DatePicker
        selected={
          value
            ? moment.utc(moment.utc(value).local().format("L LT")).toDate()
            : null
        }
        value={this.state.value}
        autoComplete={"off"}
        onChange={(date) => {
          this.setState({ value: date });
          onUpdate(date);
        }}
        isClearable={isClearable === null ? true : isClearable}
        showMonthDropdown
        showYearDropdown
        showTimeSelect={showTimeSelect}
        dropdownMode="select"
        className="form-control form-control-sm"
        style={{ width: "inherit" }}
        dateFormat={showTimeSelect ? "MM/dd/yyyy HH:mm a" : "MM/dd/yyyy"}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={() => onUpdate(this.state.value)}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}
