const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  userGroups: [], 
};

const userGroupReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "UG_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        userGroups: [...action.payload.data],
      });
      break;
    case "UG_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        userGroups: [...action.payload.data.userGroups],
      });
      break;
    case "UG_CREATE_FULFILLED":
      if (state.userGroups.length === state.pageSize)
        state.userGroups.splice(state.userGroups.length - 1, 1);
      return Object.assign({}, state, {
        ...state,
        userGroups: [action.payload.data, ...state.userGroups],
      });
      break;
    case "UG_DELETE_FULFILLED":
      var removeIndex = state.userGroups
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.users.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        userGroups: [...state.userGroups],
      });
      break;
    case "UG_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        userGroups: state.userGroups.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        }),
      });
      break;
    default:
      return state;
      break;
  }
};

export default userGroupReducer;
