import moment from 'moment';
import * as utility from '../utility';

export const getReferentAmount = (referentField, row, groupArbAwardAmount) => {
	switch (referentField.label) {
		case 'Balance Amount':
			return row.balanceAmount;
		case 'ARB Award Amount':
			return groupArbAwardAmount;
		default:
			break;
	}
};

export const getFromInterestDate = (referentDateFieldId, row) => {
	switch (referentDateFieldId) {
		case 1:
			return row.pomDate30;
		case 2:
			return row.dateIndexPurchased;
		case 3:
			return row.dateInsurerServed;
		case 4:
			return row.dateAR1Merged;
		case 5:
			return row.interestReferentDate;
		default:
			break;
	}
};

export const getPassedDays = arbAward => utility.calculatePassedDays(arbAward, getFromInterestDate);

export const getCollectiveArbAwardAmount = (arbAwardAmount, arbAwardGroupCasesOpen) =>
	arbAwardAmount / arbAwardGroupCasesOpen.filter(x => x.isActive).length;

export const calculate = arbAward => {
	const groupArbAwardAmount = getCollectiveArbAwardAmount(arbAward.arbAwardAmount, arbAward.arbAwardGroupCasesOpen);

	let openCases = arbAward.arbAwardGroupCasesOpen.map(x => {
		if (!x.isActive) return { ...x, arbAwardPercentage: 0, arbAwardAmount: 0, attorneyFees: 0, interestAccrued: 0 };

		x.arbAwardAmount = arbAward.arbAwardAmount;
		x.interestReferentDate = arbAward.referentDate;

		let balanceAmount = x.balanceAmount > 0 ? x.balanceAmount : 0;
		let arbAwardAmount = groupArbAwardAmount;

		// let arbAwardAmount = getReferentAmount(arbAward.interestReferentAmountField, x);

		if (arbAward.defaultArbAwardPercentage && arbAward.defaultArbAwardPercentage > 0)
			arbAwardAmount = utility.applyEntityPercentage(arbAward.defaultArbAwardPercentage, balanceAmount);
		// arbAwardAmount = utility.applyEntityPercentage(arbAward.defaultArbAwardPercentage, arbAwardAmount);
		if (arbAwardAmount < 0) arbAwardAmount = 0;

		const interestReferentAmount = getReferentAmount(arbAward.interestReferentAmountField, x, arbAwardAmount);

		// let arbAwardPercentage = utility.getEntityPercentage(arbAwardAmount, balanceAmount);

		let arbAwardPercentage =
			arbAward.defaultArbAwardPercentage && parseFloat(arbAward.defaultArbAwardPercentage) > 0
				? parseFloat(arbAward.defaultArbAwardPercentage)
				: utility.getEntityPercentage(parseFloat(arbAwardAmount), balanceAmount);

		let fromInterestDate = getFromInterestDate(arbAward.interestReferentDateFieldId, x);
		fromInterestDate = fromInterestDate ? moment(fromInterestDate) : null;

		let daysBetween =
			fromInterestDate && x.toInterestDate && moment(fromInterestDate).isValid()
				? moment(x.toInterestDate).diff(fromInterestDate, 'days')
				: 0;

		// let interestAmount = utility.getInterestAmount(arbAwardAmount, daysBetween);
		let interestAmount = utility.getInterestAmount(interestReferentAmount, daysBetween);
		interestAmount = utility.applyInterestDiscount(interestAmount, arbAward.interestDiscountPercentage);

		return {
			...x,
			attorneyFees: utility.getAF(arbAwardAmount, interestAmount, arbAward.attorneyFeeLimit),
			interestAccrued: interestAmount,
			arbAwardAmount,
			arbAwardPercentage,
			interestReferentDateField: arbAward.interestReferentDateField ? arbAward.interestReferentDateField.label : null,
			interestReferentDateFieldId: arbAward.interestReferentDateField ? arbAward.interestReferentDateField.value : null,
			interestReferentDate: fromInterestDate,
			interestReferentAmountField: arbAward.interestReferentAmountField
				? arbAward.interestReferentAmountField.label
				: null,
			interestReferentAmountFieldId: arbAward.interestReferentAmountField
				? arbAward.interestReferentAmountField.value
				: null,
			settlingAttorneyName: arbAward.settlingAttorney ? arbAward.settlingAttorney.label : null,
			settlingAttorneyId: arbAward.settlingAttorney ? arbAward.settlingAttorney.value : null,
			attorneyFeeLimit: arbAward.attorneyFeeLimit ? arbAward.attorneyFeeLimit.label : '20%',
			attorneyFeeLimitId: arbAward.attorneyFeeLimit ? arbAward.attorneyFeeLimit.value : null,
			arbAwardType: arbAward.arbAwardType ? arbAward.arbAwardType.label : null,
			arbAwardTypeId: arbAward.arbAwardType ? arbAward.arbAwardType.value : null,
			arbAwardStatusId: arbAward.arbAwardStatus ? arbAward.arbAwardStatus.value : null,
			adjusterName: arbAward.adjuster ? arbAward.adjuster.label : null,
			adjusterId: arbAward.adjuster ? arbAward.adjuster.value : null,
			judgeId: arbAward.judge ? arbAward.judge.value : null,
			arbitratorId: arbAward.arbitrator ? arbAward.arbitrator.value : null,
			interestDiscountPercentage: arbAward.interestDiscountPercentage,
			interestPercentage: 2,
			fromInterestDate,
			toInterestDate: arbAward.toInterestDate,
		};
	});

	if (!arbAward.attorneyFeeLimit) return openCases;

	return utility.annealByGroup(openCases, arbAward.attorneyFeeLimit);
};

const columnSumFields = [
	'claimAmount',
	'collectedAmount',
	'balanceAmount',
	'arbAwardAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'marshalPoundage',
];

export const rowSumFields = [
	'arbAwardAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'marshalPoundage',
];

export const generateColumnTotals = (table, isTakenTable = false) =>
	utility.getColumnTotals(table, isTakenTable, columnSumFields);

export const generateRowTotals = row => utility.getRowTotals(row, rowSumFields);
