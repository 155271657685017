import axios from 'axios';
import { env } from '../configs/env';
import * as service from '../services/reportServices';

import store from '../store';
const { dispatch } = store;

export function getPage(request) {
	return {
		type: 'REPORT_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/reports/page', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function create(report) {
	return new Promise((resolve, reject) => {
		service
			.create(report)
			.then(result => {
				dispatch({
					type: 'REPORT_CREATE_FULFILLED',
					payload: result,
				});
				resolve(result);
			})
			.catch(err => {
				dispatch({
					type: 'REPORT_CREATE_REJECTED',
				});
				reject(err);
			});
	});
}

export function deleteReport(reportId, moduleId) {
	return {
		type: 'REPORT_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/reports/' + reportId + '/' + moduleId, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function update(reports) {
	return {
		type: 'REPORT_UPDATE',
		payload: axios(env.SERVER_ENV.url + '/api/reports', {
			method: 'PATCH',
			data: JSON.stringify(reports),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getAllReports(moduleId) {
	return {
		type: 'REPORT_GET_ALL',
		payload: axios(env.SERVER_ENV.url + '/api/reports/all/' + moduleId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getUserGroups(id) {
	return {
		type: 'REPORT_GET_UG',
		payload: axios(env.SERVER_ENV.url + '/api/reports/userGroups/' + id, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function createParameter(reportParameter) {
	return {
		type: 'REPORT_PARAMETER_CREATE',
		payload: axios(env.SERVER_ENV.url + '/api/reports/parameters', {
			method: 'POST',
			data: JSON.stringify(reportParameter),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function updateParameter(request) {
	return {
		type: 'REPORT_PARAMETER_UPDATE',
		payload: axios(env.SERVER_ENV.url + '/api/reports/parameters', {
			method: 'PATCH',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getParameters(reportId, moduleId) {
	return {
		type: 'REPORT_PARAMETER_GET',
		payload: axios(env.SERVER_ENV.url + '/api/reports/parameters/' + reportId + '/' + moduleId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function deleteParameter(parameterId, moduleId) {
	return {
		type: 'REPORT_PARAMETER_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/reports/parameters/' + parameterId + '/' + moduleId, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getGenericPage(request) {
	return {
		type: 'REPORT_GENERIC_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/reports/generic/page', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getUserReports() {
	return {
		type: 'REPORT_GET_BY_USER',
		payload: axios(env.SERVER_ENV.url + '/api/reports/user/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getExcludedColumns(reportId) {
	return {
		type: 'REPORT_GET_EXCLUDED_COLUMNS',
		payload: axios(env.SERVER_ENV.url + '/api/reports/excludedColumns/' + reportId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}
