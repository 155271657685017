import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import arbAwardReducer from './reducers/arbAwardReducer';
import automationReducer from './reducers/automationsReducer';
import bankAccountReducer from './reducers/bankAccountReducer';
import calendarEventsReducer from './reducers/calendarEventsReducer';
import calendarEventTypeReducer from './reducers/calendarEventTypeReducer';
import caseGroupReducer from './reducers/caseGroupReducer';
import caseReducer from './reducers/caseReducer';
import checkReducer from './reducers/checkReducer';
import codeTableReducer from './reducers/codeTableReducer';
import contactReducer from './reducers/contactReducer';
import costManagementReducer from './reducers/costManagementReducer';
import costTypeReducer from './reducers/costTypeReducer';
import decisionReducer from './reducers/decisionReducer';
import dmDefaultFolderReducer from './reducers/dmDefaultFoldersReducer';
import dynamicMassUpdateReducer from './reducers/dynamicMassUpdateReducer';
import fieldDefinitionReducer from './reducers/fieldDefinitionReducer';
import fileAssociationReducer from './reducers/fileAssociationReducer';
import firmReducer from './reducers/firmReducer';
import invoiceReducer from './reducers/invoiceReducer';
import judgementReducer from './reducers/judgementReducer';
import moduleReducer from './reducers/moduleReducer';
import noteReducer from './reducers/noteReducer';
import noteTypesReducer from './reducers/noteTypesReducer';
import partyReducer from './reducers/partyReducer';
import paymentTypeReducer from './reducers/paymentTypeReducer';
import penaltyManagementReducer from './reducers/penaltyManagementReducer';
import pendingReducer from './reducers/pendingReducer';
import portalReducer from './reducers/portalReducer';
import reportReducer from './reducers/reportReducer';
import settlementReducer from './reducers/settlementReducer';
import tasksReducer from './reducers/tasksReducer';
import taskSubtypeReducer from './reducers/taskSubtypeReducer';
import issuedChecksReducer from './reducers/issuedChecksReducer';
import taskTypeReducer from './reducers/taskTypeReducer';
import templateReducer from './reducers/templateReducer';
import userGroupReducer from './reducers/userGroupReducer';
import userReducer from './reducers/userReducer';
import utilityReducer from './reducers/utilityReducer';
import viewGroupReducer from './reducers/viewGroupReducer';

const composeEnhancers = composeWithDevTools({});

const enhancer = composeEnhancers(applyMiddleware(thunk, promise));

export default createStore(
	combineReducers({
		userReducer,
		contactReducer,
		userGroupReducer,
		firmReducer,
		moduleReducer,
		partyReducer,
		checkReducer,
		bankAccountReducer,
		paymentTypeReducer,
		caseReducer,
		utilityReducer,
		viewGroupReducer,
		fieldDefinitionReducer,
		codeTableReducer,
		noteReducer,
		costTypeReducer,
		templateReducer,
		pendingReducer,
		noteTypesReducer,
		settlementReducer,
		taskTypeReducer,
		decisionReducer,
		caseGroupReducer,
		judgementReducer,
		tasksReducer,
		reportReducer,
		invoiceReducer,
		taskSubtypeReducer,
		calendarEventsReducer,
		calendarEventTypeReducer,
		dmDefaultFolderReducer,
		portalReducer,
		automationReducer,
		fileAssociationReducer,
		costManagementReducer,
		issuedChecksReducer,
		arbAwardReducer,
		penaltyManagementReducer,
		dynamicMassUpdateReducer,
	}),
	enhancer
);
