import moment from 'moment';
import { calculate, generateColumnTotals, generateRowTotals } from '../components/cases/arbAward/arbAwardUtility';
import { filterDuplicates } from '../components/utility';

const initialState = {
	arbAward: {
		caseId: -1,
		arbAwardType: null,
		interestReferentDateField: null,
		interestReferentAmountField: null,
		attorneyFeeLimit: { value: 5, label: '20%' },
		arbitrator: null,
		settlingAttorney: null,
		judge: null,
		interestPercentage: 2,
		defaultArbAwardPercentage: null,
		arbAwardPercentage: 100,
		interestDiscountPercentage: 100,
	},
};

const adjustDuplicates = (arr = [], elements = []) =>
	arr.filter(x => elements.findIndex(y => y.caseId === x.caseId) === -1);

const arbAwardReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'ARB_AWARD_CREATE_FULFILLED':
		case 'ARB_AWARD_UPDATE_FULFILLED':
		case 'ARB_AWARD_GET_FULFILLED':
			return Object.assign({}, state, {
				arbAward: Object.assign({}, state.arbAward, {
					...action.payload.data,
					arbAwardAmount: 0,
					interestReferentAmountField: null,
					arbAwardStatus: null,
					settlingAttorney: null,
					arbAwardType: null,
					interestReferentDateField: null,
					attorneyFeeLimit: null,
					attorneyFeeLimit: { value: 5, label: '20%' },
					interestPercentage: 2,
				}),
			});
			break;
		case 'ARB_AWARD_CLEAR':
			return Object.assign({}, state, {
				arbAward: {
					caseId: -1,
					arbAwardType: null,
					interestReferentDateField: null,
					interestReferentAmountField: null,
					attorneyFeeLimit: { value: 5, label: '20%' },
					arbitrator: null,
					settlingAttorney: null,
					judge: null,
					interestPercentage: 2,
					defaultArbAwardPercentage: null,
					arbAwardPercentage: 100,
					interestDiscountPercentage: 100,
				},
			});
			break;
		case 'ARB_AWARD_SET_STATE':
			var { arbAwardAmount } = action.payload;
			return Object.assign({}, state, {
				arbAward: {
					...state.arbAward,
					...action.payload,
					arbAwardAmount: arbAwardAmount ? parseFloat(arbAwardAmount) : parseFloat(state.arbAward.arbAwardAmount),
				},
			});
			break;
		case 'ARB_AWARD_GET_GROUP_CASES_FULFILLED':
			let arbAwardGroupCasesTaken = action.payload.data.arbAwardGroupCases
				.map(x => {
					if (x.isTaken) {
						return {
							...x,
							interestAccrued: x.interestAmount,
							interestPercentage: 2,
							interestDiscountPercentage: 100,
							totals: generateRowTotals(x),
						};
					}
				})
				.filter(x => x);

			let arbAwardGroupCasesOpen = action.payload.data.arbAwardGroupCases.map(x => ({
				...x,
				toInterestDate: moment(),
				interestAccrued: 0,
				arbAwardPercentage: 100,
				interestPercentage: 2,
				isActive: true,
				additionalAttorneyFees: 0,
				marshalPoundage: 0,
				attorneyFees: 0,
				interestDiscountPercentage: 100,
				totals: generateRowTotals(x),
			}));

			arbAwardGroupCasesOpen = filterDuplicates(arbAwardGroupCasesOpen, 'caseId');

			return Object.assign({}, state, {
				arbAward: Object.assign({}, state.arbAward, {
					...state.arbAward,
					toInterestDate: moment(),
					arbAwardGroupCasesTaken,
					arbAwardGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(arbAwardGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(arbAwardGroupCasesTaken, true),
				}),
			});
			break;
		case 'ARB_AWARD_CASE_DELETE_FULFILLED':
			var removeIndex = state.arbAward.arbAwardGroupCasesTaken
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			var elements = state.arbAward.arbAwardGroupCasesTaken.splice(removeIndex, 1);
			elements.id = 0;

			arbAwardGroupCasesTaken =
				state.arbAward.arbAwardGroupCasesTaken && state.arbAward.arbAwardGroupCasesTaken.length
					? [...state.arbAward.arbAwardGroupCasesTaken]
					: [];
			arbAwardGroupCasesOpen =
				state.arbAward.arbAwardGroupCasesOpen && state.arbAward.arbAwardGroupCasesOpen.length > 0
					? [...adjustDuplicates(state.arbAward.arbAwardGroupCasesOpen, elements), ...elements]
					: [...elements];

			return Object.assign({}, state, {
				arbAward: Object.assign({}, state.arbAward, {
					...state.arbAward,
					arbAwardGroupCasesTaken,
					arbAwardGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(arbAwardGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(arbAwardGroupCasesTaken, true),
				}),
			});
			break;
		case 'ARB_AWARD_CASE_UPDATE_VALUE':
			arbAwardGroupCasesOpen = state.arbAward.arbAwardGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId
					? { ...x, totals: generateRowTotals(x) }
					: {
							...action.payload,
							totals: generateRowTotals(action.payload),
					  }
			);
			return Object.assign({}, state, {
				...state,
				arbAward: {
					...state.arbAward,
					arbAwardGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(arbAwardGroupCasesOpen),
				},
			});
			break;
		case 'ARB_AWARD_CASE_TOGGLE':
			arbAwardGroupCasesOpen = state.arbAward.arbAwardGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId ? x : { ...action.payload, isActive: !action.payload.isActive }
			);

			return Object.assign({}, state, {
				...state,
				arbAward: {
					...state.arbAward,
					arbAwardGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(arbAwardGroupCasesOpen),
					shouldRecalculate: true,
				},
			});
			break;
		case 'ARB_AWARD_CASE_TOGGLE_ALL':
			arbAwardGroupCasesOpen = state.arbAward.arbAwardGroupCasesOpen.map(x => {
				return { ...x, isActive: action.payload };
			});

			return Object.assign({}, state, {
				...state,
				arbAward: {
					...state.arbAward,
					arbAwardGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(arbAwardGroupCasesOpen),
					shouldRecalculate: true,
				},
			});
			break;
		case 'ARB_AWARD_CASE_RECALCULATE':
			arbAwardGroupCasesOpen = [...calculate(action.payload)];
			return Object.assign({}, state, {
				...state,
				arbAward: {
					...state.arbAward,
					arbAwardGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(arbAwardGroupCasesOpen),
				},
			});
			break;
		default:
			return state;
			break;
	}
};

export default arbAwardReducer;
