import axios from 'axios';
import { env } from '../configs/env';
import * as service from '../services/caseServices';
import store from '../store';

const { dispatch } = store;

export function create(newCase) {
	return {
		type: 'CASE_CREATE',
		payload: axios(env.SERVER_ENV.url + '/api/case', {
			method: 'POST',
			data: JSON.stringify(newCase),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function _edit(newCase) {
	return new Promise(function (resolve, reject) {
		service
			.editCase(newCase)
			.then(result => {
				dispatch({
					type: 'CASE_EDIT_FULFILLED',
					payload: result,
				});
				resolve(result);
			})
			.catch(err => {
				dispatch({
					type: 'CASE_EDIT_REJECTED',
				});
				reject(err);
			});
	});
}

export function edit(newCase) {
	return {
		type: 'CASE_EDIT',
		payload: axios(env.SERVER_ENV.url + '/api/case', {
			method: 'PATCH',
			data: JSON.stringify(newCase),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function deleteCase(id, moduleId) {
	return {
		type: 'CASE_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/case/' + id + '/' + moduleId, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function deleteCaseBills(request) {
	return {
		type: 'CASEBILLS_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/case/bill', {
			method: 'DELETE',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function get(id) {
	return {
		type: 'CASE_GET',
		payload: axios(env.SERVER_ENV.url + '/api/case/' + id, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getDynamic(id) {
	return {
		type: 'CASE_DYNAMIC_GET',
		payload: axios(env.SERVER_ENV.url + '/api/case/dynamic/' + id, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getPage(request) {
	return {
		type: 'CASE_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/case/page', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getPageForPom(request) {
	return {
		type: 'CASE_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/case/page/pom', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getPageDataSize(request) {
	return {
		type: 'CASE_GET_DATASIZE',
		payload: axios(env.SERVER_ENV.url + '/api/case/page/datasize', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getPageDataSizeForPom(request) {
	return {
		type: 'CASE_GET_DATASIZE',
		payload: axios(env.SERVER_ENV.url + '/api/case/page/datasize/pom', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getCosts(caseId) {
	return {
		type: 'CASE_GET_COSTS',
		payload: axios(env.SERVER_ENV.url + '/api/case/costs/' + caseId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function updateCosts(request) {
	return {
		type: 'CASE_UPDATE_COSTS',
		payload: axios(env.SERVER_ENV.url + '/api/case/costs', {
			method: 'PATCH',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function addCost(cost) {
	return {
		type: 'CASE_ADD_COST',
		payload: cost,
	};
}

export function removeCost(id) {
	return {
		type: 'CASE_REMOVE_COST',
		payload: id,
	};
}

export function getPenalties(caseId) {
	return {
		type: 'CASE_GET_PENALTIES',
		payload: axios(env.SERVER_ENV.url + '/api/case/penalties/' + caseId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function updatePenalties(request) {
	return {
		type: 'CASE_UPDATE_PENALTIES',
		payload: axios(env.SERVER_ENV.url + '/api/case/penalties', {
			method: 'PATCH',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function addPenalty(cost) {
	return {
		type: 'CASE_ADD_PENALTY',
		payload: cost,
	};
}

export function removePenalty(id) {
	return {
		type: 'CASE_REMOVE_PENALTY',
		payload: id,
	};
}

export function resetNew() {
	return {
		type: 'CASE_RESET_NEW',
	};
}

export function clearCase() {
	return {
		type: 'CLEAR_CASE',
	};
}

export function createCaseGroup(cg) {
	return {
		type: 'CASE_GROUP_CREATE',
		payload: axios(env.SERVER_ENV.url + '/api/caseGroup', {
			method: 'POST',
			data: JSON.stringify(cg),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function updateCaseGroup(cg) {
	return {
		type: 'CASE_GROUP_EDIT',
		payload: axios(env.SERVER_ENV.url + '/api/caseGroup', {
			method: 'PATCH',
			data: JSON.stringify(cg),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function deleteCaseGroup(id) {
	return {
		type: 'CASE_GROUP_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/caseGroup/' + id, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getCaseGroupPage(request) {
	return {
		type: 'CASE_GROUP_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/caseGroup/page', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function setState(data) {
	return {
		type: 'CASE_GROUP_SET_STATE',
		payload: data,
	};
}

export function getCaseGroup(id) {
	return {
		type: 'CASE_GROUP_GET',
		payload: axios(env.SERVER_ENV.url + '/api/caseGroup/' + id, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function caseGroupUpdateCases(request) {
	return {
		type: 'CASE_GROUP_EDIT',
		payload: axios(env.SERVER_ENV.url + '/api/caseGroup/cases', {
			method: 'PATCH',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function setMassImportData(data) {
	return {
		type: 'CASE_MASS_IMPORT_GET',
		payload: data,
	};
}

export function toggleMassImportSwitch(row) {
	return {
		type: 'CASE_MASS_IMPORT_TOGGLE_SWITCH',
		payload: row,
	};
}

export function onCostUpdateValue(cost) {
	return {
		type: 'CASE_COST_UPDATE_VALUE',
		payload: cost,
	};
}

export function onPenaltyUpdateValue(cost) {
	return {
		type: 'CASE_COST_PENALTY_VALUE',
		payload: cost,
	};
}

export function massImport(request) {
	return new Promise(function (resolve, reject) {
		service
			.massImport(request)
			.then(result => {
				dispatch({
					type: 'CASE_MASS_IMPORT_FULFILLED',
					payload: result,
				});
				resolve(result);
			})
			.catch(err => {
				dispatch({
					type: 'CASE_MASS_IMPORT_REJECTED',
				});
				reject(err);
			});
	});
}

export function setInvalidRows(list) {
	return {
		type: 'CASE_MASS_IMPORT_SET_INVALID_ROWS',
		payload: list,
	};
}

export function unpackArhchivedBills(files) {
	return {
		type: 'CASE_MASS_BILL_IMPORT_UNPACK',
		payload: files,
	};
}

export function massBillImportValidate(request) {
	return {
		type: 'CASE_MASS_BILL_IMPORT_VALIDATE',
		payload: axios(env.SERVER_ENV.url + '/api/case/massBillImport/validate', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function toggleMassBillImportSwitch(row) {
	return {
		type: 'CASE_MASS_BILL_IMPORT_TOGGLE_SWITCH',
		payload: row,
	};
}

export function clearContents() {
	return {
		type: 'CASE_MASS_BILL_IMPORT_CLEAR',
	};
}

export function updateProgressBar(value, finished = false) {
	return {
		type: 'CASE_MASS_BILL_UPDATE_PROGRESS_BAR',
		payload: { value: value, finished: finished },
	};
}

export function disableToggle() {
	return {
		type: 'CASE_MASS_BILL_DISABLE_TOGGLE',
	};
}

export function massBillImportDM(zipFile, billCaseMapping, selectedRows, option) {
	return new Promise(function (resolve, reject) {
		service
			.massBillImportDM(zipFile, billCaseMapping, selectedRows, option)
			.then(result => {
				dispatch({
					type: 'CASE_MASS_BILL_IMPORT_FULFILLED',
					payload: result,
				});
				resolve(result);
			})
			.catch(err => {
				dispatch({
					type: 'CASE_MASS_BILL_IMPORT_REJECTED',
				});
				reject(err);
			});
	});
}

export function getAllMatterTypes(moduleId) {
	return {
		type: 'MATTER_TYPES_GET_ALL',
		payload: axios(env.SERVER_ENV.url + '/api/case/matterType/all/' + moduleId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getUserGroupPermissions(userGroupId, moduleId) {
	return {
		type: 'MATTER_TYPES_GET_BY_UG',
		payload: axios(env.SERVER_ENV.url + '/api/case/matterType/userGroup/' + userGroupId + '/' + moduleId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function togglePermission(dmDefaultFolderId, permissionType) {
	return {
		type: 'MATTER_TYPES_TOGGLE_PERMISSION',
		payload: { moduleId: dmDefaultFolderId, permissionType },
	};
}

export function updatePermissions(request) {
	return {
		type: 'MATTER_TYPES_UPDATE_PERMISSIONS',
		payload: axios(env.SERVER_ENV.url + '/api/case/matterType/permissions', {
			method: 'PATCH',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function saveTopLevelFieldsDynMassUpdate(data) {
	return {
		type: 'MASS_UPDATE_TOP_LEVEL_FIELDS',
		payload: axios(env.SERVER_ENV.url + '/api/case/massUpdateTopLevelFields', {
			method: 'POST',
			payload: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function toggleAllPermission() {
	return {
		type: 'MATTER_TYPES_TOGGLE_ALL_PERMISSION',
	};
}

export function toggleEditCost(id) {
	return {
		type: 'COST_TOGGLE_EDIT',
		payload: id,
	};
}

export function caseSetState(data) {
	return {
		type: 'CASE_SET_STATE',
		payload: data,
	};
}
