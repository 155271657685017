import moment from 'moment';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { MdLaunch } from 'react-icons/md';
import { connect } from 'react-redux';
import { saveDynamicDataFields } from '../../actions/utilityActions';
import { env } from '../../configs/env';
import { _escapeString } from '../../helpers/escapeString';
import { createCode } from '../../services/codeTableServices';
import { getDynamicFields, getDynamicSurveyDefinitionsByTab } from '../../services/surveyServices';
import { BasicField } from '../dynamicFields/basicField';
import { CheckboxField } from '../dynamicFields/checkboxField';
import CreatableSelectField from '../dynamicFields/creatableSelectField';
import { CurrencyField } from '../dynamicFields/currencyField';
import { DateField } from '../dynamicFields/dateField';
import SelectField from '../dynamicFields/selectField';
import { StarField } from '../dynamicFields/starField';
import * as utility from '../dynamicFields/utility';

import { Divider } from 'primereact/divider';

const initialState = {
	dynamicFields: null,
	dynamicFieldDefinitions: null,
	dynamicFieldsTmp: null,
	editedFields: {},
	isLoading: {},
	errors: [],
	validated: true,
};

class DynamicSurveyFields extends Component {
	constructor(props) {
		super(props);
	}

	state = { ...initialState };

	componentWillMount() {
		getDynamicSurveyDefinitionsByTab(this.props.tabName)
			.then(response => {
				this.setState({
					dynamicFieldDefinitions: response.data.fieldDefinitions,
				});

				if (this.props.keyId) {
					getDynamicFields({
						id: parseInt(this.props.keyId),
						tabName: this.props.tabName,
					}).then(result => {
						this.setState({
							dynamicFieldsTmp: result.data.dynamicFields,
						});
					});
				}
			})
			.catch(error => console.log(error));
	}

	setInitialState = () => this.setState({ ...initialState });

	componentWillUnmount() {
		this.state = { ...initialState };
	}

	onChange = e => {
		const { name, value } = e.target;
		this.setState(state => utility.getOnChangeObject(name, value, state));
	};

	handleOnChecboxChange = e => {
		const { name, value } = e.target;
		this.setState(state => utility.getOnCheckboxChangeObject(name, value, state));
	};

	handleOnDateChange = (date, name) => this.setState(state => utility.getOnDateChangeObject(date, name, state));

	handleOnSelect = (value, name) => this.setState(state => utility.getOnSelectObject(value, name, state));

	handleOnCurrencyChange = (values, name) =>
		this.setState(state => utility.getOnCurrencyChangeObject(values, name, state));

	handleOnCreateOption = (newValue, _, name, tableName, isMulti) => {
		this.setState({ isLoading: { ...this.state.isLoading, [name]: true } });
		createCode({
			name: _escapeString(newValue),
			tableName: tableName.substring(2),
			moduleId: this.props.currentModule.id,
		})
			.then(response => this.setState(state => utility.getOnCreatableSelectObject(response, name, state, isMulti)))
			.catch(() =>
				this.setState({
					isLoading: { ...this.state.isLoading, [name]: false },
				})
			);
	};

	getData = () => {
		let data = this.state.dynamicFieldDefinitions.map(x => {
			var value;
			if (!this.state.editedFields[x.name]) return undefined;
			if (x.isDropdown && this.state.dynamicFields[x.name]) {
				let result = '';
				if (x.isMultiSelect) {
					this.state.dynamicFields[x.name].forEach(v => (result += v.value + ','));
				} else result = this.state.dynamicFields[x.name].value + '';
				value = result;
			} else if (x.dataType === 'bit') {
				value = this.state.dynamicFields[x.name] ? '1' : '0';
			} else if (x.dataType === 'datetime')
				value = this.state.dynamicFields[x.name] ? this.state.dynamicFields[x.name] : null;
			else if (x.dataType === 'date')
				value = this.state.dynamicFields[x.name]
					? (value = moment(this.state.dynamicFields[x.name]).format('YYYY-MM-DD'))
					: null;
			else value = this.state.dynamicFields[x.name] ? this.state.dynamicFields[x.name].toString() : null;
			return {
				fieldDefinitionId: x.id,
				entityId: parseInt(this.props.entityId),
				value,
			};
		});
		return data.filter(x => x);
	};

	validate = () => {
		var requiredNames = this.state.dynamicFieldDefinitions
			.filter(x => x.isRequired)
			.map(x => x.name)
			.filter(x => !this.state.editedFields[x]);
		this.setState({ errors: requiredNames });
		if (requiredNames.length > 0) {
			this.setState({ validated: false });
			return false;
		}
		return true;
	};

	handleSubmit = () => {
		var entityData = this.getData();
		if (entityData.length !== 0) this.props.saveDynamicDataFields('survey', entityData);
	};

	generateDynamicFields = (fieldDefinitions, dynamicFields) => {
		if (!fieldDefinitions) return null;
		if (!dynamicFields) dynamicFields = [];
		const colElement = Col;
		colElement.sm = 6;
		colElement.md = 3;
		var rows = [];
		var fields = [];
		var i = 0;

		fieldDefinitions.forEach(fieldDefinition => {
			if (fieldDefinition.name !== 'id') {
				i++;
				const key = fieldDefinition.name;
				const name = key;

				if (fieldDefinition) {
					if (fieldDefinition.isBlank) {
						fields.push(<Col sm={6} md={this.props.columnsForField ? this.props.columnsForField : 3}></Col>);
					} else if (fieldDefinition.isRatingScale) {
						fields.push(
							<div>
								<StarField
									key={key}
									question={fieldDefinition.name}
									value={dynamicFields[key] ? dynamicFields[key] : null}
									onChange={value => this.handleOnSelect(value, name)}
								/>
								{!this.state.validated && this.state.errors.includes(fieldDefinition.name) && (
									<small className='p-error'>{'Ovo polje je obavezno.'}</small>
								)}
								<Divider className='mt-0' />
							</div>
						);
					} else if (fieldDefinition.isDropdown) {
						if (fieldDefinition.dropdownTable.substring(0, 2) !== 'ct')
							fields.push(
								<Col className='p-0' sm={6} md={this.props.columnsForField ? this.props.columnsForField : 3}>
									<div className='d-flex flex-row'>
										<SelectField
											className='d-flex flex-row'
											class='d-inline w-100'
											isMulti={fieldDefinition.isMultiSelect}
											label={key}
											key={key}
											value={dynamicFields[key] ? dynamicFields[key] : null}
											name={key}
											onChange={value => this.handleOnSelect(value, name)}
											viewMode={this.props.viewMode || fieldDefinition.readOnly}
											dataTable={fieldDefinition.dropdownTable}
											dataField={fieldDefinition.dropdownTableColumn}
										/>{' '}
										{fieldDefinition.linkPath && (
											<MdLaunch
												title='Open'
												onClick={() =>
													dynamicFields[key] && dynamicFields[key].value
														? window.open(
																env.BASE_PATH +
																	fieldDefinition.linkPath +
																	'/' +
																	dynamicFields[key].value +
																	'?viewMode=true',
																'_blank'
														  )
														: null
												}
												className={
													dynamicFields[key] && dynamicFields[key].value
														? 'survey-add-button text-primary active align-self-center'
														: 'survey-add-button text-primary inactive align-self-center'
												}
											/>
										)}
									</div>
								</Col>
							);
						else
							fields.push(
								<Col sm={6} md={this.props.columnsForField ? this.props.columnsForField : 3}>
									<CreatableSelectField
										isMulti={fieldDefinition.isMultiSelect}
										label={key}
										key={key}
										value={dynamicFields[key] ? dynamicFields[key] : null}
										name={key}
										onChange={value => this.handleOnSelect(value, name)}
										viewMode={this.props.viewMode || fieldDefinition.readOnly}
										dataTable={fieldDefinition.dropdownTable}
										dataField={fieldDefinition.dropdownTableColumn}
										isLoading={this.state.isLoading[key]}
										onCreateOption={(newValue, actionMeta) =>
											this.handleOnCreateOption(
												newValue,
												actionMeta,
												name,
												fieldDefinition.dropdownTable,
												fieldDefinition.isMultiSelect
											)
										}
									/>{' '}
								</Col>
							);
					} else if (fieldDefinition.dataType === 'datetime' || fieldDefinition.dataType === 'date') {
						fields.push(
							<Col sm={6} md={3}>
								<DateField
									label={key}
									key={key}
									value={dynamicFields[key]}
									name={key}
									onChange={this.handleOnDateChange}
									viewMode={this.props.viewMode || fieldDefinition.readOnly}
								/>
							</Col>
						);
					} else if (fieldDefinition.dataType === 'bit') {
						fields.push(
							<Col sm={6} md={3}>
								<CheckboxField
									label={key}
									key={key}
									value={dynamicFields[key]}
									name={key}
									onChange={e => this.handleOnChecboxChange(e)}
									viewMode={this.props.viewMode || fieldDefinition.readOnly}
								/>
							</Col>
						);
					} else if (fieldDefinition.dataType === 'decimal(18,2)') {
						fields.push(
							<Col sm={6} md={3}>
								<CurrencyField
									label={key}
									key={key}
									value={dynamicFields[key]}
									name={key}
									onChange={this.handleOnCurrencyChange}
									viewMode={this.props.viewMode || fieldDefinition.readOnly}
								/>
							</Col>
						);
					} else {
						fields.push(
							<Col className='p-0' sm={6} md={this.props.columnsForField ? this.props.columnsForField : 3}>
								<BasicField
									label={key}
									key={key}
									type={fieldDefinition.dataType === 'float' ? 'number' : 'text'}
									isMultilineText={fieldDefinition.isMultilineText}
									value={dynamicFields[key] ? dynamicFields[key] : ''}
									name={key}
									onChange={this.onChange}
									viewMode={this.props.viewMode || fieldDefinition.readOnly}
								/>
							</Col>
						);
					}
				}

				if (i % 4 === 0) {
					rows.push([...fields]);
					i = 0;
					fields = [];
				}
			}
		});
		rows.push([...fields]);

		return rows;
	};

	getFieldValue = (dataType, value, isDropdown = false) => {
		if (!isDropdown)
			switch (dataType) {
				case 'bit':
					//Must be ==
					value = value == '1' ? true : false;
					break;
				case 'int':
					value = value ? parseInt(value) : null;
					break;
				case 'decimal(18,2)':
				case 'float':
					value = value ? parseFloat(value) : null;
					break;
				case 'date' || 'datetime':
					value = value && value !== '' ? new Date(value) : null;
					break;
				default:
					break;
			}
		return value;
	};

	mapDynamicFields = (dynamicFieldDefinitions, dynamicFieldsTmp) => {
		if (dynamicFieldDefinitions !== null && dynamicFieldDefinitions.length !== 0 && dynamicFieldsTmp !== null) {
			var result = {};
			dynamicFieldDefinitions.forEach(definition => {
				var field = dynamicFieldsTmp.find(x => x.fieldDefinitionId === definition.id);

				if (!field) {
					result[definition.name] = null;
				} else if (!definition.isDropdown)
					result[definition.name] = this.getFieldValue(definition.dataType, field.value);
				else if (definition.isMultiSelect) {
					let values = field.value ? field.value.split(',').filter(x => x) : [];
					let labels = field.label ? field.label.split(',').filter(x => x) : [];
					result[definition.name] = [];
					var i;
					for (i = 0; i < values.length; i++)
						result[definition.name].push({
							value: parseInt(values[i]),
							label: labels[i],
						});
				} else result[definition.name] = { value: field.value, label: field.label };
			});
			this.setState({ dynamicFields: result }, () => this.setState({ editedFields: {} }));
		}
	};

	render() {
		if (!this.state.dynamicFields)
			this.mapDynamicFields(this.state.dynamicFieldDefinitions, this.state.dynamicFieldsTmp);
		return (
			<form id='survey' onSubmit={this.handleSubmit} style={this.props.style}>
				<div key={JSON.stringify(this.state.dynamicFieldDefinitions?.length)}>
					{this.generateDynamicFields(this.state.dynamicFieldDefinitions, this.state.dynamicFields)}
				</div>
			</form>
		);
	}
}

const mapStateToProps = state => {
	return {
		surveyState: state.surveyReducer,
		moduleState: state.moduleReducer,
		currentModule: state.moduleReducer.currentModule,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveDynamicDataFields: (entityCode, dynamicEntityData) => {
			dispatch(saveDynamicDataFields(entityCode, dynamicEntityData));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(DynamicSurveyFields);
