const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: null,
	modules: [],
	navModules: null,
	reportModules: null,
	loading: false,
	permissions: {
		create: false,
		update: false,
		delete: false,
	},
	reloadActions: false,
	currentModule: { id: -1 },
	reportGroups: [],
};

const moduleReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'MODULE_GET_ALL_FULFILLED':
			return Object.assign({}, state, {
				...state,
				modules: action.payload.data,
			});
			break;
		case 'MODULE_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				modules: [...action.payload.data.modules],
			});
			break;
		case 'MODULE_PERMISSIONS_GET_PENDING':
			return Object.assign({}, state, {
				...state,
				reloadActions: false,
			});
			break;
		case 'MODULE_PERMISSIONS_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				permissions: action.payload.data,
				reloadActions: true,
			});
			break;
		case 'MODULE_GET_BY_UG_FULFILLED':
			return Object.assign({}, state, {
				...state,
				modules: state.modules.map(item => {
					let value = action.payload.data.find(x => item.id === x.id);
					if (value) return { ...value, read: true };
					else
						return {
							...item,
							create: false,
							update: false,
							delete: false,
							read: false,
						};
				}),
			});
			break;
		case 'MODULE_TOGGLE_PERMISSION':
			return Object.assign({}, state, {
				...state,
				modules: state.modules.map(item => {
					if (item.id == action.payload.moduleId) {
						if (action.payload.permissionType === 'read') {
							let readValue = item['read'];
							return {
								...item,
								create: readValue ? false : item.create,
								update: readValue ? false : item.update,
								delete: readValue ? false : item.delete,
								read: !readValue,
							};
						} else
							return {
								...item,
								read: true,
								[action.payload.permissionType]: !item[action.payload.permissionType],
							};
					}
					return item;
				}),
			});
			break;
		case 'MODULE_UPDATE_PERMISSIONS_PENDING':
			return Object.assign({}, state, {
				...state,
				loading: true,
			});
			break;
		case 'MODULE_UPDATE_PERMISSIONS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				loading: false,
			});
			break;
		case 'MODULE_GET_BY_USER_FULFILLED':
			localStorage.setItem('navModules', [...action.payload.data]);
			return Object.assign({}, state, {
				...state,
				navModules: [...action.payload.data],
			});
			break;
		case 'REPORT_GET_GROUPS_FULFILLED':
			localStorage.setItem('reportGroups', JSON.stringify([...action.payload.data]));
			return Object.assign({}, state, {
				...state,
				reportGroups: [...action.payload.data],
			});
			break;
		case 'REPORT_GET_BY_USER_FULFILLED':
			localStorage.setItem('reportModules', [...action.payload.data]);
			return Object.assign({}, state, {
				...state,
				reportModules: [...action.payload.data],
			});
			break;
		case 'MODULE_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				modules: state.modules.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'MODULE_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				modules: [action.payload.data, ...state.modules],
			});
			break;
		case 'MODULE_DELETE_FULFILLED':
			var removeIndex = state.modules
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.modules.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				modules: [...state.modules],
			});
			break;
		case 'SET_NAV_MODULES':
			return Object.assign({}, state, {
				...state,
				navModules: [...action.payload],
			});
			break;
		case 'USER_LOGOUT':
			return Object.assign({}, state, { ...initialState });
			break;
		case 'CURRENT_MODULE_UPDATE': {
			var currentModule = state.navModules.find(x => x.relativePath === action.payload);
			return Object.assign({}, state, {
				...state,
				currentModule: currentModule ? currentModule : { id: -1 },
			});
			break;
		}
		case 'MODULE_TOGGLE_ALL_PERMISSION':
			return Object.assign({}, state, {
				...state,
				modules: state.modules.map(item => {
					return {
						...item,
						read: !state.checkedAll,
						create: !state.checkedAll,
						update: !state.checkedAll,
						delete: !state.checkedAll,
					};
				}),
				checkedAll: !state.checkedAll,
			});
			break;
		default:
			return state;
			break;
	}
};

export default moduleReducer;
