const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	costs: [],
	data: [],
};

const costManagementReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'COST_MANAGEMENT_GET_PAGE_FULFILLED':
			action.payload.data.dataSize = undefined;
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'COST_MANAGEMENT_GET_DATASIZE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				dataSize: action.payload.data,
			});
			break;
		case 'COST_MANAGEMENT_ADD_COST':
			return Object.assign({}, state, {
				...state,
				data: [
					...state.data,
					{
						...action.payload,
						costStatus: { value: 0, label: 'Unconfirmed' },
						checkNumber: '',
						payee: null,
					},
				],
			});
			break;
		case 'COST_MANAGEMENT_UPDATE_COST':
			return Object.assign({}, state, {
				...state,
				data: state.data.map(x => (x.id === action.payload.id ? action.payload : x)),
			});
			break;
		case 'COST_MANAGEMENT_REMOVE_COST':
			return Object.assign({}, state, {
				...state,
				data: state.data.filter(x => x.id !== action.payload),
			});
			break;
		case 'COST_MANAGEMENT_CLEAR_DATA':
			return Object.assign({}, state, {
				...state,
				data: [],
			});
			break;
		case 'COST_MANAGEMENT_CLEAR':
			return Object.assign({}, state, {
				...state,
				costs: [],
			});
			break;
		default:
			return state;
			break;
	}
};

export default costManagementReducer;
