import moment from 'moment';

const initialState = {
	portal: {
		cases: [],
		page: 1,
		pageSize: 25,
		dataSize: 1,
		doughnutData: [],
		doughnutLabels: [],
		lineData: [],
		filters: {},
		events: [],
		insurerDetails: [],
		documents: [],
		barData: [],
		remittances: [],
		selectedProviders: [{ value: '-1', label: '' }],
	},
	case: {
		read: null,
	},
	event: {
		authorized: true,
	},
	task: {
		files: [],
		documents: [],
		authorized: true,
	},
};

const portalReducer = (state = { ...initialState }, action) => {
	const count = (ary, classifier, sumfield) => {
		classifier = classifier || String;
		return ary.reduce((counter, item) => {
			var p = classifier(item);
			var value = sumfield(item);
			counter[p] = counter.hasOwnProperty(p) ? counter[p] + value : value;
			return counter;
		}, {});
	};

	const { dataSize } = state.portal;
	switch (action.type) {
		case 'PORTAL_GET_ALL_FULFILLED':
			return Object.assign({}, state, {
				...state,
				portal: {
					...state.portal,
					...action.payload.data,
					dataSize,
				},
			});
			break;
		case 'PORTAL_GET_ALL_DATA_SIZE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				portal: {
					...state.portal,
					dataSize: action.payload.data,
				},
			});
			break;
		case 'PORTAL_SET_STATE':
			return Object.assign({}, state, {
				portal: {
					...state.portal,
					...action.payload,
				},
			});
			break;
		case 'TASK_SET_STATE':
			return Object.assign({}, state, {
				task: {
					...state.task,
					...action.payload,
				},
			});
			break;
		case 'PORTAL_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					...action.payload.data,
					firms: action.payload.data.firms.map(x => ({
						...x,
						value: x.id,
						label: x.name,
					})),
					caseGroups: action.payload.data.caseGroups
						? action.payload.data.caseGroups.map(x => ({
								...x,
								value: x.id,
								label: x.name,
						  }))
						: [],
				},
			});
		case 'PORTAL_EVENT_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				event: {
					...state.case,
					...action.payload.data,
					authorized: true,
				},
			});
		case 'PORTAL_EVENT_GET_REJECTED':
			return Object.assign({}, state, {
				...state,
				event: {
					authorized: false,
				},
			});
			break;
		case 'PORTAL_GET_REJECTED':
			return Object.assign({}, state, {
				...state,
				case: { ...initialState.case, read: false },
			});
			break;
		case 'PORTAL_DATA_GET':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					doughnutLabels: '',
					doughnutData: 0,
					totalCount: 0,
				}),
			});
			break;
		case 'PORTAL_DATA_GET_FULFILLED':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					...action.payload.data,
					doughnutLabels: action.payload.data.doughnutData ? action.payload.data.doughnutData.map(x => x.status) : '',
					doughnutData: action.payload.data.doughnutData ? action.payload.data.doughnutData.map(x => x.count) : 0,
					totalCount: action.payload.data.doughnutData
						? action.payload.data.doughnutData.reduce((acc, x) => acc + x.count, 0)
						: 0,
				}),
			});
			break;
		case 'PORTAL_INSURER_DETAILS_GET_FULFILLED':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					insurerDetails: action.payload.data,
				}),
			});
			break;
		case 'PORTAL_CASE_DOCUMENTS_GET_FULFILLED':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					documents: action.payload.data,
				}),
			});
			break;
		case 'PORTAL_LINE_CHART_DATA_GET_FULFILLED':
			var obj = {};
			action.payload.data.map(
				x =>
					(obj[x.created] =
						obj[x.created] === undefined
							? { count: x.count, claimAmount: x.claimAmount }
							: { count: obj[x.created].count + x.count, claimAmount: obj[x.created].claimAmount + x.claimAmount })
			);

			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					...action.payload.data,
					lineData: Object.entries(obj).map(x => {
						return { x: new Date(x[0]).toISOString(), y: x[1].count, claimAmount: x[1].claimAmount };
					}),
					totalLineCount: Object.entries(obj).reduce((acc, curr) => acc + curr[1].count, 0),
				}),
			});
			break;
		case 'PORTAL_BAR_CHART_DATA_GET':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					remittances: [],
					barData: [],
					totalBarCount: 0,
				}),
			});
			break;
		case 'PORTAL_BAR_CHART_DATA_GET_FULFILLED':
			var obj = count(
				action.payload.data,
				i => i.remittedOn,
				i => i.providerAmount
			);

			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					remittances: action.payload.data,
					barData: Object.entries(obj).map(x => {
						return { x: new Date(x[0]).toISOString(), y: x[1] };
					}),
					totalBarCount: action.payload.data.length,
				}),
			});
			break;
		case 'PORTAL_EVENTS_DATA_GET_FULFILLED':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					events: action.payload.data.map(x => {
						let additionalInfo = x.calendarEventAdditionalInfo ? ': ' + x.calendarEventAdditionalInfo : '';
						return {
							...x,
							start: moment.utc(x.dateFrom).local().toDate(),
							end: moment.utc(x.dateTo).local().toDate(),
							title: 'Case: ' + x.caseNo + additionalInfo,
						};
					}),
				}),
			});
			break;
		case 'PORTAL_TASK_GET_FULFILLED':
		case 'PORTAL_TASK_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				task: {
					...state.task,
					...action.payload.data,
					authorized: true,
					taskStatusOption: {
						label: action.payload.data.taskStatus,
						value: action.payload.data.taskStatusId,
					},
				},
			});
		case 'PORTAL_TASK_GET_REJECTED':
			return Object.assign({}, state, {
				...state,
				task: {
					authorized: false,
				},
			});
		case 'TASK_DOCUMENTS_GET_FULFILLED':
			return Object.assign({}, state, {
				task: {
					...state.task,
					documents: action.payload.data.documents,
				},
			});
			break;
		case 'PORTAL_SET_SELECTED_PROVIDERS':
			return Object.assign({}, state, {
				portal: Object.assign({}, state.portal, {
					...state.portal,
					selectedProviders: action.data,
				}),
			});
		default:
			return state;
			break;
	}
};

export default portalReducer;
