import { getColumn } from '../components/reports/reportUtility';

const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	reports: [],
	parameters: [],
	genericData: [],
	columns: {},
	excludedColumns: [],
	requestColumns: true,
};

const constructColumns = data => {
	var columns = [];

	for (var key in data) if (data.hasOwnProperty(key)) columns.push(getColumn(key, data[key]));

	return columns;
};

const reportReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'REPORT_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				reports: action.payload.data.reports?.map(x => ({ ...x, excludedColumns: x.excludedColumnNames })),
			});
			break;
		case 'REPORT_CREATE_FULFILLED':
			if (state.reports.length === state.pageSize) state.reports.splice(state.reports.length - 1, 1);
			return Object.assign({}, state, {
				...state,
				reports: [action.payload.data, ...state.reports],
			});
			break;
		case 'REPORT_DELETE_FULFILLED':
			var removeIndex = state.reports
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.reports.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				reports: [...state.reports],
			});
			break;
		case 'REPORT_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				reports: state.reports.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'REPORT_GET_ALL_FULFILLED':
			return Object.assign({}, state, {
				...state,
				reports: action.payload.data,
			});
			break;
		case 'REPORT_GET_UG_FULFILLED':
			return Object.assign({}, state, {
				...state,
				reports: state.reports.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userGroups: action.payload.data.userGroups.map(x => {
							return { ...x, value: x.id, label: x.name };
						}),
					};
				}),
			});
			break;
		case 'REPORT_PARAMETER_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				parameters: [...action.payload.data],
			});
			break;
		case 'REPORT_PARAMETER_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				parameters: [action.payload.data, ...state.parameters],
			});
			break;
		case 'REPORT_PARAMETER_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				parameters: state.parameters.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'REPORT_PARAMETER_DELETE_FULFILLED':
			var removeIndex = state.parameters
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.parameters.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				parameters: [...state.parameters],
			});
			break;
		case 'REPORT_GENERIC_GET_PAGE_FULFILLED':
			const data = action.payload.data.columns;
			var columns = state.requestColumns ? constructColumns(data) : state.columns;
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				genericData: [...action.payload.data.reportData],
				columns,
				columnTypes: action.payload.data.columns,
				chartData: action.payload.data.chartData,
			});
			break;
		case 'REPORT_GET_EXCLUDED_COLUMNS_FULFILLED':
			var columns = Object.values(state.columns).filter(x => !action.payload.data.includes(x.dataField));
			return Object.assign({}, state, {
				...state,
				columns,
				excludedColumns: [...action.payload.data],
			});
			break;
		default:
			return state;
			break;
	}
};

export default reportReducer;
