import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Container, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './alert-generator.css';

const COLOR = {
	success: '#5cb85c',
	fail: '#d9534f',
	pending: '#000000',
};

const AlertGenerator = (props, ref) => {
	const [show, setShow] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState(null);

	const pending = useSelector(state => state.pendingReducer);
	const action = pending.lastActionName;

	const resetValues = () => {
		setShow(false);
		setMessage(null);
		setColor(null);
	};

	const onClose = () => {
		if (props.isCustomMessage) props.onClose();
		resetValues();
	};

	const timeOutClose = interval =>
		window.setTimeout(() => {
			if (props.isCustomMessage) props.onClose();
			resetValues();
		}, interval);

	const isGetRequest = () => action !== '' && action.includes('_GET');

	const requestFinished = () => pending[action] && !pending[action].pending;

	const shouldShow = () => {
		return (
			(action !== '' &&
				pending[action] &&
				pending[action].pending &&
				(!Array.isArray(props.ignoreEvents) || !props.ignoreEvents.includes(action))) ||
			message
		);
	};

	useEffect(() => {
		if ((action !== '' && pending[action].pending && !isGetRequest()) || message) setShow(true);
	}, [shouldShow()]);

	useEffect(() => {
		timeOutClose(1500);
	}, [requestFinished(), message]);

	useEffect(() => {
		setMessage(props.customMessage);
	}, [props.customMessage]);

	const getInfo = () => {
		if (message) return message;

		if (pending[action] && pending[action].pending) return '';
		else if (pending[action] && pending[action].success) return 'Zahtjev uspješan!';
		else return 'Greška. Molimo Vas da kontaktirate administratora!';
	};

	const getColor = () => {
		if (color) return color;
		if (pending[action] && pending[action].pending) return COLOR.pending;
		if (pending[action] && pending[action].success) return COLOR.success;
		return COLOR.fail;
	};

	const handleCustomRequest = (promise, callback, action, errCallback = () => {}) => {
		setMessage('Zahtjev u toku...');

		setColor(COLOR.pending);

		return promise()
			.then(res => {
				setMessage('Zahtjev uspješan!');
				setColor(COLOR.success);
				callback(res);
			})
			.catch(() => {
				setMessage('Greška. Molimo Vas da kontaktirate administratora!');
				setColor(COLOR.fail);
				errCallback();
			});
	};

	useImperativeHandle(ref, () => ({
		handleCustomRequest: handleCustomRequest,
	}));

	return (
		<Modal className='alert-modal' show={show} onHide={() => onClose()} centered>
			<Modal.Header>
				<Form.Label style={{ color: getColor() }}>{getInfo()}</Form.Label>
			</Modal.Header>
			<Modal.Body>
				<Container className='alert-container'>
					<Spinner animation='border' variant='info' />
				</Container>
			</Modal.Body>
		</Modal>
	);
};

export default React.forwardRef(AlertGenerator);
