import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { saveAs } from 'file-saver';

export function* getId() {
	let id = 0;
	while (true) yield id++;
}

export const adjustAreaInput = str => {
	if (str && str !== '') {
		str = str.replaceAll(/\n/g, ',').replaceAll(/\s/g, '');
		str = new Set(str.split(',').filter(x => x && x !== ''));
		return Array.from(str).join(',');
	}
	return null;
};

export const adjustExcludedColumnAreaInput = str => {
	if (str && str !== '') {
		str = str.replaceAll(/\n/g, ',');
		str = str.replaceAll(', ', ',');
		str = str.replaceAll(' ,', ',');
		str = new Set(str.split(',').filter(x => x && x !== ''));
		return Array.from(str).join(',');
	}
	return null;
};

export const roundToTwo = value => Math.round((value + Number.EPSILON) * 100) / 100;

export const round = (value, dp) => Math.round((value + Number.EPSILON) * Math.pow(10, dp)) / Math.pow(10, dp);

export const filterDuplicates = (arr = [], key = 'id') =>
	arr.filter((x, index, arr) => arr.findIndex(y => y[key] === x[key]) === index);

export const addTooltip = (obj, tooltip, placement = 'right') => (
	<OverlayTrigger
		placement={placement}
		overlay={
			<Popover id={'popover-disabled'}>
				<Popover.Content>{tooltip}</Popover.Content>
			</Popover>
		}>
		{obj}
	</OverlayTrigger>
);

export const sizePerPageList = [
	{ text: '10', value: 10 },
	{ text: '25', value: 25 },
	{ text: '50', value: 50 },
	{ text: '100', value: 100 },
	{ text: '200', value: 200 },
];

export const executeSeq = async (arr, f, message = 'Error: ') => {
	for (const req of arr)
		try {
			await f(req);
		} catch (e) {
			console.log(message, e);
		}
};

export const executeSeqWithResponse = async (arr, f, message = 'Error: ') => {
	let response = [];
	for (const req of arr)
		try {
			const result = await f(req);
			response.push(result.data);
		} catch (e) {
			console.log(message, e);
		}
	return response;
};

export const openPdf = (file, fileName, output = 'blob') => {
	const isFirefox = typeof InstallTrigger !== 'undefined';

	if (file.output) {
		if (isFirefox) file.output('save', fileName + '.pdf');
		else {
			const url = window.URL.createObjectURL(file.output(output), {
				type: 'application/pdf',
			});
			window.open(url, '_blank');
		}
	}
};

export const previewBlob = (file, fileName) => {
	const isFirefox = typeof InstallTrigger !== 'undefined';

	if (isFirefox) {
		const url = window.URL.createObjectURL(file);
		saveAs(url, fileName);
	} else {
		const url = window.URL.createObjectURL(file, {
			type: 'application/pdf',
		});
		window.open(url, '_blank');
	}
};

export const getSum = (arr, name) => arr.reduce((a, c) => a + c[name], 0);

export const getCurrencySum = (arr, name) => arr.reduce((a, c) => a + roundToTwo(c[name]), 0);

export const arrayToString = (arr, name, delimiter = ',') => (arr ? arr.map(x => x[name] || x).join(delimiter) : '');
