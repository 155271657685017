import axios from 'axios';
import { env } from '../configs/env';
import { login as Login, codeLogin as CodeLogin, edit as Edit } from '../services/userServices';
import store from '../store';

const { dispatch } = store;

export function login(credentials) {
	return {
		type: 'USER_LOGIN',
		payload: Login(credentials),
	};
}

export function codeLogin(code) {
	return {
		type: 'USER_CODE_LOGIN',
		payload: CodeLogin(code),
	};
}

export function codeLoginWithPromise(code) {
	return new Promise(function (resolve, reject) {
		CodeLogin(code)
			.then(response => {
				dispatch({
					type: 'USER_CODE_LOGIN_FULFILLED',
					payload: response,
				});
				resolve(response);
			})
			.catch(err => {
				dispatch({
					type: 'USER_CODE_LOGIN_REJECTED',
					payload: err,
				});
				reject(err);
			});
	});
}

export function loginWithPromise(credentials) {
	return new Promise(function (resolve, reject) {
		Login(credentials)
			.then(response => {
				dispatch({
					type: 'USER_LOGIN_FULFILLED',
					payload: response,
				});
				resolve(response);
			})
			.catch(err => {
				dispatch({
					type: 'USER_LOGIN_REJECTED',
					payload: err,
				});
				reject(err);
			});
	});
}

export function create(user) {
	return {
		type: 'USER_CREATE',
		payload: axios(env.SERVER_ENV.url + '/api/user', {
			method: 'POST',
			data: JSON.stringify(user),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function edit(user) {
	return {
		type: 'USER_EDIT',
		payload: axios(env.SERVER_ENV.url + '/api/user', {
			method: 'PATCH',
			data: JSON.stringify(user),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

// export function edit(user) {
// 	return new Promise(function (resolve, reject) {
// 		Edit(user)
// 			.then(response => {
// 				dispatch({
// 					type: 'USER_EDIT_FULFILLED',
// 					payload: response,
// 				});
// 				resolve(response);
// 			})
// 			.catch(err => {
// 				dispatch({
// 					type: 'USER_EDIT_REJECTED',
// 					payload: err,
// 				});
// 				reject(err);
// 			});
// 	});
// }

export function editEmailCredentials(user) {
	console.log('userr creds', user);
	return {
		type: 'USER_EDIT_EMAIL_CREDENTIALS',
		payload: axios(env.SERVER_ENV.url + '/api/user/emailCredentials', {
			method: 'PATCH',
			data: JSON.stringify(user),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function resetPassword(username) {
	return {
		type: 'USER_RESET_PASSWORD',
		payload: axios(env.SERVER_ENV.url + '/api/user/resetPassword', {
			method: 'PATCH',
			data: JSON.stringify(username),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function resetPasswordByAdmin(username) {
	return {
		type: 'USER_RESET_PASSWORD',
		payload: axios(env.SERVER_ENV.url + '/api/user/resetPasswordByAdmin', {
			method: 'PATCH',
			data: JSON.stringify(username),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function deleteUser(id, moduleId) {
	return {
		type: 'USER_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/user/' + id + '/' + moduleId, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function loadUsersPage(request) {
	return {
		type: 'USER_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/user/page', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function setUser(token) {
	return {
		type: 'SET_CURRENT_USER',
		payload: token,
	};
}

export function logout() {
	return {
		type: 'USER_LOGOUT',
		payload: {},
	};
}

export function updateUserGroups(userId, userGroupIds) {
	return {
		type: 'USER_UPDATE_UGS',
		payload: axios(env.SERVER_ENV.url + '/api/user/userGroups', {
			method: 'POST',
			data: { userId, userGroupIds },
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getUserGroups(userId) {
	return {
		type: 'USER_GET_UGS',
		payload: axios(env.SERVER_ENV.url + '/api/user/userGroups/' + userId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getFirms(userId) {
	return {
		type: 'USER_GET_FIRMS',
		payload: axios(env.SERVER_ENV.url + '/api/user/firms/' + userId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function updateFirms(userId, firmIds) {
	return {
		type: 'USER_UPDATE_FIRMS',
		payload: axios(env.SERVER_ENV.url + '/api/user/firms', {
			method: 'POST',
			data: { userId, firmIds },
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}
