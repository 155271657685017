import Axios from 'axios';
import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsBuilding, BsPerson } from 'react-icons/bs';
import ReactInputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { create, edit, getPartyTypes, resetNew } from '../../actions/partyActions';
import { adjustDateOffset, saveDynamicDataFields } from '../../actions/utilityActions';
import { env } from '../../configs/env';
import { getPartyTypeOptions } from '../../services/partyServices';
import AlertGenerator from '../alertGenerator/AlertGenerator';
import InvoicesTable from '../checks/invoicesTable';
import ContactsTable from '../contacts/ContactsTable';
import SelectField from '../dynamicFields/selectField';
import NotesPanel from '../notes/notesPanel';
import DynamicPartyFields from './dynamicPartyFields';
import PartyReferrals from './partyRefferals';

var initialState = {
	id: -1,
	isCompany: false,
	firstName: undefined,
	middleName: undefined,
	lastName: undefined,
	companyName: undefined,
	gender: null,
	ssn: null,
	dateOfBirth: null,
	isActive: true,
	taxId: undefined,
	partyTypeName: undefined,
	partyType: null,
	action: '',
	validated: false,
	new: false,
	tabNames: null,
	refsGenerated: false,
	parent: null,
	isTaxRequired: false,
};

const partyTypes = {
	Patient: 1,
	Provider: 2,
	Adjuster: 12,
};

class PartyForm extends Component {
	componentDidMount() {
		this.props.getPartyTypes(this.props.moduleState.currentModule.id);
		this.contacts = React.createRef();
		this.referrals = React.createRef();
		this.referralsAttorney = React.createRef();
	}

	state = { ...initialState };

	//Set state depending if it's user creation or edit
	static getDerivedStateFromProps(nextProps, prevState) {
		if (!nextProps.party) var newState = null;
		if (prevState.id !== -1 && !prevState.new) {
			newState = { ...initialState };
		}
		if (nextProps.newType) {
			newState = {
				...newState,
				partyType: {
					value: partyTypes[nextProps.newType],
					label: nextProps.newType,
				},
			};
			return { ...newState };
		} else if (!nextProps.party) return { ...newState };
		if (nextProps.party.id === prevState.id) return null;

		nextProps.party.dateOfBirth = nextProps.party.dateOfBirth ? new Date(nextProps.party.dateOfBirth) : null;
		nextProps.party.partyType = {
			value: nextProps.party.partyTypeName,
			label: nextProps.party.partyTypeName,
			isParentable: nextProps.party.isParentable,
			labelName: nextProps.party.labelName,
			parentTable: nextProps.party.parentTable,
		};
		nextProps.party.parent =
			nextProps.party.parentId > 0
				? {
						value: nextProps.party.parentId,
						label: nextProps.party.parent,
				  }
				: null;
		return { ...nextProps.party };
	}

	switchChange = () => {
		this.setState({ isActive: this.state.isActive ? false : true });
	};

	handleOnChange = e => {
		if (e.target.name === 'isCompany') this.setState({ isCompany: this.state.isCompany ? false : true });
		else this.setState({ [e.target.name]: e.target.value });
	};

	handleGenderChange = e => {
		this.setState({
			gender: e.target.value,
		});
	};

	dateChange = date => {
		this.setState({ dateOfBirth: date });
	};

	handleOnSelectChange = value => {
		this.setState({
			partyType: value,
			partyTypeValidator: value ? true : false,
		});
		this.setState({ isTaxRequired: value.id === 2 ? true : false });
	};

	validateSelect = () =>
		this.setState({
			partyTypeValidator: this.state.partyType ? true : false,
		});

	componentWillUnmount() {
		this.setState({ ...initialState });
	}
	handleOnSelectChangeParent = value => {
		this.setState({
			parent: value,
		});
	};

	loadOptions = (inputValue, callback) => {
		getPartyTypeOptions(inputValue).then(response => {
			const data = response.data.map(x => {
				return { ...x, label: x.name.trim(), value: x.id };
			});
			callback(data);
		});
	};

	createDynamicRefs = partyTypeCode => {
		Axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/party-' + partyTypeCode, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
			.then(response => {
				response.data.forEach(name => (this[name] = React.createRef()));
				this.setState({ tabNames: [...response.data], refsGenerated: true });
			})
			.catch(err => this.setState({ tabNames: [], refsGenerated: true }));
	};

	close = () => {
		this.setState({ ...initialState });
		this.props.resetNew();
		if (this.state.tabNames)
			this.state.tabNames.forEach(x => (this[x].current ? this[x].current.setInitialState() : null));
		if (this.props.callback && this.state.id !== -1) {
			this.props.callback(
				{
					label: this.props.partyState.newParty.isCompany
						? this.props.partyState.newParty.companyName
						: this.props.partyState.newParty.firstName + ' ' + this.props.partyState.newParty.lastName,
					value: this.props.partyState.newParty.id,
				},
				this.props.newType
			);
		}
		this.props.onHide();
	};

	switchViewMode = e => {
		e.preventDefault();
		this.props.switchViewMode(false);
	};

	handleOnSubmit = e => {
		e.preventDefault();
		this.validateSelect();
		const form = e.currentTarget;
		if (!form.checkValidity() || !this.state.partyType) {
			e.stopPropagation();
		} else {
			this.setState({ validated: true });

			//In case of new user create, else edit
			if (this.state.id === -1) {
				this.state.action = 'PARTY_CREATE';
				this.props.create({
					isCompany: this.state.isCompany,
					firstName: this.state.isCompany ? undefined : this.state.firstName,
					middleName: this.state.isCompany ? undefined : this.state.middleName,
					lastName: this.state.isCompany ? undefined : this.state.lastName,
					companyName: !this.state.isCompany ? undefined : this.state.companyName,
					dateOfBirth: this.state.isCompany ? undefined : adjustDateOffset(this.state.dateOfBirth),
					taxId: !this.state.isCompany ? undefined : this.state.taxId,
					partyTypeId: this.state.partyType ? this.state.partyType.value : null,
					isActive: this.state.isActive,
					moduleId: this.props.moduleId,
					gender: this.state.gender,
					ssn: this.state.ssn,
					parentId: this.state.parent ? this.state.parent.value : null,
				});
				this.setState({ action: 'PARTY_CREATE' });
			} else {
				this.state.action = 'PARTY_EDIT';
				this.props.edit({
					id: this.state.id,
					isCompany: this.state.isCompany,
					firstName: this.state.isCompany ? undefined : this.state.firstName,
					middleName: this.state.isCompany ? undefined : this.state.middleName,
					lastName: this.state.isCompany ? undefined : this.state.lastName,
					companyName: !this.state.isCompany ? undefined : this.state.companyName,
					dateOfBirth: this.state.isCompany ? undefined : adjustDateOffset(this.state.dateOfBirth),
					taxId: !this.state.isCompany ? undefined : this.state.taxId,
					partyTypeId:
						this.state.partyType && !isNaN(this.state.partyType.value - 0) ? this.state.partyType.value : undefined,
					isActive: this.state.isActive,
					moduleId: this.props.moduleId,
					gender: this.state.gender,
					ssn: this.state.ssn,
					parentId: this.state.parent ? this.state.parent.value : null,
				});

				const partyType = this.state.partyType.code ? this.state.partyType.code : '';
				var dynamicData = [];
				this.state.tabNames.forEach(x => {
					if (this[x].current) dynamicData.push(...this[x].current.getData());
				});
				dynamicData = dynamicData.filter(x => x);

				if (this.contacts.current) this.contacts.current.handleSave();
				if (dynamicData.length > 0) this.props.saveDynamicDataFields('party-' + partyType, dynamicData);
				if (this.referrals.current) this.referrals.current.handleOnSave();
				if (this.referralsAttorney.current) this.referralsAttorney.current.handleOnSave();
			}
		}
	};

	render() {
		var action = this.state.action;
		if (action !== '' && this.props.pendingState[action]) {
			if (!this.props.pendingState[action].pending) {
				if (this.props.pendingState[action].success && action === 'PARTY_CREATE' && this.props.partyState.newParty) {
					this.setState({
						...this.props.partyState.newParty,
						dateOfBirth: this.props.partyState.newParty.dateOfBirth
							? new Date(this.props.partyState.newParty.dateOfBirth)
							: null,
						new: true,
					});

					this.state.action = '';
				}
			}
		}
		if (!this.state.refsGenerated && this.state.partyTypeName) {
			this.createDynamicRefs(this.state.partyType.code);
		}
		var defaultActiveKey;
		var tabs;
		if (this.state.tabNames) {
			defaultActiveKey = this.state.tabNames.length === 0 ? 'contacts' : this.state.tabNames[0];
			tabs = this.state.tabNames.map(x => (
				<Tab eventKey={x} title={x}>
					<Card>
						<Card.Body>
							<DynamicPartyFields
								ref={this[x]}
								partyId={this.state.id}
								viewMode={this.props.viewMode}
								tabName={x}
								partyType={this.state.partyType.code}
								style={{ marginTop: '0.5em' }}
							/>
						</Card.Body>
					</Card>
				</Tab>
			));
		}

		return (
			<Modal show={this.props.show} onHide={this.close} size='xl' centered dialogClassName='myModal'>
				<Modal.Body>
					<Row>
						<Container>
							<AlertGenerator />
						</Container>
					</Row>
					<Form
						validated={this.state.validated}
						onSubmit={this.handleOnSubmit}
						id='user-form'
						disabled={this.props.viewMode}>
						{this.state.id === -1 ? (
							<Row style={{ marginBottom: '2%' }}>
								<Col>
									<Button
										variant={this.state.isCompany ? 'secondary' : 'outline-secondary'}
										onClick={() => this.setState({ isCompany: true })}
										style={{ margin: '0.2em' }}
										size='lg'>
										<BsBuilding style={{ fontSize: '2em', margin: '0.1em' }} />
										Company
									</Button>
									<Button
										variant={this.state.isCompany ? 'outline-secondary' : 'secondary'}
										onClick={() => this.setState({ isCompany: false })}
										style={{ margin: '0.2em' }}
										size='lg'>
										<BsPerson style={{ fontSize: '2em', margin: '0.1em' }} />
										Person
									</Button>
								</Col>
							</Row>
						) : null}
						{this.state.isCompany ? (
							<Row>
								<Col>
									<Form.Group>
										<Form.Label className='form-label'>Company Name</Form.Label>
										<Form.Control
											required
											size='sm'
											type='text'
											placeholder='Company Name'
											name='companyName'
											value={this.state.companyName}
											onChange={this.handleOnChange}
											disabled={this.props.viewMode}
										/>
										<Form.Control.Feedback type='invalid'>Invalid name.</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label className='form-label'>Tax ID</Form.Label>
										{this.state.isTaxRequired ? (
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Tax ID'
												name='taxId'
												value={this.state.taxId}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										) : (
											<Form.Control
												size='sm'
												type='text'
												placeholder='Tax ID'
												name='taxId'
												value={this.state.taxId}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										)}
									</Form.Group>
								</Col>
							</Row>
						) : (
							<Row>
								<Col>
									<Form.Group>
										<Form.Label className='form-label'>First Name</Form.Label>
										<Form.Control
											required
											size='sm'
											type='text'
											placeholder='First Name'
											name='firstName'
											value={this.state.firstName}
											onChange={this.handleOnChange}
											disabled={this.props.viewMode}
										/>
										<Form.Control.Feedback type='invalid'>Invalid name.</Form.Control.Feedback>
									</Form.Group>
									<Form.Group>
										<Form.Label className='form-label'>Middle Name</Form.Label>
										<Form.Control
											size='sm'
											type='text'
											placeholder='Middle Name'
											name='middleName'
											value={this.state.middleName}
											onChange={this.handleOnChange}
											disabled={this.props.viewMode}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label className='form-label'>SSN</Form.Label>
										<ReactInputMask
											placeholder='###-##-####'
											mask='***-**-9999'
											className='form-control form-control-sm'
											name='ssn'
											disabled={this.state.viewMode}
											onChange={this.handleOnChange}
											value={this.state.ssn}>
											{props => <Form.Control {...props} />}
										</ReactInputMask>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label className='form-label'>Last Name</Form.Label>
										<Form.Control
											required
											size='sm'
											type='text'
											placeholder='Last Name'
											name='lastName'
											value={this.state.lastName}
											onChange={this.handleOnChange}
											disabled={this.props.viewMode}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label style={{ display: 'block' }}>Date of Birth</Form.Label>
										<DatePicker
											autoComplete='off'
											selected={this.state.dateOfBirth}
											value={this.state.dateOfBirth}
											name='dateOfBirth'
											onChange={this.dateChange}
											disabled={this.props.viewMode}
											showMonthDropdown
											showYearDropdown
											dropdownMode='select'
											className='form-control form-control-sm'
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label className='form-label'>Gender</Form.Label>
										<div key={'radio'} className='mb-6'>
											<Form.Check
												inline
												type={'radio'}
												id={'radio-1'}
												onChange={this.handleGenderChange}
												size='sm'
												value='M'
												label='Male'
												disabled={this.props.viewMode}
												checked={this.state.gender === 'M'}
											/>
											<Form.Check
												inline
												type={'radio'}
												id={'radio-2'}
												onChange={this.handleGenderChange}
												size='sm'
												value='F'
												label='Female'
												disabled={this.props.viewMode}
												checked={this.state.gender === 'F'}
											/>
											<Form.Check
												inline
												type={'radio'}
												id={'radio-3'}
												onChange={this.handleGenderChange}
												size='sm'
												value='O'
												label='Other'
												disabled={this.props.viewMode}
												checked={this.state.gender === 'O'}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						)}
						<Row>
							<SelectField
								as={Col}
								class='col-md-6 col-sm-12'
								label='Party Type'
								value={this.state.partyType}
								name='partyType'
								onChange={this.handleOnSelectChange}
								viewMode={this.props.viewMode || (this.props.newType && this.state.id === -1)}
								loadOptions={this.loadOptions}
								loadOptionsModal={this.loadOptions}
								isValid={this.state.partyTypeValidator}
							/>
							<Col>
								<Form.Group>
									<Form.Label />
									<Form.Check
										id='switch'
										size='sm'
										name='isActive'
										type='switch'
										label={this.state.isActive ? 'Active' : 'Inactive'}
										checked={this.state.isActive}
										onChange={this.switchChange}
										disabled={this.props.viewMode}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							{this.state.partyType && (this.state.partyType.isParentable || this.props.isPerentable) && (
								<SelectField
									as={Col}
									class='col-md-6 col-sm-12'
									label={this.state.partyType.labelName ? this.state.partyType.labelName : this.props.parentLabel}
									value={this.state.parent}
									name='parent'
									onChange={this.handleOnSelectChangeParent}
									viewMode={this.props.viewMode}
									dataTable={
										this.state.partyType.parentTable ? this.state.partyType.parentTable : this.props.parentTable
									}
									dataField='name'
								/>
							)}
						</Row>
					</Form>
					{this.state.id !== -1 && this.state.tabNames ? (
						<Tabs defaultActiveKey={defaultActiveKey} className='m-0' mountOnEnter={true} unmountOnExit={false}>
							{tabs}

							<Tab eventKey='contacts' title='Contacts'>
								<ContactsTable ref={this.contacts} partyId={this.state.id} isDisabled={this.props.viewMode} />
							</Tab>

							<Tab eventKey='notes' title='Notes'>
								<NotesPanel
									entityCode={this.state.partyTypeName ? 'party-' + this.state.partyType.code : ''}
									entityId={this.state.id}
									viewMode={this.props.viewMode}
								/>
							</Tab>

							{this.state.partyTypeName === 'Provider' && (
								<Tab eventKey='Firm Fee Vendors' title='Firm Fee Referral Vendors'>
									<PartyReferrals
										ref={this.referrals}
										type='Firm Fee'
										viewMode={this.props.viewMode}
										entityId={this.state.id}
									/>
								</Tab>
							)}

							{this.state.partyTypeName === 'Provider' && (
								<Tab eventKey='Attorney Fee Vendors' title='Attorney Fee Referral Vendors'>
									<PartyReferrals
										ref={this.referralsAttorney}
										type='Attorney Fee'
										viewMode={this.props.viewMode}
										entityId={this.state.id}
									/>
								</Tab>
							)}

							{this.state.partyTypeName === 'Provider' && (
								<Tab eventKey='Invoices' title='Invoices'>
									<InvoicesTable providerId={this.state.id} />
								</Tab>
							)}
						</Tabs>
					) : null}
				</Modal.Body>
				<Modal.Footer>
					<Button size='sm' variant='secondary' onClick={this.close}>
						Close
					</Button>
					{this.props.viewMode ? (
						this.props.moduleState.currentModule.update && (
							<Button size='sm' id='editButton' variant='info' onClick={this.switchViewMode} form=''>
								Edit
							</Button>
						)
					) : (
						<Button size='sm' id='submitButton' variant='primary' type='submit' form='user-form'>
							Save changes
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		);
	}
}

const mapStateToProps = state => {
	return {
		pendingState: state.pendingReducer,
		partyState: state.partyReducer,
		moduleState: state.moduleReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		create: party => {
			dispatch(create(party));
		},
		edit: party => {
			dispatch(edit(party));
		},
		getPartyTypes: moduleId => {
			dispatch(getPartyTypes(moduleId));
		},
		resetNew: () => {
			dispatch(resetNew());
		},
		saveDynamicDataFields: (entityCode, dynamicEntityData) => {
			dispatch(saveDynamicDataFields(entityCode, dynamicEntityData));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartyForm);
