import moment from 'moment';
import { round, roundToTwo } from '../../utility';
import * as utility from '../utility';

export const getReferentAmount = (referentField, row, settlementAmount) => {
	switch (referentField.label) {
		case 'Balance Amount':
			return row.balanceAmount;
		case 'Settled Amount':
			return settlementAmount;
		case 'Adjusted Balance':
			return row.adjustedBalanceAmount;
		default:
			break;
	}
};

export const getFromInterestDate = (referentDateFieldId, row) => {
	switch (referentDateFieldId) {
		case 1:
			return row.pomDate30;
		case 2:
			return row.dateIndexPurchased;
		case 3:
			return row.dateInsurerServed;
		case 4:
			return row.dateInterestTakenFrom;
		default:
			break;
	}
};

const discontinuanceSettings = {
	settlementAmount: 0,
	filingFees: 0,
	settlementPercentage: 0,
};

export const getPassedDays = settlement => utility.calculatePassedDays(settlement, getFromInterestDate);

export const shouldApplyPercentage = (settlementAmount, settlementPercentage) =>
	settlementAmount > 0 && settlementPercentage > 0;

export const getCollectiveSettlementAmount = (settlementAmount, settlementGroupCasesOpen) =>
	settlementAmount / settlementGroupCasesOpen.filter(x => x.isActive).length;

const hasExceedingIssue = (arr, groupAmount) => arr.some(x => groupAmount > x.balanceAmount);

const getExceedingCases = (arr, groupAmount) => arr.filter(x => groupAmount > x.balanceAmount);

const _getAdjustedBalances = (openCases, adjustedBalance) => {
	openCases = openCases
		.filter(x => x.isActive)
		.map(x => ({ ...x, balanceAmount: x.balanceAmount < 0 ? 0 : x.balanceAmount })); // possible negative balances

	var groupAmount = getCollectiveSettlementAmount(adjustedBalance, openCases);
	var dict = {};
	var remainingCases = openCases;

	do {
		var exceedingCases = getExceedingCases(remainingCases, groupAmount);
		remainingCases = remainingCases.filter(x => !exceedingCases.map(x => x.caseId).includes(x.caseId));

		exceedingCases.forEach(x => {
			adjustedBalance -= x.balanceAmount;
			dict[x.caseId] = x.balanceAmount;
		});

		groupAmount = getCollectiveSettlementAmount(adjustedBalance, remainingCases);
	} while (hasExceedingIssue(remainingCases, groupAmount));

	remainingCases.forEach(x => (dict[x.caseId] = groupAmount));
	return dict;
};

const getAdjustedBalances = (openCases, adjustedBalance) => {
	openCases = openCases
		.filter(x => x.isActive)
		.map(x => ({ ...x, balanceAmount: x.balanceAmount < 0 ? 0 : x.balanceAmount })); // possible negative balances

	const totalBalance = getSum(openCases, 'balanceAmount');
	const percentage = adjustedBalance / totalBalance;
	const dict = {};

	console.log('Adjusted balance derived by percentage:', percentage);

	openCases.forEach(x => (dict[x.caseId] = percentage >= 1 ? x.balanceAmount : x.balanceAmount * percentage));
	return dict;
};

const getEffectiveSettlementPercentage = (defaultSettlementPercentage, totalBalance, totalSettlementAmount) =>
	defaultSettlementPercentage > 0
		? defaultSettlementPercentage
		: (roundToTwo(totalSettlementAmount) * 100) / roundToTwo(totalBalance);

export const calculate = settlement => {
	const isAppliedPercentage = shouldApplyPercentage(
		settlement.settlementAmount,
		settlement.defaultSettlementPercentage
	);

	const totalBalance = getSum(settlement.settlementGroupCasesOpen, 'balanceAmount');
	const adjustedBalances = getAdjustedBalances(settlement.settlementGroupCasesOpen, settlement.settlementAmount);
	const takenFilingFees = getSum(settlement.settlementGroupCasesTaken, 'filingFees');

	let openCases = settlement.settlementGroupCasesOpen.map(x => {
		if (!x.isActive) return x;

		let additonalSettings = {};
		let balanceAmount = x.balanceAmount > 0 ? x.balanceAmount : 0;

		x.adjustedBalanceAmount = isAppliedPercentage ? adjustedBalances[x.caseId] : balanceAmount;
		x.settlementAmount = adjustedBalances[x.caseId];

		const defaultSettlementPercentage = getEffectiveSettlementPercentage(
			settlement.defaultSettlementPercentage,
			totalBalance,
			settlement.settlementAmount
		);

		if (defaultSettlementPercentage && defaultSettlementPercentage > 0)
			x.settlementAmount = utility.applyEntityPercentage(
				parseFloat(defaultSettlementPercentage),
				x.adjustedBalanceAmount
			);
		if (x.settlementAmount < 0) x.settlementAmount = 0;

		const interestReferentAmount = getReferentAmount(settlement.interestReferentAmountField, x, x.settlementAmount);

		let settlementPercentage =
			defaultSettlementPercentage && parseFloat(defaultSettlementPercentage) > 0
				? parseFloat(defaultSettlementPercentage)
				: utility.getEntityPercentage(parseFloat(x.settlementAmount), balanceAmount);

		// interest calculation
		let fromInterestDate = getFromInterestDate(settlement.interestReferentDateFieldId, x);
		fromInterestDate = fromInterestDate ? moment(fromInterestDate) : null;

		let daysBetween =
			fromInterestDate && x.toInterestDate && moment(fromInterestDate).isValid()
				? moment(x.toInterestDate).diff(fromInterestDate, 'days')
				: 0;

		let interestAmount = utility.getInterestAmount(interestReferentAmount, daysBetween);
		interestAmount = utility.applyInterestDiscount(interestAmount, settlement.interestDiscountPercentage);

		// discontinuance
		if (settlement.settlementType && settlement.settlementType.label.toLowerCase().includes('discontinuance'))
			additonalSettings = { ...discontinuanceSettings };

		return {
			...x,
			attorneyFees: utility.getAttorneyFees(x.settlementAmount, interestAmount),
			interestAccrued: interestAmount,
			settlementAmount: round(x.settlementAmount, 3),
			settlementPercentage: roundToTwo(settlementPercentage),
			interestReferentDateField: settlement.interestReferentDateField
				? settlement.interestReferentDateField.label
				: null,
			interestReferentDateFieldId: settlement.interestReferentDateField
				? settlement.interestReferentDateField.value
				: null,
			interestReferentDate: fromInterestDate,
			interestReferentAmountField: settlement.interestReferentAmountField
				? settlement.interestReferentAmountField.label
				: null,
			interestReferentAmountFieldId: settlement.interestReferentAmountField
				? settlement.interestReferentAmountField.value
				: null,
			settlingAttorneyName: settlement.settlingAttorney ? settlement.settlingAttorney.label : null,
			settlingAttorneyId: settlement.settlingAttorney ? settlement.settlingAttorney.value : null,
			attorneyFeeLimit: settlement.attorneyFeeLimit ? settlement.attorneyFeeLimit.label : '20%',
			attorneyFeeLimitId: settlement.attorneyFeeLimit ? settlement.attorneyFeeLimit.value : null,
			settlementType: settlement.settlementType ? settlement.settlementType.label : null,
			settlementTypeId: settlement.settlementType ? settlement.settlementType.value : null,
			adjusterName: settlement.adjuster ? settlement.adjuster.label : null,
			adjusterId: settlement.adjuster ? settlement.adjuster.value : null,
			interestDiscountPercentage: parseFloat(settlement.interestDiscountPercentage),
			interestPercentage: 2,
			fromInterestDate,
			note: settlement.note,
			...additonalSettings,
		};
	});

	if (settlement.filingFeeLimit) openCases = adjustFilingFees(settlement.filingFeeLimit, openCases, takenFilingFees);

	if (!settlement.attorneyFeeLimit) return openCases;

	return utility.annealByGroup(openCases, settlement.attorneyFeeLimit);
};

const adjustFilingFees = (limit, cases, takenFilingFees = 0) => {
	const fees = getSum(cases, 'filingFees');

	if (!limit || fees + takenFilingFees <= limit) return cases;

	const ratio = (limit - takenFilingFees) / fees;
	return cases.map(x => ({ ...x, filingFees: x.filingFees * (ratio > 0 ? ratio : 0) }));
};

export const getSum = (arr, name) => arr.reduce((a, c) => a + roundToTwo(parseFloat(c[name] > 0 ? c[name] : 0)), 0);

const columnSumFields = [
	'claimAmount',
	'collectedAmount',
	'balanceAmount',
	'settlementAmount',
	'adjustedBalanceAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'marshalPoundage',
];

export const rowSumFields = [
	'settlementAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'marshalPoundage',
];

export const generateColumnTotals = (table, isTakenTable = false) =>
	utility.getColumnTotals(table, isTakenTable, columnSumFields);

export const generateRowTotals = row => utility.getRowTotals(row, rowSumFields);
