import React, { Component } from 'react';
import { Button, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory, {
	PaginationListStandalone,
	PaginationProvider,
	SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { MdLibraryAdd, MdSystemUpdate } from 'react-icons/md';
import { connect } from 'react-redux';
import { deleteParty, edit, getPage } from '../../actions/partyActions';
import { env } from '../../configs/env';
import * as service from '../../services/partyServices';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import { ExportXlsx } from '../dynamicFields/exportXlsx';
import * as utility from '../utility';
import PartyForm from './partyForm';
import { columnsConfig } from './partiesTableConfig';
import { GrUpdate } from 'react-icons/gr';

var initialState = {
	page: 1,
	pageSize: 25,
	editParty: null,
	modalShow: false,
	confirmationModalShow: false,
	deleteId: -1,
	viewMode: false,
	filters: {},
	selectedPartiesId: [],
	selectedParties: [],
};

const exportColumns = [
	'id',
	'companyName',
	'firstName',
	'lastName',
	'dateOfBirth',
	'taxId',
	'partyTypeName',
	'created',
	'createdByName',
	'modified',
	'modifiedByName',
	'isActive',
];

class PartiesPanel extends Component {
	state = { ...initialState };

	componentDidMount() {
		document.title = 'BabySteps - Bolnice';
		this.props.getPage({
			page: this.props.partyState.page,
			pageSize: this.props.partyState.pageSize,
			moduleId: this.props.moduleState.currentModule.id,
		});
	}

	componentWillUpdate() {
		document.title = 'BabySteps - Bolnice';
	}

	onPageChange = page => {
		this.props.getPage({
			page: page,
			pageSize: this.props.partyState.pageSize,
			moduleId: this.props.moduleState.currentModule.id,
		});
	};

	onPageSizeChange = pageSize => {
		var size = Math.ceil(this.props.partyState.parties.length / pageSize);
		if (size > this.state.page) size = this.state.page;
		this.props.getPage({
			page: size,
			pageSize: pageSize,
			moduleId: this.props.moduleState.currentModule.id,
		});
	};

	handleOpen = () => this.setState({ modalShow: true, editParty: null, viewMode: false });
	handleClose = () => this.setState({ modalShow: false });

	handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			if (this.state.selectedParties.length === 0 || row.partyTypeName === this.state.selectedPartyTypeName) {
				this.setState(() => ({
					selectedPartiesId: [...this.state.selectedPartiesId, row.id],
					selectedParties: [...this.state.selectedParties, row],
					selectedPartyTypeName: row.partyTypeName,
					selectedPartyTypeId: row.partyTypeId,
					selectedPartyTypeCode: row.partyTypeCode,
				}));
				console.log('row: ', row);
			}
			return false;
		} else {
			this.setState(() => ({
				selectedPartiesId: this.state.selectedPartiesId.filter(x => x !== row.id),
				selectedParties: this.state.selectedParties.filter(x => x.id !== row.id),
			}));
		}
	};

	handleOnSelectAll = (isSelect, rows) => {
		const ids = rows.map(r => r.id);
		if (isSelect) {
			this.setState(() => ({
				selectedPartiesId: ids,
				selectedParties: rows,
			}));
		} else {
			this.setState(() => ({
				selectedPartiesId: [],
				selectedParties: [],
			}));
		}
	};

	switchViewMode = viewMode => {
		this.setState({ viewMode });
	};

	confirmationHandleOpen = deleteId => this.setState({ deleteId, confirmationModalShow: true });
	confirmationHandleClose = () => this.setState({ confirmationModalShow: false });

	onEdit = (editId, viewMode = false) => {
		this.setState({
			editParty: this.props.partyState.parties.find(u => (u.id == editId ? u : null)),
			viewMode,
			modalShow: true,
		});
	};

	handleDelete = () => {
		this.props.deleteParty(this.state.deleteId, this.props.moduleState.currentModule.id);
		this.setState({ confirmationModalShow: false });
	};

	handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
		var filterValues = {};
		Object.keys(filters).forEach(element => {
			if (filters[element].filterType === 'DATE_FROM_TO') {
				filterValues[element + 'To'] = filters[element].filterVal.To;
				filterValues[element + 'From'] = filters[element].filterVal.From;
			}
			if (filters[element].filterType === 'CUSTOM_MULTISELECT') {
				var value = '';
				filters[element].filterVal.forEach(x => (value += x.value + ','));
				filterValues[element] = value;
			} else filterValues[element] = filters[element].filterVal;
		});

		this.setState({ filters: filterValues });
		if (page !== this.props.partyState.page || sizePerPage !== this.props.partyState.pageSize)
			this.handleSearch(page, sizePerPage);
	};

	handleSearch = (page, pageSize) => {
		const filters = this.state.filters ? this.state.filters : {};

		const request = {
			page,
			pageSize,
			...filters,
			moduleId: this.props.moduleState.currentModule.id,
		};

		this.props.getPage(request);
	};

	handleKeyDown = e => {
		if (e.keyCode === 13) {
			//ENTER
			const { page, pageSize } = this.props.partyState;
			this.handleSearch(page, pageSize);
		}
	};

	handleUpdate = row => {
		row.isActive = row.isActive ? false : true;
		this.props.update({
			...row,
			moduleId: this.props.moduleState.currentModule.id,
		});
	};

	isLoading = () => this.props.pendingState['PARTY_GET_PAGE']?.pending;

	render() {
		const columns = columnsConfig({
			confirmationHandleOpen: this.confirmationHandleOpen,
			handleUpdate: this.handleUpdate,
			delete: this.props.moduleState.currentModule.delete,
			update: this.props.moduleState.currentModule.update,
			search: true,
			isMassUpdate: false,
		});

		const selectRow = {
			mode: 'checkbox',
			clickToSelect: true,
			selected: this.state.selectedPartiesId,
			onSelect: this.handleOnSelect,
			hideSelectAll: true,
		};

		const rowEvents = {
			onDoubleClick: (e, row, rowIndex) => {
				window.open(env.BASE_PATH + 'bolnica/' + row.id + '?viewMode=true', '_blank');
			},
		};

		return (
			<PaginationProvider
				pagination={paginationFactory({
					custom: true,
					page: this.props.partyState.page,
					sizePerPage: this.props.partyState.pageSize,
					totalSize: this.props.partyState.dataSize,
					style: { hover: 'right' },
					sizePerPageList: utility.sizePerPageList,
				})}>
				{({ paginationProps, paginationTableProps }) => (
					<Container style={{ maxWidth: '100vw', marginTop: '20px' }} onKeyDown={this.handleKeyDown} tabIndex='0'>
						<Card>
							<Card.Header>
								<Card.Title>
									Bolnice
									<div className='float-right'>
										<Row>
											<Button
												size='sm'
												variant='info'
												className=''
												onClick={() => {
													const { page, pageSize } = this.props.partyState;
													this.handleSearch(page, pageSize);
												}}>
												<GrUpdate className='my-icon' /> Pretraži
											</Button>
											<ExportXlsx
												className='ml-2'
												data={this.props.partyState.parties}
												dateFields={['dateOfBirth', 'created', 'modified']}
												currencyIndices={[]}
												fieldsOrder={true}
												collectionName='parties'
												fields={exportColumns}
												isDropdown={true}
												request={() =>
													service.getPage({
														page: 1,
														pageSize: Math.pow(2, 31) - 1,
														...this.state.filters,
														moduleId: this.props.moduleState.currentModule.id,
													})
												}
											/>
											{this.props.moduleState.currentModule.create && (
												<Button size='sm' variant='primary' className='my-button ml-2' onClick={this.handleOpen}>
													<MdLibraryAdd style={{ fontSize: '1.2em' }} /> Dodaj novi
												</Button>
											)}
											{this.props.moduleState.currentModule.update && this.state.selectedParties.length != 0 && (
												<Button
													size='sm'
													variant='success'
													className=' ml-2'
													style={{ marginRight: '0.4em' }}
													onClick={() => {
														this.props.history.push({
															pathname: '/parties-mass-update',
															state: {
																moduleId: this.props.moduleId,
																userId: this.props.userId,
																selectedParties: this.state.selectedParties,
																partyTypeName: this.state.selectedPartyTypeName,
																partyTypeId: this.state.selectedPartyTypeId,
																partyTypeCode: this.state.selectedPartyTypeCode,
															},
														});
													}}>
													<MdSystemUpdate className='my-icon' /> Mass Update
												</Button>
											)}
										</Row>
									</div>
								</Card.Title>
							</Card.Header>
							<Card.Body>
								{this.isLoading() && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
								<BootstrapTable
									remote
									keyField='id'
									columns={columns}
									data={this.props.partyState.parties}
									className='table-body'
									rowEvents={rowEvents}
									selectRow={selectRow}
									bordered={true}
									striped
									hover
									condensed
									responsive
									filter={filterFactory()}
									onTableChange={this.handleTableChange}
									{...paginationTableProps}
								/>
								{this.isLoading() && this.props.partyState.parties?.length > 0 && (
									<ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />
								)}
							</Card.Body>
							<Card.Footer>
								<SizePerPageDropdownStandalone {...paginationProps} variation='dropup' />
								<PaginationListStandalone {...paginationProps} />
							</Card.Footer>
						</Card>

						<PartyForm
							moduleId={this.props.moduleState.currentModule.id}
							show={this.state.modalShow}
							onHide={this.handleClose}
							party={this.state.editParty}
							viewMode={this.state.viewMode}
							switchViewMode={this.switchViewMode}
							hover
						/>

						<ConfirmationModal
							show={this.state.confirmationModalShow}
							handleClose={this.confirmationHandleClose}
							confirmFunction={this.handleDelete}
							title='Delete Alert'
							message='Are you sure you want to delete the party?'
						/>
					</Container>
				)}
			</PaginationProvider>
		);
	}
}

const mapStateToProps = state => {
	return {
		partyState: state.partyReducer,
		moduleState: state.moduleReducer,
		pendingState: state.pendingReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPage: request => {
			dispatch(getPage(request));
		},
		deleteParty: (id, moduleId) => {
			dispatch(deleteParty(id, moduleId));
		},
		update: party => {
			dispatch(edit(party));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartiesPanel);
