import { Switch } from "react-router-dom";

const initialState = {
  firms: [],
  newFirm: null
};

const firmReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "FIRM_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        firms: [...action.payload.data]
      });
      break;
    case "FIRM_EDIT_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        firms: state.firms.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        })
      });
    case "FIRM_CREATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        firms: [action.payload.data, ...state.firms],
      });
      break;
    case "FIRM_DELETE_FULFILLED":
      var removeIndex = state.firms
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.firms.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        firms: [...state.firms]
      });
      break;
    case "USER_CREATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        newFirm: action.payload.data
      });
      break;
    default:
      return state;
      break;
  }
};

export default firmReducer;
