const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  templates: [],
  loading: false,
  automations: [],
  caseStatus: undefined
};

const templateReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "TEMPLATE_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        templates: [...action.payload.data.templates],
      });
      break;
    case "TEMPLATE_CREATE_FULFILLED":
      if (state.templates.length === state.pageSize)
        state.templates.splice(state.templates.length - 1, 1);
      return Object.assign({}, state, {
        ...state,
        templates: [action.payload.data, ...state.templates],
      });
      break;
    case "TEMPLATE_DELETE_FULFILLED":
      var removeIndex = state.templates
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.templates.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        templates: [...state.templates],
      });
      break;
    case "TEMPLATE_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        templates: state.templates.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        }),
      });
      break;
    case "TEMPLATE_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        templates: action.payload.data,
      });
      break;
    case "TEMPLATES_GET_BY_UG_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        templates: state.templates.map((item) => {
          let value = action.payload.data.find((x) => item.id === x.id);
          if (value)
            return { ...value, read: true };
          else
            return {
              ...item,
              read: false,
              generateCost: false
            };
        }),
        checkedAll: false
      });
      break;
    case "TEMPLATE_TOGGLE_PERMISSION":
      return Object.assign({}, state, {
        ...state,
        templates: state.templates.map((item) => {
          if (item.id == action.payload.moduleId) {
            if (action.payload.permissionType === "read") {
              let readValue = item["read"];
              return {
                ...item,
                generateCost: readValue ? false : item.generateCost,
                read: !readValue,
              };
            } else
              return {
                ...item,
                read: true,
                generateCost: !item.generateCost
              };
          }
          return item;
        }),
      });
      break;
    case "TEMPLATE_UPDATE_PERMISSIONS_PENDING":
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
      break;
    case "TEMPLATE_UPDATE_PERMISSIONS_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        loading: false,
      });
      break;
    case "TEMPLATE_GET_FIRMS_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        templates: state.templates.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return {
            ...item,
            firms: action.payload.data.firms.map((x) => {
              return { ...x, value: x.id, label: x.name };
            }),
          };
        }),
      });
      break;
    case "TEMPLATE_UPDATE_FIRMS_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        templates: state.templates.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return {
            ...item,
            firmNames: action.payload.data.firms.map((x) => {
              return { firmName: x.name }
            }).reduce((acc, val) => acc + val.firmName + "&#x0D;", "")
          };
        }),
      });
      break;
    case "TEMPLATE_AUTOMATION_GET_FULFILLED":
      var status = action.payload.data.find(x => x.automationType === "status")
      if (!status) status = { value: -1, label: "" }
      return Object.assign({}, state, {
        ...state,
        automations: [...action.payload.data],
        caseStatus: { value: status.caseStatusId, label: status.caseStatusName }
      })
      break;
    case "TEMPLATE_AUTOMATION_CREATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        caseStatus: action.payload.data.automationType === "status" ?
          { value: action.payload.data.caseStatusId, label: action.payload.data.caseStatusName } : state.caseStatus,
        automations: [action.payload.data, ...state.automations],
      });
      break;
    case "TEMPLATE_AUTOMATION_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        caseStatus: action.payload.data.automationType === "status" ?
          { value: action.payload.data.caseStatusId, label: action.payload.data.caseStatusName } : state.caseStatus,
        automations: state.automations.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        }),
      });
      break;
    case "TEMPLATE_AUTOMATION_DELETE_FULFILLED":
      var removeIndex = state.automations
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.automations.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        automations: [...state.automations],
      });
    case "TEMPLATE_TOGGLE_ALL_PERMISSION":
      return Object.assign({}, state, {
        ...state,
        templates: state.templates.map((item) => {
          return {
            ...item,
            read: !state.checkedAll,
            generateCost: !state.checkedAll,
          };
        }),
        checkedAll: !state.checkedAll
      });
      break;
      break;
    default:
      return state;
      break;
  }
};

export default templateReducer;
