import axios from "axios";
import { env } from "../configs/env";


export function getPage(request){
  return {
    type: "REFERRAL_GET_PAGE",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral/page", {
      method: "POST",
      data: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function create(referral) {
  return {
    type: "REFERRAL_CREATE",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral", {
      method: "POST",
      data: JSON.stringify(referral),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function deleteReferral(referralId) {
  return {
    type: "REFERRAL_DELETE",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral/"+referralId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function update(referral) {
  return {
    type: "REFERRAL_UPDATE",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral", {
      method: "PATCH",
      data: JSON.stringify(referral),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}