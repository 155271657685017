import axios from 'axios';
import { env } from '../configs/env';

export function postCheck(check) {
	return axios(env.SERVER_ENV.url + '/api/check', {
		method: 'POST',
		data: JSON.stringify(check),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function edit(check) {
	return axios(env.SERVER_ENV.url + '/api/check', {
		method: 'PATCH',
		data: JSON.stringify(check),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getOutChecksForPrinting(request) {
	return axios(env.SERVER_ENV.url + '/api/check/page/out', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCheckOut(id) {
	return axios(env.SERVER_ENV.url + '/api/check/out/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function printCheckOut(request) {
	return axios(env.SERVER_ENV.url + '/api/check/out/print', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function voidCheckOut(request) {
	return axios(env.SERVER_ENV.url + '/api/check/out/void', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function clearChecks(request) {
	return axios(env.SERVER_ENV.url + '/api/check/clear', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function remittCheck(request) {
	return axios(env.SERVER_ENV.url + '/api/check/remitt', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPageRemittance(request) {
	return axios(env.SERVER_ENV.url + '/api/check/page/remittance', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function generateInvoices(request) {
	return axios(env.SERVER_ENV.url + '/api/check/generateInvoice', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function depositChecks(request) {
	return axios(env.SERVER_ENV.url + '/api/check/deposit', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPage(request) {
	const utcProps = ['checkDateFrom', 'checkDateTo', 'clearedFrom', 'clearedTo'];
	const getUTCDate = date =>
		date
			? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
			: null;

	utcProps.forEach(x => (request[x] = getUTCDate(request[x])));

	return axios(env.SERVER_ENV.url + '/api/check/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getAvailableRecdAmount(caseId) {
	return axios(env.SERVER_ENV.url + '/api/check/available-amount/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
