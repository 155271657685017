const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  paymentTypes: [],
  newPaymentType: null,
};

const paymentTypeReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "PAYMENT_TYPE_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        paymentTypes: [
          ...action.payload.data.paymentTypes.map((x) => {
            return {
              ...x,
              paymentTypeCategory: {
                label: x.paymentTypeCategory,
                value: x.paymentTypeCategoryId,
              },
            };
          }),
        ],
      });
      break;
    case "PAYMENT_TYPE_CREATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        newPaymentType: {
          ...action.payload.data,
          paymentTypeCategory: {
            label: action.payload.data.paymentTypeCategory,
            value: action.payload.data.paymentTypeCategoryId,
          },
        },
      });
      break;
    case "PAYMENT_TYPE_DELETE_FULFILLED":
      var removeIndex = state.paymentTypes
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.paymentTypes.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        paymentTypes: [...state.paymentTypes],
      });
      break;
    case "PAYMENT_TYPE_EDIT_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        paymentTypes: state.paymentTypes.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return {
            ...action.payload.data,
            paymentTypeCategory: {
              label: action.payload.data.paymentTypeCategory,
              value: action.payload.data.paymentTypeCategoryId,
            },
          };
        }),
      });
      break;
    case "PAYMENT_TYPE_GET_VGS_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        paymentTypes: state.paymentTypes.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return {
            ...item,
            viewGroups: action.payload.data.viewGroups,
            paymentTypeCategory: {
              label: action.payload.data.paymentTypeCategory,
              value: action.payload.data.paymentTypeCategoryId,
            },
          };
        }),
      });
      break;
    default:
      return state;
      break;
  }
};

export default paymentTypeReducer;
