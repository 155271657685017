const pendingReducer = (state = { lastActionName: "" }, action) => {
  const { type } = action;
  const actionName = type.split("_").slice(0, -1).join("_");

  if (!actionName) {
    return {
      ...state,
    };
  }

  if (type.endsWith("_PENDING")) {
    return {
      ...state,
      [actionName]: {
        pending: true,
        success: false,
      },
      lastActionName: actionName,
    };
  }

  if (type.endsWith("_FULFILLED") || type.endsWith("_REJECTED")) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        success: type.endsWith("_FULFILLED"),
      },
      lastActionName: actionName,
    };
  }

  return {
    ...state,
  };
};

export default pendingReducer;
