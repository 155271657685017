import moment from 'moment';
import React, { Component } from 'react';
import { Alert, Button, Col, Container, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { FiCheck, FiDownload, FiEye, FiRepeat, FiX } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
	addPayment,
	clearInvoice,
	get,
	updateIssedCheckOnInvoices,
	updatePaymentValue,
} from '../../actions/invoiceActions';
import { getCheckOut, voidCheckOut } from '../../services/checkServices';
import { clearInvoicePayments, emailInvoice } from '../../services/invoiceServices';
import AlertGenerator from '../alertGenerator/AlertGenerator';
import { createChecks } from '../checks/checkGenerator';
import { generateInvoices as generate, getInvoiceBlob } from '../checks/invoiceGenerator';
import DateInput from '../checks/utils/DateInput';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import { CurrencyField } from '../dynamicFields/currencyField';
import { DateField } from '../dynamicFields/dateField';
import { selectStyle } from '../dynamicFields/selectStyle';
import * as utility from '../utility';

var initialState = {
	id: -1,
	action: '',
	validated: false,
	new: false,
	amountInput: 0.0,
	cleared: { value: false, label: 'Not Cleared' },
	confirmationModalShow: false,
	emailConfirmationModalShow: false,
	showAlert: false,
};

class InvoiceForm extends Component {
	state = { ...initialState };

	componentDidMount() {}

	//Set state depending if it's user creation or update
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.invoice == null)
			if (prevState.id !== -1 && !prevState.new) return { ...initialState };
			else return null;
		if (nextProps.invoice.id === prevState.id) return null;
		nextProps.get(nextProps.invoice.id);
		return {
			id: nextProps.invoice.id,
			amountInput: 0.0,
			cleared: {
				value: nextProps.invoice.isCleared,
				label: nextProps.invoice.isCleared ? 'Cleared' : 'Not Cleared',
			},
			clearDate: nextProps.invoice.clearDate,
			showAlert: false,
		};
	}

	confirmationHandleOpen = () => this.setState({ confirmationModalShow: true });
	confirmationHandleClose = () => this.setState({ confirmationModalShow: false });

	emailConfirmationHandleOpen = () => this.setState({ emailConfirmationModalShow: true });
	emailConfirmationHandleClose = () => this.setState({ emailConfirmationModalShow: false });

	handleOnSelect = (name, value) => {
		this.setState({ [name]: value, isChanged: true });
	};

	handleOnChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleOnCurrencyChange = values => {
		const { formattedValue, value } = values;
		this.setState({
			amountInput: parseFloat(value),
		});
	};

	handleOnDateChange = (date, name) => {
		this.setState({
			[name]: new Date(date),
		});
	};

	handleOnChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	close = () => {
		this.state = { ...initialState };
		this.props.onHide();
	};

	onAddPayment = () => {
		this.props.addPayment({
			invoiceId: this.props.invoiceState.invoice.id,
			amount: this.state.amountInput,
			date: this.state.date,
			checkNumber: this.state.checkNumber,
		});
		this.setState({ amountInput: 0.0 });
	};

	handleOnClear = () => {
		this.props.clearInvoice({
			id: this.props.invoiceState.invoice.id,
			clearDate: this.state.clearDate,
		});
		this.confirmationHandleClose();
	};

	handleOnClearPayments = () => {
		this.setState({ loading: true });
		let request = {
			moduleId: this.props.moduleState.currentModule.id,
		};

		let payments = [...this.props.invoiceState.invoice.payments];
		payments = payments.filter(x => x.isCleared === false && x.cleared.value === 'true' && x.clearDate !== null);

		payments = payments.map(x => {
			return { invoicePaymentId: x.id, clearDate: x.clearDate };
		});

		request.invoicePaymentClearRequest = payments;

		clearInvoicePayments(request)
			.then(() => {
				this.setState({ loading: false });
				this.props.get(this.props.invoiceState.invoice.id);
			})
			.catch(() => {
				this.setState({
					loading: false,
					errMessage: 'Clear Payments Failed! Please Contact The Administrator!',
				});
			});

		this.setState({ confirmationModalShow: false });
	};

	resendInvoiceEmail = () => {
		const { invoice } = this.props.invoiceState;
		this.setState({ loading: true, emailConfirmationModalShow: false });

		getInvoiceBlob(invoice, blob => {
			emailInvoice(blob, invoice)
				.then(() => {
					this.setState({
						showAlert: true,
						alertVariant: 'success',
						alertMessage: 'Email(s) has(have) been sent successfully!',
						loading: false,
					});
				})
				.catch(err => {
					this.setState({
						showAlert: true,
						alertVariant: 'danger',
						alertMessage: 'Sending email failed! Please contact the administrator!',
						loading: false,
					});
					console.log('Error -', err);
				});
		});
	};

	getEmailConfirmationMessage = () => {
		const { invoice } = this.props.invoiceState;

		if (invoice.providerEmail)
			return (
				<div>
					<div>{'Are you sure you want to resend this invoice to ' + invoice.providerEmail + '?'}</div>
					<div>{invoice.provider ? '(' + invoice.provider + ')' : ''}</div>
				</div>
			);
		return 'Email for selected provider not found.';
	};

	getInvoice = () => {
		const invoice = this.props.invoiceState.invoice ? this.props.invoiceState.invoice : null;
		this.setState({ loading: true });

		getInvoiceBlob(invoice)
			.then(res => {
				this.setState({ loading: false });
				utility.previewBlob(res, invoice.invoiceNo);
			})
			.catch(() => this.setState({ loading: false }));
	};

	isLoading = () => this.props.pendingState['INVOICE_GET']?.pending;

	render() {
		const columns = [
			{
				dataField: 'amount',
				text: 'Amount',
				editable: false,
				formatter: (_, row) => {
					return (
						<div className='text-right'>
							<NumberFormat
								value={row.amount}
								displayType={'text'}
								thousandSeparator={true}
								prefix={'$'}
								fixedDecimalScale={true}
								decimalScale={2}
							/>
						</div>
					);
				},
			},
			{
				dataField: 'checkNumber',
				text: 'Check Number',
				editable: false,
			},
			{
				dataField: 'paymentDate',
				text: 'Payment Date',
				editable: false,

				formatter: (_, row) => {
					return moment.utc(row.paymentDate).local().format('L');
				},
			},
			{
				dataField: 'clearDate',
				text: 'Clear Date',
				formatter: cellContent => {
					if (cellContent === null || /^\s+$/.test(cellContent)) return '';
					return moment.utc(cellContent).local().format('ll');
				},
				editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
					<DateInput {...editorProps} value={value} maxDate={new Date()} />
				),
			},
			{
				dataField: 'cleared',
				text: 'Clear Status',
				formatter: (cellContent, row) => (row.isDeposited ? cellContent.label : 'Not Cleared - Posted'),
				editor: {
					type: Type.SELECT,
					options: [
						{
							value: false,
							label: 'Not Cleared - Deposited',
						},
						{
							value: true,
							label: 'Cleared',
						},
					],
				},
			},
			{
				dataField: 'created',
				text: 'Created',
				editable: false,
				formatter: (_, row) => {
					return moment.utc(row.created).local().format('L') + ' by ' + row.createdByName;
				},
			},
		];

		var invoice = this.props.invoiceState.invoice ? this.props.invoiceState.invoice : null;

		return (
			this.props.invoiceState.invoice && (
				<Modal show={this.props.show} onHide={this.close} size='xl' centered>
					<Modal.Header>
						<Button variant='info' onClick={this.emailConfirmationHandleOpen}>
							Resend invoice to provider
						</Button>
					</Modal.Header>

					<Modal.Body>
						<Container>
							<Row>
								<Container>
									<AlertGenerator />
								</Container>
							</Row>

							<Container>
								<Alert
									show={this.state.showAlert}
									className='alert-block'
									variant={this.state.alertVariant ? this.state.alertVariant : 'danger'}
									onClose={() => this.setState({ showAlert: false })}
									dismissible>
									{this.state.alertMessage}
								</Alert>
							</Container>

							{this.isLoading() && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
							<Form id='user-form' disabled={this.props.viewMode}>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label className='form-label'>Invoice Number</Form.Label>
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Invoice No'
												name='invoiceNo'
												value={invoice.invoiceNo}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label className='form-label'>Amount</Form.Label>
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Amount'
												name='amount'
												value={'$' + invoice.amount.toFixed(2)}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label className='form-label'>Status</Form.Label>
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Status'
												name='status'
												value={invoice.status}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										{invoice.isRemittance && (
											<>
												<Form.Group>
													<Form.Label>Clear Status</Form.Label>
													<Select
														value={this.state.cleared}
														options={[
															{ value: true, label: 'Cleared' },
															{
																value: false,
																label: 'Not Cleared - Deposited',
															},
														]}
														styles={selectStyle({})}
														name='cleared'
														onChange={value => this.handleOnSelect('cleared', value)}
														isDisabled={invoice.isCleared}
													/>
												</Form.Group>

												<DateField
													label={'Clear Date'}
													value={this.state.clearDate}
													showTimeSelect={false}
													onChange={date => this.handleOnDateChange(date, 'clearDate')}
													viewMode={!this.state.cleared.value || invoice.isCleared}
													maxDate={new Date()}
													dateFormat
												/>
											</>
										)}
									</Col>

									<Col>
										<Form.Group>
											<Form.Label className='form-label'>Provider</Form.Label>
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Provider'
												name='provider'
												value={invoice.provider}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label className='form-label'>Invoiced By</Form.Label>
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Invoiced By'
												name='invoicedByName'
												value={invoice.invoicedByName}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label className='form-label'>Invoiced</Form.Label>
											<Form.Control
												required
												size='sm'
												type='text'
												placeholder='Invoiced'
												name='invoiced'
												value={moment.utc(invoice.invoiced).local().format('L')}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										{invoice.isRemittance &&
											(this.state.reIssue ? (
												<Form.Group>
													<Form.Label className='form-label'>Issued Check No</Form.Label>
													<div className='d-flex'>
														<Form.Control
															required
															key='newCheckLabel'
															size='sm'
															className='mb-0'
															type='text'
															name='checkNumber'
															placeHolder='New Check Number'
															value={this.state.checkNumber}
															onChange={this.handleOnChange}
														/>

														<Button
															size='sm'
															title='Cancel'
															onClick={() => this.setState({ reIssue: false })}
															variant='danger'
															className='ml-1 align-self-start'>
															<FiX />
														</Button>
														<Button
															size='sm'
															title='Submit'
															onClick={() => {
																voidCheckOut({
																	id: invoice.issuedCheckId,
																	checkNumber: this.state.checkNumber,
																}).then(response => {
																	utility.openPdf(createChecks([response.data]), invoice.invoiceNo);

																	this.props.updateIssedCheckOnInvoices({
																		id: invoice.issuedCheckId,
																		newId: response.data.id,
																		newCheckNumber: response.data.checkNumber,
																	});
																	this.setState({
																		reIssue: false,
																		checkNumber: undefined,
																	});
																});
															}}
															variant='success'
															className='ml-1 align-self-start'>
															<FiCheck />
														</Button>
													</div>
												</Form.Group>
											) : (
												<Form.Group>
													<Form.Label className='form-label'>Issued Check No</Form.Label>
													<div className='d-flex'>
														<Form.Control
															required
															size='sm'
															className='mb-0'
															type='text'
															name='status'
															value={invoice.issuedCheckNumber}
															onChange={this.handleOnChange}
															disabled={this.props.viewMode}
														/>

														<Button
															size='sm'
															title='Reissue check'
															onClick={() => this.setState({ reIssue: true })}
															variant='danger'
															className='ml-1 align-self-start'>
															<FiRepeat />
														</Button>
														<Button
															size='sm'
															title='View check'
															onClick={() =>
																getCheckOut(invoice.issuedCheckId).then(res =>
																	utility.openPdf(createChecks([res.data]), invoice.invoiceNo)
																)
															}
															variant='success'
															className='ml-1 align-self-start'>
															<FiEye />
														</Button>
													</div>
												</Form.Group>
											))}
									</Col>
								</Row>
								{!invoice.isRemittance && (
									<Row>
										<Col>
											<Form.Label className='form-label'>Payments:</Form.Label>
											<BootstrapTable
												keyField='id'
												columns={columns}
												data={invoice.payments ? invoice.payments : []}
												className='table-body'
												bordered={true}
												striped
												hover
												condensed
												responsive
												cellEdit={cellEditFactory({
													mode: 'click',
													blurToSave: true,
													nonEditableRows: () =>
														invoice.payments
															? invoice.payments.map(x => (x.isCleared || !x.isDeposited ? x.id : undefined))
															: [],
													afterSaveCell: (oldValue, newValue, row, column) => {
														if (column.dataField === 'cleared') {
															row.cleared = {
																value: newValue,
																label: newValue === 'true' ? 'Cleared' : 'Not Cleared',
															};
														} else row = { ...row, [column.dataField]: newValue };
														this.props.updateValue(row);
													},
												})}
											/>
											<div>
												<Form id='payment'>
													<Row className='align-right'>
														<Col sm={6} md={3}>
															<CurrencyField
																label='Amount'
																value={this.state.amountInput}
																name='amount'
																onChange={this.handleOnCurrencyChange}
																isAllowed={values => {
																	const { formattedValue, value } = values;
																	return parseFloat(value) > 0.0;
																}}
															/>
														</Col>
														<Col sm={6} md={3}>
															<DateField
																label='Payment Date'
																value={this.state.date}
																onChange={date => this.handleOnDateChange(date, 'date')}
															/>
														</Col>
														<Col sm={6} md={3}>
															<Form.Label>Check Number</Form.Label>
															<Form.Control
																size='sm'
																type='text'
																placeholder='Check Number'
																name='checkNumber'
																value={this.state.checkNumber}
																onChange={this.handleOnChange}
															/>
														</Col>
														<Col sm={6} md={3} style={{ alignSelf: 'center' }}>
															<Button
																onClick={this.onAddPayment}
																disabled={
																	this.state.amountInput <= 0 ||
																	!this.state.checkNumber ||
																	this.state.checkNumber.trim().length === 0
																}>
																Add Payment
															</Button>
														</Col>
													</Row>
												</Form>
											</div>
										</Col>
									</Row>
								)}
							</Form>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button size='sm' variant='secondary' onClick={this.close}>
							Close
						</Button>
						<Button size='sm' className='ml-1 mb-1' onClick={() => generate(invoice)}>
							<FiDownload />
						</Button>
						<Button size='sm' onClick={this.getInvoice} variant='success' className='ml-1 mb-1'>
							<FiEye />
						</Button>
						<Button
							size='sm'
							id='submitButton'
							variant='primary'
							disabled={
								(invoice.isRemittance &&
									!this.props.invoiceState.invoice.isCleared &&
									(!this.state.cleared.value || !this.state.clearDate)) ||
								(!invoice.isRemittance &&
									!invoice.payments.some(
										x => x.isCleared === false && x.cleared.value === 'true' && x.clearDate !== null
									))
							}
							onClick={this.confirmationHandleOpen}>
							{invoice.isRemittance ? 'Clear Invoice' : 'Clear Payments'}
						</Button>
					</Modal.Footer>
					<ConfirmationModal
						show={this.state.confirmationModalShow}
						handleClose={this.confirmationHandleClose}
						confirmFunction={invoice.isRemittance ? this.handleOnClear : this.handleOnClearPayments}
						title='Clear Alert'
						message={
							invoice.isRemittance
								? 'Are you sure you want to clear the invoice?'
								: 'Are you sure you want to clear payments?'
						}
					/>
					<ConfirmationModal
						show={this.state.emailConfirmationModalShow}
						handleClose={this.emailConfirmationHandleClose}
						confirmFunction={this.resendInvoiceEmail}
						title='Resend Invoice to Provider Alert'
						message={this.getEmailConfirmationMessage()}
						disabledConfirm={!invoice.providerEmail}
					/>
				</Modal>
			)
		);
	}
}

const mapStateToProps = state => {
	return {
		pendingState: state.pendingReducer,
		invoiceState: state.invoiceReducer,
		moduleState: state.moduleReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		get: id => {
			dispatch(get(id));
		},
		addPayment: request => {
			dispatch(addPayment(request));
		},
		clearInvoice: request => {
			dispatch(clearInvoice(request));
		},
		updateValue: row => {
			dispatch(updatePaymentValue(row));
		},
		updateIssedCheckOnInvoices: req => {
			dispatch(updateIssedCheckOnInvoices(req));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
