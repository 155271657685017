import moment from 'moment';

const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	tasks: [],
	isGeneralModule: false,
	taskTypeInstructions: null,
	taskSubtypeInstructions: null,
	taskDocuments: [],
	isCaseTask: null,
	isProviderTask: null,
	providerName: null,
	files: [],
	groupAssociationIds: [],
	task: {
		files: [],
		comments: null,
		dueDate: null,
		taskStatusOption: null,
		case: null,
		taskTypeOption: null,
		userAssignments: [],
		userGroupAssignments: [],
	},
	newTasks: [],
	loadDynFields: false,
};

const tasksReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'TASK_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'TASK_GET_FULFILLED':
			const task = action.payload.data && action.payload.data.length > 0 ? action.payload.data[0] : {};
			return Object.assign({}, state, {
				...state,
				task: {
					...state.task,
					...task,
					taskTypeOption: { value: task.taskTypeId, label: task.taskType },
					taskSubtypeOption: {
						value: task.taskSubtypeId,
						label: task.taskSubtype,
					},
					taskStatusOption: {
						value: task.taskStatusId,
						label: task.taskStatus,
					},
					case: { value: task.caseId, label: task.caseNo },
					dueDate: moment.utc(task.dueDate).local().format('L LT'),
					tabNames: null,
				},
			});
			break;
		case 'TASK_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				tasks: [action.payload.data, ...state.tasks],
				task: { ...state.task, ...action.payload.data },
			});
			break;
		case 'TASK_MASS_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				tasks: [...action.payload.data, ...state.tasks],
				newTasks: [...action.payload.data]
			});
			break;
		case 'CLEAR_TASK':
			return Object.assign({}, state, {
				...state,
				task: { ...initialState.task },
				taskDocuments: [],
				directorySize: 0,
				isCaseTask: null
				
			});
			break;
		case 'TASK_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				tasks: state.tasks.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'TASK_DELETE_FULFILLED':
			var removeIndex = state.tasks
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.tasks.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				tasks: [...state.tasks],
			});
			break;
		case 'TASK_GET_USER_ASSIGNMENTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				task: {
					...state.task,
					userAssignments: action.payload.data.userAssignments.map(x => ({
						...x,
						value: x.id,
						label: x.shortName,
					})),
				},
			});
			break;
		case 'TASK_UPDATE_USER_ASSIGNMENTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				tasks: state.tasks.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userAssignmentNames: action.payload.data.userAssignments
							.map(x => {
								return { UAName: x.name };
							})
							.reduce((acc, val) => acc + val.UAName + '&#x0D;', ''),
					};
				}),
			});
			break;
		case 'TASK_GET_USER_GROUP_ASSIGNMENTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				task: {
					...state.task,
					userGroupAssignments: action.payload.data.userGroupAssignments.map(x => ({
						...x,
						value: x.id,
						label: x.shortName,
					})),
				},
			});
			break;
		case 'TASK_UPDATE_USER_GROUP_ASSIGNMENTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				tasks: state.tasks.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userGroupAssignmentNames: action.payload.data.userGroupAssignments
							.map(x => {
								return { UAName: x.name };
							})
							.reduce((acc, val) => acc + val.UAName + '&#x0D;', ''),
					};
				}),
			});
			break;
		case 'SET_GENERAL_TASKS_MODE':
			return Object.assign({}, state, {
				...state,
				isGeneralModule: true,
			});
			break;
		case 'TASK_SUBTYPE_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				taskSubtypeInstructions: action.payload.data.instructions,
				isFileAssociation: action.payload.data.isFileAssociation,
			});
			break;
		case 'TASK_TYPE_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				taskTypeInstructions: action.payload.data.instructions,
				isCaseTask: action.payload.data.isCaseTask,
				isProviderTask: action.payload.data.isProviderTask,
				...action.payload.data,
			});
			break;
		case 'TASK_DOCUMENTS_GET_FULFILLED':
			const data = action.payload.data;
			return Object.assign({}, state, {
				...state,
				taskDocuments: data.documents && data.documents.length > 0 ? [...data.documents] : [],
				directorySize: data.directorySize ? data.directorySize : 0,
			});
			break;
		case 'TASK_DOCUMENTS_GET_REJECTED':
			return Object.assign({}, state, {
				...state,
				taskDocuments: [],
				directorySize: 0,
			});
			break;
		case 'TASK_UPDATE_STATUS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				tasks: state.tasks.map((item, index) => {
					if (item.id != action.payload.data) return item;
					return { ...item, taskStatus: 'Completed' };
				}),
			});
			break;
		case 'TASK_SET_STATE':
			return Object.assign({}, state, {
				task: {
					...state.task,
					...action.payload,
				},
			});
			break;
		default:
			return state;
			break;
	}
};

export default tasksReducer;
