import axios from "axios";
import { env } from "../configs/env";

export function createCode(codeTable) {
  return axios(env.SERVER_ENV.url + "/api/codeTable", {
    method: "POST",
    data: JSON.stringify(codeTable),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}
