import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { codeLoginWithPromise, login, loginWithPromise } from '../../actions/userActions';
import { hasContract } from '../../services/partyServices';
import { generateCode } from '../../services/surveyServices';
import { getLoginRedirect } from '../../services/userServices';
import './login.css';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import SelectField from '../dynamicFields/selectField';

import { env } from '../../configs/env';

class Login extends Component {
	constructor(props) {
		super(props);
		this.messages = React.createRef();
		this.toast = React.createRef();
	}

	handleGenerateQRCode = () => {
		if (this.state.party) {
			this.setState({ loading: true });

			generateCode(this.state.party.value, 1).then(res => {
				this.setState({ loading: false });
				console.log(res.data);
				this.handleLoginWithCode(res.data[0].key);
			});
		}
	};

	accept = () => {
		this.handleGenerateQRCode();
	};

	reject = () => {
		this.toast.current.show({
			severity: 'warn',
			summary: 'Hvala!',
			sticky: true,
			detail: (
				<div>
					Hvala Vam na interesovanju. Nažalost ovaj sistem se ne odnosi na period Vašeg poroda. Ukoliko ipak želite
					podijeliti iskustvo ili prijaviti korupciju u porodilištima, možete to uraditi ovdje:
					<br />
					<br />
					<a href='https://babysteps.ba/iskustva' target='_blank'>
						https://babysteps.ba/iskustva
					</a>
				</div>
			),
		});
	};

	confirm = () => {
		confirmDialog({
			acceptLabel: 'Da',
			rejectLabel: 'Ne',
			message: this.state.contractMessage,
			icon: 'pi pi-exclamation-triangle',
			accept: this.accept,
			reject: this.reject,
		});
	};

	state = {
		password: '',
		username: '',
		path: '',
		party: null,
		hasContract: false,
	};

	componentDidMount() {
		const { location } = this.props;
		if (location.state) this.setState({ path: location.state.referrer + location.search });
		document.title = 'BabySteps - Prijava';
	}

	componentWillUpdate() {
		document.title = 'BabySteps - Prijava';
	}

	componentWillMount() {
		//?code=XXXXXX
		var code = this.props.location.search.slice(6);

		if (code.length === 8) {
			this.handleLoginWithCode(code);
		}
	}

	handleOnChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleOnSelect = (name, data) => {
		if (data)
			hasContract(data.value).then(res => {
				this.setState({ ...res.data, [name]: data });
			});
	};

	handleContinue = e => {
		this.handleLoginWithCode(this.state.code);
	};

	handleLoginWithCode = code => {
		this.setState({ loading: true });
		codeLoginWithPromise(code)
			.then(res => {
				this.setState({ loading: false });
				if (
					(this.props.currentModule.id === -1 || this.props.currentModule === '/login') &&
					this.state.path !== env.BASE_PATH
				) {
					this.props.history.push('/anketa');
				} else if (this.props.currentModule.id === -1 || this.props.currentModule === '/login') {
					this.props.history.push(getLoginRedirect(res.data.token));
				}
			})
			.catch(err => {
				this.setState({
					loading: false,
				});
				this.messages.current.show([
					{
						life: 5000,
						severity: 'error',
						detail: 'Greška: Molimo Vas da kontaktirate administratora!',
					},
				]);
			});
	};

	handleOnSubmit = event => {
		event.preventDefault();

		let credentials = {
			username: this.state.username,
			password: this.state.password,
		};

		this.setState({ loading: true });
		loginWithPromise(credentials)
			.then(res => {
				this.setState({ loading: false });

				if (
					(this.props.currentModule.id === -1 || this.props.currentModule === '/login') &&
					this.state.path !== env.BASE_PATH
				) {
					// this.props.history.push('/' + this.state.path?.replace(env.BASE_PATH, ''));
					this.props.history.push('/izvjestaj/1');
				} else if (this.props.currentModule.id === -1 || this.props.currentModule === '/login') {
					this.props.history.push(getLoginRedirect(res.data.token));
				}
			})
			.catch(err => {
				this.setState({
					loading: false,
				});
				this.messages.current.show([
					{
						life: 5000,
						severity: 'error',
						detail: 'Greška: Molimo Vas da kontaktirate administratora!',
					},
				]);
			});
	};

	handleOnClick = e => {
		e.preventDefault();
		this.setState({
			forHospitals: !this.state.forHospitals,
		});
	};

	render() {
		return (
			<div className='login-wrapper login-form'>
				<Toast ref={this.toast} />
				<ConfirmDialog />

				<Card className='field p-fluid' title='Dobrodošli!'>
					<div className='divider field'></div>
					<div className='text-secondary text-md text-center field p-fluid'>
						Platforma za ocjenu porodilišta i prijavu korupcije je namijenjena porodiljama koje su se porodile nakon{' '}
						<strong>01.03.2023.</strong> u nekom od niže ponuđenih porodilišta. Ukoliko se niste porodili nakon
						navedenog datuma ili u jednom od navedenih porodilišta, svoje iskustvo možete podijeliti ovdje:<br></br>
						<a href='https://babysteps.ba/iskustva/podijeli-svoje-iskustvo/' target='_blank'>
							babysteps.ba/iskustva/podijeli-svoje-iskustvo
						</a>
					</div>
					<div className='divider field'></div>
					<SelectField
						className='d-flex flex-row'
						class='d-inline w-100'
						value={this.state.party}
						name='party'
						onChange={value => this.handleOnSelect('party', value)}
						dataTable={'prtParties_All'}
						dataField={'name'}
						placeholder={'Odaberite porodilište...'}
						viewMode={this.props.viewMode}
					/>

					{this.state.party !== null ? (
						this.state.hasContract ? (
							<>
								{this.state.loading && <ProgressBar mode='indeterminate' style={{ height: '3px' }}></ProgressBar>}
								<div className='field'>
									<Messages ref={this.messages} />
								</div>
								<div className='field'>
									<div className='p-inputgroup'>
										<InputText
											type='text'
											name='code'
											value={this.state.code}
											className='p-inputtext-sm'
											placeholder='Unesite kod...'
											onChange={this.handleOnChange}
										/>
										<Button label='Dalje' onClick={this.handleContinue} />
									</div>
									<div className='text-secondary text-xs text-center'>
										Ukoliko niste dobili QR kod, molimo da se javite na e-mail
										<a href='mailto: info@babysteps.ba'> info@babysteps.ba</a> ili{' '}
										<a href='https://www.facebook.com/udruzenjebabysteps'>Facebook profil</a>.
									</div>
								</div>
								<div className='divider field'></div>
								<form onSubmit={this.handleOnSubmit}>
									<div className='mt-2 mb-2 text-center'>
										<a href='' className='text-color-secondary' onClick={this.handleOnClick}>
											Prijava za porodilišta
											<icon
												className={
													this.state.forHospitals
														? 'pi pi-chevron-up ml-2'
														: 'pi pi-chevron-down ml-2 vertical-align-middle'
												}></icon>
										</a>
									</div>
									{this.state.forHospitals && (
										<>
											<div className='field'>
												<InputText
													type='text'
													className='p-inputtext-sm'
													placeholder='Korisničko ime'
													name='username'
													value={this.state.username}
													onChange={this.handleOnChange}
												/>
											</div>
											<div className='field'>
												<InputText
													type='password'
													className='p-inputtext-sm'
													placeholder='Lozinka'
													name='password'
													value={this.state.password}
													onChange={this.handleOnChange}
												/>
											</div>
											<div className='text-center field'>
												<Button type='submit' className='mb-2' label='Prijavi se' />
												<Link to='/reset-password' className='text-primary'>
													Zaboravili ste lozinku?
												</Link>
											</div>
										</>
									)}
								</form>
							</>
						) : (
							this.confirm()
						)
					) : null}
				</Card>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		pendingState: state.pendingReducer,
		currentModule: state.moduleReducer.currentModule,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		login: credentials => {
			dispatch(login(credentials));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
