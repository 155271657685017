import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { MdDelete } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import {
	addReferral,
	editReferral,
	getAttorneyFeeCollections,
	getBillingFeeCollections,
	getFirmFeeCollections,
	removeReferral,
} from '../../actions/partyActions';
import { create, deleteReferral, update } from '../../actions/referralActions';
import { env } from '../../configs/env';
import NumberInput from '../checks/utils/NumberInput';
import SelectField from '../dynamicFields/selectField';

class PartyReferrals extends Component {
	state = {
		updated: false,
		newId: -1,
		viewMode: true,
	};

	componentWillReceiveProps(nextProps) {
		this.setState({ viewMode: nextProps.viewMode });
	}

	componentWillMount() {
		if (this.props.type === 'Firm Fee')
			this.props.getFirmFeeCollections({
				partyIds: this.props.entityId + ',',
			});
		if (this.props.type === 'Attorney Fee')
			this.props.getAttorneyFeeCollections({
				partyIds: this.props.entityId + ',',
			});
		if (this.props.type === 'Billing Fee')
			this.props.getBillingFeeCollections({
				partyIds: this.props.entityId + ',',
			});
	}

	handleOnDelete = id => {
		this.props.removeReferral(id);
		this.setState({ updated: true });
	};

	handleOnSave = () => {
		const DeleteIds = this.props.partyState.deleteReferrals;
		DeleteIds.forEach(x => this.props.deleteReferral(x));
		var collection;

		if (this.props.type === 'Firm Fee') collection = this.props.partyState.firmFee;
		else if (this.props.type === 'Attorney Fee') collection = this.props.partyState.attorneyFee;
		else collection = this.props.partyState.billingFee;

		collection.forEach(x => {
			if (x.new) this.props.create(x);
			if (x.edited && !x.deleted) this.props.update(x);
		});
	};

	render() {
		var config;

		if (this.props.type === 'Firm Fee') config = { data: this.props.partyState.firmFee };
		else if (this.props.type === 'Attorney Fee') config = { data: this.props.partyState.attorneyFee };
		else config = { data: this.props.partyState.billingFee };

		const rowStyles = row => {
			let style = {};

			if (row.new) {
				style.backgroundColor = '#95d095';
			} else if (row.edited) {
				style.backgroundColor = 'rgb(91, 192, 222)';
			}
			if (row.deleted) {
				style.backgroundColor = '#e89996';
			}
			return style;
		};

		var columns =
			this.props.type === 'Firm Fee' || this.props.type === 'Attorney Fee'
				? [
						{
							dataField: 'referralName',
							text: 'Referral',
							editable: false,
						},
						{
							dataField: 'referralPercentage',
							text: 'Referral Percentage',
							edtable: true,
							editorRenderer: (editorProps, value) => <NumberInput {...editorProps} value={value} suffix='%' />,
							formatter: (_, row) => {
								return (
									<div className='text-right'>
										<NumberFormat
											value={row.referralPercentage}
											displayType={'text'}
											thousandSeparator={true}
											suffix={'%'}
											fixedDecimalScale={true}
											decimalScale={2}
										/>
									</div>
								);
							},
							validator: newValue => {
								if (newValue < 0) {
									return {
										valid: false,
										message: 'Amount cannot be negative',
									};
								}
								return true;
							},
						},
						{
							dataField: 'created',
							text: 'Created',
							editable: false,

							formatter: (_, row) => {
								if (row.created === null || /^\s+$/.test(row.created)) return '';
								return moment.utc(row.created).local().format('L LT') + ' by ' + row.createdByName;
							},
						},
						{
							dataField: 'modified',
							text: 'Modified',
							editable: false,
							formatter: (_, row) => {
								if (!row.modifiedByName) return '';
								return moment.utc(row.modified).local().format('L LT') + ' by ' + row.modifiedByName;
							},
						},
				  ]
				: [
						{
							dataField: 'referralName',
							text: 'Referral',
							editable: false,
						},
						{
							dataField: 'referralPercentage',
							text: 'Referral Principal',
							edtable: true,
							editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
								<NumberInput {...editorProps} value={value} suffix='%' />
							),
							formatter: (cellContent, row) => {
								return (
									<div className='text-right'>
										<NumberFormat
											value={row.referralPercentage}
											displayType={'text'}
											thousandSeparator={true}
											suffix={'%'}
											fixedDecimalScale={true}
											decimalScale={2}
										/>
									</div>
								);
							},
							validator: (newValue, row, column) => {
								if (newValue < 0) {
									return {
										valid: false,
										message: 'Amount cannot be negative',
									};
								}
								return true;
							},
						},
						{
							dataField: 'referralInterestPercentage',
							text: 'Referral Interest',
							edtable: true,
							editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
								<NumberInput {...editorProps} value={value} suffix='%' />
							),
							formatter: (cellContent, row) => {
								return (
									<div className='text-right'>
										<NumberFormat
											value={row.referralInterestPercentage}
											displayType={'text'}
											thousandSeparator={true}
											suffix={'%'}
											fixedDecimalScale={true}
											decimalScale={2}
										/>
									</div>
								);
							},
							validator: newValue => {
								if (newValue < 0) {
									return {
										valid: false,
										message: 'Amount cannot be negative',
									};
								}
								return true;
							},
						},
						{
							dataField: 'created',
							text: 'Created',
							editable: false,
							formatter: (_, row) => {
								if (row.created === null || /^\s+$/.test(row.created)) return '';
								return moment.utc(row.created).local().format('L LT') + ' by ' + row.createdByName;
							},
						},
						{
							dataField: 'modified',
							text: 'Modified',
							editable: false,
							formatter: (_, row) => {
								if (!row.modifiedByName) return '';
								return moment.utc(row.modified).local().format('L LT') + ' by ' + row.modifiedByName;
							},
						},
				  ];

		columns = this.props.viewMode
			? columns
			: [
					...columns,
					{
						dataField: 'actions',
						text: '',
						isDummyField: true,
						editable: false,
						headerStyle: { width: '5em' },
						editCellStyle: () => {
							return { padding: 0 };
						},
						formatter: (_, row) => {
							return (
								<div className='action-buttons'>
									<MdDelete
										className='text-danger'
										onClick={() =>
											this.handleOnDelete({
												id: row.id,
												referralType: this.props.type,
											})
										}
									/>
								</div>
							);
						},
					},
			  ];

		return (
			<Card>
				<Card.Body>
					<BootstrapTable
						keyField='id'
						columns={columns}
						data={config.data ? config.data : []}
						className='table-body'
						rowStyle={rowStyles}
						bordered={true}
						striped
						hover
						condensed
						cellEdit={cellEditFactory({
							mode: 'click',
							blurToSave: true,
							nonEditableRows: () => (this.props.viewMode ? config.data.map(x => x.id) : []),
							afterSaveCell: (oldValue, newValue, row) => {
								if (row.id > 0) {
									row.edited = true;
									this.props.editReferral();
								}
								row.referralPercentage = parseFloat(row.referralPercentage);
								row.referralInterestPercentage = parseFloat(row.referralInterestPercentage);
							},
						})}
					/>
					<SelectField
						class='m-0 pb-0'
						style={{ color: 'black' }}
						onChange={value => {
							value = {
								partyId: this.props.entityId,
								referralId: value.value,
								referralName: value.label,
								referralPercentage: 0.0,
								referralInterestPercentage: 0.0,
								referralType: this.props.type,
								new: true,
								id: this.state.newId--,
								createdByName: this.props.user.fullName,
							};
							this.props.addReferral(value);
							value = null;
						}}
						dataTable={this.props.dataTable}
						dataField={this.props.dataField ? this.props.dataField : 'name'}
						viewMode={this.state.viewMode}
					/>
				</Card.Body>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	return {
		partyState: state.partyReducer,
		user: state.userReducer.user,
		moduleState: state.moduleReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getFirmFeeCollections: partyId => {
			dispatch(getFirmFeeCollections(partyId));
		},
		getAttorneyFeeCollections: request => {
			dispatch(getAttorneyFeeCollections(request));
		},
		getBillingFeeCollections: request => {
			dispatch(getBillingFeeCollections(request));
		},
		create: request => {
			dispatch(create(request));
		},
		deleteReferral: id => {
			dispatch(deleteReferral(id));
		},
		addReferral: referral => {
			dispatch(addReferral(referral));
		},
		removeReferral: id => {
			dispatch(removeReferral(id));
		},
		update: referral => {
			dispatch(update(referral));
		},
		editReferral: () => {
			dispatch(editReferral());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(PartyReferrals);
