import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { getCaseDocuments } from '../../services/caseServices';
import { getCaseDocument } from '../../services/portalServices';
import { getTaskFilesBlobMultiple } from '../../services/taskServices';
import * as utility from '../utility';

const imgData = require('./sga.jpg');

export const generateInvoices = invoice => {
	var fileName = invoice.invoiceNo + '.pdf';
	if (invoice.pdfFilePath) {
		getZeusInvoiceBlob(invoice)
			.then(blob => (blob ? utility.previewBlob(blob, fileName) : null))
			.catch(e => console.log('Error -', e));
	} else {
		var doc = createInvoice(invoice);
		doc = createCostTable(invoice, invoice.costs, doc, 'Total Costs', 'Cost Type');
		doc = createCostTable(invoice, invoice.statements, doc, 'Total Reimbursement', 'Payment Type');
		doc = createPenaltiesTable(invoice, invoice.penalties, doc);
		doc.save(fileName);
	}
};

const costsPage = data =>
	data.map(x => {
		return [
			x.caseNo,
			x.patientId +
				' - ' +
				x.patientName +
				' - DOS: ' +
				(x.dosStart ? x.dosStart : '') +
				(x.dosEnd ? ' - ' + x.dosEnd : ''),
			x.checkNumber,
			'$' + x.amount.toFixed(2),
			x.costType,
		];
	});

const penaltiesPage = data =>
	data.map(x => {
		return [
			x.caseNo,
			x.patientId +
				' - ' +
				x.patientName +
				' - DOS: ' +
				(x.dosStart ? x.dosStart : '') +
				(x.dosEnd ? ' - ' + x.dosEnd : ''),
			'$' + x.amount.toFixed(2),
			x.costType,
		];
	});

const createCostTable = (invoice, data, doc, totalLabel = '', typeLabel = '') => {
	if (data.length === 0) return doc;

	const page = costsPage(data);
	const total = data.reduce((acc, curr) => acc + curr.amount, 0);

	if (doc) doc.addPage();
	else doc = new jsPDF();

	doc.setFontSize(8);
	doc.setFont(undefined, 'normal');

	let body = [[totalLabel + ' ', '$' + total.toFixed(2)]];

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		startY: 30,
		head: [['Case No', 'Patient', 'Check Number', 'Check Amount', typeLabel]],
		columnStyles: {
			0: { cellWidth: 25 },
			1: { cellWidth: 90 },
			2: { cellWidth: 25 },
			3: { cellWidth: 25 },
			4: { cellWidth: 20 },
		},
		body: [...page],
		lineWidth: 1,
		lineColor: 'black',
	});

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		tableWidth: 'wrap',
		columnStyles: {
			0: { fillColor: [41, 128, 185], textColor: 255 },
		},
		header: ['', ''],
		body,
		lineWidth: 1,
		lineColor: 'black',
	});

	let finalX = doc.lastAutoTable.finalY;

	doc.text('PROVIDER NAME: ' + invoice.provider, 15, finalX + 5);
	doc.text('PROVIDER ID: ' + invoice.providerId, 15, finalX + 10);

	return doc;
};

const createPenaltiesTable = (invoice, data, doc) => {
	if (data.length === 0) return doc;

	const page = penaltiesPage(data);
	const total = data.reduce((acc, curr) => acc + curr.amount, 0);

	if (doc) doc.addPage();
	else doc = new jsPDF();

	doc.setFontSize(8);
	doc.setFont(undefined, 'normal');

	let body = [['Total Penalties ', '$' + total.toFixed(2)]];

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		startY: 30,
		head: [['Case No', 'Patient', 'Amount', 'Penalty Type']],
		columnStyles: {
			0: { cellWidth: 30 },
			1: { cellWidth: 70 },
			2: { cellWidth: 30 },
			3: { cellWidth: 50 },
		},
		body: [...page],
		lineWidth: 1,
		lineColor: 'black',
	});

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		tableWidth: 'wrap',
		columnStyles: {
			0: { fillColor: [41, 128, 185], textColor: 255 },
		},
		header: ['', ''],
		body,
		lineWidth: 1,
		lineColor: 'black',
	});

	let finalX = doc.lastAutoTable.finalY;

	doc.text('PROVIDER NAME: ' + invoice.provider, 15, finalX + 5);
	doc.text('PROVIDER ID: ' + invoice.providerId, 15, finalX + 10);

	return doc;
};

const getZeusInvoiceBlob = async invoice => {
	const result = await getCaseDocument(invoice.pdfFilePath, invoice.invoiceNo);
	const buffer = await result.data.arrayBuffer();
	return new Blob([buffer], { type: 'application/pdf' });
};

const getZeusInvoice = async invoice => {
	const result = await getCaseDocument(invoice.pdfFilePath, invoice.invoiceNo);
	return await result.data.arrayBuffer();
};

export const getInvoiceBlob = async (invoice, next = null) => {
	if (invoice.pdfFilePath) return getZeusInvoiceBlob(invoice);

	let blob = null;
	let ids = invoice.data.map(x => x.documentId).filter(x => x);
	ids = Array.from(new Set(ids));

	const { includeEOB } = invoice;

	var eobs = [];
	if (includeEOB) {
		const result = await getCaseDocuments(invoice.eobs);
		eobs = result.filter(x => x.status === 'fulfilled' && x.value).map(x => x.value.data);
		console.log('EOBS results -', result);
	}

	return new Promise((resolve, reject) => {
		getTaskFilesBlobMultiple(ids)
			.then(async resp => {
				resp = resp.concat(eobs);
				const pdfs = await mergePdfs([getExtendedInvoiceArrayBuffer(invoice), ...resp]);
				blob = b64toBlob(pdfs, 'application/pdf');
				if (next) next(blob);
				resolve(blob);
			})
			.catch(err => {
				console.log(err);
				reject(err);
			});
	});
};

export const getExtendedInvoiceArrayBuffer = invoice => {
	var doc = createInvoice(invoice);
	doc = createCostTable(invoice, invoice.costs, doc, 'Total Cost Spent', 'Cost Type');
	doc = createCostTable(invoice, invoice.statements, doc, 'Total Cost Received', 'Payment Type');
	doc = createPenaltiesTable(invoice, invoice.penalties, doc);
	const blob = doc.output('arraybuffer');
	return blob;
};

export const getInvoiceArrayBuffer = (invoice, next = null) => {
	var doc = createInvoice(invoice);
	const blob = doc.output('arraybuffer');
	if (next) next(blob);
	return blob;
};

export const createInvoice = invoice => {
	invoice.providerAddress = invoice.providerAddress ? invoice.providerAddress : '';

	const page = invoicePage(invoice.data);
	const foot = footer(invoice);
	const summary = getSummaryData(invoice);

	var doc = new jsPDF();
	doc.setFontSize(10);
	doc.setFont('times');

	doc.addImage(imgData, 'JPEG', 150, 10);

	doc.text(
		'Sanders Grossman Aronova, PLLC.\n100 Garden City Plaza, Suite 500\nGarden City, NY 11530\nPHONE: (516) 741-4799 FAX: (516) 741-0128',
		130,
		40
	);

	doc.text(invoice.remittanceName ? invoice.remittanceName : invoice.provider, 15, 65);
	doc.text(invoice.providerAddress, 15, 70);
	doc.text('DATE: ' + moment.utc(invoice.invoiced).format('L'), 15, 75);

	doc.setFontSize(12);
	doc.setFont(undefined, 'bold');

	doc.text('INVOICE NO: ' + invoice.invoiceNo, 80, 90);

	doc.setFontSize(8);
	doc.setFont(undefined, 'normal');

	let body = invoice.isOldLayout ? getOldSummaryBody(invoice, foot) : getSummaryBody(invoice, summary);

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		startY: 100,
		head: [
			[
				'Case No',
				'Patient',
				'Collected Amount',
				'Check No',
				'Firm Fee',
				'Firm Fee ' + (invoice.isRemittance ? 'Deducted' : 'Charged'),
				'Payment Type',
			],
		],
		columnStyles: {
			0: { cellWidth: 30 },
			1: { cellWidth: 50 },
			2: { cellWidth: 20 },
			3: { cellWidth: 25 },
			4: { cellWidth: 20 },
			5: { cellWidth: 20 },
			6: { cellWidth: 20 },
			7: { cellWidth: 20 },
		},
		body: [...page],
		foot: [foot],
		lineWidth: 1,
		lineColor: 'black',
	});

	const labelStyle = { fillColor: [41, 128, 185], textColor: 255 };
	const emptyStyle = { fillColor: 'white', cellWidth: 10 };

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		tableWidth: 'wrap',
		columnStyles: {
			0: labelStyle,
			1: {},
			2: emptyStyle,
			3: labelStyle,
			4: {},
			5: emptyStyle,
			6: labelStyle,
			7: {},
			8: emptyStyle,
		},
		header: ['', ''],
		body,
		lineWidth: 1,
		lineColor: 'black',
	});

	if (!invoice.isRemittance && !invoice.isOldLayout) {
		const invoiceBalanceBody = getInvoiceBalanceBody(summary);

		doc.autoTable({
			styles: { fontSize: 7 },
			columnStyles: { europe: { halign: 'center' } },
			tableWidth: 'wrap',
			columnStyles: {
				0: labelStyle,
				1: {},
			},
			header: ['', ''],
			body: invoiceBalanceBody,
			lineWidth: 1,
			lineColor: 'black',
		});
	}

	let finalX = doc.lastAutoTable.finalY;

	doc.text('PROVIDER NAME: ' + invoice.provider, 15, finalX + 10);
	doc.text('PROVIDER ID: ' + invoice.providerId, 15, finalX + 15);

	return doc;
};

const invoicePage = checks => {
	return checks.map(x => {
		return [
			x.caseNo,
			x.patient + '\nDOS: ' + (x.dosStart ? x.dosStart : '') + (x.dosEnd ? ' - \n' + x.dosEnd : ''),
			'$' + x.amount.toFixed(2),
			x.checkNo,
			x.firmFeePercentage.toFixed(2) + '%',
			'$' + parseFloat(x.firmFee).toFixed(2),
			x.paymentType,
		];
	});
};

const getSummaryData = invoice => {
	const checks = invoice.data;

	var totalAmount = 0.0;
	var totalFeeAmount = 0.0;

	console.table(checks);
	checks.forEach(x => {
		totalAmount += x.isDirectPayment ? 0 : parseFloat(x.amount);
		totalFeeAmount += parseFloat(x.firmFee);
	});

	return {
		collectedAmount: '$' + totalAmount?.toFixed(2),
		firmFeeAmount: '$' + totalFeeAmount?.toFixed(2),
		remittanceAmount: '$' + invoice.totalRemittanceAmount?.toFixed(2),
		totalCostsAmount: '$' + invoice.totalCostsAmount?.toFixed(2),
		totalPaymentsAmount: '$' + invoice.totalPaymentsAmount?.toFixed(2),
		costBalance: '$' + parseFloat(invoice.costBalance).toFixed(2),
		penaltiesBalance: '$' + parseFloat(invoice.penaltiesBalance).toFixed(2),
		invoiceBalance: '$' + parseFloat(invoice.invoiceBalance).toFixed(2),
		totalCostsDeducted: '$' + invoice.totalCostsDeducted?.toFixed(2),
		totalPenaltiesDeducted: '$' + (invoice.totalPenaltiesDeducted ?? 0)?.toFixed(2),
		previousCostBalance: '$' + (invoice.previousCostBalance ?? 0)?.toFixed(2),
		previousPenaltyBalance: '$' + (invoice.previousPenaltyBalance ?? 0)?.toFixed(2),
		totalPenaltiesAmount: '$' + (invoice.totalPenaltiesAmount ?? 0)?.toFixed(2),
		previousInvoiceBalance: '$' + (invoice.previousInvoiceBalance ?? 0)?.toFixed(2),
		invoicePaymentAmount: '$' + (invoice.invoicePaymentAmount ?? 0)?.toFixed(2),
		invoicedAmount: '$' + invoice.invoicedAmount?.toFixed(2),
	};
};

const getInvoiceBalanceBody = summary => [
	['Previous Invoice Balance', summary.previousInvoiceBalance],
	['Invoice Payment', summary.invoicePaymentAmount],
	['Invoiced Amount', summary.invoicedAmount],
	['Invoice Balance', summary.invoiceBalance],
];

const getSummaryBody = (invoice, summary) =>
	!invoice.isRemittance
		? [
				// Live Check client
				[
					'Collected Amount',
					summary.collectedAmount,
					'',
					'Previous Costs Balance',
					summary.previousCostBalance,
					'',
					'Previous Penalties Balance',
					summary.previousPenaltyBalance,
				],
				[
					'Firm Fees Charged',
					summary.firmFeeAmount,
					'',
					'Costs Expended',
					summary.totalCostsAmount,
					'',
					'Penalties Applied',
					summary.totalPenaltiesAmount,
				],
				['Costs Charged', summary.totalCostsDeducted, '', 'Costs Collected', summary.totalPaymentsAmount, '', '', ''],
				[
					'Penalties Charged',
					summary.totalPenaltiesDeducted,
					'',
					'Costs Charged',
					summary.totalCostsDeducted,
					'',
					'Penalties Charged',
					summary.totalPenaltiesDeducted,
				],
				[
					'Invoiced Amount',
					summary.invoicedAmount,
					'',
					'Costs Balance',
					summary.costBalance,
					'',
					'Penalties Balance',
					summary.penaltiesBalance,
				],
		  ]
		: [
				[
					'Collected Amount',
					summary.collectedAmount,
					'',
					'Previous Costs Balance',
					summary.previousCostBalance,
					'',
					'Previous Penalties Balance',
					summary.previousPenaltyBalance,
				],
				[
					'Firm Fees Deducted',
					summary.firmFeeAmount,
					'',
					'Costs Expended',
					summary.totalCostsAmount,
					'',
					'Penalties Applied',
					summary.totalPenaltiesAmount,
				],
				['Costs Deducted', summary.totalCostsDeducted, '', 'Costs Collected', summary.totalPaymentsAmount, '', '', ''],
				[
					'Penalties Deducted',
					summary.totalPenaltiesDeducted,
					'',
					'Costs Deducted',
					summary.totalCostsDeducted,
					'',
					'Penalties Deducted',
					summary.totalPenaltiesDeducted,
				],
				[
					'Remittance Amount',
					summary.remittanceAmount,
					'',
					'Costs Balance',
					summary.costBalance,
					'',
					'Penalties Balance',
					summary.penaltiesBalance,
				],
		  ];

const getOldSummaryBody = (invoice, foot) => {
	return !invoice.isRemittance
		? [
				['Collected Amount', foot[2]],
				['Firm Fees Charged', foot[5]],
				['Costs Deducted', foot[14]],
				['Penalties Deducted', foot[15]],
				['Total Invoiced Amount', foot[11]],
				['Invoices Balance', '$' + parseFloat(invoice.invoiceBalance).toFixed(2)],
				['Costs Balance', '$' + parseFloat(invoice.costBalance).toFixed(2)],
				['Penalties Balance', '$' + parseFloat(invoice.penaltiesBalance).toFixed(2)],
		  ]
		: [
				['Collected Amount', foot[2]],
				['Firm Fees Deducted', foot[5]],
				['Costs Deducted', foot[14]],
				['Penalties Deducted', foot[15]],
				[invoice.isHeldInTrust ? 'Held in Trust' : 'Remittance Amount', foot[11]],
				['Invoices Balance', '$' + parseFloat(invoice.invoiceBalance).toFixed(2)],
				['Costs Balance', '$' + parseFloat(invoice.costBalance).toFixed(2)],
				['Penalties Balance', '$' + parseFloat(invoice.penaltiesBalance).toFixed(2)],
		  ];
};

const footer = invoice => {
	const checks = invoice.data;

	var totalAmount = 0.0;
	var totalFeeAmount = 0.0;
	var totalRemittanceAmount = 0.0;

	console.table(checks);
	checks.forEach(x => {
		totalAmount += x.isDirectPayment ? 0 : parseFloat(x.amount);
		totalFeeAmount += parseFloat(x.firmFee);
		totalRemittanceAmount += x.remittanceAmount;
	});

	let footer = [
		'',
		'',
		'$' + totalAmount?.toFixed(2),
		'',
		'',
		'$' + totalFeeAmount?.toFixed(2),
		'',
		'',
		'$' + totalRemittanceAmount?.toFixed(2),
		'',
		'',
		'$' + invoice.totalRemittanceAmount?.toFixed(2),
		'$' + invoice.totalCostsAmount?.toFixed(2),
		'$' + invoice.totalPaymentsAmount?.toFixed(2),
		'$' + invoice.totalCostsDeducted?.toFixed(2),
		'$' + (invoice.totalPenaltiesDeducted || 0)?.toFixed(2),
		'$' + (invoice.penaltiesBalance || 0)?.toFixed(2),
	];
	return footer;
};
const mergePdfs = async arr => {
	const actions = arr.map(async buffer => PDFDocument.load(buffer));
	const result = await Promise.allSettled(actions);
	const pdfs = result.filter(x => x.status === 'fulfilled' && x.value).map(x => x.value);

	const mergedPdf = await PDFDocument.create();
	const f = async pdf => {
		const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
		for (const page of pages) mergedPdf.addPage(page);
	};

	await utility.executeSeq(pdfs, f, 'Invoice generation failed');
	return await mergedPdf.saveAsBase64();
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const getInvoicesBlob = async invoices => {
	const calls = invoices.map(
		x => new Promise(resolve => resolve(x.pdfFilePath ? getZeusInvoice(x) : getExtendedInvoiceArrayBuffer(x)))
	);

	const result = await Promise.allSettled(calls);
	const docs = result.filter(x => x.status === 'fulfilled' && x.value).map(x => x.value);

	if (docs.length > 0) {
		const pdfs = await mergePdfs(docs);
		return b64toBlob(pdfs, 'application/pdf');
	}
};
