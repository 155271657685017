import moment from 'moment';
import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { getInvoice } from '../../services/invoiceServices';
import { getInvoicesBlob } from '../checks/invoiceGenerator';
import * as utility from '../utility';

const getLink = (row, name, link, condition) =>
	condition ? (
		<Link to={link} style={{ textDecoration: 'none' }} target='_blank'>
			{row[name]}
		</Link>
	) : (
		row[name]
	);

const GenerateInvoice = props => {
	const [loading, setLoading] = useState();
	const cb = () => setLoading(false);

	const onClick = async () => {
		setLoading(true);

		getInvoice(props.id)
			.then(res =>
				getInvoicesBlob([res.data])
					.then(blob => {
						utility.previewBlob(blob, props.fileName);
						cb();
					})
					.catch(cb)
			)
			.catch(cb);
	};

	return (
		<>
			<div className='text-center'>
				{loading && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
				<Link onClick={onClick}>{props.row[props.name]}</Link>
			</div>
		</>
	);
};

const config = {
	'Case No': (_, row, name) => getLink(row, name, '/case/' + row['Case ID'] + '?viewMode=true', row['Case ID']),
	Provider: (_, row, name) => getLink(row, name, '/party/' + row['Provider ID'] + '?viewMode=true', row['Provider ID']),
	Patient: (_, row, name) => getLink(row, name, '/party/' + row['Patient ID'] + '?viewMode=true', row['Patient ID']),
	Insurer: (_, row, name) => getLink(row, name, '/party/' + row['Insurer ID'] + '?viewMode=true', row['Insurer ID']),
	'Invoice Number': (_, row, name) => (
		<GenerateInvoice row={row} name={name} id={row['Invoice ID']} fileName={row['Invoice Number']} />
	),
};

const specialColumns = Object.keys(config);

export const getColumn = (key, value) => {
	var temp = key.replace(/([A-Z])/g, ' $1');
	let result = {
		dataField: key,
		text: temp.charAt(0).toUpperCase() + temp.slice(1),
	};

	switch (value) {
		case 'Int32':
		case 'Int64':
			result.formatter = (_, row) => {
				return row[result.dataField] ? parseInt(row[result.dataField]) : null;
			};
			break;
		case 'Decimal':
			result.formatter = (_, row) => {
				return (
					<div className='text-right'>
						<NumberFormat
							value={row[result.dataField]}
							displayType={'text'}
							thousandSeparator={true}
							prefix={'$'}
							fixedDecimalScale={true}
							decimalScale={2}
						/>
					</div>
				);
			};
			break;
		case 'Double':
		case 'Single':
			result.formatter = (_, row) => {
				return row[result.dataField] ? parseFloat(row[result.dataField]) : null;
			};
			break;
		case 'DateTime':
			result.formatter = (_, row) => {
				if (row[result.dataField] === null || /^\s+$/.test(row[result.dataField])) return '';

				let date = moment.utc(row[result.dataField]);
				if (!date.isValid() || row[result.dataField] == undefined) return '';
				return row[result.dataField].includes('T00:00:00') ? date.local().format('L') : date.local().format('L LT');
			};
			break;
		case 'Boolean':
			result.formatter = (_, row) => {
				return (row[result.dataField] = value ? 'YES' : 'NO');
			};
			break;
		default:
			if (specialColumns.includes(key)) result.formatter = (_, row) => config[key](_, row, result.dataField);
			else result.formatter = (_, row) => row[result.dataField];
			break;
	}
	return result;
};

const dataCheck = value => {
	if (value === true) return 'YES';
	else if (value === false) return 'NO';
	return value;
};

const isItDate = str => {
	var conditions = ['modified', 'created'];

	return str.toLowerCase().includes('date') || conditions.some(s => str.toLowerCase() == s);
};

export const printTable = (columns, data, parameters, css = null) => {
	columns = constructColumns(columns);

	var styleCss = !css
		? (styleCss = `table.reportTbl {
        border: 0px solid #1C6EA4;
        background-color: #EEEEEE;
        width: 100%;
        text-align: left;
        border-collapse: collapse;
      }
      table.reportTbl td, table.reportTbl th {
        border: 1px solid #AAAAAA;
        padding: 3px 2px;
      }
      table.reportTbl tbody td {
        font-size: 13px;
      }
      table.reportTbl tr:nth-child(even) {
        background: #FFFFFF;
      }
      .titleDiv {
          width: 100%;
      }
      h2 {
          text-align: center;
      }
      table { page-break-inside:auto }
      div   { page-break-inside:avoid; } /* This is the key */
      `)
		: css;

	var mywindow = window.open('', 'PRINT', 'height=1000,width=1500');

	var colDataNames = columns.map(x => x.dataField);

	mywindow.document.write('<html><head><style>' + styleCss + '</style>');
	mywindow.document.write('<link rel="stylesheet" href="reports.css" type="text/css" />');
	mywindow.document.write('</head><body>');
	mywindow.document.write(
		'<div class="titleDiv"><h2>' +
			document.title.substring(document.title.indexOf('-') + 1, document.title.length) +
			'</h2></div>'
	);

	for (const [key, value] of Object.entries(parameters)) {
		var temp = key.replace(/([A-Z])/g, ' $1');
		if (temp.toLocaleLowerCase())
			mywindow.document.write(
				`<b>${temp.charAt(0).toUpperCase() + temp.slice(1)}</b> : <label>` +
					(isItDate(temp) ? moment.utc(value).format('L') : value) +
					` </label> <br><br>`
			);
	}

	mywindow.document.write('<table class="reportTbl">');
	mywindow.document.write('<thead><tr>');
	columns.forEach(element => {
		mywindow.document.write('<th>' + element.text + '</th>');
	});
	mywindow.document.write('</tr></thead>');

	data.forEach(element => {
		mywindow.document.write('<tr>');
		colDataNames.forEach(key => {
			if (isItDate(key))
				mywindow.document.write(
					'<td><div>' +
						(element[key] == '' || element[key] === null || /^\s+$/.test(element[key])
							? ''
							: moment.utc(element[key]).local().format('L')) +
						'</div></td>'
				);
			else mywindow.document.write('<td><div>' + dataCheck(element[key]) + '</div></td>');
		});

		mywindow.document.write('<tr>');
	});
	mywindow.document.write('</tr>');
	mywindow.document.write('</table>');
	mywindow.document.write('</body></html>');

	mywindow.document.close(); // necessary for IE >= 10
	mywindow.focus(); // necessary for IE >= 10*/

	mywindow.print();

	return true;
};

const constructColumns = data => {
	var columns = [];
	for (var key in data) if (data.hasOwnProperty(key)) columns.push(getColumn(key, data[key]));
	return columns;
};
