import * as service from '../services/invoiceServices';

export function getPage(request) {
	return {
		type: 'INVOICE_GET_PAGE',
		payload: service.getInvoicePage(request),
	};
}

export function clearInvoice(request) {
	return {
		type: 'INVOICE_CLEAR',
		payload: service.clearInvoice(request),
	};
}

export function addPayment(request) {
	return {
		type: 'INVOICE_ADD_PAYMENT',
		payload: service.addPayment(request),
	};
}

export function updateIssedCheckOnInvoices(row) {
	return {
		type: 'CHECK_OUT_UPDATE_ON_INVOICES',
		payload: row,
	};
}

export function get(id) {
	return {
		type: 'INVOICE_GET',
		payload: service.getInvoice(id),
	};
}
export function updatePaymentValue(row) {
	return {
		type: 'INVOICE_PAYMENT_UPDATE_VALUE',
		payload: row,
	};
}

export function getInvociePaymentDepositPage(request) {
	return {
		type: 'INVOICE_PAYMENT_GET_PAGE',
		payload: service.getInvoicePaymentDepositPage(request),
	};
}

export function depositInvoicePayment(request) {
	return {
		type: 'INVOICE_PAYMENT_DEPOSIT',
		payload: service.depositInvoicePayment(request),
	};
}

export function removeInvoice(id) {
	return {
		type: 'INVOICE_REMOVE',
		payload: id,
	};
}
