import React, { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/fallbacks/error';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Login from './components/login/Login';
import Navigation from './components/navigation/Navigation';
import 'react-toastify/dist/ReactToastify.css';
import { getUserPermissions } from './actions/moduleActions';
import { getUserReports } from './actions/reportActions';
import './App.css';
import { ProtectedRoute } from './components/protectedRoute';
import { env } from './configs/env';
import PartiesPanel from './components/parties/partiesPanel';
import Party from './components/parties/party';
import Survey from './components/survey/survey';
// import GenericReport from './components/reports/genericReport';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { getLoginRedirect } from './services/userServices';

const AlertGenerator = lazy(() => import('./components/alertGenerator/AlertGenerator'));
const FieldDefinitionPanel = lazy(() => import('./components/fieldDefinitions/fieldDefinitionPanel'));
const firmsPanel = lazy(() => import('./components/firms/firmsPanel'));
const PasswordResetPage = lazy(() => import('./components/login/passwordResetPage'));
const modulesPanel = lazy(() => import('./components/modules/modulesPanel'));
// const Party = lazy(() => import('./components/parties/party'));
const PartyContactRedirect = lazy(() => import('./components/parties/partyContactRedirect'));
const PartyTypesPanel = lazy(() => import('./components/parties/partyTypesPanel'));
const GenericReport = lazy(() => import('./components/reports/genericReport'));
const ReportConfigurator = lazy(() => import('./components/reports/reportConfigurator'));
const userGroupPermissionsPanel = lazy(() => import('./components/userGroups/userGroupPermissionsPanel'));
const userGroupsPanel = lazy(() => import('./components/userGroups/userGroupsPanel'));
const UserProfile = lazy(() => import('./components/userProfile/userProfile'));
const UsersPanel = lazy(() => import('./components/users/UsersPanel'));
// const Survey = lazy(() => import('./components/survey/survey'));
const Dashboard = lazy(() => import('./components/dashboard/dashboard'));
const DashboardPanel = lazy(() => import('./components/dashboard/dashboardPanel'));
const PartyLaborsPanel = lazy(() => import('./components/parties/partyLaborsPanel'));

const Footer = lazy(() => import('./components/footer/footer'));

const BarcodeGenerator = lazy(() => import('./components/barcode/barcodeGenerator'));
const QRCodeGenerator = lazy(() => import('./components/qrCodeGenerator/qrCodeGenerator'));

const ignoreEvents = [
	'USER_CREATE',
	'USER_EDIT',
	'CONTACT_CREATE',
	'CONTACT_EDIT',
	'CONTACT_DELETE',
	'MODULE_GET_BY_USER',
	'REPORT_GET_BY_USER',
	'REPORT_GET_GROUPS',
];

class App extends React.Component {
	constructor(props) {
		super(props);
		this.channel = React.createRef();
	}

	state = {};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.user.user && !nextProps.moduleState.navModules) nextProps.getUserPermissions();
		if (nextProps.user.user && !nextProps.moduleState.reportModules) nextProps.getUserReports();

		return null;
	}

	componentDidMount() {
		this.channel = new BroadcastChannel('toast');
		this.channel.onmessage = ev => this.showToast(ev.data);
	}

	componentWillUnmount() {
		this.channel.close();
	}

	checkPermission = () => {
		const result = this.props.moduleState.navModules.map(x => x.relativePath);
		return result;
	};

	myErrorHandler = (error, componentStack) => {
		console.log(error, componentStack);
	};

	getModuleId = uniquePath => {
		let mapping = this.props.moduleState.navModules.find(u => u.relativePath === uniquePath);
		return mapping ? mapping.id : -1;
	};

	showToast = ({ message, type }) => (type ? toast[type](message) : toast(message));

	setToast = (message, type) => {
		this.channel.postMessage({ message, type });
		type ? toast[type](message) : toast(message);
	};

	render() {
		const { user, tokenExpired } = this.props.user;
		const userId = this.props.user.user ? parseInt(this.props.user.user.unique_name) : -1;

		return (
			<BrowserRouter basename={env.BASE_PATH}>
				<ToastContainer position='bottom-right' theme='dark' progressStyle={{ backgroundColor: 'red' }} />
				<React.Fragment>
					<Navigation userId={userId} />
					<AlertGenerator ignoreEvents={ignoreEvents} />
					<ErrorBoundary FallbackComponent={ErrorFallback} onError={this.myErrorHandler}>
						<Suspense fallback={<div>Loading...</div>}>
							<Switch>
								{user && user.sub === 'korisnik' && !tokenExpired && window.location.search.includes('code') && (
									<React.Fragment>
										<Route exact path='/login' component={Login} />
										<Redirect to={'/anketa'} component={Login} />
									</React.Fragment>
								)}
								{user && !tokenExpired && this.props.moduleState.navModules && (
									<React.Fragment>
										<ProtectedRoute
											exact
											path='/qr-kod-generator'
											title='QR Kod Generator'
											component={QRCodeGenerator}
										/>
										<ProtectedRoute
											exact
											path='/mjesecni-porodjaj'
											title='Mjesecni porodjaj'
											component={PartyLaborsPanel}
										/>
										<ProtectedRoute exact path='/anketa' title='Anketa' component={Survey} />
										<ProtectedRoute exact path='/users' title='User Panel' component={UsersPanel} />
										<ProtectedRoute exact path='/user-groups' title='User Groups' component={userGroupsPanel} />
										<ProtectedRoute
											exact
											title='User Group Module Permissions'
											path='/user-group-module-permissions'
											userId={userId}
											moduleId={this.getModuleId('/user-group-module-permissions')}
											component={userGroupPermissionsPanel}
										/>
										<ProtectedRoute
											exact
											path='/modules'
											title='Modules'
											userId={userId}
											moduleId={this.getModuleId('/modules')}
											component={modulesPanel}
										/>
										<ProtectedRoute exact path='/firms' component={firmsPanel} />
										<ProtectedRoute exact path='/bolnica-types' component={PartyTypesPanel} />
										<ProtectedRoute exact path='/bolnice' component={PartiesPanel} />
										<ProtectedRoute path='/bolnica/:id' authPath='/bolnice' component={Party} />
										<ProtectedRoute path='/bolnica-contact/:id' authPath='/bolnice' component={PartyContactRedirect} />
										<ProtectedRoute exact path='/bolnica-new' authPath='/bolnice' component={Party} new={true} />
										<ProtectedRoute exact path='/dashboards' component={DashboardPanel} />
										{/* <ProtectedRoute exact path='/pocetna' component={Dashboard} /> */}

										<ProtectedRoute
											title='Dynamic Fields Configuration'
											path='/dynamic-fields-configurator'
											component={FieldDefinitionPanel}
										/>
										<ProtectedRoute
											title='Konfigurator Izvještaja'
											path='/konfigurator-izvjestaja'
											component={ReportConfigurator}
										/>
										<ProtectedRoute path='/izvjestaj/:id' authPath='/izvjestaj' component={GenericReport} />
										<ProtectedRoute title='Barcode Generator' path='/barcode-generator' component={BarcodeGenerator} />
										<Route path='/user-profile' component={UserProfile} />
										<Route exact path='/'>
											<Redirect to={getLoginRedirect(localStorage.getItem('token'))} />
										</Route>
										<Route exact path='/pocetna' component={Dashboard} />
									</React.Fragment>
								)}
								{user && tokenExpired && <Route path='' component={Login} />}
								{!user && (
									<React.Fragment>
										<Route exact path='/pocetna' component={Dashboard} />
										<Route exact path='/login' component={Login} />
										<Route exact path='/reset-password' component={PasswordResetPage} />
										<Redirect
											to={{
												pathname: '/login',
												search: window.location.search,
												state: { referrer: window.location.pathname },
											}}
											component={Login}
										/>
									</React.Fragment>
								)}
							</Switch>
						</Suspense>
					</ErrorBoundary>
				</React.Fragment>
				<Footer />
			</BrowserRouter>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getUserPermissions: () => {
			dispatch(getUserPermissions());
		},
		getUserReports: () => {
			dispatch(getUserReports());
		},
	};
};

const mapStateToProps = state => {
	return {
		user: state.userReducer,
		moduleState: state.moduleReducer,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
