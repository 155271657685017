const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  costTypes: [],
  transfers: [],
  newCostType: null,
};

const costTypeReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "COST_TYPE_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
      });
      break;
    case "COST_TYPE_CREATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        newCostType: action.payload.data,
      });
      break;
     case "COST_TYPE_DELETE_FULFILLED":
      var removeIndex = state.costTypes
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.costTypes.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        costTypes: [...state.costTypes],
      });
      break;
    case "COST_TYPE_EDIT_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        costTypes: state.costTypes.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        }),
      });
      break;
    case "TRANSFER_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
      });
      break;
    case "TRANSFER_MONEY_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        transfers: [action.payload.data, ...state.transfers],
      });
      break;
    default:
      return state;
      break;
  }
};

export default costTypeReducer;
