import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export const DebounceButton = props => {
	const [isDisabled, setIsDisabled] = useState(false);

	const timeout = props.timeout || 5000;

	const debounce = () =>
		setTimeout(() => {
			setIsDisabled(false);
		}, timeout);

	const onClick = e => {
		setIsDisabled(true);
		debounce();
		props.onClick(e);
	};

	return <Button {...props} onClick={onClick} disabled={props.disabled || isDisabled} />;
};
