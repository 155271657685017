const initialState = {
    page: 1,
    pageSize: 25,
    dataSize: 1,
    calendarEventTypes: [],
    permissions: {
        data: [],
    }
};

const calendarEventTypeReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case "CALENDAR_EVENT_TYPE_GET_PAGE_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                ...action.payload.data,
            });
            break;
        case "CALENDAR_EVENT_TYPE_CREATE_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: [action.payload.data, ...state.calendarEventTypes],
            });
            break;
        case "CALENDAR_EVENT_TYPE_DELETE_FULFILLED":
            var removeIndex = state.calendarEventTypes
                .map(function (item) {
                    return item.id;
                })
                .indexOf(action.payload.data);
            state.calendarEventTypes.splice(removeIndex, 1);
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: [...state.calendarEventTypes],
            });
            break;
        case "CALENDAR_EVENT_TYPE_EDIT_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: state.calendarEventTypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return action.payload.data;
                }),
            });
            break;
        case "CALENDAR_EVENT_TYPE_GET_FIRMS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: state.calendarEventTypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        firms: action.payload.data.firms.map((x) => {
                            return { ...x, value: x.id, label: x.name };
                        }),
                    };
                }),
            });
            break;
        case "CALENDAR_EVENT_TYPE_GET_MATTERS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: state.calendarEventTypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        matters: action.payload.data.matters.map((x) => {
                            return { ...x, value: x.id, label: x.name };
                        }),
                    };
                }),
            });
            break;
        case "CALENDAR_EVENT_TYPE_GET_USER_ASSIGNMENTS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: state.calendarEventTypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        userAssignments: action.payload.data.userAssignments.map((x) => {
                            return { ...x, value: x.id, label: x.shortName };
                        }),
                    };
                }),
            });
            break;
        case "CALENDAR_EVENT_TYPE_GET_USER_GROUP_ASSIGNMENTS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                calendarEventTypes: state.calendarEventTypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        userGroupAssignments: action.payload.data.userGroupAssignments.map((x) => {
                            return { ...x, value: x.id, label: x.shortName };
                        }),
                    };
                }),
            });
            break;
        case "CALENDAR_EVENT_TYPE_GET_ALL_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                permissions: {
                    data: action.payload.data
                },
            });
            break;
        case "CALENDAR_EVENT_TYPE_GET_BY_UG_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                permissions: {
                    data: state.permissions.data.map(item => {
                        let value = action.payload.data.find((x) => item.id === x.id);
                        if (value) return { ...value, read: true };
                        else return {
                            ...item, create: false, update: false, delete: false, read: false,
                        };
                    })
                },
            });
            break;
        case "CALENDAR_EVENT_TYPE_TOGGLE_PERMISSION":
            return Object.assign({}, state, {
                ...state,
                permissions: {
                    data: state.permissions.data.map(item => {
                        if (item.id == action.payload.moduleId) {
                            if (action.payload.permissionType === "read") {
                                let readValue = item["read"];
                                return {
                                    ...item,
                                    create: readValue ? false : item.create,
                                    update: readValue ? false : item.update,
                                    delete: readValue ? false : item.delete,
                                    read: !readValue,
                                };
                            }
                            return { ...item, read: true, [action.payload.permissionType]: !item[action.payload.permissionType], };
                        }
                        return item;
                    })
                }
            });
            break;
        case "CALENDAR_EVENT_TYPE_UPDATE_PERMISSIONS_PENDING":
            return Object.assign({}, state, {
                ...state,
                loading: true,
            });
            break;
        case "CALENDAR_EVENT_TYPE_UPDATE_PERMISSIONS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                loading: false,
            });
            break;
        case "CALENDAR_EVENT_TYPE_TOGGLE_ALL_PERMISSION":
            return Object.assign({}, state, {
                ...state,
                permissions: {
                    data: state.permissions.data.map((item) => {
                        return {
                            ...item,
                            read: !state.checkedAll,
                            create: !state.checkedAll,
                            update: !state.checkedAll,
                            delete: !state.checkedAll,
                        };
                    }),
                    checkedAll: !state.checkedAll,
                }
            });
            break;
        default:
            return state;
            break;
    }
};

export default calendarEventTypeReducer;
