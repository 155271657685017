import { act } from 'react-dom/test-utils';

const initialState = {
	contacts: [],
	contactTypes: [],
	createdContacts: [],
	editedContacts: [],
	deletedContacts: [],
	newContactType: null,
	states: [],
};

const contactReducer = (state = { ...initialState }, action) => {
	var data = [];
	switch (action.type) {
		case 'CONTACT_GET_USER_FULFILLED':
		case 'CONTACT_GET_FIRM_FULFILLED':
		case 'CONTACT_GET_PARTY_FULFILLED':
			return Object.assign({}, state, {
				...state,
				contacts: [...action.payload.data],
				createdContacts: [],
				editedContacts: [],
				deletedContacts: [],
			});
			break;
		case 'CONTACT_TYPES_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				contactTypes: [...action.payload.data],
			});
			break;
		case 'CONTACT_STATES_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				states: [...action.payload.data],
			});
		case 'CONTACT_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				contacts: [...state.contacts, ...action.payload.data],
				createdContacts: [],
			});
			break;
		case 'CONTACT_ADD':
			return Object.assign({}, state, {
				...state,
				createdContacts: [...state.createdContacts, action.payload],
			});
			break;
		case 'CONTACT_ADD_EDITED':
			const editedContacts = state.editedContacts.filter(x => x.id !== action.payload.id);
			editedContacts.push(action.payload);

			const i = state.contacts.findIndex(x => x.id === action.payload.id);
			const contacts = [...state.contacts];
			contacts[i] = action.payload;

			return Object.assign({}, state, {
				...state,
				contacts,
				editedContacts,
			});
			break;
		case 'CONTACT_TOGGLE_DELETED':
			if (!action.payload.deleted) {
				if (!action.payload.new) {
					var removeIndex = state.deletedContacts.find(x => {
						return x.id === action.payload.id;
					});
					state.deletedContacts.splice(removeIndex, 1);
				} else {
					var removeIndex = state.createdContacts.find(x => {
						return x.id === action.payload.id;
					});
					state.createdContacts.splice(removeIndex, 1);
				}
			} else state.deletedContacts = [...state.deletedContacts, action.payload];
			return Object.assign({}, state, {
				...state,
				createdContacts: [...state.createdContacts],
				deletedContacts: [...state.deletedContacts],
			});
			break;
		case 'CONTACT_EDIT_FULFILLED':
			data = action.payload.data;
			return Object.assign({}, state, {
				...state,
				contacts: state.contacts.map((item, index) => {
					var edited = data.find(x => x.id === item.id);
					if (!edited) return item;
					return edited;
				}),
				editedContacts: [],
			});
			break;
		case 'CONTACT_DELETE_FULFILLED':
			data = action.payload.data;
			data.forEach(x => {
				state.contacts.splice(
					state.contacts
						.map(function (item) {
							return item.id;
						})
						.indexOf(x),
					1
				);
			});
			return Object.assign({}, state, {
				...state,
				contacts: [...state.contacts],
				deletedContacts: [],
			});
			break;
		case 'CONTACT_TYPE_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				contactTypes: [action.payload.data, ...state.contactTypes],
				newContactType: action.payload.data,
			});
			break;
		case 'CONTACT_TYPE_DELETE_FULFILLED':
			data = action.payload.data;
			data.forEach(x => {
				state.contactTypes.splice(
					state.contactTypes
						.map(function (item) {
							return item.id;
						})
						.indexOf(x),
					1
				);
			});
			return Object.assign({}, state, {
				...state,
				contactTypes: [...state.contactTypes],
			});
			break;
		case 'CONTACT_TYPE_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				contactTypes: state.contactTypes.map((item, index) => {
					if (item.id !== action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		default:
			return state;
			break;
	}
};

export default contactReducer;
