import React, { Component } from "react";
import { Form } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

export const DateField = (props) => {

  return (
    <Form.Group as={props.as} className={props.className}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      {props.viewMode ? (
        <Form.Control
          size="sm"
          type="text"
          name={props.name}
          placeholder={props.placeholder}
          value={
            props.value
              ? props.showTimeSelect
                ? moment.utc(props.value).local().format("L LT")
                : moment.utc(props.value).format("YYYY-MM-DD")
              : ""
          }
          disabled={props.viewMode}
        />
      ) : (
        <DatePicker
          selected={
            props.value
              ? moment(props.value).toDate()
              : null
          }
          value={
            props.value
              ? moment(props.value).toDate()
              : null
          }
          name={props.name}
          onChange={(date) => props.onChange(date, props.name)}
          isClearable={props.isClearable === null ? true : props.isClearable}
          disabled={props.viewMode}
          autoComplete={"off"}
          showMonthDropdown
          showYearDropdown
          showTimeSelect={props.showTimeSelect}
          dropdownMode="select"
          className="form-control form-control-sm"
          style={{ width: "inherit" }}
          dateFormat={
            props.showTimeSelect ? "MM/dd/yyyy hh:mm a" : "MM/dd/yyyy"
          }
          maxDate={props.maxDate}
          minDate={props.minDate}
        />
      )}
    </Form.Group>
  );
};
