import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

export const CurrencyField = props => {
	return (
		<Form.Group as={props.as}>
			<Form.Label>{props.label}</Form.Label>
			<NumberFormat
				className='form-control form-control-sm text-right'
				value={props.value}
				thousandSeparator={true}
				prefix={'$'}
				fixedDecimalScale={true}
				decimalScale={2}
				onValueChange={values => props.onChange(values, props.name)}
				disabled={props.viewMode}
				isAllowed={props.isAllowed}
			/>
		</Form.Group>
	);
};
