import moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { BsBuilding, BsPerson } from 'react-icons/bs';
import ReactInputMask from 'react-input-mask';
import { connect } from 'react-redux';
import {
	create,
	edit,
	getPartyTypes,
	selectDate,
	selectParent,
	selectPartyType,
	setState,
} from '../../actions/partyActions';
import { adjustDateOffset, saveDynamicDataFields } from '../../actions/utilityActions';
import AlertGenerator from '../alertGenerator/AlertGenerator';
import { getPartyTypeOptions } from '../../services/partyServices';
import { DateField } from '../dynamicFields/dateField';
import SelectField from '../dynamicFields/selectField';
import { getPatientCases, getProviderCases } from '../../services/caseServices';
import CasesModal from '../cases/CasesModal';
var initialState = {
	action: '',
	validated: false,
	confirmationModalShow: false,
	showAlertMessage: false,
	alertMessage: null,
	isCompany: false,
	firstName: null,
	middleName: null,
	lastName: null,
	companyName: null,
	dateOfBirth: null,
	taxId: null,
	partyTypeId: null,
	moduleId: null,
	gender: null,
	ssn: null,
	parentId: null,
};

class BasicPartyInfo extends Component {
	state = { ...initialState };

	constructor(props) {
		super(props);
		props.getPartyTypes(this.props.moduleState.currentModule.id);
	}

	componentWillUnmount() {
		this.setState({ ...initialState });
	}

	handleOnChange = e => {
		if (e.target.name === 'isCompany')
			this.props.setState({
				isCompany: !this.props.party.isCompany,
			});
		else {
			this.props.setState({ [e.target.name]: e.target.value });
			this.setState({ [e.target.name]: e.target.value });
		}
	};

	dateChange = date => {
		this.props.selectDate(new Date(date));
		this.setState({ dateOfBirth: new Date(date) });
	};

	handleOnSelectChange = value => {
		this.props.selectPartyType(value);
	};

	handleOnSelectChangeParent = value => {
		this.props.selectParent(value);
	};

	handleGenderChange = e => {
		if (this.props.party) this.props.party.gender = e.target.value;

		this.setState({
			gender: e.target.value,
		});
	};

	loadOptions = (inputValue, callback) => {
		getPartyTypeOptions(inputValue).then(response => {
			const data = response.data.map(x => {
				return { ...x, label: x.name.trim(), value: x.id };
			});
			callback(data);
		});
	};

	prepareData = () => {
		if (this.props.isMassUpdate) {
			return {
				isCompany: this.props.isCompany,
				firstName: this.props.isCompany ? undefined : this.state.firstName,
				middleName: this.props.isCompany ? undefined : this.state.middleName,
				lastName: this.props.isCompany ? undefined : this.state.lastName,
				companyName: !this.props.isCompany ? undefined : this.state.companyName,
				dateOfBirth: this.props.isCompany ? undefined : adjustDateOffset(new Date(this.state.dateOfBirth)),
				taxId: !this.props.isCompany ? undefined : this.state.taxId,
				partyTypeId: this.props.partyType ? this.props.partyType.value : null,
				moduleId: this.props.moduleState.currentModule.id,
				gender: this.state.gender,
				ssn: this.state.ssn,
				parentId: !this.props.party?.parent ? undefined : this.props.party?.parent.value,
				partyIds: this.props.partyIds,
			};
		}

		return {
			isCompany: this.props.isCompany,
			firstName: this.props.isCompany ? undefined : this.props.party.firstName,
			middleName: this.props.isCompany ? undefined : this.props.party.middleName,
			lastName: this.props.isCompany ? undefined : this.props.party.lastName,
			companyName: !this.props.isCompany ? undefined : this.props.party.companyName,
			dateOfBirth: this.props.isCompany ? undefined : adjustDateOffset(new Date(this.props.party.dateOfBirth)),
			taxId: !this.props.isCompany ? undefined : this.props.party.taxId,
			partyTypeId: this.props.partyType ? this.props.partyType.value : null,
			moduleId: this.props.moduleState.currentModule.id, //Provjeriti zbog autentifikacije
			gender: this.props.party.gender,
			ssn: this.props.party.ssn,
			parentId: !this.props.party?.parent ? undefined : this.props.party?.parent.value,
		};
	};

	isTopLevelFieldsPopulated = () =>
		this.state.firstName ||
		this.state.middleName ||
		this.state.lastName ||
		this.state.companyName ||
		this.state.dateOfBirth ||
		this.state.taxId ||
		this.state.gender ||
		this.state.ssn;

	handleOnSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		const data = this.prepareData();

		if (form.checkValidity() === false) {
			e.stopPropagation();
		} else {
			if (!this.props.isMassUpdate) this.setState({ validated: true });

			if (!this.props.isMassUpdate) {
				//In party of new user create, else edit
				if (this.props.party.id === -1) {
					this.props.party.action = 'PARTY_CREATE';
					this.props.create(data);
					this.setState({ action: 'PARTY_CREATE' });
				} else {
					this.props.party.action = 'PARTY_EDIT';
					this.props.edit({ id: this.props.party.id, ...data });
					this.setState({ action: 'PARTY_EDIT' });
				}
			} else {
				if (this.isTopLevelFieldsPopulated()) {
					if (data) {
						this.setState({ alertMessage: 'Updating', showAlertMessage: true });
						this.props
							.saveMassUpdateFields(data)
							.then(response => {
								this.setState({ alertMessage: 'Update successful', showAlertMessage: true });
								this.props.setSelectedTasks(response.data);
							})
							.catch(err => console.log(err));
					}
				}
			}
		}
	};

	render() {
		return (
			<React.Fragment>
				<Row>
					<Container>
						<AlertGenerator
							isCustomMessage={this.state.showAlertMessage}
							customMessage={this.state.alertMessage}
							onClose={() => this.setState({ showAlertMessage: false, alertMessage: null })}
						/>
					</Container>
				</Row>
				<Form
					validated={this.state.validated}
					onSubmit={this.handleOnSubmit}
					id='user-form'
					style={{ marginTop: '15px', marginBottom: '15px' }}
					disabled={this.props.viewMode}>
					{this.props.party?.id === -1 ? (
						<Row>
							<Col>
								<Button
									variant={this.props.party?.isCompany ? 'secondary' : 'outline-secondary'}
									onClick={() => this.setState({ isCompany: true })}
									style={{ margin: '0.2em' }}
									size='lg'>
									<BsBuilding style={{ fontSize: '2em', margin: '0.1em' }} />
									Company
								</Button>
								<Button
									variant={this.props.party?.isCompany ? 'outline-secondary' : 'secondary'}
									onClick={() => this.setState({ isCompany: false })}
									style={{ margin: '0.2em' }}
									size='lg'>
									<BsPerson style={{ fontSize: '2em', margin: '0.1em' }} />
									Person
								</Button>
							</Col>
						</Row>
					) : null}
					{this.props.isCompany ? (
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className='form-label'>Naziv</Form.Label>
									<Form.Control
										required
										size='sm'
										type='text'
										placeholder='Company Name'
										name='companyName'
										value={this.props.party?.companyName}
										onChange={this.handleOnChange}
										disabled={this.props.viewMode}
									/>
									<Form.Control.Feedback type='invalid'>Ime nije validno.</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label className='form-label'>Tax ID</Form.Label>
									<Form.Control
										size='sm'
										type='text'
										placeholder='Tax ID'
										name='taxId'
										value={this.props.party?.taxId}
										onChange={this.handleOnChange}
										disabled={this.props.viewMode}
									/>
								</Form.Group>
							</Col>
						</Row>
					) : (
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className='form-label'>First Name</Form.Label>
									<Form.Control
										required
										size='sm'
										type='text'
										placeholder='First Name'
										name='firstName'
										value={this.props.party?.firstName}
										onChange={this.handleOnChange}
										disabled={this.props.viewMode}
									/>
									<Form.Control.Feedback type='invalid'>Invalid name.</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label className='form-label'>Middle Name</Form.Label>
									<Form.Control
										size='sm'
										type='text'
										placeholder='Middle Name'
										name='middleName'
										value={this.props.party?.middleName}
										onChange={this.handleOnChange}
										disabled={this.props.viewMode}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label className='form-label'>SSN</Form.Label>
									<ReactInputMask
										mask='***-**-9999'
										className='form-control form-control-sm'
										name='ssn'
										onChange={this.handleOnChange}
										disabled={this.props.viewMode}
										value={
											this.props.party?.ssn ? this.props.party?.ssn.split('-').join('') : this.props.party?.ssn
										}></ReactInputMask>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label className='form-label'>Last Name</Form.Label>
									<Form.Control
										size='sm'
										type='text'
										placeholder='Last Name'
										name='lastName'
										value={this.props.party?.lastName}
										onChange={this.handleOnChange}
										disabled={this.props.viewMode}
									/>
								</Form.Group>
								<Form.Group>
									<DateField
										label='Date Of Birth'
										value={this.props.party ? this.props.party.dateOfBirth : this.state.dateOfBirth}
										name='dateOfBirth'
										onChange={this.dateChange}
										viewMode={this.props.viewMode}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label className='form-label'>Gender</Form.Label>
									<div key={'radio'} className='mb-6'>
										<Form.Check
											inline
											type={'radio'}
											id={'radio-1'}
											onChange={this.handleGenderChange}
											size='sm'
											value='M'
											label='Male'
											disabled={this.props.viewMode}
											checked={this.props.party ? this.props.party?.gender === 'M' : this.state.gender === 'M'}
										/>
										<Form.Check
											inline
											type={'radio'}
											id={'radio-2'}
											onChange={this.handleGenderChange}
											size='sm'
											value='F'
											label='Female'
											disabled={this.props.viewMode}
											checked={this.props.party ? this.props.party?.gender === 'F' : this.state.gender === 'F'}
										/>
										<Form.Check
											inline
											type={'radio'}
											id={'radio-3'}
											onChange={this.handleGenderChange}
											size='sm'
											value='O'
											label='Other'
											disabled={this.props.viewMode}
											checked={this.props.party ? this.props.party?.gender === 'O' : this.state.gender === 'O'}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					)}
					<Row>
						<SelectField
							as={Col}
							class='col-md-6 col-sm-12'
							label='Vrsta/Tip'
							value={this.props.partyType}
							name='partyTypeName'
							onChange={this.handleOnSelectChange}
							viewMode
							loadOptions={this.loadOptions}
						/>
						{this.props.isParentable && (
							<SelectField
								isClearable
								as={Col}
								class='col-md-6 col-sm-12'
								label={this.props.party?.labelName}
								value={this.props.party?.parent}
								name='parent'
								onChange={this.handleOnSelectChangeParent}
								viewMode={this.props.viewMode}
								dataTable={this.props.parentTable}
								dataField='name'
							/>
						)}
					</Row>
					{!this.props.isMassUpdate && (
						<Row>
							<Form.Group className='col-sm-6 col-md-3'>
								<Form.Label>Kreirao</Form.Label>
								<Form.Control
									size='sm'
									type='text'
									name='Created By'
									value={this.props.party?.createdByName}
									disabled
								/>
							</Form.Group>
							<Form.Group className='col-sm-6 col-md-3'>
								<Form.Label>Datum kreiranja</Form.Label>
								<Form.Control
									size='sm'
									type='text'
									name='Created'
									value={this.props.party?.created ? moment.utc(this.props.party?.created).local().format('L LT') : ''}
									disabled
								/>
							</Form.Group>
							<Form.Group className='col-sm-6 col-md-3'>
								<Form.Label>Izmijenio</Form.Label>
								<Form.Control
									size='sm'
									type='text'
									name='Modified By'
									value={this.props.party?.modifiedByName}
									disabled
								/>
							</Form.Group>
							<Form.Group className='col-sm-6 col-md-3'>
								<Form.Label>Datum izmjene</Form.Label>
								<Form.Control
									size='sm'
									type='text'
									name='Modified'
									value={
										this.props.party?.modified ? moment.utc(this.props.party?.modified).local().format('L LT') : ''
									}
									disabled
								/>
							</Form.Group>
						</Row>
					)}
				</Form>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		pendingState: state.pendingReducer,
		moduleState: state.moduleReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		create: party => {
			dispatch(create(party));
		},
		edit: party => {
			dispatch(edit(party));
		},
		getPartyTypes: moduleId => {
			dispatch(getPartyTypes(moduleId));
		},
		selectPartyType: id => {
			dispatch(selectPartyType(id));
		},
		selectParent: id => {
			dispatch(selectParent(id));
		},
		selectDate: request => {
			dispatch(selectDate(request));
		},
		setState: data => {
			dispatch(setState(data));
		},
		saveDynamicDataFields: (entityCode, dynamicEntityData) => {
			dispatch(saveDynamicDataFields(entityCode, dynamicEntityData));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(BasicPartyInfo);
