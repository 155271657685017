const initialState = {
    page: 1,
    pageSize: 25,
    dataSize: 1,
    codes: []
  };
  
  const codeTableReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "CODE_TABLE_GET_ALL_FULFILLED":
        return Object.assign({}, state, {
          ...state,
          codes: [...action.payload.data]
        });
        break;
      case "CODE_TABLE_GET_PAGE_FULFILLED":
        return Object.assign({}, state, {
          ...state,
          ...action.payload.data,
          codes: [...action.payload.data.codes]
        });
        break;
      case "CODE_TABLE_CREATE_FULFILLED":
        if(state.codes.length === state.pageSize)
        state.codes.splice(state.codes.length - 1, 1);
        return Object.assign({}, state, {
          ...state,
          codes: [action.payload.data, ...state.codes]
        });
      case "CODE_TABLE_DELETE_FULFILLED":
        var removeIndex = state.codes
          .map(function(item) {
            return item.id;
          })
          .indexOf(action.payload.data);
        state.codes.splice(removeIndex, 1);
        return Object.assign({}, state, {
          ...state,
          codes: [...state.codes]
        });
        break;
      case "CODE_TABLE_UPDATE_FULFILLED":
        return Object.assign({}, state, {
          ...state,
          codes: state.codes.map((item, index) => {
            if (item.id != action.payload.data.id) return item;
            return action.payload.data;
          })
        });
        break;
      default:
        return state;
        break;
    }
  };
  
  export default codeTableReducer;
  