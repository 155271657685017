export const selectStyle = (props) => {
  return {
    container: (provided, state) => ({
      ...provided,
      padding: 0,
      minHeight: "calc(1.5em + 0.5rem - 2px)",
    }),
    control: (provided, state) => ({
      ...provided,
      borderWidth: 1,
      minHeight: "calc(1.5em + 0.5rem - 2px)",
      borderColor: state.isFocused
        ? "#ddd"
        : props.isValid === undefined || props.isValid
        ? "#ddd"
        : "red",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused
          ? "#ddd"
          : props.isValid === undefined || props.isValid
          ? "#ddd"
          : "red",
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "calc(1.5em + 0.5rem - 2px)",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0,
    }),
    selectIndicator: (provided, state) => ({
      ...provided,
      height: "calc(1.5em + 0.5rem - 2px)",
      padding: 0,
      margin: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: "2px",
    }),
    input: (provided, state) => ({
      ...provided,
      height: "20px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      paddin: 0,
      height: "20px",
    }),
  };
};
