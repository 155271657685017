import React, {Component} from "react";

import SelectField from "../dynamicFields/selectField";

export default class SelectFilter extends Component {

    state = {
        value: null
    }

    onChange = (value) => {
        this.setState({value}, () => this.props.onFilter(value) );
    }

    render() {
        return(
            <SelectField
              class = "pb-0 mb-2 mt-1 pt-1 border-top"
              isMulti={this.props.isMulti}
              value={this.state.value}
              name={this.props.name}
              onChange={this.onChange}
              viewMode={this.props.viewMode}
              loadOptions={this.props.loadOptions}
              dataTable={this.props.dataTable}
              dataField={this.props.dataField}
              isHidden={this.props.isHidden}
            />
        );
    }
}