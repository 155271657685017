import parse from 'html-react-parser';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, Form, ProgressBar, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, {
	PaginationListStandalone,
	PaginationProvider,
	SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { MdDelete, MdEdit, MdMail } from 'react-icons/md';
import { connect } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { create, deleteNote, getPage, update } from '../../actions/noteActions';
import * as notesServices from '../../services/noteServices';
import { getUserNoteTypes } from '../../services/noteServices';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import SelectFilter from '../customFilterFields/selctFilter';
import SelectField from '../dynamicFields/selectField';
import EmailModal from '../email/EmailModal';
import * as utility from '../utility';

class NotesPanel extends Component {
	componentDidMount() {
		this.props.getPage({
			page: this.props.noteState.page,
			pageSize: this.props.noteState.pageSize,
			entityCode: this.props.entityCode,
			entityId: this.props.entityId,
		});
	}

	state = {
		page: 1,
		pageSize: 25,
		confirmationModalShow: false,
		deleteNote: -1,
		modalShow: false,
		expanded: [],
		id: -1,
		noteType: null,
		validated: false,
		filters: {},
	};

	componentWillReceiveProps(nextProps) {
		const { viewMode } = this.props;
		if (nextProps.show !== viewMode) this.setState({ viewMode });
	}

	onDeleteNote = id => this.props.deleteNote(id);

	handleOnChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleTableChange = (_, { filters, page, sizePerPage }) => {
		var filterValues = {};

		Object.keys(filters).forEach(element => {
			if (filters[element].filterType === 'DATE_FROM_TO') {
				filterValues[element + 'To'] = filters[element].filterVal.To;
				filterValues[element + 'From'] = filters[element].filterVal.From;
			}

			if (filters[element].filterType === 'CUSTOM_MULTISELECT') {
				var value = '';
				filters[element].filterVal.forEach(x => (value += x.value + ','));
				filterValues[element] = value;
			} else filterValues[element] = filters[element].filterVal;
		});

		this.setState({ filters: filterValues });
		if (page !== this.props.noteState.page || sizePerPage !== this.props.noteState.pageSize)
			this.handleSearch(page, sizePerPage);
	};

	handleSearch = (page, pageSize) => {
		const filters = this.state.filters ? this.state.filters : {};
		const request = {
			page,
			pageSize,
			entityCode: this.props.entityCode,
			entityId: this.props.entityId,
			...filters,
		};

		this.props.getPage(request);
	};

	handleOnKeyDown = e => {
		if (e.keyCode === 13) {
			//ENTER
			const { page, pageSize } = this.props.noteState;
			this.handleSearch(page, pageSize);
		}
	};

	handleOnSelectChange = value => this.setState({ noteType: value });

	confirmationHandleOpen = deleteNote => this.setState({ deleteNote, confirmationModalShow: true });

	confirmationHandleClose = () => this.setState({ confirmationModalShow: false });

	emailModalOpen = noteId => {
		this.setState({ note: this.props.noteState.notes.find(x => x.id === noteId), emailModalShow: true });
	};

	emailModalClose = () => this.setState({ emailModalShow: false });

	onOldCaseNotesImport = () => {
		this.setState({ pending: true });
		notesServices
			.importOldCaseNotes(this.props.entityId)
			.then(() => {
				this.props.getPage({
					page: 1,
					pageSize: this.props.noteState.pageSize,
					entityCode: this.props.entityCode,
					entityId: this.props.entityId,
				});
				this.setState({ pending: false });
			})
			.catch(() => this.setState({ pending: false }));
	};

	onPageChange = newPage => {
		this.props.getPage({
			page: newPage,
			pageSize: this.props.noteState.pageSize,
			entityCode: this.props.entityCode,
			entityId: this.props.entityId,
		});
	};

	onPageSizeChange = newPageSize => {
		if (this.props.noteState.pageSize !== newPageSize) {
			var size = Math.ceil(this.props.noteState.dataSize / newPageSize);
			if (size > this.props.noteState.page) size = this.props.noteState.page;
			this.props.getPage({
				page: size,
				pageSize: newPageSize,
				entityCode: this.props.entityCode,
				entityId: this.props.entityId,
			});
		}
	};

	isLoading = () => this.props.pendingState['NOTE_GET_PAGE']?.pending;

	handleOpen = () => this.setState({ modalShow: true, editNote: null, viewMode: false });

	handleClose = () => this.setState({ modalShow: false });

	onEdit = row =>
		this.setState({
			...row,
			noteType: { label: row.noteType, value: row.noteTypeId },
		});

	handleSubmit = e => {
		e.preventDefault();
		const form = e.currentTarget;

		if (!(form.checkValidity() && this.state.noteType)) e.stopPropagation();
		else {
			this.setState({ validated: true });
			if (this.state.noteType) {
				const note = {
					id: this.state.id,
					subject: this.state.subject,
					body: this.state.body,
					noteTypeId: this.state.noteType.value,
					entityCode: this.props.entityCode,
					entityId: parseInt(this.props.entityId),
				};

				this.state.id === -1 ? this.props.create(note) : this.props.update(note);
				this.setState({
					id: -1,
					subject: '',
					body: '',
					noteType: null,
				});
			}
		}
	};

	loadOptions = (_, callback) => {
		setTimeout(
			getUserNoteTypes().then(response =>
				callback(
					response.data.map(cg => {
						return { value: cg.id, label: cg.name };
					})
				)
			),
			1000
		);
	};

	render() {
		const style = { whiteSpace: 'pre-line' };

		var columns = [
			{
				dataField: 'subject',
				text: 'Subject',
				filter: textFilter({
					placeholder: 'Subject',
					className: 'form-control-sm mt-1',
					delay: 10,
				}),
				formatter: (_, row) => <div style={style}>{parse(sanitizeHtml(row.subject ? row.subject : ''))}</div>,
			},
			{
				dataField: 'body',
				text: 'Body',
				filter: textFilter({
					placeholder: 'Body',
					className: 'form-control-sm mt-1',
					delay: 10,
				}),
				headerStyle: { width: '55%' },
				formatter: (_, row) => <div style={style}>{parse(sanitizeHtml(row.body ? row.body : ''))}</div>,
			},
			{
				dataField: 'noteType',
				text: 'Type',
				headerStyle: { width: '7%' },
				filter: customFilter({ type: 'CUSTOM_MULTISELECT', delay: 10 }),
				filterRenderer: onFilter => (
					<SelectFilter onFilter={onFilter} isMulti={true} dataTable='ctNoteTypes' dataField='name' />
				),
				formatter: (_, row) => row.noteType,
			},
			{
				dataField: 'created',
				text: 'Created',
				formatter: (_, row) => {
					if (row.created === null || /^\s+$/.test(row.created)) return '';
					return moment.utc(row.created).local().format('L LT') + ' by ' + row.createdBy;
				},
			},
			{
				dataField: 'modified',
				text: 'Modified',
				formatter: (_, row) => {
					if (row.modified === null || /^\s+$/.test(row.modified)) return '';
					return moment.utc(row.modified).local().format('L LT') + ' by ' + row.modifiedBy;
				},
			},
			{
				dataField: 'emailAction',
				text: '',
				isDummyField: true,
				headerStyle: { width: '2em' },
				editCellStyle: () => ({ padding: 0 }),

				formatter: (_, row) => {
					return (
						<div className='action-buttons'>
							<MdMail
								className='text-secondary'
								style={{ height: '0.75em' }}
								onClick={() => this.emailModalOpen(row.id)}
							/>
						</div>
					);
				},
			},
		];

		columns = this.props.viewMode
			? columns
			: [
					...columns,
					{
						dataField: 'actions',
						text: '',
						isDummyField: true,
						headerStyle: { width: '3em' },
						hidden: this.state.viewMode,
						editCellStyle: () => ({ padding: 0 }),

						formatter: (_, row) => {
							return (
								<div className='action-buttons'>
									<MdEdit hidden={!row.update} className='text-secondary' onClick={() => this.onEdit(row)} />
									<MdDelete
										hidden={!row.delete}
										className='text-danger'
										onClick={() => this.confirmationHandleOpen(row.id)}
										disabled={this.props.viewMode}
									/>
								</div>
							);
						},
					},
			  ];

		const rowEvents = {
			onDoubleClick: (_, row) => this.onEdit(row),
		};

		return (
			<PaginationProvider
				pagination={paginationFactory({
					custom: true,
					page: this.props.noteState.page,
					sizePerPage: this.props.noteState.pageSize,
					totalSize: this.props.noteState.dataSize,
					style: { hover: 'right' },
					sizePerPageList: utility.sizePerPageList,
				})}>
				{({ paginationProps, paginationTableProps }) => (
					<Card onKeyDown={this.handleOnKeyDown} tabIndex='0'>
						<Card.Body>
							{this.props.entityCode === 'case' && (
								<Button className='mb-2' onClick={this.onOldCaseNotesImport}>
									Import Old Case Notes
								</Button>
							)}
							{(this.state.pending || this.isLoading()) && (
								<ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />
							)}
							<BootstrapTable
								keyField='id'
								columns={columns}
								data={this.props.noteState.notes}
								className='table-body'
								bordered={true}
								filter={filterFactory()}
								onTableChange={this.handleTableChange}
								{...paginationTableProps}
								remote={{ filter: true, pagination: true }}
								striped
								hover
								condensed
								rowEvents={rowEvents}
							/>
							{(this.state.pending || this.isLoading()) && this.props.noteState.notes?.length > 0 && (
								<ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />
							)}
							<Card.Footer>
								<SizePerPageDropdownStandalone {...paginationProps} variation='dropup' />
								<PaginationListStandalone {...paginationProps} />
							</Card.Footer>
						</Card.Body>

						{this.props.viewMode ? null : (
							<Card.Footer>
								<Form
									id='note'
									onSubmit={this.handleSubmit}
									disabled={this.props.viewMode}
									validated={this.state.validated}>
									<Row>
										<Form.Group className='col-md-8 col-sm-12'>
											<Form.Label>Subject</Form.Label>
											<Form.Control
												size='sm'
												type='text'
												name='subject'
												value={this.state.subject}
												onChange={this.handleOnChange}
												disabled={this.props.viewMode}
											/>
										</Form.Group>
										<SelectField
											class='col-md-4 col-sm-12'
											label='Note Type'
											value={this.state.noteType}
											name='noteType'
											onChange={this.handleOnSelectChange}
											viewMode={this.props.viewMode}
											loadOptions={this.loadOptions}
											dataTable='ctNoteTypes'
											dataField='name'
										/>
									</Row>
									<Form.Group>
										<Form.Label>Body </Form.Label>
										<Form.Control
											size='sm'
											as='textarea'
											rows='10'
											name='body'
											value={this.state.body}
											onChange={this.handleOnChange}
											disabled={this.props.viewMode}
										/>
									</Form.Group>
									<Button size='sm' type='submit' form='note' disabled={this.props.viewMode || !this.state.noteType}>
										{this.state.id === -1 ? 'Add' : 'Edit'}
									</Button>
								</Form>
							</Card.Footer>
						)}
						<ConfirmationModal
							show={this.state.confirmationModalShow}
							handleClose={this.confirmationHandleClose}
							confirmFunction={() => {
								this.props.deleteNote(this.state.deleteNote);
								this.confirmationHandleClose();
							}}
							title='Delete Alert'
							message='Are you sure you want to delete the note?'
						/>
						{this.state.emailModalShow && (
							<EmailModal
								templateSelectShow={false}
								caseId={this.props.entityId}
								title={this.props.title ? this.props.title : this.props.label}
								show={this.state.emailModalShow}
								onHide={() => this.setState({ emailModalShow: false })}
								closeModal={this.emailModalClose}
								data={this.state.note}
							/>
						)}
					</Card>
				)}
			</PaginationProvider>
		);
	}
}

const mapStateToProps = state => {
	return {
		pendingState: state.pendingReducer,
		noteState: state.noteReducer,
		moduleState: state.moduleReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPage: (page, pageSize) => {
			dispatch(getPage(page, pageSize));
		},
		deleteNote: noteId => {
			dispatch(deleteNote(noteId));
		},
		create: note => {
			dispatch(create(note));
		},
		update: note => {
			dispatch(update(note));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesPanel);
