import React from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

export const BasicField = props => {
	return (
		<Form.Group as={props.as}>
			<Form.Label>{props.label}</Form.Label>
			<OverlayTrigger
				placement={'bottom'}
				overlay={
					props.viewMode ? (
						<Popover id={'popover-disabled'} hidden={!props.viewMode || !props.value}>
							<Popover.Content>{props.value}</Popover.Content>
						</Popover>
					) : (
						<div />
					)
				}>
				<Form.Control
					size='sm'
					className={props.type === 'number' ? 'text-right' : ''}
					type={props.type ? props.type : 'text'}
					as={props.isMultilineText ? 'textarea' : 'input'}
					name={props.name}
					placeholder={props.placeholder}
					value={props.value}
					disabled={props.viewMode}
					onChange={props.onChange}
				/>
			</OverlayTrigger>
		</Form.Group>
	);
};
