import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentModule } from '../actions/moduleActions';

import UnauthorizedFallback from './fallbacks/unauthorized';

export const ProtectedRoute = ({ component: Component, authPath, ...rest }) => {
	var navModules = useSelector(state => state.moduleReducer.navModules);
	const reportModules = useSelector(state => state.moduleReducer.reportModules);

	navModules = navModules && reportModules ? [...navModules, ...reportModules] : navModules;
	const currentModule = useSelector(state => state.moduleReducer.currentModule);
	const path = authPath ? authPath : rest.path;
	const dispatch = useDispatch();
	return (
		<Route
			{...rest}
			render={props => {
				const authorized = navModules
					? navModules.some(x => x.relativePath === path || path === '/izvjestaj')
						? true
						: false
					: false;
				if (authorized) {
					if (currentModule.relativePath !== path && path !== '/izvjestaj') dispatch(updateCurrentModule(path));
					return <Component {...props} {...rest} />;
				} else return <UnauthorizedFallback />;
			}}
		/>
	);
};
