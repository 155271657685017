import React from 'react';
import { Container } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';

const UnatherizedFallback = props => (
	<Container className='text-secondary text-center text-muted'>
		<h2>{props.header ? props.header : 'UNAUTHORIZED'}</h2>
		<MdWarning style={{ fontSize: '20rem' }} />
		<h4>{props.message ? props.message : "You don't have a permission to access this page!"}</h4>
	</Container>
);

export default UnatherizedFallback;
