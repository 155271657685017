import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DebounceButton } from '../dynamicFields/debounceButton';

export const ConfirmationModal = props => {
	return (
		<Modal show={props.show} onHide={props.handleClose} scrollable={props.scrollable}>
			<Modal.Header closeButton>
				<Modal.Title>{props.title}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p>{props.message}</p>
			</Modal.Body>

			<Modal.Footer>
				<Button variant='secondary' onClick={props.handleClose}>
					No
				</Button>

				{props.debounceSubmit ? (
					<DebounceButton onClick={props.confirmFunction} disabled={props.disabledConfirm} variant='primary'>
						Yes
					</DebounceButton>
				) : (
					<Button variant='primary' onClick={props.confirmFunction} disabled={props.disabledConfirm}>
						Yes
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};
