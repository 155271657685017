const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  caseGroups: [],
  newCaseGroup: null,
};

const caseGroupReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "CASE_GROUP_GET_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        caseGroups: state.caseGroups.map(x => {
          if (x.id !== action.payload.data.id) return x;
          x = { ...action.payload.data };
          return x;
        }),
        options: action.payload.data.cases ? action.payload.data.cases.map(x => {
          return { value: x.id, label: x.caseNo };
        }) : [],
      });
      break;
    case "CASE_GROUP_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        caseGroups: [...action.payload.data.caseGroups]
      });
      break;
    case "CASE_GROUP_CREATE_FULFILLED":
      if (state.caseGroups.length === state.pageSize)
        state.caseGroups.splice(state.caseGroups.length - 1, 1);
      return Object.assign({}, state, {
        ...state,
        caseGroups: [action.payload.data, ...state.caseGroups],
        options: []
      });
      break;
    case "CASE_GROUP_DELETE_FULFILLED":
      var removeIndex = state.caseGroups
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.caseGroups.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        caseGroups: [...state.caseGroups]
      });
      break;
    case "CASE_GROUP_EDIT_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        caseGroups: state.caseGroups.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        }),
        options: action.payload.data.cases ? action.payload.data.cases.map(x => {
          return { value: x.id, label: x.caseNo };
        }) : [],
      });
      break;
    case "CASE_GROUP_SET_STATE":
      return Object.assign({}, state, {
        ...state,
        ...action.payload,
      });
      break;
    default:
      return state;
      break;
  }
};

export default caseGroupReducer;
