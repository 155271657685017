import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { MdContactPhone, MdDelete } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import {
	addContact,
	addEditContact,
	create,
	deleteContacts,
	edit,
	getContactTypes,
	getFirmContacts,
	getPartyContacts,
	getStates,
	getUserContacts,
	toggleDeletedContact,
} from '../../actions/contactActions';
import NumberInput from '../checks/utils/NumberInput';

var initialContact = {
	id: -1,
	email: '',
	phone: '',
	mobile: '',
	fax: '',
	address: '',
	city: '',
	zip: '',
	stateName: '',
	contactTypeName: '',

	validationError: '',
	name: '',
};

class ContactsTable extends Component {
	state = {
		contact: { ...initialContact },
		editedContacts: [],
		deletedContacts: [],
		createdContacts: [],
	};

	componentDidMount() {
		this.props.getContactTypes(this.props.currentModule.id);
		this.props.getStates();
		if (this.props.userId) this.props.getUserContacts(this.props.userId);
		else if (this.props.firmId) this.props.getFirmContacts(this.props.firmId);
		else if (this.props.partyId) this.props.getPartyContacts(this.props.partyId);
	}

	handleAddContact = newContact => {
		if (this.state.contact.contactTypeName) {
			if (this.props.isMassUpdate) {
				newContact.id = this.props.contactState.createdContacts.length + 1;
			}
			this.props.addContact(newContact);
			initialContact.id--;
			this.setState({ contact: { ...initialContact }, validationError: '' });

			if (this.props.handleAddContact) this.props.handleAddContact(newContact);
		} else {
			this.setState({ validationError: 'Contact type must not be empty!' });
		}
	};

	getContactTypes = () => this.props.contactState.contactTypes;

	getStates = () => this.props.contactState.states;

	createContacts = () => {
		var contacts = this.props.contactState.createdContacts.map(x => {
			if (!x.deleted) return x;
		});

		contacts.forEach(i => {
			var contactType = this.props.contactState.contactTypes.find(x => {
				return x.name === i.contactTypeName;
			});
			var cntState = this.props.contactState.states.find(x => {
				return x.name === i.stateName;
			});
			i.userId = this.props.userId;
			i.firmId = this.props.firmId;
			i.partyId = this.props.partyId;
			i.contactTypeId = contactType ? contactType.id : null;
			i.stateId = cntState ? cntState.id : null;
		});

		this.props.create(contacts);
	};

	editContacts = () => {
		this.props.contactState.editedContacts.forEach(i => {
			var contactType = this.props.contactState.contactTypes.find(x => {
				return x.name === i.contactTypeName;
			});
			var cntState = this.props.contactState.states.find(x => {
				return x.name === i.stateName;
			});
			i.contactTypeId = contactType ? contactType.id : null;
			i.stateId = cntState ? cntState.id : null;
		});

		this.props.edit(this.props.contactState.editedContacts);
	};

	deleteContacts = () => {
		var ids = this.props.contactState.deletedContacts.map(x => {
			return x.id;
		});
		this.props.deleteContacts(ids);
	};

	handleSave = () => {
		if (this.props.contactState.createdContacts.length !== 0) this.createContacts();
		if (this.props.contactState.editedContacts.length !== 0) this.editContacts();
		if (this.props.contactState.deletedContacts.length !== 0) this.deleteContacts();
	};

	handleDeleteContact = row => {
		if (!this.props.isMassUpdate) this.props.toggleDeletedContact(row);
		else this.props.handleDeleteContact(row);
	};

	render() {
		//Mapping Contact Types
		let typeOptions = this.props.contactState.contactTypes.map(ct => {
			return { value: ct.name, label: ct.name };
		});

		let contactsTypes = this.props.isMassUpdate
			? this.props.contacts.map(x => x.contactTypeName)
			: this.props.contactState.contacts.map(x => x.contactTypeName);
		typeOptions = typeOptions.filter(x => !contactsTypes.includes(x.value));

		//Mapping States
		const cntStatesOptions = this.props.contactState.states.map(ct => {
			return { value: ct.name, label: ct.name, id: ct.id };
		});

		//Column Configuration
		const columns = [
			{
				dataField: 'id',
				text: 'Contact ID',
				type: 'number',
				headerStyle: { width: '5%' },
				style: {
					overflowWrap: 'break-word',
				},
				formatter: (cellContent, row) => (row.id > 0 ? row.id : null),
			},

			{
				dataField: 'name',
				text: 'Name',

				style: {
					overflowWrap: 'break-word',
				},
			},
			{
				dataField: 'email',
				text: 'Email',
				type: 'email',

				style: {
					overflowWrap: 'break-word',
				},
				editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
					<EmailInput {...editorProps} value={value} />
				),
			},
			{
				dataField: 'phone',
				text: 'Phone',

				style: {
					overflowWrap: 'break-word',
				},
				editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
					<NumberInput
						{...editorProps}
						value={value}
						format={value ? value.replace(/[0-9]/g, '#') : '(###) ###-####'}
						mask='_'
						stringValue={true}
					/>
				),
				formatter: (cellContent, row) => {
					return (
						<NumberFormat
							value={row.phone}
							displayType={'text'}
							format={row.phone ? row.phone.replace(/[0-9]/g, '#') : '(###) ###-####'}
							mask='_'
						/>
					);
				},
			},
			{
				dataField: 'mobile',
				text: 'Mobile',

				style: {
					overflowWrap: 'break-word',
				},
				editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
					<NumberInput
						{...editorProps}
						value={value}
						format={value ? value.replace(/[0-9]/g, '#') : '(###) ###-####'}
						mask='_'
						stringValue={true}
					/>
				),
				formatter: (cellContent, row) => {
					return (
						<NumberFormat
							value={row.mobile}
							displayType={'text'}
							format={row.mobile ? row.mobile.replace(/[0-9]/g, '#') : '(###) ###-####'}
							mask='_'
						/>
					);
				},
			},
			{
				dataField: 'fax',
				text: 'Fax',

				style: {
					overflowWrap: 'break-word',
				},
				editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
					<NumberInput
						{...editorProps}
						value={value}
						format={value ? value.replace(/[0-9]/g, '#') : '(###) ###-####'}
						mask='_'
						stringValue={true}
					/>
				),
				formatter: (cellContent, row) => {
					return (
						<NumberFormat
							value={row.fax}
							displayType={'text'}
							format={row.fax ? row.fax.replace(/[0-9]/g, '#') : '(###) ###-####'}
							mask='_'
						/>
					);
				},
			},
			{
				dataField: 'address',
				text: 'Address',

				style: {
					overflowWrap: 'break-word',
				},
			},
			{
				dataField: 'city',
				text: 'City',

				style: {
					overflowWrap: 'break-word',
				},
			},
			{
				dataField: 'zip',
				text: 'Zip',

				style: {
					overflowWrap: 'break-word',
				},
			},
			{
				dataField: 'stateName',
				text: 'State',

				style: {
					overflowWrap: 'break-word',
				},
				editor: {
					type: Type.SELECT,
					options: cntStatesOptions,
				},
			},
			{
				dataField: 'contactTypeName',
				text: 'Contact Type',

				style: {
					overflowWrap: 'break-word',
				},
				editor: {
					type: Type.SELECT,
					options: typeOptions,
				},
			},
			{
				dataField: 'actions',
				text: '',
				isDummyField: true,
				headerStyle: { width: '4.2em' },
				editable: false,
				formatter: (cellContent, row) => {
					if (!this.props.isDisabled) {
						if (row.id < 0 && !row.new) {
							return (
								<div className='text-center'>
									<Button
										variant='primary'
										size='sm'
										style={{ fontSize: '0.8em' }}
										onClick={() => this.handleAddContact(row)}>
										<MdContactPhone style={{ fontSize: '1.2em' }} /> Add
									</Button>
								</div>
							);
						}
						return (
							<div className='action-buttons'>
								<MdDelete className='text-danger' onClick={() => this.handleDeleteContact(row)} />
							</div>
						);
					}
					return <div></div>;
				},
			},
		];

		//Custom Row Classes
		const rowStyles = (row, rowIndex) => {
			let style = {};

			if (row.new) {
				style.backgroundColor = '#95d095';
			} else if (row.deleted) {
				style.backgroundColor = '#e89996';
			} else if (row.edited) {
				style.backgroundColor = '#5bc0de';
			}
			return style;
		};

		let data = this.props.isMassUpdate
			? [...this.props.contacts, this.state.contact]
			: [...this.props.contactState.contacts, ...this.props.contactState.createdContacts, this.state.contact];

		return (
			<div style={{ width: '100%', marginTop: '0.5em' }}>
				<BootstrapTable
					keyField='id'
					columns={columns}
					data={data}
					rowStyle={rowStyles}
					bordered={true}
					striped
					hover
					condensed
					bodyStyle={{ wordBreak: 'break-all' }}
					cellEdit={cellEditFactory(
						this.props.isDisabled
							? {}
							: {
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => {
										if (row.id >= 0) {
											this.props.addEditContact(row);
										} else {
											this.setState({ contact: row });
										}
									},
							  }
					)}
				/>
				<p className='text-danger'>{this.state.validationError}</p>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		contactState: state.contactReducer,
		pendingState: state.pendingReducer,
		currentModule: state.moduleReducer.currentModule,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getUserContacts: userId => {
			dispatch(getUserContacts(userId));
		},
		getFirmContacts: firmId => {
			dispatch(getFirmContacts(firmId));
		},
		getPartyContacts: partyId => {
			dispatch(getPartyContacts(partyId));
		},
		getContactTypes: moduleId => {
			dispatch(getContactTypes(moduleId));
		},
		create: contact => {
			dispatch(create(contact));
		},
		addContact: contact => {
			dispatch(addContact(contact));
		},
		addEditContact: contact => {
			dispatch(addEditContact(contact));
		},
		toggleDeletedContact: contact => {
			dispatch(toggleDeletedContact(contact));
		},
		edit: contact => {
			dispatch(edit(contact));
		},
		deleteContacts: contact => {
			dispatch(deleteContacts(contact));
		},
		getStates: () => {
			dispatch(getStates());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(ContactsTable);

class EmailInput extends React.Component {
	static defaultProps = {
		value: '',
	};
	state = {
		value: this.props.value,
	};

	handleKeyDown = e => {
		const { onEscape } = this.props;
		if (e.keyCode === 27) {
			// ESC
			this.props.onUpdate(this.state.value);
		} else if (e.keyCode === 13) {
			// ENTER
			this.props.onUpdate(this.state.value);
		}
	};

	render() {
		const { value, onUpdate, ...rest } = this.props;
		return [
			<Form onSubmit={this.onSubmit}>
				<Form.Control
					type='email'
					size='sm'
					value={this.state.value}
					onBlur={() => onUpdate(this.state.value)}
					onKeyDown={this.handleKeyDown}
					onChange={e => this.setState({ value: e.target.value })}
					formatErrorTxt='Incorect email format!'
				/>
			</Form>,
		];
	}
}
