import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { getUserPermissions } from './actions/moduleActions';
import { getReportGroups } from './actions/moduleActions';
import { getUserReports } from './actions/reportActions';
import { setUser } from './actions/userActions';
import App from './App';
import './index.css';

import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import * as serviceWorker from './serviceWorker';
import store from './store';

const token = localStorage.getItem('token');

const container = document.getElementById('root');
const root = createRoot(container);

if (token && token !== 'undefined' && token !== '') {
	store.dispatch(setUser(token));
	store.dispatch(getUserPermissions());
	store.dispatch(getUserReports());
}

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
