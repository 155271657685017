import moment from 'moment';
import {
	calculate,
	generateColumnTotals,
	generateRowTotals,
	getCollectiveDecisionAmount,
} from '../components/cases/decision/decisionUtility';
import { filterDuplicates } from '../components/utility';

const initialState = {
	decision: {
		caseId: -1,
		decisionType: null,
		interestReferentDateField: null,
		interestReferentAmountField: null,
		attorneyFeeLimit: { value: 5, label: '20%' },
		arbitrator: null,
		settlingAttorney: null,
		judge: null,
		interestPercentage: 2,
		defaultDecisionPercentage: null,
		decisionPercentage: 100,
		interestDiscountPercentage: 100,
		referentDate: null,
	},
};

const adjustDuplicates = (arr = [], elements = []) =>
	arr.filter(x => elements.findIndex(y => y.caseId === x.caseId) === -1);

const decisionReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'DECISION_CREATE_FULFILLED':
		case 'DECISION_GET_FULFILLED':
			return Object.assign({}, state, {
				decision: Object.assign({}, state.decision, {
					...action.payload.data,
					decisionAmount: 0,
					interestReferentAmountField: null,
					decisionStatus: null,
					settlingAttorney: null,
					decisionType: null,
					interestReferentDateField: null,
					attorneyFeeLimit: null,
					attorneyFeeLimit: { value: 5, label: '20%' },
					interestPercentage: 2,
					referentDate: null,
				}),
			});
			break;
		case 'DECISION_CLEAR':
			return Object.assign({}, state, {
				decision: {
					caseId: -1,
					decisionType: null,
					interestReferentDateField: null,
					interestReferentAmountField: null,
					attorneyFeeLimit: { value: 5, label: '20%' },
					arbitrator: null,
					settlingAttorney: null,
					judge: null,
					interestPercentage: 2,
					defaultDecisionPercentage: null,
					decisionPercentage: 100,
					interestDiscountPercentage: 100,
					referentDate: null,
				},
			});
			break;
		case 'DECISION_SET_STATE':
			const { decisionAmount } = action.payload;
			return Object.assign({}, state, {
				decision: {
					...state.decision,
					...action.payload,
					decisionAmount: decisionAmount ? parseFloat(decisionAmount) : parseFloat(state.decision.decisionAmount),
				},
			});
			break;
		case 'DECISION_GET_GROUP_CASES_FULFILLED':
			let decisionGroupCasesTaken = action.payload.data.decisionGroupCases
				.map(x => {
					if (x.isTaken) {
						return {
							...x,
							interestAccrued: x.interestAmount,
							interestPercentage: 2,
							interestDiscountPercentage: 100,
							totals: generateRowTotals(x),
						};
					}
				})
				.filter(x => x);

			let decisionGroupCasesOpen = action.payload.data.decisionGroupCases.map(x => ({
				...x,
				toInterestDate: moment(),
				interestAccrued: 0,
				decisionPercentage: 100,
				interestPercentage: 2,
				isActive: true,
				additionalAttorneyFees: 0,
				marshalPoundage: 0,
				attorneyFees: 0,
				interestDiscountPercentage: 100,
				totals: generateRowTotals(x),
			}));

			decisionGroupCasesOpen = filterDuplicates(decisionGroupCasesOpen, 'caseId');

			return Object.assign({}, state, {
				decision: Object.assign({}, state.decision, {
					...state.decision,
					toInterestDate: moment(),
					decisionGroupCasesTaken,
					decisionGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(decisionGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(decisionGroupCasesTaken, true),
				}),
			});
			break;
		case 'DECISION_CASE_DELETE_FULFILLED':
			var removeIndex = state.decision.decisionGroupCasesTaken
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			var elements = state.decision.decisionGroupCasesTaken.splice(removeIndex, 1);
			elements.id = 0;

			decisionGroupCasesTaken =
				state.decision.decisionGroupCasesTaken && state.decision.decisionGroupCasesTaken.length
					? [...state.decision.decisionGroupCasesTaken]
					: [];
			decisionGroupCasesOpen =
				state.decision.decisionGroupCasesOpen && state.decision.decisionGroupCasesOpen.length > 0
					? [...adjustDuplicates(state.decision.decisionGroupCasesOpen, elements), ...elements]
					: [...elements];

			return Object.assign({}, state, {
				decision: Object.assign({}, state.decision, {
					...state.decision,
					decisionGroupCasesTaken,
					decisionGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(decisionGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(decisionGroupCasesTaken, true),
				}),
			});
			break;
		case 'DECISION_CASE_UPDATE_VALUE':
			decisionGroupCasesOpen = state.decision.decisionGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId
					? { ...x, totals: generateRowTotals(x) }
					: {
							...action.payload,
							totals: generateRowTotals(action.payload),
					  }
			);
			return Object.assign({}, state, {
				...state,
				decision: {
					...state.decision,
					decisionGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(decisionGroupCasesOpen),
				},
			});
			break;
		case 'DECISION_CASE_TOGGLE':
			decisionGroupCasesOpen = state.decision.decisionGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId ? x : { ...action.payload, isActive: !action.payload.isActive }
			);

			return Object.assign({}, state, {
				...state,
				decision: {
					...state.decision,
					decisionGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(decisionGroupCasesOpen),
					shouldRecalculate: true,
				},
			});
			break;
		case 'DECISION_CASE_TOGGLE_ALL':
			decisionGroupCasesOpen = state.decision.decisionGroupCasesOpen.map(x => {
				return { ...x, isActive: action.payload };
			});

			return Object.assign({}, state, {
				...state,
				decision: {
					...state.decision,
					decisionGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(decisionGroupCasesOpen),
					shouldRecalculate: true,
				},
			});
			break;
		case 'DECISION_CASE_RECALCULATE':
			decisionGroupCasesOpen = [...calculate(action.payload)];
			return Object.assign({}, state, {
				...state,
				decision: {
					...state.decision,
					decisionGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(decisionGroupCasesOpen),
				},
			});
			break;
		default:
			return state;
			break;
	}
};

export default decisionReducer;
