export const env = {
	SERVER_ENV: {
		url:
			!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
				? 'http://localhost:5000'
				: 'https://devocracy.software/baby-steps-api',
	},
	CODE_ENV: {
		url:
			!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
				? 'http://localhost:5000/babysteps/'
				: 'http://23.254.225.191/babysteps/',
	},
	DOCUMENT_MANAGMENT_ENV: {
		url:
			!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
				? 'http://localhost:4646'
				: 'http://10.255.83.111/LIFE_DM',
	},
	DOCUMENT_MANAGMENT_API_ENV: {
		url:
			!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
				? 'http://localhost:14848'
				: 'http://10.255.83.111/LIFE_DM_API',
	},
	APP_OWNER: 'Babysteps',
	BASE_PATH: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '/' : '/babysteps/',
};
