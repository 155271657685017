import axios from 'axios';
import { env } from '../configs/env';

export function createCase(newCase) {
	return axios(env.SERVER_ENV.url + '/api/case', {
		method: 'POST',
		data: JSON.stringify(newCase),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function editCase(newCase) {
	return axios(env.SERVER_ENV.url + '/api/case', {
		method: 'PATCH',
		data: JSON.stringify(newCase),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPage(request) {
	return axios(env.SERVER_ENV.url + '/api/case/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPageForPom(request) {
	return axios(env.SERVER_ENV.url + '/api/case/page/pom', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function createBill(request) {
	return axios(env.SERVER_ENV.url + '/api/case/bill', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updateBill(request) {
	return axios(env.SERVER_ENV.url + '/api/case/bill', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updatePoms(request) {
	return axios(env.SERVER_ENV.url + '/api/case/pom', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getBillPage(request) {
	return axios(env.SERVER_ENV.url + '/api/case/bill/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPatientCases(patientId) {
	return axios(env.SERVER_ENV.url + '/api/case/patient/' + patientId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getProviderCases(providerId) {
	return axios(env.SERVER_ENV.url + '/api/case/provider/' + providerId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseStatusHistory(caseId) {
	return axios(env.SERVER_ENV.url + '/api/case/statusLogs/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updateCaseFields(caseId) {
	return axios(env.SERVER_ENV.url + '/api/case/bill/' + caseId, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getGroupCases(groupId) {
	return axios(env.SERVER_ENV.url + '/api/case/group/' + groupId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCasePartyTypes(searchTerm) {
	return axios(env.SERVER_ENV.url + '/api/case/partyTypes', {
		method: 'POST',
		data: JSON.stringify({ searchTerm }),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseParties(caseId) {
	return axios(env.SERVER_ENV.url + '/api/case/caseParties/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCasePlaintiffParties(caseId) {
	return axios(env.SERVER_ENV.url + '/api/case/casePlaintiffParties/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseParentParties(request) {
	return axios(env.SERVER_ENV.url + '/api/case/parentParties', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getUserMatterTypes() {
	return axios(env.SERVER_ENV.url + '/api/case/matterType/userTypes', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getNotifications(id) {
	return axios(env.SERVER_ENV.url + '/api/case/notifications/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/Case/dynamic', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function deleteCase(id, moduleId) {
	return axios(env.SERVER_ENV.url + '/api/case/' + id + '/' + moduleId, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function saveTopLevelFieldsDynMassUpdate(data) {
	return axios(env.SERVER_ENV.url + '/api/case/massUpdateTopLevelFields', {
		method: 'POST',
		data: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function massImport(request) {
	return axios(env.SERVER_ENV.url + '/api/case/massImport', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function massBillImportDM(zipFile, billCaseMapping, selectedRows, option) {
	option = '/BILLING/' + option;
	const fileName = zipFile.name;

	var data = new FormData();
	data.append('file', zipFile, fileName);
	data.append('FileName', fileName.substr(0, fileName.lastIndexOf('.')));
	data.append('Option', option);
	data.append('AccessToken', localStorage.getItem('token'));
	data.append('ForceOverwrite', true);
	data.append('UserId', localStorage.getItem('userId'));
	data.append('CaseBillMapping', JSON.stringify(billCaseMapping));
	data.append('SelectedRows', selectedRows);

	return axios(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/MassBillImport', {
		method: 'POST',
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

export function getClusterCases(caseId) {
	return axios(env.SERVER_ENV.url + '/api/case/cluster/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDynamicCaseDefinitionsByTab(tabName, caseId, entityCode = 'case') {
	return axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/definitions', {
		method: 'POST',
		data: {
			entityCode,
			tabName,
			caseId,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getShort(id) {
	return axios(env.SERVER_ENV.url + '/api/case/short/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getHistory(request) {
	return axios(env.SERVER_ENV.url + '/api/case/history', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export async function getCaseDocuments(arr) {
	const action = unc =>
		axios.get(
			env.DOCUMENT_MANAGMENT_API_ENV.url +
				'/api/file?UNC=' +
				unc +
				'&FileName=none&AccessToken=' +
				localStorage.getItem('token'),
			{ responseType: 'arraybuffer' }
		);

	const promises = arr.map(x => action(x.unc));
	return await Promise.allSettled(promises);
}

export function validateDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/case/dynamicFieldsMassImportValidate', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function massImportDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/case/dynamicFieldsMassImport', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getSelectFieldOptions(searchTerm, dataTableName, dataFieldName) {
	return axios(env.SERVER_ENV.url + '/api/utility/option', {
		method: 'POST',
		data: {
			dataTable: dataTableName,
			dataField: dataFieldName,
			searchTerm,
			fetchSize: 300,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDynamicTabs(entityCode, caseId) {
	return axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/' + entityCode + '/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function saveCaseParties(request) {
	return axios(env.SERVER_ENV.url + '/api/case/caseParties', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseRelationTabs(entityCode, caseId) {
	return axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/relation/' + entityCode + '/' + caseId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseRelationsForTab(request) {
	return axios(env.SERVER_ENV.url + '/api/case/relations', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getRelationDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/Case/relations/dynamic', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function createCaseRelation(request) {
	return axios(env.SERVER_ENV.url + '/api/case/relation', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function editCaseRelation(request) {
	return axios(env.SERVER_ENV.url + '/api/case/relation', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function deleteCaseRelation(id, moduleId) {
	return axios(env.SERVER_ENV.url + '/api/case/relation/' + id + '/' + moduleId, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseRelationsSelectFieldOptions(request) {
	return axios(env.SERVER_ENV.url + '/api/case/relation/option', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
