import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { PDFDocument } from 'pdf-lib';
import { getTaskFilesBlobMultiple } from '../../services/taskServices';

export const generateStatement = check => {
	var fileName = 'statement_' + check.checkNo + '.pdf';
	var doc = createStatement(check);

	doc.save(fileName);
};

export const getStatementArrayBuffer = (check, next = null) => {
	var doc = createStatement(check);
	const blob = doc.output('arraybuffer');
	if (next) next(blob);
	return blob;
};

export const createStatement = check => {
	//const foot = footer(invoice.data, invoice.isRemittance);

	var doc = new jsPDF('l', 'mm', [900, 800]);
	createStatementPage(doc, check);

	return doc;
};

export const createStatementPage = (doc, check) => {
	doc.setFontSize(10);
	doc.setFont('times');

	var imgData = require('./sbg.png');
	doc.addImage(imgData, 'JPEG', 230, 15);

	var page = statementRows(check.statementData, check.checkTypeId === 3);

	doc.text(
		'Sanders Grossman Aronova, PLLC.\n100 Garden City Plaza, Suite 500\nGarden City, NY 11530\nPHONE: (516) 741-4799 FAX: (516) 741-0128',
		230,
		40
	);
	doc.text(check.providerName, 15, 65);
	doc.text(
		check.providerAddress + ', ' + check.providerZip + ', ' + check.providerCity + ', ' + check.providerState,
		15,
		70
	);
	doc.text('DATE: ' + moment.utc(check.checkDate).local().format('L'), 15, 75);

	doc.setFontSize(10);
	doc.setFont(undefined, 'bold');

	doc.text('CHECK TYPE: ' + check.checkTypeName, 120, 75);
	doc.text('STATEMENT FOR CHECK: ' + check.checkNumber, 120, 90);

	doc.setFontSize(7);
	doc.setFont(undefined, 'normal');

	doc.autoTable({
		styles: { fontSize: 7 },
		columnStyles: { europe: { halign: 'center' } },
		startY: 100,
		head: [
			[
				'Invoice No',
				'Invoice Date',
				'Case No',
				'Provider',
				'Patient',
				'DOS Start',
				'DOS End',
				'Claim Amount',
				'Collected Amount',
				'Settlement Amount',
				'Check No',
				'Payment Amount',
				'% Applied',
				'Firm Fees',
				'Referral %',
				'Referral Fees',
				'Type',
			],
		],
		columnStyles: {
			0: { cellWidth: 18 },
			1: { cellWidth: 18 },
			2: { cellWidth: 18 },
			3: { cellWidth: 25 },
			4: { cellWidth: 20 },
			5: { cellWidth: 18 },
			6: { cellWidth: 18 },
			7: { cellWidth: 15 },
			8: { cellWidth: 15 },
			9: { cellWidth: 15 },
			10: { cellWidth: 15 },
			11: { cellWidth: 15 },
			12: { cellWidth: 10 },
			13: { cellWidth: 15 },
			14: { cellWidth: 10 },
			15: { cellWidth: 15 },
			16: { cellWidth: 'auto' },
		},
		body: [...page],
		//foot: [foot],
		lineWidth: 1,
		lineColor: 'black',
	});
};

const statementRows = (statementData, attorneyFee) => {
	const style = { alignText: 'right' };

	return statementData.map(x => {
		return [
			x.invoiceNo,
			x.invoiceDate ? moment(x.invoiceDate).format('YYYY-MM-DD') : '',
			x.caseNo,
			x.providerId + ' - ' + x.providerName,
			x.patientName,
			x.dosStart ? moment(x.dosStart).format('YYYY-MM-DD') : '',
			x.dosEnd ? moment(x.dosEnd).format('YYYY-MM-DD') : '',
			'$' + x.claimAmount.toFixed(2),
			'$' + x.collectedAmount.toFixed(2),
			'$' + x.settlementAmount.toFixed(2),
			x.checkNo,
			'$' + x.paymentAmount.toFixed(2),
			x.paymentAmount == 0 ? '' : ((x.firmFee / x.paymentAmount) * 100).toFixed(2) + '%',
			'$' + x.firmFee.toFixed(2),
			!attorneyFee && x.firmFee == 0
				? ''
				: attorneyFee
				? ((x.referralFee / x.paymentAmount) * 100).toFixed(2) + '%'
				: ((x.referralFee / x.firmFee) * 100).toFixed(2) + '%',
			'$' + x.referralFee.toFixed(2),
			x.paymentType,
		];
	});
};

const footer = (checks, isRemittance) => {
	var totalAmount = 0.0;
	var totalFeeAmount = 0.0;
	var totalCD = 0.0;
	var totalPen = 0.0;
	var totalRemittanceAmount = 0.0;
	var totalToPay = 0.0;
	var totalDP = 0.0;

	checks.forEach(x => {
		totalAmount += parseFloat(x.amount);
		totalFeeAmount += parseFloat(x.firmFee);
		totalCD += parseFloat(x.costDeducted);
		totalPen += parseFloat(x.penaltiesDeducted);
		totalRemittanceAmount +=
			parseFloat(x.amount) - parseFloat(x.firmFee) - parseFloat(x.costDeducted) - parseFloat(x.penaltiesDeducted);
		totalToPay += parseFloat(x.firmFee) + parseFloat(x.costDeducted) + parseFloat(x.penaltiesDeducted);
		totalDP += x.isDirectPayment ? x.amount : 0;
	});

	let footer = [
		'',
		'',
		'$' + totalAmount.toFixed(2),
		'',
		'',
		'$' + totalFeeAmount.toFixed(2),
		'$' + totalCD.toFixed(2),
		'$' + totalPen.toFixed(2),
		'$' + (totalRemittanceAmount - totalDP).toFixed(2),
		'',
		'$' + totalToPay.toFixed(2),
	];
	return footer;
};

const mergePdfs = async pdfsToMerges => {
	const mergedPdf = await PDFDocument.create();
	const actions = pdfsToMerges.map(async pdfBuffer => {
		const pdf = await PDFDocument.load(pdfBuffer);
		const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
		copiedPages.forEach(page => {
			// console.log('page', page.getWidth(), page.getHeight());
			// page.setWidth(210);
			mergedPdf.addPage(page);
		});
	});
	await Promise.all(actions);
	const mergedPdfFile = await mergedPdf.saveAsBase64();
	return mergedPdfFile;
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};
