const initialState = {
	automations: [],
	page: 1,
	pageSize: 25,
	dataSize: null,
	automation: {},
};

const constructFormData = a => {
	const taskTypeOption = a.taskTypeId > 0 ? { value: a.taskTypeId, label: a.taskType } : null;
	const taskSubtypeOption = a.taskSubtypeId > 0 ? { value: a.taskSubtypeId, label: a.taskSubtype } : null;
	const status = a.caseStatusId > 0 ? { value: a.caseStatusId, label: a.caseStatus } : null;
	const type = a.automationTypeId > 0 ? { value: a.automationTypeId, label: a.automationType } : null;
	const templateOption = a.templateId > 0 ? { value: a.templateId, label: a.template } : null;

	return {
		tabName: { value: a.tabId, label: a.tab },
		status,
		taskTypeOption,
		taskSubtypeOption,
		templateOption,
		type,
		...a,
	};
};

const automationReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'AUTOMATION_SET_STATE':
			return Object.assign({}, state, {
				automation: {
					...state.automation,
					...action.payload,
				},
			});
			break;
		case 'AUTOMATION_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				automations: action.payload.data.automations.map(x => ({
					...x,
					type: {
						label: x.automationType,
						value: x.automationTypeId,
					},
				})),
			});
			break;
		case 'AUTOMATION_CREATE_FULFILLED':
			if (state.automations.length === state.pageSize) state.automations.splice(state.automations.length - 1, 1);

			action.payload.data.type = {
				label: action.payload.data.automationType,
				value: action.payload.data.automationTypeId,
			};

			return Object.assign({}, state, {
				...state,
				automation: constructFormData(action.payload.data),
				automations: [action.payload.data, ...state.automations],
			});
			break;
		case 'AUTOMATION_DELETE_FULFILLED':
			var removeIndex = state.automations
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.automations.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				automations: [...state.automations],
			});
			break;
		case 'AUTOMATION_UPDATE_FULFILLED':
		case 'AUTOMATION_STATUS_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				automations: state.automations.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'AUTOMATION_GET_ALL_FULFILLED':
			return Object.assign({}, state, {
				...state,
				automations: action.payload.data,
			});
			break;
		case 'AUTOMATION_MODAL_CREATE':
			return Object.assign({}, state, {
				...state,
				automation: {},
			});
			break;
		case 'AUTOMATION_MODAL_EDIT':
			const a = state.automations.find(x => x.id === action.payload);

			return Object.assign({}, state, {
				...state,
				automation: constructFormData(a),
			});
			break;

		default:
			return state;
			break;
	}
};

export default automationReducer;
