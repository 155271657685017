import axios from 'axios';
import { env } from '../configs/env';

export function getEmailTemplates(request) {
	return axios(env.SERVER_ENV.url + '/api/emailTemplate/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getEmailTemplatesAll() {
	return axios(env.SERVER_ENV.url + '/api/emailTemplate/all', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getEmailTemplateById(id) {
	return axios(env.SERVER_ENV.url + '/api/emailTemplate/'+id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getEmailBodyWithoutTags(request) {
	return axios(env.SERVER_ENV.url + '/api/emailTemplate/replaceTags', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function createEmailTemplates(request) {
	console.log('serviceCreate',JSON.stringify(request))
	return axios(env.SERVER_ENV.url + '/api/emailTemplate', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updateEmailTemplates(request) {
	console.log('serviceUpdate',JSON.stringify(request))
	return axios(env.SERVER_ENV.url + '/api/emailTemplate', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function deleteEmailTemplates(id) {
	return axios(env.SERVER_ENV.url + '/api/emailTemplate/' + id, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getEmailTemplateTags() {
	return axios(env.SERVER_ENV.url + '/api/emailTemplate/tags', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
