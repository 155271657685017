import jwtDecode from 'jwt-decode';
import { getLoginRedirect } from '../services/userServices';

const userReducer = (
	state = {
		tokenExpired: false,
		user: undefined,
		page: 1,
		pageSize: 25,
		dataSize: 1,
		users: [],
	},
	action
) => {
	if (
		action.type.endsWith('_REJECTED') &&
		action.payload &&
		action.payload.response &&
		action.payload.response.status === 401
	) {
		var wwwAuthenticate = action.payload.response.headers['www-authenticate'].toString();
		if (!state.tokenExpired && (wwwAuthenticate.includes('expired') || wwwAuthenticate.includes('invalid_token'))) {
			localStorage.removeItem('token');
			localStorage.removeItem('userId');
			localStorage.removeItem('myMattersConfigId');
			localStorage.removeItem('firmName');
			return Object.assign({}, state, {
				...state,
				user: undefined,
				tokenExpired: true,
			});
		}
	}

	switch (action.type) {
		case 'USER_LOGIN_FULFILLED':
			var token = action.payload.data.token;
			localStorage.setItem('token', token);
			var decoded = jwtDecode(token);
			localStorage.setItem('userId', decoded.unique_name);
			localStorage.setItem('myMattersConfigId', decoded.myMattersConfigId);
			localStorage.setItem('firmName', decoded.firmName);
			return Object.assign({}, state, {
				...state,
				user: decoded,
				tokenExpired: false,
				loginRedirect: getLoginRedirect(token),
			});
			break;
		case 'USER_CODE_LOGIN_FULFILLED':
			token = action.payload.data.token;
			localStorage.setItem('token', token);
			decoded = jwtDecode(token);
			localStorage.setItem('userId', decoded.unique_name);
			localStorage.setItem('keyId', decoded.keyId);
			localStorage.setItem('partyId', decoded.partyId);
			localStorage.setItem('surveyId', decoded.surveyId);
			return Object.assign({}, state, {
				...state,
				user: decoded,
				tokenExpired: false,
				loginRedirect: '/anketa',
			});
			break;
		case 'USER_LOGOUT':
			localStorage.removeItem('token');
			localStorage.removeItem('userId');
			localStorage.clear();
			return Object.assign({}, state, {
				...state,
				user: undefined,
			});
			break;
		case 'SET_CURRENT_USER':
			const user = jwtDecode(action.payload);
			localStorage.setItem('userId', user.unique_name);
			localStorage.setItem('myMattersConfigId', user.myMattersConfigId);
			localStorage.setItem('firmName', user.firmName);
			return Object.assign({}, state, { ...state, user });
			break;
		case 'USER_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				users: [...action.payload.data.users],
			});
			break;
		case 'USER_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				users: state.users.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'USER_EDIT_EMAIL_CREDENTIALS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				user: { ...state.user, emailUsername: action.payload.data.emailUsername },
			});
			break;
		case 'USER_DELETE_FULFILLED':
			var removeIndex = state.users
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.users.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				users: [...state.users],
			});
			break;
		case 'USER_GET_UGS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				users: state.users.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userGroups: action.payload.data.userGroups.map(x => {
							return { ...x, value: x.id, label: x.name };
						}),
					};
				}),
			});
			break;
		case 'USER_UPDATE_UGS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				users: state.users.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userGroupNames: action.payload.data.userGroups
							.map(x => {
								return { UGName: x.name };
							})
							.reduce((acc, val) => acc + val.UGName + '&#x0D;', ''),
					};
				}),
			});
			break;
		case 'USER_GET_FIRMS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				users: state.users.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						firms: action.payload.data.firms.map(x => {
							return { ...x, value: x.id, label: x.name };
						}),
					};
				}),
			});
			break;
		case 'USER_UPDATE_FIRMS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				users: state.users.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						firmNames: action.payload.data.firms
							.map(x => {
								return { firmName: x.name };
							})
							.reduce((acc, val) => acc + val.firmName + '&#x0D;', ''),
					};
				}),
			});
			break;
		case 'USER_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				users: [...state.users, { ...action.payload.data, firms: [], userGroups: [] }],
				newUser: action.payload.data,
			});
			break;
		default:
			return state;
			break;
	}
};

export default userReducer;
