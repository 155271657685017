import moment from 'moment';
import { act } from 'react-dom/test-utils';
import invoiceForm from '../components/invoices/invoiceForm';

const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	invoices: [],
	invoicePayments: [],
	invoice: null,
};

const invoiceReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'INVOICE_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'INVOICE_PAYMENT_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'INVOICE_GET_FULFILLED':
		case 'INVOICE_CLEAR_FULFILLED':
			return Object.assign({}, state, {
				...state,
				invoice: {
					...state.invoice,
					...action.payload.data,
					payments: action.payload.data.payments.map(x => {
						return {
							...x,
							cleared: {
								value: x.isCleared,
								label: x.isCleared ? 'Cleared' : 'Not Cleared - Deposited',
							},
						};
					}),
				},
				invoices: state.invoices.map(x => {
					if (x.id !== action.payload.data.id) return x;
					return { ...x, ...action.payload.data };
				}),
			});
			break;
		case 'INVOICE_ADD_PAYMENT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				invoice: {
					...state.invoice,
					payments:
						action.payload.data.invoiceId === state.invoice.id
							? [
									...state.invoice.payments,
									{
										...action.payload.data,
										clearDate: null,
										isCleared: false,
										cleared: { label: 'Not Cleared - Posted', value: false },
									},
							  ]
							: [...state.invoice.payments],
				},
				invoices: state.invoices.map(x => {
					if (x.id !== action.payload.data.invoiceId) return x;
					return { ...x, payments: [...x.payments, action.payload.data] };
				}),
			});
		case 'INVOICE_PAYMENT_UPDATE_VALUE':
			return Object.assign({}, state, {
				...state,
				invoice: {
					...state.invoice,
					payments: [
						...state.invoice.payments.map(x => {
							if (x.id !== action.payload.id) return x;
							return {
								...x,
								clearDate: action.payload.clearDate !== null ? new Date(moment.utc(action.payload.clearDate)) : null,
								cleared: action.payload.cleared,
							};
						}),
					],
				},
			});

		case 'CHECK_OUT_UPDATE_ON_INVOICES':
			return Object.assign({}, state, {
				...state,
				invoice:
					state.invoice.issuedCheckId !== action.payload.id
						? state.invoice
						: {
								...state.invoice,
								issuedCheckId: action.payload.newId,
								issuedCheckNumber: action.payload.newCheckNumber,
						  },
				invoices: [
					...state.invoices.map(x => {
						if (x.issuedCheckId !== action.payload.id) return x;
						return {
							...x,
							issuedCheckId: action.payload.newId,
							issuedCheckNumber: action.payload.newCheckNumber,
						};
					}),
				],
			});
		case 'INVOICE_REMOVE':
			return Object.assign({}, state, {
				...state,
				invoices: state.invoices.filter(x => x.id !== action.payload),
			});

		default:
			return state;
			break;
	}
};

export default invoiceReducer;
