import axios from 'axios';
import { env } from '../configs/env';

export function generateCode(partyId, numberOfCodes) {
	return axios(env.SERVER_ENV.url + '/api/survey/generateCode', {
		method: 'POST',
		data: { partyId, numberOfCodes },
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function finalize(keyId) {
	return axios(env.SERVER_ENV.url + '/api/survey/finalize', {
		method: 'POST',
		data: keyId,
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/survey/dynamic/', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDynamicSurveyDefinitionsByTab(tabName) {
	return axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/definitions', {
		method: 'POST',
		data: {
			entityCode: 'survey',
			tabName,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
