import React, { useState, useEffect } from 'react';

import './star.css';

export const StarField = props => {
	const [rating, setRating] = useState(0);
	const [hover, setHover] = useState(0);

	return (
		<div className='p-inputgroup'>
			<label>{props.question}</label>
			<div className='star ml-auto inline-flex'>
				{[...Array(5)].map((star, index) => {
					index += 1;
					return (
						<button
							type='button'
							key={index}
							className={index <= (hover || rating) ? 'on' : 'off'}
							onClick={() => {
								setRating(index);
								props.onChange(index);
							}}
							onMouseEnter={() => setHover(index)}
							onMouseLeave={() => setHover(rating)}>
							<i className='pi pi-star-fill'></i>
						</button>
					);
				})}
			</div>
		</div>
	);
};
