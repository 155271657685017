const initialState = {
	folders: [],
	documents: [],
	statuses: [],
	cases: [],
};

const fileAssociationReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'FILE_ASSOCIATION_DOCUMENTS_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				documents: action.payload.data.documents,
			});
			break;
		case 'FILE_ASSOCIATION_FOLDERS_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				folders: action.payload.data.map(x => {
					return {
						value: x.defaultCaseFolderId,
						label: x.defaultCaseFolder,
						path: x.path,
					};
				}),
			});
			break;
		case 'FILE_ASSOCIATION_STATUS_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				statuses: action.payload.data,
			});
			break;
		case 'FILE_ASSOCIATION_SET_STATE':
			return {
				...state,
				...action.payload,
			};
			break;
		case 'TASK_SUBTYPE_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				taskSubtype: action.payload.data,
			});
			break;
		default:
			return state;
			break;
	}
};

export default fileAssociationReducer;
