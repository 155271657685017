const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  notes: [],
};

const noteReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "NOTE_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        notes: [...action.payload.data]
      });
      break;
    case "NOTE_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        notes: [...action.payload.data.notes]
      });
      break;
    case "NOTE_CREATE_FULFILLED":
      if (state.notes.length === state.pageSize)
        state.notes.splice(state.notes.length - 1, 1);
      return Object.assign({}, state, {
        ...state,
        notes: [action.payload.data, ...state.notes]
      });
      break;
    case "NOTE_DELETE_FULFILLED":
      var removeIndex = state.notes
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.payload.data);
      state.notes.splice(removeIndex, 1);
      return Object.assign({}, state, {
        ...state,
        notes: [...state.notes]
      });
      break;
    case "NOTE_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        notes: state.notes.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        })
      });
      break;
    default:
      return state;
      break;
  }
};

export default noteReducer;
