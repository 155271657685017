const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	data: [],
};

const dynamicMassUpdateReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'DYNAMIC_MASS_UPDATE_GET_PAGE_FULFILLED':
			action.payload.data.dataSize = undefined;
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'DYNAMIC_MASS_UPDATE_GET_DATASIZE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				dataSize: action.payload.data,
			});
			break;
		case 'DYNAMIC_MASS_UPDATE_ROW_UPDATE':
			const found = state.data.find(x => x.id === action.payload.id);
			return Object.assign({}, state, {
				...state,
				data: state.data.map(x => (x.id === action.payload.id ? { ...action.payload, isUpdated: true } : x)),
			});
			break;
		default:
			return state;
			break;
	}
};

export default dynamicMassUpdateReducer;
