import axios from "axios";
import { env } from "../configs/env";

export function getAllNotes() {
  return {
    type: "NOTE_GET_ALL",
    payload: axios(env.SERVER_ENV.url + "/api/Note/all", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function getPage(request){
  return {
    type: "NOTE_GET_PAGE",
    payload: axios(env.SERVER_ENV.url + "/api/note/page", {
      method: "POST",
      data: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function create(note) {
  return {
    type: "NOTE_CREATE",
    payload: axios(env.SERVER_ENV.url + "/api/note", {
      method: "POST",
      data: JSON.stringify(note),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function deleteNote(noteId) {
  return {
    type: "NOTE_DELETE",
    payload: axios(env.SERVER_ENV.url + "/api/note/"+noteId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function update(note) {
  return {
    type: "NOTE_UPDATE",
    payload: axios(env.SERVER_ENV.url + "/api/note", {
      method: "PATCH",
      data: JSON.stringify(note),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}