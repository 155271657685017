const initialState = {
  noteTypes: [],
  notes: [],
  shouldCreate: false
};

const NoteTypesReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "UG_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
      });
      break;
    case "NOTE_TYPE_PERMISSION_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        noteTypes: action.payload.data,
      });
      break;
    case "NOTE_TYPES_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        notes: action.payload.data,
      });
      break;
    case "NOTE_TYPES_GET_BY_UG_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        noteTypes: action.payload.data.filter((permission) => {
          return state.notes.map(noteType => noteType.id).includes(permission.noteTypeId)
        }),
        shouldCreate: true
      });
      break;
    case "NOTE_TYPES_GET_BY_UG_PENDING":
      return Object.assign({}, state, {
        ...state,
        shouldCreate: false
      });

      break;
    case "NOTE_TYPES_CREATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        noteTypes: [action.payload.data, ...state.noteTypes],
        shouldCreate: false
      });
      break;
    case "NOTE_TYPE_TOGGLE_PERMISSION":
      return Object.assign({}, state, {
        ...state,
        noteTypes: state.noteTypes.map((item) => {
          if (item.id == action.payload.permissionId)
            return {
              ...item,
              [action.payload.permissionType]: !item[action.payload.permissionType]
            };
          return item;
        }),
      });
      break;
    case "NOTE_TYPES_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        noteTypes: state.noteTypes.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        })
      });
      break;
    default:
      return state;
      break;
  }
};

export default NoteTypesReducer;
