import Axios from 'axios';
import React, { Component } from 'react';
import { Button, Card, Container, Tab, Tabs } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { create, deleteParty, edit, get, getPartyTypes, resetNew, setState } from '../../actions/partyActions';
import { saveDynamicDataFields } from '../../actions/utilityActions';
import { env } from '../../configs/env';
import { getPatientCases, getProviderCases } from '../../services/caseServices';
import { clone, getHistory, getPartyTypeOptions } from '../../services/partyServices';
import CasesModalTableConfig from '../cases/casesModalTableConfig';
import History from '../cases/history';
import InvoicesTable from '../checks/invoicesTable';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import ContactsTable from '../contacts/ContactsTable';
import ContactsTableNew from '../contacts/ContactsTableNew';
import NotesPanel from '../notes/notesPanel';
import BasicPartyInfo from './basicPartyInfo';
import DynamicPartyFields from './dynamicPartyFields';
import PartyReferrals from './partyRefferals';

var initialState = {
	action: '',
	validated: false,
	new: false,
	tabNames: null,
	refsGenerated: false,
	confirmationModalShow: false,
};

class Party extends Component {
	state = { ...initialState };

	constructor(props) {
		super(props);
		document.title = 'BabySteps - Bolnica';
		props.getPartyTypes(this.props.moduleState.currentModule.id);
		this.basicInfo = React.createRef();
		this.contacts = React.createRef();
		this.referrals = React.createRef();
		this.referralsAttorney = React.createRef();
		this.referralsBilling = React.createRef();
		this.partyHistory = React.createRef();
	}

	componentWillUpdate() {
		document.title = 'BabySteps - Bolnica';
	}

	componentWillMount() {
		var newState = false;

		if (!this.props.new) {
			if (this.props.match.params && this.props.match.params.id) this.props.get(this.props.match.params.id);
		} else newState = true;

		const params = new URLSearchParams(this.props.location.search);
		const viewMode = params.get('viewMode');
		if (viewMode) this.setState({ viewMode: viewMode && !newState, newState });
	}

	componentWillUnmount() {
		this.setState({ ...initialState });
	}

	createDynamicRefs = partyTypeCode => {
		Axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/party-' + partyTypeCode, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
			.then(response => {
				response.data.forEach(name => (this[name] = React.createRef()));
				this.setState({ tabNames: [...response.data], refsGenerated: true });
			})
			.catch(() => this.setState({ tabNames: [], refsGenerated: true }));
	};

	handleOnChange = e => {
		if (e.target.name === 'isCompany')
			this.props.setState({
				isCompany: !this.props.partyState.party.isCompany,
			});
		else this.props.setState({ [e.target.name]: e.target.value });
	};

	handleOnSelectChange = value => {
		this.props.selectPartyType(value);
	};

	switchViewMode = e => {
		e.preventDefault();
		this.setState({ viewMode: false });
	};

	confirmationHandleOpen = () => this.setState({ confirmationModalShow: true });
	confirmationHandleClose = () => this.setState({ confirmationModalShow: false });

	handleDelete = e => {
		e.preventDefault();
		this.props.deleteParty(this.props.partyState.party.id, this.props.moduleState.currentModule.id);
		this.setState({ confirmationModalShow: false });
		this.props.history.push('/bolnice');
	};

	loadOptions = (inputValue, callback) => {
		getPartyTypeOptions(inputValue).then(response => {
			const data = response.data.map(x => {
				return { ...x, label: x.name.trim(), value: x.id };
			});
			callback(data);
		});
	};

	getCasesMethod = () => {
		const { partyTypeName } = this.props.partyState.party;

		const obj = {
			Patient: getPatientCases,
			Provider: getProviderCases,
		};

		return obj[partyTypeName];
	};

	handleOnSubmit = e => {
		e.preventDefault();
		this.basicInfo.current.handleOnSubmit(e);

		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.stopPropagation();
		} else {
			this.setState({ validated: true });

			//In party of new user create, else edit
			if (this.props.partyState.party.id !== -1) {
				const partyType = this.props.partyState.party.partyTypeCode ? this.props.partyState.party.partyTypeCode : '';

				var dynamicData = [];
				this.state.tabNames.forEach(x => {
					if (this[x].current) dynamicData.push(...this[x].current.getData());
				});
				dynamicData = dynamicData.filter(x => x);

				if (dynamicData.length > 0) this.props.saveDynamicDataFields('party-' + partyType, dynamicData);
				if (this.contacts.current) this.contacts.current.handleSave();
				if (this.referrals.current) this.referrals.current.handleOnSave();
				if (this.referralsAttorney.current) this.referralsAttorney.current.handleOnSave();
				if (this.referralsBilling.current) this.referralsBilling.current.handleOnSave();
			}
		}
	};

	toggleEdit = () => {
		this.setState({ viewMode: this.state.viewMode ? false : true });
	};

	onClone = () => {
		const { id } = this.props.match.params;
		clone(id)
			.then(res => window.open(env.BASE_PATH + 'bolnica/' + res.data, '_blank'))
			.catch(err => console.log('Error - ', err));
	};

	showRelatedCases = () => {
		const { partyTypeName } = this.props.partyState.party;
		return partyTypeName === 'Provider' || partyTypeName === 'Patient';
	};

	getDocumentTitle = () => {
		if (this.props.new) return 'NEW';
		if (!this.props.partyState.party) return '';

		const { isCompany, companyName, firstName, lastName } = this.props.partyState.party;
		const title = isCompany ? companyName : firstName + ' ' + lastName;
		return title || '';
	};

	openProviderDM = () =>
		window.open(
			env.DOCUMENT_MANAGMENT_ENV.url +
				'?FileNo=PROVIDER-' +
				this.props.partyState.party.id +
				'&AccessToken=' +
				localStorage.getItem('token'),
			'_blank'
		);

	render() {
		document.title = 'BabySteps - ' + this.getDocumentTitle();
		var action = this.state.action;

		if (action !== '')
			if (!this.props.pendingState[action].pending) {
				if (this.props.pendingState[action].success && action == 'PARTY_CREATE') {
					this.props.history.push('/bolnica/' + this.props.partyState.newParty.id);
				}
				this.state.action = '';
			}

		if (!this.props.new && !this.state.refsGenerated && this.props.partyState.party) {
			this.createDynamicRefs(this.props.partyState.party.partyTypeCode);
		}

		var defaultActiveKey;
		var tabs;

		if (this.state.tabNames) {
			defaultActiveKey = this.state.tabNames.length === 0 ? 'contacts' : this.state.tabNames[0];
			tabs = this.state.tabNames.map(x => (
				<Tab eventKey={x} title={x}>
					<Card>
						<Card.Body>
							<DynamicPartyFields
								ref={this[x]}
								partyId={parseInt(this.props.match.params.id)}
								viewMode={this.state.viewMode}
								tabName={x}
								partyType={this.props.partyState.party.partyTypeCode ? this.props.partyState.party.partyTypeCode : ''}
								style={{ marginTop: '0.5em' }}
							/>
						</Card.Body>
					</Card>
				</Tab>
			));
		}

		return (
			<Container style={{ maxWidth: '100vw', marginTop: '20px' }}>
				<Card>
					<Card.Header>
						<Card.Title>
							{this.getDocumentTitle() + ' | ID: ' + (this.props.partyState.party?.id || '')}
							<div className='float-right'>
								{this.props.partyState.party && this.props.partyState.party.partyTypeName === 'Provider' && (
									<Button size='sm' style={{ margin: '0.4em' }} variant='info' onClick={this.openProviderDM}>
										Open Document Manager
									</Button>
								)}

								{this.props.moduleState.currentModule.create && !this.props.new && (
									<Button
										size='sm'
										style={{ margin: '0.4em' }}
										variant='dark'
										onClick={() => this.setState({ cloneModalShow: true })}>
										Kloniraj
									</Button>
								)}

								{this.state.viewMode && this.props.moduleState.currentModule.update ? (
									<Button size='sm' style={{ margin: '0.4em' }} variant='primary' form='#' onClick={this.toggleEdit}>
										Izmijeni
									</Button>
								) : null}
								{this.props.match.params.id && this.props.moduleState.currentModule.delete ? (
									<Button
										size='sm'
										style={{ margin: '0.4em' }}
										variant='danger'
										form='#'
										onClick={this.confirmationHandleOpen}>
										Obriši
									</Button>
								) : null}
							</div>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<Container className='mw-100'>
							<div className='float-right'></div>
							{this.props.partyState.party ? (
								<BasicPartyInfo
									moduleId={this.props.moduleState.currentModule.id}
									isCompany={this.props.partyState.party.isCompany}
									partyType={{
										label: this.props.partyState.party.partyTypeName,
										value: this.props.partyState.party.partyTypeId,
									}}
									party={this.props.partyState.party}
									isMassUpdate={false}
									isParentable={this.props.partyState.party.isParentable}
									parentTable={this.props.partyState.party.partyType.parentTable}
									id='basic-info'
									ref={this.basicInfo}
									isGeneralModule={true}
									viewMode={this.state.viewMode}
								/>
							) : null}
							{this.props.partyState.party && this.state.tabNames ? (
								<Tabs defaultActiveKey={defaultActiveKey} className='m-0' mountOnEnter={true} unmountOnExit={false}>
									{tabs}

									<Tab eventKey='contacts' title='Contacts'>
										<ContactsTableNew
											ref={this.contacts}
											partyId={parseInt(this.props.match.params.id)}
											isDisabled={this.state.viewMode}
										/>
										{/* <ContactsTable
											ref={this.contacts}
											partyId={parseInt(this.props.match.params.id)}
											isDisabled={this.state.viewMode}
										/>  */}
									</Tab>

									{/*<Tab eventKey='notes' title='Notes'>
										<NotesPanel
											entityCode={
												this.props.partyState.party.partyTypeName
													? 'party-' + this.props.partyState.party.partyTypeCode
													: ''
											}
											entityId={parseInt(this.props.match.params.id)}
											viewMode={this.state.viewMode}
										/>
										</Tab>*/}
									{/*this.showRelatedCases() && (
										<Tab eventKey='Related Cases' title='Related Cases'>
											<CasesModalTableConfig
												show={true}
												entityId={parseInt(this.props.match.params.id)}
												getMethod={this.getCasesMethod()}
												entity={
													this.props.partyState.party.partyTypeName === 'Provider' ? 'provider' : 'patient'
												}></CasesModalTableConfig>
										</Tab>
									)*/}

									{this.props.partyState.party.partyTypeName === 'Provider' && (
										<Tab eventKey='Firm Fee Vendors' title='Firm Fee Referral Vendors'>
											<PartyReferrals
												type='Firm Fee'
												viewMode={this.state.viewMode}
												entityId={parseInt(this.props.match.params.id)}
												ref={this.referrals}
												dataTable='prtParties_FirmFeeReferralVendors'
											/>
										</Tab>
									)}

									{this.props.partyState.party.partyTypeName === 'Provider' && (
										<Tab eventKey='Attorney Fee Vendors' title='Attorney Fee Referral Vendors'>
											<PartyReferrals
												ref={this.referralsAttorney}
												type='Attorney Fee'
												viewMode={this.state.viewMode}
												entityId={parseInt(this.props.match.params.id)}
												dataTable='prtParties_Attorneys'
											/>
										</Tab>
									)}

									{this.props.partyState.party.partyTypeName === 'Provider' && (
										<Tab eventKey='Billing Fee Vendors' title='Billing Referral Vendors'>
											<PartyReferrals
												ref={this.referralsBilling}
												type='Billing Fee'
												viewMode={this.state.viewMode}
												entityId={parseInt(this.props.match.params.id)}
												dataTable='prtParties_BillingReferralVendors'
											/>
										</Tab>
									)}

									{this.props.partyState.party.partyTypeName === 'Provider' && (
										<Tab eventKey='Invoices' title='Invoices'>
											<InvoicesTable providerId={parseInt(this.props.match.params.id)} />
										</Tab>
									)}

									{/*<Tab eventKey='history' title='Audit Log'>
										<History
											ref={this.partyHistory}
											entityType='partyId'
											entityId={parseInt(this.props.match.params.id)}
											getMethod={getHistory}
										/>
									</Tab>
									*/}
								</Tabs>
							) : null}
						</Container>
					</Card.Body>
					<Card.Footer>
						{!this.state.viewMode && (
							<div className='float-right'>
								<Button variant='secondary' style={{ margin: '0.4em' }} onClick={this.toggleEdit}>
									Poništi
								</Button>
								{this.props.moduleState.currentModule.update && (
									<Button
										onClick={this.handleOnSubmit}
										style={{ margin: '0.4em' }}
										variant='primary'
										id='case-button'
										form='case'>
										{this.props.new ? 'Kreiraj' : 'Spremi'}
									</Button>
								)}
							</div>
						)}
					</Card.Footer>
				</Card>

				<ConfirmationModal
					show={this.state.confirmationModalShow}
					handleClose={this.confirmationHandleClose}
					confirmFunction={this.handleDelete}
					title='Delete Alert'
					message='Are you sure you want to delete this party?'
				/>

				<ConfirmationModal
					show={this.state.cloneModalShow}
					handleClose={() => this.setState({ cloneModalShow: false })}
					confirmFunction={() => {
						this.onClone();
						this.setState({ cloneModalShow: false });
					}}
					title='Clone Alert'
					message='Are you sure you want to clone this party?'
				/>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		pendingState: state.pendingReducer,
		partyState: state.partyReducer,
		moduleState: state.moduleReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		create: party => {
			dispatch(create(party));
		},
		edit: party => {
			dispatch(edit(party));
		},
		getPartyTypes: moduleId => {
			dispatch(getPartyTypes(moduleId));
		},
		resetNew: () => {
			dispatch(resetNew());
		},
		get: id => {
			dispatch(get(id));
		},
		deleteParty: (id, moduleId) => {
			dispatch(deleteParty(id, moduleId));
		},
		setState: data => {
			dispatch(setState(data));
		},
		saveDynamicDataFields: (entityCode, dynamicEntityData) => {
			dispatch(saveDynamicDataFields(entityCode, dynamicEntityData));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Party);
