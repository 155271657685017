import axios from 'axios';
import { env } from '../configs/env';

export function getCases(request) {
	return axios(env.SERVER_ENV.url + '/api/Portal/cases', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPage(request) {
	return axios(env.SERVER_ENV.url + '/api/portal/cases', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCase(id) {
	return axios(env.SERVER_ENV.url + '/api/Portal/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCardData(type, id = null, selectedProviders = null) {
	return axios(env.SERVER_ENV.url + '/api/Portal/' + type, {
		method: 'POST',
		data: { MatterTypeId: id, SelectedProviders: selectedProviders },
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getCaseDocument(unc, fileName) {
	return axios.get(
		env.DOCUMENT_MANAGMENT_API_ENV.url +
			'/api/file?UNC=' +
			unc +
			'&FileName=' +
			fileName +
			'&AccessToken=' +
			localStorage.getItem('token'),
		{ responseType: 'blob' }
	);
}

export function getProviders() {
	return axios(env.SERVER_ENV.url + '/api/portal/providers', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updateTask(request) {
	return axios(env.SERVER_ENV.url + '/api/portal/task', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
