import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { Component } from 'react';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { getInvoice, getInvoicesByProvider } from '../../services/invoiceServices';
import InvoiceForm from '../invoices/invoiceForm';
import * as utility from '../utility';
import { getInvoiceBlob } from './invoiceGenerator';
import { invoiceTabConfig } from './tableConfigs/invoiceTabConfig';

class InvoicesTable extends Component {
	state = {
		invoices: [],
		invoiceData: [],
		loading: false,
	};

	componentWillMount() {
		this.setState({ loading: true });
		getInvoicesByProvider(this.props.providerId)
			.then(result => {
				this.setState({ invoices: [], invoiceData: result.data, loading: false });
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	}

	handleOpen = id => this.setState({ modalShow: true, editInvoice: id, viewMode: true });
	handleClose = () => this.setState({ modalShow: false });

	downloadInvoices = () => {
		this.setState({ loading: true });
		var zip = new JSZip();

		const promise = i =>
			getInvoice(i.id)
				.then(res =>
					getInvoiceBlob(res.data)
						.then(res => zip.file(i.invoiceNo + '.pdf', res))
						.catch(err => console.log('Error -', err))
				)
				.catch(err => console.log('Error -', err));

		const callback = () => {
			zip.generateAsync({ type: 'blob' }).then(res => saveAs(res, 'invoices.zip'));
			this.setState({ loading: false });
		};

		utility
			.executeSeq(this.state.invoiceData, promise, 'Invoice download failed')
			.then(() => callback())
			.catch(err => {
				console.log('Error -', err);
				this.setState({ loading: false });
				callback();
			});
	};

	render() {
		const invoiceDwonloadColumns = invoiceTabConfig(this.state.invoiceData);
		const isEscrow =
			this.state.invoiceData && this.state.invoiceData.length > 0 ? this.state.invoiceData[0].isEscrow : false;

		if (!isEscrow) {
			invoiceDwonloadColumns.splice(2, 1);
		}

		const rowEvents = {
			onDoubleClick: (e, row) => this.handleOpen(row),
		};

		return (
			<Card>
				<Card.Body>
					<div style={{ width: '100%', marginTop: '0.5em' }}>
						<h4>
							<Button size='sm' className='float-right mb-1' onClick={this.downloadInvoices}>
								Download All
							</Button>
						</h4>
						{this.state.loading && <ProgressBar animated style={{ borderRadius: '0' }} variant='mono' now={100} />}
						<BootstrapTable
							remote
							keyField='id'
							columns={invoiceDwonloadColumns}
							data={this.state.invoiceData}
							className='table-body'
							rowStyle={{ fontWeight: 'bold' }}
							bordered={true}
							rowEvents={rowEvents}
							striped
							hover
							condensed
							responsive
						/>
					</div>
					<InvoiceForm
						moduleId={this.props.moduleState.currentModule.id}
						show={this.state.modalShow}
						onHide={this.handleClose}
						invoice={this.state.editInvoice}
						viewMode={this.state.viewMode}
						switchViewMode={this.switchViewMode}
						hover
					/>
				</Card.Body>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	return {
		moduleState: state.moduleReducer,
		pendingState: state.pendingReducer,
	};
};

export default connect(mapStateToProps, null)(InvoicesTable);
