const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  penalties: [],
  data: [],
};

const penaltyManagementReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'PENALTY_MANAGEMENT_GET_PAGE_FULFILLED':
      action.payload.data.dataSize = undefined;
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
      });
      break;
    case 'PENALTY_MANAGEMENT_GET_DATASIZE_FULFILLED':
      return Object.assign({}, state, {
        ...state,
        dataSize: action.payload.data,
      });
      break;
    case 'PENALTY_MANAGEMENT_ADD_PENALTY':
      return Object.assign({}, state, {
        ...state,
        data: [
          ...state.data,
          {
            ...action.payload,
          },
        ],
      });
      break;
    case 'PENALTY_MANAGEMENT_UPDATE_PENALTY':
      return Object.assign({}, state, {
        ...state,
        data: state.data.map(x => (x.id === action.payload.id ? { ...action.payload } : x)),
      });
      break;
    case 'PENALTY_MANAGEMENT_REMOVE_PENALTY':
      return Object.assign({}, state, {
        ...state,
        data: state.data.filter(x => x.id !== action.payload),
      });
      break;
    case 'PENALTY_MANAGEMENT_CLEAR_DATA':
      return Object.assign({}, state, {
        ...state,
        data: [],
      });
      break;
      break;
    default:
      return state;
      break;
  }
};

export default penaltyManagementReducer;
