const initialState = {
	outChecks: [],
	page: 1,
	pageSize: 25,
	dataSize: null,
};

const issuedChecksReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'ISSUED_CHECK_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
		default:
			return state;
			break;
	}
};

export default issuedChecksReducer;
