const initialState = {
    page: 1,
    pageSize: 25,
    dataSize: 1,
    fieldDefinitions: [],
    newFieldDefinition: null,
    matterTypes: [],
  };
  
  const fieldDefinitionReducer = (state = { ...initialState }, action) => {
    //console.log("action", action.type);
    switch (action.type) {
      case "FIELD_DEFINITION_GET_PAGE_FULFILLED":
        return Object.assign({}, state, {
          ...state,
          ...action.payload.data
        });
        break;
      case "FIELD_DEFINITION_CREATE_FULFILLED":
        return Object.assign({}, state, {
          ...state,
          newFieldDefinition: action.payload.data
        });
        break;
      case "FIELD_DEFINITION_DELETE_FULFILLED":
        var removeIndex = state.fieldDefinitions
          .map(function(item) {
            return item.id;
          })
          .indexOf(action.payload.data);
        state.fieldDefinitions.splice(removeIndex, 1);
        return Object.assign({}, state, {
          ...state,
          fieldDefinitions: [...state.fieldDefinitions]
        });
        break;
      case "FIELD_DEFINITION_EDIT_FULFILLED":
        return Object.assign({}, state, {
          ...state,
          fieldDefinitions: state.fieldDefinitions.map((item, index) => {
            if (item.id != action.payload.data.id) return item;
            return action.payload.data;
          })
        });
        break;
      default:
        return state;
        break;
    }
  };
  
  export default fieldDefinitionReducer;
  