import axios from 'axios';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { env } from '../../configs/env';
import { MdSearch } from 'react-icons/md';
import DropdownOptionsModal from './DropdownOptionsModal';

export default class SelectField extends Component {
	search = searchTerm => {
		return searchTerm;
	};

	state = {
		ignore: [],
	};

	static getDerivedStateFromProps(props, state) {
		return { ignore: props.ignore };
	}

	getOptions = searchTerm => {
		return axios(env.SERVER_ENV.url + '/api/utility/option', {
			method: 'POST',
			data: {
				dataTable: this.props.dataTable,
				dataField: this.props.dataField,
				searchTerm,
				fetchSize: this.props.fetchSize,
				showAll: this.state.modalShow,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	};

	loadOptions = (inputValue, callback) => {
		this.getOptions(inputValue).then(response => {
			const data = response.data.map(x => {
				return { ...x, label: x.label.trim() };
			});
			callback(data);
		});
	};

	handleModalClose = () => {
		this.setState({ modalShow: false });
	};

	handleOnClickModalShow = e => {
		if (!this.props.viewMode) this.setState({ modalShow: true });
	};

	render() {
		const customStyles = {
			container: (provided, state) => ({
				...provided,
				padding: 0,
				minHeight: 'calc(1.5em + 0.5rem - 2px)',
			}),
			control: (provided, state) => ({
				...provided,
				borderWidth: 1,
				minHeight: 'calc(1.5em + 0.5rem - 2px)',
				borderColor: state.isFocused ? '#ddd' : this.props.isValid === undefined || this.props.isValid ? '#ddd' : 'red',
				// overwrittes hover style
				'&:hover': {
					borderColor: state.isFocused
						? '#ddd'
						: this.props.isValid === undefined || this.props.isValid
						? '#ddd'
						: 'red',
				},
			}),
			indicatorsContainer: (provided, state) => ({
				...provided,
				height: 'calc(1.5em + 0.5rem - 2px)',
			}),
			valueContainer: (provided, state) => ({
				...provided,
				padding: 0,
			}),
			selectIndicator: (provided, state) => ({
				...provided,
				height: 'calc(1.5em + 0.5rem - 2px)',
				padding: 0,
				margin: 0,
			}),
			dropdownIndicator: (provided, state) => ({
				...provided,
				padding: '2px',
			}),
			input: (provided, state) => ({
				...provided,
				height: '20px',
			}),
			multiValue: (provided, state) => ({
				...provided,
				paddin: 0,
				height: '20px',
			}),
			menuPortal: base => ({ ...base, zIndex: 9999 }),
		};

		return (
			<React.Fragment>
				<Form.Group
					as={this.props.as}
					className={this.props.class}
					style={this.props.style}
					onBlur={this.props.onBlur ? this.props.onBlur : null}>
					{this.props.label && <Form.Label>{this.props.label}</Form.Label>}
					<div className='d-flex flex-row'>
						<div className='d-inline w-100'>
							{this.props.viewMode && !this.props.isMulti ? (
								<Form.Control
									size='sm'
									type='text'
									name={this.props.name}
									placeholder={this.props.placeholder}
									value={this.props.value ? this.props.value.label : ''}
									disabled={this.props.viewMode}
								/>
							) : (
								<AsyncSelect
									menuPortalTarget={document.body}
									cacheOptions
									isMulti={this.props.isMulti}
									closeMenuOnSelect={this.props.closeMenuOnSelect}
									className={this.props.isMulti ? 'basic-multi-select' : 'basic-single'}
									classNamePrefix='select'
									isSearchable={true}
									value={this.props.value}
									name={this.props.name}
									defaultOptions={true}
									options={this.props.options}
									onChange={this.props.onChange}
									placeholder={this.props.placeholder ? this.props.placeholder : 'Odaberi...'}
									onInputChange={this.props.search ? this.props.search : this.search}
									search={this.props.search}
									loadOptions={this.props.loadOptions ? this.props.loadOptions : this.loadOptions}
									isDisabled={this.props.viewMode || this.props.disabled}
									styles={customStyles}
									isOptionDisabled={
										this.props.isOptionDisabled
											? this.props.isOptionDisabled
											: option => {
													if (!this.state.ignore) return false;
													return this.state.ignore.includes(option.label) || this.state.ignore.includes(option.value);
											  }
									}
									ignore={this.props.ignore}
									isValid={this.props.isValid}
									isClearable={this.props.isClearable}
									menuPlacement={this.props.menuPlacement ? this.props.menuPlacement : 'auto'}
								/>
							)}
						</div>
						<div>
							{this.props.isHidden ? null : (
								<MdSearch
									name={this.props.name}
									onClick={this.handleOnClickModalShow}
									className={
										'party-add-button' +
										(this.props.viewMode || this.props.disabled ? ' text-secondary' : '  text-primary active')
									}
								/>
							)}
						</div>
					</div>
				</Form.Group>

				{this.state.modalShow && (
					<DropdownOptionsModal
						title={this.props.title ? this.props.title : this.props.label}
						isMulti={this.props.isMulti}
						value={this.props.value}
						show={this.state.modalShow}
						onHide={() => this.setState({ modalShow: false })}
						onChange={this.props.onChange}
						closeModal={this.handleModalClose}
						loadOptions={this.props.loadOptionsModal ? this.props.loadOptionsModal : this.loadOptions}
						isOptionDisabled={this.props.isOptionDisabled}
					/>
				)}
			</React.Fragment>
		);
	}
}
