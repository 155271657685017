const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	parties: [],
	partyTypes: [],
	newParty: null,
	party: null,
	firmFee: [],
	attorneyFee: [],
	billingFee: [],
	deleteReferrals: [],
};

const partyReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'PARTY_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'PARTY_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				party: {
					...action.payload.data,
					partyType: {
						id: action.payload.data.partyTypeId,
						label: action.payload.data.partyTypeName,
						isParentable: action.payload.data.isParentable,
						labelName: action.payload.data.labelName,
						parentTable: action.payload.data.parentTable,
						code: action.payload.data.partyTypeCode,
					},
					parent: {
						value: action.payload.data.parentId,
						label: action.payload.data.parent,
					},
				},
			});
			break;
		case 'PARTY_RESET_NEW':
			return Object.assign({}, state, {
				...state,
				newParty: null,
			});
			break;
		case 'PARTY_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				newParty: action.payload.data,
			});
			break;
		case 'PARTY_DELETE_FULFILLED':
			var removeIndex = state.parties
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.parties.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				parties: [...state.parties],
			});
			break;
		case 'PARTY_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				parties: state.parties.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'PARTY_TYPE_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				partyTypes: [...action.payload.data],
			});
			break;
		case 'PARTY_TYPE_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				partyTypes: [action.payload.data, ...state.partyTypes],
			});
			break;
		case 'PARTY_TYPE_DELETE_FULFILLED':
			var removeIndex = state.partyTypes
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.partyTypes.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				partyTypes: [...state.partyTypes],
			});
			break;
		case 'PARTY_TYPE_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				partyTypes: state.partyTypes.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'PARTY_GET_FIRM_FEE_COLLECTIONS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				firmFee: [...action.payload.data.referrals],
			});
			break;
		case 'PARTY_GET_ATTORNEY_FEE_COLLECTIONS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				attorneyFee: [...action.payload.data.referrals],
			});
			break;
		case 'PARTY_GET_BILLING_FEE_COLLECTIONS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				billingFee: [...action.payload.data.referrals],
			});
			break;
		case 'REFERRAL_DELETE_FULFILLED':
			var index = state.firmFee.indexOf(x => (x.id = action.payload.data));
			if (index) state.firmFee.splice(index, 1);
			index = state.attorneyFee.indexOf(x => x.id === action.payload.data);
			if (index) state.attorneyFee.splice(index, 1);
			index = state.billingFee.indexOf(x => x.id === action.payload.data);
			if (index) state.billingFee.splice(index, 1);

			return Object.assign({}, state, {
				...state,
				attorneyFee: [...state.attorneyFee],
				firmFee: [...state.firmFee],
				billingFee: [...state.billingFee],
			});

			break;
		case 'REFERRAL_CREATE_FULFILLED':
			action.payload.config.data = JSON.parse(action.payload.config.data);

			if (action.payload.data.referralType === 'Firm Fee')
				state.firmFee = state.firmFee.map(x => (x.id == action.payload.config.data.id ? action.payload.data : x));
			else if (action.payload.data.referralType === 'Attorney Fee')
				state.attorneyFee = state.attorneyFee.map(x =>
					x.id == action.payload.config.data.id ? action.payload.data : x
				);
			else
				state.billingFee = state.billingFee.map(x => (x.id == action.payload.config.data.id ? action.payload.data : x));

			return Object.assign({}, state, {
				...state,
				attorneyFee: [...state.attorneyFee],
				firmFee: [...state.firmFee],
				billingFee: [...state.billingFee],
			});
			break;
		case 'REFERRAL_UPDATE_FULFILLED':
			if (action.payload.data.referralType === 'Firm Fee')
				state.firmFee = state.firmFee.map(x => {
					if (x.id !== action.payload.data.id) return x;
					else return action.payload.data;
				});
			else if (action.payload.data.referralType === 'Attorney Fee')
				state.attorneyFee = state.firmFee.map(x => {
					if (x.id !== action.payload.data.id) return x;
					else return action.payload.data;
				});
			else
				state.billingFee = state.billingFee.map(x => {
					if (x.id !== action.payload.data.id) return x;
					else return action.payload.data;
				});
			return Object.assign({}, state, {
				...state,
				attorneyFee: [...state.attorneyFee],
				firmFee: [...state.firmFee],
				billingFee: [...state.billingFee],
			});
			break;
		case 'PARTY_ADD_REFERRAL':
			if (action.payload.referralType === 'Firm Fee') state.firmFee = [...state.firmFee, action.payload];
			else if (action.payload.referralType === 'Attorney Fee')
				state.attorneyFee = [...state.attorneyFee, action.payload];
			else state.billingFee = [...state.billingFee, action.payload];
			return Object.assign({}, state, {
				...state,
				attorneyFee: [...state.attorneyFee],
				firmFee: [...state.firmFee],
				billingFee: [...state.billingFee],
			});
			break;
		case 'PARTY_REMOVE_REFERRAL':
			var collection;
			if (action.payload.referralType === 'Firm Fee') collection = state.firmFee;
			else if (action.payload.referralType === 'Attorney Fee') collection = state.attorneyFee;
			else collection = state.billingFee;

			var removeIndex = collection
				.map(item => {
					return item.id;
				})
				.indexOf(action.payload.id);

			var deleteReferrals = [...state.deleteReferrals];
			if (collection[removeIndex].new) {
				collection.splice(removeIndex, 1);
			} else if (collection[removeIndex].deleted) {
				collection[removeIndex].deleted = false;
				deleteReferrals.filter(x => x !== collection[removeIndex].id);
			} else {
				collection[removeIndex].deleted = true;
				deleteReferrals = [...deleteReferrals, collection[removeIndex].id];
			}

			return Object.assign({}, state, {
				...state,
				attorneyFee: [...state.attorneyFee],
				firmFee: [...state.firmFee],
				billingFee: [...state.billingFee],
				deleteReferrals,
			});
			break;
		case 'PARTY_EDIT_REFERRAL':
			return Object.assign({}, state, {
				...state,
				attorneyFee: [...state.attorneyFee],
				firmFee: [...state.firmFee],
				billingFee: [...state.billingFee],
			});
			break;
		case 'PARTY_SELECT_TYPE':
			return Object.assign({}, state, {
				...state,
				party: {
					...state.party,
					partyType: { id: action.payload.value, label: action.payload.label },
				},
			});
			break;
		case 'PARTY_SELECT_PARENT':
			return Object.assign({}, state, {
				...state,
				party: {
					...state.party,
					parent: { ...action.payload },
				},
			});
			break;
		case 'PARTY_SELECT_GENDER':
			return Object.assign({}, state, {
				...state,
				party: {
					...state.party,
					gender: action.payload,
				},
			});
			break;
		case 'PARTY_SELECT_DATE':
			return Object.assign({}, state, {
				...state,
				party: {
					...state.party,
					dateOfBirth: action.payload,
				},
			});
			break;
		case 'PARTY_SET_STATE':
			return Object.assign({}, state, {
				party: { ...state.party, ...action.payload },
			});
			break;
		default:
			return state;
			break;
	}
};

export default partyReducer;
