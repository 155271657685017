import axios from 'axios';
import { env } from '../configs/env';

export function getDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/party/dynamic/', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPartyTypeOptions(searchTerm) {
	searchTerm = searchTerm ? searchTerm : ' ';
	return axios(env.SERVER_ENV.url + '/api/party/type/options/' + searchTerm, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDynamicPartyDefinitionsByTab(partyType, tabName) {
	return axios(env.SERVER_ENV.url + '/api/fieldDefinition/tabs/definitions', {
		method: 'POST',
		data: {
			entityCode: 'party-' + partyType,
			tabName,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPage(request) {
	return axios(env.SERVER_ENV.url + '/api/party/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getHistory(request) {
	return axios(env.SERVER_ENV.url + '/api/party/history', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function hasContract(partyId) {
	return axios(env.SERVER_ENV.url + '/api/party/contract', {
		method: 'POST',
		data: partyId,
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function clone(id) {
	return axios(env.SERVER_ENV.url + '/api/party/clone/' + id, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function saveTopLevelFieldsDynMassUpdate(data) {
	return axios(env.SERVER_ENV.url + '/api/party/massUpdateTopLevelFields', {
		method: 'POST',
		data: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function massUpdatePartyContacts(data) {
	return axios(env.SERVER_ENV.url + '/api/party/massUpdatePartyContacts', {
		method: 'POST',
		data: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getHospitalLaborsNumber(id) {
	return axios(env.SERVER_ENV.url + '/api/party/labors/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getHospitalLaborsNumberPage(request) {
	return axios(env.SERVER_ENV.url + '/api/party/labors/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updateHospitalLaborsNumber(request) {
	return axios(env.SERVER_ENV.url + '/api/party/labors/update', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function createHospitalLaborsNumber(request) {
	return axios(env.SERVER_ENV.url + '/api/party/labors/create', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getMonthsOption(request) {
	return axios(env.SERVER_ENV.url + '/api/party/labors/month-option', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
