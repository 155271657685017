import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalFooter, ProgressBar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

const DropdownOptionsModal = props => {
	const [selectedFieldsId, setSelectedFieldsId] = useState([]);
	const [selectedFields, setSelectedFields] = useState([]);
	const [optionList, setOptionList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [title, setTitle] = useState(props.title);

	useEffect(() => {
		if (props.show && optionList.length === 0) loadOptions();
		if (props.isMulti && props.value != null && props.value.length > 0) {
			setSelectedFieldsId(props.value.map(x => x.value));
			setSelectedFields(props.value);
		}
	}, [props.show]);

	const loadOptions = (inputValue, callback) => {
		setIsLoading(true);
		props.loadOptions('', list => {
			setOptionList(list);
			setIsLoading(false);
		});
	};

	const handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			if (props.isOptionDisabled === undefined || !props.isOptionDisabled(row)) {
				setSelectedFieldsId([...selectedFieldsId, row.value]);
				setSelectedFields([...selectedFields, row]);
				props.onChange([...selectedFields, row]);
			}
			return false;
		} else {
			setSelectedFieldsId(selectedFieldsId.filter(x => x !== row.value));
			setSelectedFields(selectedFields.filter(x => x.value !== row.value));
			props.onChange(selectedFields.filter(x => x.value !== row.value));
		}
	};

	const handleOnSelectAll = (isSelect, rows) => {
		const ids = rows.map(r => r.value);
		if (isSelect) {
			setSelectedFieldsId(ids);
			setSelectedFields(rows);
			props.onChange(rows);
		} else {
			setSelectedFieldsId([]);
			setSelectedFields([]);
			props.onChange([]);
		}
	};

	var columns = [
		{
			dataField: 'label',
			text: <div class='textWrapCenter'>Naziv</div>,
		},
	];

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: true,
		selected: selectedFieldsId,
		onSelect: handleOnSelect,
		hideSelectAll: props.isOptionDisabled ? true : false,
		onSelectAll: handleOnSelectAll,
	};

	const rowEvents = {
		onDoubleClick: (e, row) => {
			props.onChange(row);
			props.closeModal();
		},
	};

	const modalStyle = {
		minWidth: '50vw',
		maxHeight: '95%',
		overflow: 'auto',
	};
	return (
		<Modal show={props.show} onHide={props.onHide} scrollable style={modalStyle}>
			<Modal.Header>
				<div>{title}</div>
			</Modal.Header>
			<Modal.Body>
				{isLoading && <ProgressBar animated className='fa-progress' variant='mono' now={100} />}
				<BootstrapTable
					keyField='value'
					columns={columns}
					selectRow={props.isMulti ? selectRow : undefined}
					className='table-body'
					data={optionList}
					bordered={true}
					rowStyle={{ wordWrap: 'break-word' }}
					striped
					hover
					condensed
					responsive
					rowEvents={props.isMulti ? undefined : rowEvents}
				/>
			</Modal.Body>
			<ModalFooter>
				<div className='float-right'>
					<Button
						size='sm'
						variant='secondary'
						onClick={() => {
							props.closeModal();
						}}>
						Close
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default DropdownOptionsModal;
