import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class DateFilter extends React.Component {
	state = {
		value: {
			From: null,
			To: null,
		},
	};

	onChange = (date, name) => {
		var cDate = date ? new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)) : null;
		this.setState({ value: { ...this.state.value, [name]: cDate } }, () => this.props.onFilter(this.state.value));
	};

	render() {
		return (
			<div className='d-flex mt-1' style={{ marginBottom: '8px' }}>
				<DatePicker
					selected={this.state.value.From}
					name='From'
					placeholderText='Date From'
					onChange={date => this.onChange(date, 'From')}
					showMonthDropdown
					showYearDropdown
					showMonthYearPicker={this.props.showMonthYearPicker}
					utcOffset={moment.utc().utcOffset()}
					autoComplete={'off'}
					dropdownMode='select'
					className='form-control form-control-sm'
				/>
				<DatePicker
					selected={this.state.value.To}
					name='to'
					placeholderText='Date To'
					onChange={date => this.onChange(date, 'To')}
					showMonthDropdown
					showYearDropdown
					showMonthYearPicker={this.props.showMonthYearPicker}
					autoComplete='off'
					dropdownMode='select'
					className='form-control form-control-sm'
				/>
			</div>
		);
	}
}

export default DateFilter;
