import axios from "axios";
import { env } from "../configs/env";

export function getUserContacts(userId) {
  return {
    type: "CONTACT_GET_USER",
    payload: axios(env.SERVER_ENV.url + "/api/contact/user/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function getFirmContacts(firmId) {
  return {
    type: "CONTACT_GET_FIRM",
    payload: axios(env.SERVER_ENV.url + "/api/contact/firm/" + firmId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function getPartyContacts(partyId) {
  return {
    type: "CONTACT_GET_PARTY",
    payload: axios(env.SERVER_ENV.url + "/api/contact/party/" + partyId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function addContact(contact) {
  contact.new = true;
  return {
    type: "CONTACT_ADD",
    payload: contact
  };
}

export function addEditContact(contact) {
  if (contact.id >= 0 && !contact.new) {
    if (!contact.edited) contact.edited = true;
    return {
      type: "CONTACT_ADD_EDITED",
      payload: contact
    };
  }
  return { type: "", payload: null };
}

export function toggleDeletedContact(contact) {
  if (!contact.deleted) {
    contact.deleted = true;
    contact.edited = false;
  }
  else contact.deleted = false;
  return {
    type: "CONTACT_TOGGLE_DELETED",
    payload: contact
  };
}

export function create(contacts) {
  return {
    type: "CONTACT_CREATE",
    payload: axios(env.SERVER_ENV.url + "/api/contact", {
      method: "POST",
      data: JSON.stringify(contacts),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function edit(contacts) {
  return {
    type: "CONTACT_EDIT",
    payload: axios(env.SERVER_ENV.url + "/api/contact", {
      method: "PATCH",
      data: JSON.stringify(contacts),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function deleteContacts(ids) {
  return {
    type: "CONTACT_DELETE",
    payload: axios(env.SERVER_ENV.url + "/api/contact", {
      method: "DELETE",
      data: JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function getContactTypes(moduleId) {
  return {
    type: "CONTACT_TYPES_GET",
    payload: axios(env.SERVER_ENV.url + "/api/contact/type/" + moduleId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function getStates() {
  return {
    type: "CONTACT_STATES_GET",
    payload: axios(env.SERVER_ENV.url + "/api/contact/state", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function createContactType(contactType) {
  return {
    type: "CONTACT_TYPE_CREATE",
    payload: axios(env.SERVER_ENV.url + "/api/contact/type", {
      method: "POST",
      data: JSON.stringify(contactType),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function editContactType(contactType) {
  return {
    type: "CONTACT_TYPE_EDIT",
    payload: axios(env.SERVER_ENV.url + "/api/contact/type", {
      method: "PATCH",
      data: JSON.stringify(contactType),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}

export function deleteContactType(id, moduleId) {
  return {
    type: "CONTACT_TYPE_DELETE",
    payload: axios(env.SERVER_ENV.url + "/api/contact/type/" + id + "/" + moduleId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
  };
}