import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FiDownload, FiEye } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import * as service from '../../../services/invoiceServices';
import * as utility from '../../utility.js';
import { generateInvoices as generate, getInvoiceBlob } from '../invoiceGenerator';

const getSum = (data, name) => utility.roundToTwo(data.reduce((a, c) => a + parseFloat(c[name]), 0));

export function invoiceTabConfig(data) {
	return [
		{
			dataField: 'invoiceNo',
			text: <div>Invoice Number</div>,
			footer: '',
			footerFormatter: () => <div className='font-weight-bold'>Total:</div>,
		},
		{
			dataField: 'amount',
			text: 'Amount',
			formatter: (cellContent, row) => {
				return (
					<div className='text-right'>
						<NumberFormat
							value={cellContent}
							displayType={'text'}
							thousandSeparator={true}
							prefix={'$'}
							fixedDecimalScale={true}
							decimalScale={2}
						/>
					</div>
				);
			},
			footer: '',
			footerFormatter: () => {
				const sum = getSum(data, 'amount');
				return (
					<div className='text-right p-0'>
						<NumberFormat
							value={sum}
							displayType={'text'}
							thousandSeparator={true}
							prefix={'$'}
							fixedDecimalScale={true}
							decimalScale={2}
						/>
					</div>
				);
			},
		},
		{
			dataField: 'issuedCheckNumber',
			text: 'Check Number',
			formatter: (cellContent, row) => {
				return (
					<div className='text-center'>
						{cellContent}
					</div>
				);
			},
			footer: ''
		},
		{
			dataField: 'issuedCheckAmount',
			text: 'Remitted Amount',
			formatter: (cellContent, row) => {
				return (
					<div className='text-right'>
						<NumberFormat
							value={cellContent}
							displayType={'text'}
							thousandSeparator={true}
							prefix={'$'}
							fixedDecimalScale={true}
							decimalScale={2}
						/>
					</div>
				);
			},
			footer: ''
		},
		{
			dataField: 'invoiced',
			text: 'Date',
			formatter: (_, row) => {
				return moment.utc(row.invoiced).local().format('L') + ' by ' + row.invoicedByName;
			},
			footer: '',
		},
		{
			dataField: 'status',
			text: 'Status',
			footer: '',
		},
		{
			dataField: 'actions',
			text: '',
			headerStyle: { width: '7.5em' },
			isDummyField: true,
			editable: false,
			formatter: (_, row) => {
				return (
					<div>
						<Button
							size='sm'
							className='ml-1 mb-1'
							onClick={() => {
								service
									.getInvoice(row.id)
									.then(res => generate(res.data))
									.catch(err => console.log('Error -', err));
							}}>
							<FiDownload />
						</Button>
						<Button
							size='sm'
							onClick={() =>
								service
									.getInvoice(row.id)
									.then(res => getInvoiceBlob(res.data).then(response => utility.previewBlob(response, row.invoiceNo)))
									.catch(err => console.log('Error -', err))
							}
							variant='success'
							className='ml-1 mb-1'>
							<FiEye />
						</Button>
					</div>
				);
			},
			footer: '',
		},
	];
}
