import moment from 'moment';
import { roundToTwo } from '../../utility';
import * as utility from '../utility';

export const getReferentAmount = (referentField, row, groupDecisionAmount) => {
	switch (referentField.label) {
		case 'Balance Amount':
			return row.balanceAmount;
		case 'Decision Amount':
			return groupDecisionAmount;
		default:
			break;
	}
};

export const getFromInterestDate = (referentDateFieldId, row) => {
	switch (referentDateFieldId) {
		case 1:
			return row.pomDate30;
		case 2:
			return row.dateIndexPurchased;
		case 3:
			return row.dateInsurerServed;
		case 4:
			return row.interestReferentDate;
		default:
			break;
	}
};

export const getPassedDays = decision => utility.calculatePassedDays(decision, getFromInterestDate);

export const getCollectiveDecisionAmount = (decisionAmount, decisionGroupCasesOpen) =>
	decisionAmount / decisionGroupCasesOpen.filter(x => x.isActive).length;

export const calculate = decision => {
	// const groupDecisionAmount = decision.decisionAmount / decision.decisionGroupCasesOpen.length;
	const groupDecisionAmount = getCollectiveDecisionAmount(decision.decisionAmount, decision.decisionGroupCasesOpen);

	let openCases = decision.decisionGroupCasesOpen.map(x => {
		if (!x.isActive) return { ...x, decisionPercentage: 0, decisionAmount: 0, attorneyFees: 0, interestAccrued: 0 };

		x.decisionAmount = decision.decisionAmount;
		x.interestReferentDate = decision.referentDate;

		let balanceAmount = x.balanceAmount > 0 ? x.balanceAmount : 0;
		let decisionAmount = groupDecisionAmount;

		if (decision.defaultDecisionPercentage && decision.defaultDecisionPercentage > 0)
			decisionAmount = utility.applyEntityPercentage(parseFloat(decision.defaultDecisionPercentage), balanceAmount);

		if (decisionAmount < 0) decisionAmount = 0;

		const interestReferentAmount = getReferentAmount(decision.interestReferentAmountField, x, decisionAmount);

		// let decisionPercentage = (decisionPercentage = utility.getEntityPercentage(decisionAmount, balanceAmount));

		let decisionPercentage =
			decision.defaultDecisionPercentage && parseFloat(decision.defaultDecisionPercentage) > 0
				? parseFloat(decision.defaultDecisionPercentage)
				: utility.getEntityPercentage(parseFloat(decisionAmount), balanceAmount);

		let fromInterestDate = getFromInterestDate(decision.interestReferentDateFieldId, x);
		fromInterestDate = fromInterestDate ? moment(fromInterestDate) : null;

		let daysBetween =
			fromInterestDate && x.toInterestDate && moment(fromInterestDate).isValid()
				? moment(x.toInterestDate).diff(fromInterestDate, 'days')
				: 0;

		// let interestAmount = utility.getInterestAmount(decisionAmount, daysBetween);
		let interestAmount = utility.getInterestAmount(interestReferentAmount, daysBetween);
		interestAmount = utility.applyInterestDiscount(interestAmount, decision.interestDiscountPercentage);

		return {
			...x,
			// attorneyFees: utility.getAF(decisionAmount, interestAmount),
			attorneyFees: utility.getAF(decisionAmount, interestAmount, decision.attorneyFeeLimit),
			interestAccrued: interestAmount,
			decisionAmount,
			decisionPercentage: roundToTwo(decisionPercentage),
			interestReferentDateField: decision.interestReferentDateField ? decision.interestReferentDateField.label : null,
			interestReferentDateFieldId: decision.interestReferentDateField ? decision.interestReferentDateField.value : null,
			interestReferentDate: fromInterestDate,
			interestReferentAmountField: decision.interestReferentAmountField
				? decision.interestReferentAmountField.label
				: null,
			interestReferentAmountFieldId: decision.interestReferentAmountField
				? decision.interestReferentAmountField.value
				: null,
			settlingAttorneyName: decision.settlingAttorney ? decision.settlingAttorney.label : null,
			settlingAttorneyId: decision.settlingAttorney ? decision.settlingAttorney.value : null,
			attorneyFeeLimit: decision.attorneyFeeLimit ? decision.attorneyFeeLimit.label : '20%',
			attorneyFeeLimitId: decision.attorneyFeeLimit ? decision.attorneyFeeLimit.value : null,
			decisionType: decision.decisionType ? decision.decisionType.label : null,
			decisionTypeId: decision.decisionType ? decision.decisionType.value : null,
			decisionStatusId: decision.decisionStatus ? decision.decisionStatus.value : null,
			adjusterName: decision.adjuster ? decision.adjuster.label : null,
			adjusterId: decision.adjuster ? decision.adjuster.value : null,
			judgeId: decision.judge ? decision.judge.value : null,
			arbitratorId: decision.arbitrator ? decision.arbitrator.value : null,
			interestDiscountPercentage: decision.interestDiscountPercentage,
			interestPercentage: 2,
			fromInterestDate,
			toInterestDate: decision.toInterestDate,
		};
	});

	if (!decision.attorneyFeeLimit) return openCases;

	return utility.annealByGroup(openCases, decision.attorneyFeeLimit);
};

const columnSumFields = [
	'claimAmount',
	'collectedAmount',
	'balanceAmount',
	'decisionAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'marshalPoundage',
];

export const rowSumFields = [
	'decisionAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'marshalPoundage',
];

export const generateColumnTotals = (table, isTakenTable = false) =>
	utility.getColumnTotals(table, isTakenTable, columnSumFields);

export const generateRowTotals = row => utility.getRowTotals(row, rowSumFields);
