const utilityReducer = (
  state = {
    fieldDefinitions:[]
  },
  action
) => {
  switch (action.type) {
    case "FIELD_DEFINITIONS_GET_FULFILLED":
      return Object.assign({}, state, { ...state, fieldDefinitions: [...action.payload.data] });
      break;
    case "DYNAMIC_FIELDS_MASS_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state
      });
      break;
    default:
      return state;
      break;
  }
};

export default utilityReducer;
