import axios from 'axios';
import { env } from '../configs/env';
import jwtDecode from 'jwt-decode';

export function login(credentials) {
	return axios(env.SERVER_ENV.url + '/api/user/login', {
		method: 'POST',
		data: JSON.stringify(credentials),
		headers: {
			'Content-Type': 'application/json',
		},
	});
}

export function codeLogin(code) {
	return axios(env.SERVER_ENV.url + '/api/user/login?code=' + code, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});
}

export function getDynamicFields(request) {
	return axios(env.SERVER_ENV.url + '/api/user/dynamic', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getLoginRedirect(token) {
	const decoded = jwtDecode(token);
	if (decoded.sub == 'korisnik') return '/anketa';
	return decoded.basePath;
}

export function getUser(id) {
	return axios(env.SERVER_ENV.url + '/api/user/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function edit(request) {
	return axios(env.SERVER_ENV.url + '/api/user', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function update(request) {
	return axios(env.SERVER_ENV.url + '/api/user', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function isPortalUser() {
	const decoded = jwtDecode(localStorage.getItem('token'));
	return decoded && decoded.basePath == '/portal';
}
