import * as utility from '../components/cases/utility';

const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	cases: [],
	case: {
		id: null,
		caseNo: null,
		costs: [],
		penalties: [],
		penaltyDeleteIds: [],
		costTypeIds: [],
		deleteIds: [],
		read: null,
	},
	newCase: null,
	newCaseGroup: null,
	permissions: {
		create: false,
		update: false,
		delete: false,
	},
	reloadActions: false,
	delete: false,
	massImport: {
		rows: [],
		invalidRows: [],
		importedRows: [],
	},
	massBillImport: {
		files: [],
		allowValid: false,
		validated: false,
		statusBarValue: null,
		disableCheck: false,
	},
};

const caseReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'SETTLEMENT_GET_FULFILLED':
		case 'JUDGEMENT_GET_FULFILLED':
		case 'DECISION_GET_FULFILLED':
		case 'ARB_AWARD_GET_FULFILLED':
		case 'CALENDAR_EVENT_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				case: {
					...initialState.case,
					id: state.isGeneralEventsModule ? null : action.payload.data.caseId,
					caseNo: state.isGeneralEventsModule ? null : action.payload.data.caseNo,
				},
			});
			break;
		case 'CASE_GET_PAGE_FULFILLED':
			action.payload.data.dataSize = undefined;
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'CASE_GET_DATASIZE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				dataSize: action.payload.data,
			});
			break;
		case 'CASE_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					...action.payload.data,
					firms: action.payload.data.firms.map(x => ({
						...x,
						value: x.id,
						label: x.name,
					})),
					caseGroups: action.payload.data.caseGroups
						? action.payload.data.caseGroups.map(x => ({
								...x,
								value: x.id,
								label: x.name,
						  }))
						: [],
				},
			});
			break;
		case 'CASE_GET_REJECTED':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					read: false,
				},
			});
			break;
		case 'MODULE_PERMISSIONS_GET_PENDING':
			return Object.assign({}, state, {
				...state,
				reloadActions: false,
			});
			break;
		case 'MODULE_PERMISSIONS_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				permissions: action.payload.data,
				reloadActions: true,
			});
			break;
		case 'CASE_DYNAMIC_GET_FULFILLED':
			state.case.dynamic = {};
			return Object.assign({}, state, {
				...state,
				case: Object.assign({}, state.case, {
					...state.case,
					dynamic: Object.assign({}, state.case.dynamic, {
						...action.payload.data,
					}),
				}),
			});
			break;
		case 'CASE_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				newCase: action.payload.data,
			});
			break;
		case 'MASS_UPDATE_TOP_LEVEL_FIELDS_FULFILLED':
			return Object.assign({}, state, {
				...state,
			});
			break;
		case 'CASE_DELETE_FULFILLED':
			var removeIndex = state.cases
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.cases.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				cases: [...state.cases],
			});
			break;
		case 'CASE_EDIT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				cases: state.cases.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
				case: { ...state.case, ...action.payload.data },
			});
			break;
		case 'CASE_GET_COSTS_FULFILLED':
		case 'CASE_UPDATE_COSTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					costs: [
						...action.payload.data.map(x => {
							return {
								...x,
								cId: x.id,
								costStatus: { value: x.costStatusId, label: x.costStatus },
								checkNumber: x.checkNumber ? x.checkNumber : '',
								payee: { value: x.payeeId, label: x.payee },
							};
						}),
						...state.case.costs.filter(x => x.new && !x.payee),
					],
					costIds: [...action.payload.data.map(x => x.costTypeId)],
					costTypeIds: [],
					deleteIds: [],
				},
			});
			break;
		case 'CASE_ADD_COST':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					costs: [
						...state.case.costs,
						{
							...action.payload,
							costStatus: { value: 0, label: 'Unconfirmed' },
							checkNumber: '',
							payee: null,
						},
					],
					costTypeIds: [...state.case.costTypeIds, action.payload.costTypeId],
				},
			});
			break;
		case 'CASE_REMOVE_COST':
			var removeIndex = state.case.costs
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload);

			var deleteIds = [...state.case.deleteIds];
			if (state.case.costs[removeIndex].new) {
				state.case.costs.splice(removeIndex, 1);
				state.case.costIds.splice(removeIndex, 1);
			} else if (state.case.costs[removeIndex].deleted) {
				state.case.costs[removeIndex].deleted = false;
				deleteIds.filter(x => x !== state.case.costs[removeIndex].costTypeId);
			} else {
				state.case.costs[removeIndex].deleted = true;
				deleteIds = [...deleteIds, state.case.costs[removeIndex].id];
			}

			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					costs: [...state.case.costs],
					costIds: [...state.case.costIds],
					deleteIds,
				},
			});
			break;
		case 'SETTLEMENT_CLEAR':
		case 'DECISION_CLEAR':
		case 'JUDGEMENT_CLEAR':
		case 'ARB_AWARD_CLEAR':
		case 'CLEAR_CASE':
			return Object.assign({}, state, {
				...state,
				case: { ...initialState.case },
			});
			break;

		case 'CASE_GET_PENALTIES_FULFILLED':
		case 'CASE_UPDATE_PENALTIES_FULFILLED':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					penalties: action.payload.data,
					penaltyDeleteIds: [],
				},
			});
			break;
		case 'CASE_ADD_PENALTY':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					penalties: [...state.case.penalties, { ...action.payload, amount: action.payload.defaultAmount }],
				},
			});
			break;

		case 'CASE_REMOVE_PENALTY':
			var removeIndex = state.case.penalties
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload);

			var penaltyDeleteIds = [...state.case.penaltyDeleteIds];
			if (state.case.penalties[removeIndex].new) {
				state.case.penalties.splice(removeIndex, 1);
				state.case.costIds.splice(removeIndex, 1);
			} else if (state.case.penalties[removeIndex].deleted) {
				state.case.penalties[removeIndex].deleted = false;
				penaltyDeleteIds.filter(x => x !== state.case.penalties[removeIndex].id);
			} else {
				state.case.penalties[removeIndex].deleted = true;
				penaltyDeleteIds = [...penaltyDeleteIds, state.case.penalties[removeIndex].id];
			}

			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					penalties: [...state.case.penalties],
					penaltyDeleteIds,
				},
			});
			break;
		case 'CASE_GROUP_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				newCaseGroup: { ...action.payload.data },
			});
			break;
		case 'CASE_MASS_IMPORT_GET':
			return Object.assign({}, state, {
				...state,
				massImport: {
					...state.massImport,
					importedRows: [],
					...action.payload,
				},
			});
			break;
		case 'CASE_MASS_IMPORT_TOGGLE_SWITCH':
			return Object.assign({}, state, {
				...state,
				massImport: {
					...state.massImport,
					rows: state.massImport.rows.map(item => {
						if (item.rowId === action.payload.rowId) return { ...item, isValid: item.isValid ? false : true };
						return item;
					}),
				},
			});
			break;
		case 'CASE_MASS_IMPORT_SET_INVALID_ROWS':
			return Object.assign({}, state, {
				...state,
				massImport: {
					...state.massImport,
					invalidRows: action.payload,
				},
			});
			break;
		case 'CASE_MASS_IMPORT_FULFILLED':
			return Object.assign({}, state, {
				...state,
				massImport: {
					...state.massImport,
					importedRows: state.massImport.rows
						.map((item, index) => {
							if (
								!action.payload.data.some(x => x.rowId === item.rowId && x.errorMessage && x.errorMessage != '') &&
								!state.massImport.invalidRows.includes(item.rowId) &&
								item.isValid
							) {
								return item.rowId;
							}
						})
						.filter(x => x),
					rows: state.massImport.rows.map((item, index) => {
						if (action.payload.data.some(x => x.rowId === item.rowId && x.errorMessage && x.errorMessage != '')) {
							let found = action.payload.data.find(
								x => x.rowId === item.rowId && x.errorMessage && x.errorMessage != ''
							);
							return {
								...item,
								...found,
								errorMessage: found.errorMessage,
								isValid: false,
							};
						}
						let found = action.payload.data.find(x => x.rowId === item.rowId);
						return {
							...item,
							...found,
							errorMessage: item.isValid ? 'Imported' : item.errorMessage,
						};
					}),
				},
			});
			break;
		case 'CASE_MASS_BILL_IMPORT_UNPACK':
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					...state.massBillImport,
					allowValid: true,
					validated: false,
					files: action.payload,
				},
			});
			break;
		case 'CASE_MASS_BILL_IMPORT_VALIDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					...state.massBillImport,
					validated: true,
					files: action.payload.data.map(item => {
						const name = state.massBillImport.files.find(x => x.rowId === item.rowId).fileName;
						return {
							...item,
							fileName: name,
							isValid: utility.numberOfDots(name) > 1 ? false : item.caseId < 1 ? false : true,
						};
					}),
				},
			});
			break;
		case 'CASE_MASS_BILL_IMPORT_TOGGLE_SWITCH':
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					...state.massBillImport,
					files: state.massBillImport.files.map(item => {
						if (item.rowId === action.payload.rowId) return { ...item, isValid: item.isValid ? false : true };
						return item;
					}),
				},
			});
			break;
		case 'CASE_MASS_BILL_IMPORT_CLEAR':
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					files: [],
					validated: false,
					allowValid: false,
				},
			});
			break;
		case 'CASE_MASS_BILL_DISABLE_TOGGLE':
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					...state.massBillImport,
					disableCheck: true,
				},
			});
			break;
		case 'CASE_MASS_BILL_UPDATE_PROGRESS_BAR':
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					...state.massBillImport,
					statusBarValue: action.payload.value,
					statusBarFinished: action.payload.finished,
					disableCheck: action.payload.finished ? false : true,
				},
			});
			break;

		case 'CASE_MASS_BILL_IMPORT_FULFILLED':
			const data = JSON.parse(action.payload.data);
			return Object.assign({}, state, {
				...state,
				massBillImport: {
					...state.massBillImport,
					files: state.massBillImport.files.map(item => {
						let found = data.find(x => (x.rowId = item.rowId && item.isValid));
						return found
							? {
									...item,
									isValid: item.caseId >= 1,
									imported: true,
							  }
							: item;
					}),
				},
			});
			break;
		case 'CASE_COST_UPDATE_VALUE':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					costs: state.case.costs.map(x => {
						if (x.id !== action.payload.id) {
							return x;
						} else {
							return {
								...action.payload,
								costStatus:
									action.payload.checkNumber === null ||
									/^\s+$/.test(action.payload.checkNumber) ||
									action.payload.checkNumber.length === 0
										? { value: 0, label: 'Unconfirmed' }
										: { value: 1, label: 'Confirmed' },
							};
						}
					}),
					costTypeIds: [...state.case.costTypeIds, action.payload.costTypeId],
				},
			});
		case 'CASE_PENALTY_UPDATE_VALUE':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					penalties: state.case.penalties.map(x => {
						if (x.id !== action.payload.id) return x;
						else return action.payload;
					}),
					costTypeIds: [...state.case.costTypeIds, action.payload.costTypeId],
				},
			});
		case 'SET_GENERAL_CALENDAR_EVENTS_MODE':
			return Object.assign({}, state, {
				...state,
				isGeneralEventsModule: true,
			});
			break;
		case 'MATTER_TYPES_GET_ALL_FULFILLED':
			return Object.assign({}, state, {
				...state,
				matterTypes: action.payload.data,
			});
			break;
		case 'MATTER_TYPES_GET_BY_UG_FULFILLED':
			return Object.assign({}, state, {
				...state,
				matterTypes: state.matterTypes.map(item => {
					let value = action.payload.data.find(x => item.id === x.id);
					if (value) return { ...value, read: true };
					else
						return {
							...item,
							create: false,
							update: false,
							delete: false,
							read: false,
						};
				}),
			});
			break;
		case 'MATTER_TYPES_TOGGLE_PERMISSION':
			return Object.assign({}, state, {
				...state,
				matterTypes: state.matterTypes.map(item => {
					if (item.id == action.payload.moduleId) {
						if (action.payload.permissionType === 'read') {
							let readValue = item['read'];
							return {
								...item,
								create: readValue ? false : item.create,
								update: readValue ? false : item.update,
								delete: readValue ? false : item.delete,
								read: !readValue,
							};
						} else
							return {
								...item,
								read: true,
								[action.payload.permissionType]: !item[action.payload.permissionType],
							};
					}
					return item;
				}),
			});
			break;
		case 'MATTER_TYPES_UPDATE_PERMISSIONS_PENDING':
			return Object.assign({}, state, {
				...state,
				loading: true,
			});
			break;
		case 'MATTER_TYPES_UPDATE_PERMISSIONS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				loading: false,
			});
			break;
		case 'MATTER_TYPES_TOGGLE_ALL_PERMISSION':
			return Object.assign({}, state, {
				...state,
				matterTypes: state.matterTypes.map(item => {
					return {
						...item,
						read: !state.checkedAll,
						create: !state.checkedAll,
						update: !state.checkedAll,
						delete: !state.checkedAll,
					};
				}),
				checkedAll: !state.checkedAll,
			});
			break;
		case 'COST_TOGGLE_EDIT':
			return Object.assign({}, state, {
				...state,
				case: {
					...state.case,
					costs: state.case.costs.map(x => {
						if (x.id !== action.payload) return x;
						else {
							return { ...x, edit: x.edit ? false : true };
						}
					}),
				},
			});
		case 'CASE_SET_STATE':
			return Object.assign({}, state, {
				...state,
				...action.payload,
			});
			break;
		default:
			return state;
			break;
	}
};

export default caseReducer;
