import axios from 'axios';
import { env } from '../configs/env';

const path = 'BILLING/INVOICE';

export function uploadInvoiceToDM(fileBlob, invoiceInfo) {
	// upload only to provider case

	if (!invoiceInfo.providerCaseId) return;

	var data = new FormData();
	data.append('file', fileBlob, invoiceInfo.invoiceNo + '.pdf');
	data.append('FileName', invoiceInfo.invoiceNo);
	data.append('NodeForGrid', invoiceInfo.providerCaseId + '/' + path);
	data.append('CaseId', invoiceInfo.providerCaseId);
	data.append('AccessToken', localStorage.getItem('token'));
	data.append('ForceOverwrite', true);
	data.append('UserId', localStorage.getItem('userId'));

	axios
		.post(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/fileupload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(result => {
			var search_params = new URLSearchParams();
			search_params.append('UserId', localStorage.getItem('userId'));
			search_params.append('NodeId', result.data.NodeId ? result.data.NodeId : 0);

			for (var key in result.data) {
				if (key !== 'UserId' && key !== 'NodeId') {
					search_params.append(key, result.data[key]);
				}
			}
			data = axios.post(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/document', search_params);
		});

	// invoiceInfo.data.forEach((x) => {
	//   var data = new FormData();
	//   data.append("file", fileBlob, invoiceInfo.invoiceNo + ".pdf");
	//   data.append("FileName", invoiceInfo.invoiceNo);
	//   data.append("NodeForGrid", x.caseId + "/" + path);
	//   data.append("CaseId", x.caseId);
	//   data.append("AccessToken", localStorage.getItem("token"));
	//   data.append("ForceOverwrite", true);
	//   data.append("UserId", localStorage.getItem("userId"));

	//   axios
	//     .post(env.DOCUMENT_MANAGMENT_API_ENV.url + "/api/fileupload", data, {
	//       headers: {
	//         "Content-Type": "multipart/form-data",
	//       },
	//     })
	//     .then((result) => {
	//       var search_params = new URLSearchParams();

	//       search_params.append("UserId", localStorage.getItem("userId"));
	//       search_params.append("NodeId", 0);
	//       for (var key in result.data) {
	//         if (key !== "UserId" && key !== "NodeId") {
	//           search_params.append(key, result.data[key]);
	//         }
	//       }
	//       data = axios.post(
	//         env.DOCUMENT_MANAGMENT_API_ENV.url + "/api/document",
	//         search_params
	//       );
	//     });
	// });
}

export function emailInvoice(fileBlob, invoiceInfo) {
	const data = new FormData();
	data.append('file', fileBlob, invoiceInfo.invoiceNo + '.pdf');
	data.append('email', invoiceInfo.providerEmail);

	return axios.post(env.SERVER_ENV.url + '/api/check/uploadInvoice', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getInvoicesByProvider(providerId) {
	return axios(env.SERVER_ENV.url + '/api/check/invoices/' + providerId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function downloadInvoiceFromZeus(filePath) {
	return axios(
		env.DOCUMENT_MANAGMENT_API_ENV.url + '/filefetch?AccessToken' + localStorage.getItem('token') + '&UNC=' + filePath,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		}
	);
}

export function getInvoice(id) {
	return axios(env.SERVER_ENV.url + '/api/invoice/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getInvoicePage(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function addPayment(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/payment', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function clearInvoice(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/clear', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function clearInvoiceBulk(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/clearBulk', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function clearInvoicePayments(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/payment/clear', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getInvoicePaymentDepositPage(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/payment/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function depositInvoicePayment(request) {
	return axios(env.SERVER_ENV.url + '/api/invoice/payment/deposit', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function invoiceReverseValidate(id) {
	return axios(env.SERVER_ENV.url + '/api/invoice/reverse-validate/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function invoiceReverse(id) {
	return axios(env.SERVER_ENV.url + '/api/invoice/reverse/' + id, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
