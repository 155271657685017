import moment from "moment";

const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	bankAccounts: [],
	transfers: [],
	newBankAccount: null,
	automaticTransfers: [],
};

const bankAccountReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case "BANK_ACC_GET_PAGE_FULFILLED":
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case "BANK_ACC_CREATE_FULFILLED":
			return Object.assign({}, state, {
				...state,
				newBankAccount: action.payload.data,
			});
			break;
		case "BANK_ACC_DELETE_FULFILLED":
			var removeIndex = state.bankAccounts
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.bankAccounts.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				bankAccounts: [...state.bankAccounts],
			});
			break;
		case "BANK_ACC_EDIT_FULFILLED":
			return Object.assign({}, state, {
				...state,
				bankAccounts: state.bankAccounts.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case "TRANSFER_GET_PAGE_FULFILLED":
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
				transfers: action.payload.data.transfers.map((x) => {
					return {
						...x,
						remittance: [],
						cleared: {
							value: x.isCleared,
							label: x.isCleared ? "Cleared" : "Not Cleared",
						},
					};
				}),
			});
			break;
		case "TRANSFER_MONEY_FULFILLED":
			return Object.assign({}, state, {
				...state,
				transfers: [
					{
						...action.payload.data,
						cleared: {
							value: action.payload.data.isCleared,
							label: action.payload.data.isCleared ? "Cleared" : "Not Cleared",
						},
					},
					...state.transfers,
				],
			});
			break;
		case "TRANSFER_GET_AUTOMATIC_FULFILLED":
			var i = 0;
			return Object.assign({}, state, {
				...state,
				automaticTransfers: action.payload.data.map((x) => {
					return { ...x, id: i++ };
				}),
			});
			break;
		case "TRANSFER_REMOVE_AUTOMATIC":
			state.automaticTransfers.splice(
				state.automaticTransfers.map((x) => x.id).indexOf(action.payload.id),
				1
			);
			return Object.assign({}, state, {
				...state,
				automaticTransfers: [...state.automaticTransfers],
			});
			break;
		case "TRANSFER_UPDATE_ROW":
			return Object.assign({}, state, {
				...state,
				automaticTransfers: state.automaticTransfers.map((x) => {
					if (x.id !== action.payload.id) return x;
					else {
						return { ...action.payload, changed: x.changed ? false : true };
					}
				}),
			});
			break;
		case "TRANSFER_UPDATE_VALUE":
			return Object.assign({}, state, {
				...state,
				transfers: [
					...state.transfers.map((x) => {
						if (x.id !== action.payload.id) return x;
						return {
							...x,
							clearDate:
								action.payload.clearDate !== null
									? new Date(moment.utc(action.payload.clearDate))
									: null,
							cleared: action.payload.cleared,
						};
					}),
				],
			});
		default:
			return state;
			break;
	}
};

export default bankAccountReducer;
