import moment from 'moment';
import { calculate, generateColumnTotals, generateRowTotals } from '../components/cases/settlement/settlementUtility';
import { annealByGroup, getAF } from '../components/cases/utility';

const initialState = {
	settlement: {
		settlementType: null,
		interestReferentDateField: { value: 2, label: 'Date Index Purchased' },
		interestReferentAmountField: null,
		attorneyFeeLimit: { value: 5, label: '20%' },
		interestReferentAmountField: { value: 2, label: 'Balance Amount' },
		adjuster: null,
		settlingAttorney: { value: 427749, label: 'DOUGLAS H. SANDERS' },
		interestPercentage: 2,
		settlementPercentage: 100,
		interestDiscountPercentage: 100,
		defaultSettlementPercentage: 85,
		filingFeeLimit: 0,
	},
};

const discontinuanceSettings = {
	attorneyFeeLimit: { value: 4, label: '<= 1360' },
	settlementAmount: 0,
	adjustedBalanceAmount: 0,
	defaultSettlementPercentage: 0,
	filingFees: 0,
	interestReferentAmountField: { value: 1, label: 'Settled Amount' },
};

const settlementReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'SETTLEMENT_CREATE_FULFILLED':
		case 'SETTLEMENT_GET_FULFILLED':
			const { data } = action.payload;

			return Object.assign({}, state, {
				settlement: Object.assign({}, state.settlement, {
					...data,
					settlementAmount: 0,
					adjustedBalanceAmount: 0,
					interestDiscountPercentage: 100,
					defaultSettlementPercentage: 85,
					interestReferentAmountField: null,
					settlementType: { value: 148, label: 'SETTLED' },
					interestReferentDateField: { value: 2, label: 'Date Index Purchased' },
					attorneyFeeLimit: null,
					attorneyFeeLimit: { value: 4, label: '<= 1360' },
					interestReferentAmountField: { value: 2, label: 'Balance Amount' },
					interestPercentage: 2,
					settlingAttorney: { value: 427749, label: 'DOUGLAS H. SANDERS' },
				}),
			});
			break;
		case 'SETTLEMENT_CLEAR':
			return Object.assign({}, state, {
				settlement: {
					settlementType: null,
					interestReferentDateField: { value: 2, label: 'Date Index Purchased' },
					interestReferentAmountField: null,
					attorneyFeeLimit: { value: 5, label: '20%' },
					interestReferentAmountField: { value: 2, label: 'Balance Amount' },
					adjuster: null,
					settlingAttorney: { value: 427749, label: 'DOUGLAS H. SANDERS' },
					interestPercentage: 2,
					settlementPercentage: 100,
					interestDiscountPercentage: 100,
					defaultSettlementPercentage: 85,
					filingFeeLimit: 0,
				},
			});
			break;
		case 'SETTLEMENT_SET_STATE':
			if ('settlementType' in action.payload && action.payload.settlementType) {
				const { label } = action.payload.settlementType;
				const oldLabel = state.settlement.settlementType ? state.settlement.settlementType.label : null;
				if (label.toLowerCase().includes('discontinuance') && label !== oldLabel)
					action.payload = { ...action.payload, ...discontinuanceSettings };
			}

			return Object.assign({}, state, {
				settlement: {
					...action.payload,
					settlementAmount: parseFloat(action.payload.settlementAmount),
					adjustedBalanceAmount: parseFloat(action.payload.adjustedBalanceAmount),
				},
			});
			break;
		case 'SETTLEMENT_GET_GROUP_CASES_FULFILLED':
			let settlementGroupCasesTaken = action.payload.data.settlementGroupCases
				.map(x => {
					if (x.settlementStatusName === 'Taken') {
						return {
							...x,
							interestAccrued: x.interestAmount,
							interestPercentage: 2,
							totals: generateRowTotals(x),
						};
					}
				})
				.filter(x => x);

			let settlementGroupCasesOpen = action.payload.data.settlementGroupCases
				.map(x => {
					if (x.settlementStatusName !== 'Taken') {
						return {
							...x,
							toInterestDate: moment(),
							interestAccrued: 0,
							settlementPercentage: 100,
							interestPercentage: 2,
							isActive: true,
							additionalAttorneyFees: 0,
							marshalPoundage: 0,
							attorneyFees: 0,
							adjustedBalanceAmount: 0,
							interestDiscountPercentage: 100,
							totals: generateRowTotals(x),
						};
					}
				})
				.filter(x => x);

			return Object.assign({}, state, {
				settlement: Object.assign({}, state.settlement, {
					...state.settlement,
					settlementGroupCasesTaken,
					settlementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(settlementGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(settlementGroupCasesTaken, true),
				}),
			});

			break;
		case 'SETTLEMENT_CASE_DELETE_FULFILLED':
			var removeIndex = state.settlement.settlementGroupCasesTaken
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			var elements = state.settlement.settlementGroupCasesTaken.splice(removeIndex, 1);
			elements.id = 0;

			settlementGroupCasesTaken =
				state.settlement.settlementGroupCasesTaken && state.settlement.settlementGroupCasesTaken.length
					? [...state.settlement.settlementGroupCasesTaken]
					: [];
			settlementGroupCasesOpen =
				state.settlement.settlementGroupCasesOpen && state.settlement.settlementGroupCasesOpen.length > 0
					? [...state.settlement.settlementGroupCasesOpen, ...elements]
					: [...elements];

			return Object.assign({}, state, {
				settlement: Object.assign({}, state.settlement, {
					...state.settlement,
					settlementGroupCasesTaken,
					settlementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(settlementGroupCasesOpen),
					rowTotalsTaken: generateColumnTotals(settlementGroupCasesTaken, true),
				}),
			});
			break;
		case 'SETTLEMENT_CASE_UPDATE_VALUE':
			settlementGroupCasesOpen = state.settlement.settlementGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId
					? { ...x, totals: generateRowTotals(x) }
					: {
							...action.payload,
							totals: generateRowTotals(action.payload),
					  }
			);

			// adjust attorney fees by group
			settlementGroupCasesOpen.map(x =>
				x.isActive
					? { ...x, attorneyFees: getAF(x.settlementAmount, x.interestAmount, state.settlement.attorneyFeeLimit) }
					: x
			);

			const adjustedByGroup = annealByGroup(settlementGroupCasesOpen, state.settlement.attorneyFeeLimit, [
				'patientId',
				'providerId',
			]);

			settlementGroupCasesOpen = settlementGroupCasesOpen.map(x =>
				x.isActive ? adjustedByGroup.find(y => y.caseId === x.caseId) : x
			);

			return Object.assign({}, state, {
				...state,
				settlement: {
					...state.settlement,
					settlementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(settlementGroupCasesOpen),
				},
			});
			break;
		case 'SETTLEMENT_CASE_TOGGLE':
			settlementGroupCasesOpen = state.settlement.settlementGroupCasesOpen.map(x =>
				x.caseId !== action.payload.caseId ? x : { ...action.payload, isActive: !action.payload.isActive }
			);
			return Object.assign({}, state, {
				...state,
				settlement: {
					...state.settlement,
					settlementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(settlementGroupCasesOpen),
					shouldRecalculate: true,
				},
			});
			break;
		case 'SETTLEMENT_CASE_TOGGLE_ALL':
			settlementGroupCasesOpen = state.settlement.settlementGroupCasesOpen.map(x => {
				return { ...x, isActive: action.payload };
			});
			return Object.assign({}, state, {
				...state,
				settlement: {
					...state.settlement,
					settlementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(settlementGroupCasesOpen),
					shouldRecalculate: true,
				},
			});
			break;
		case 'SETTLEMENT_CASE_RECALCULATE':
			settlementGroupCasesOpen = [...calculate(action.payload)];
			return Object.assign({}, state, {
				...state,
				settlement: {
					...state.settlement,
					settlementGroupCasesOpen,
					rowTotalsOpen: generateColumnTotals(settlementGroupCasesOpen),
					shouldRecalculate: false,
				},
			});
			break;
		default:
			return state;
			break;
	}
};

export default settlementReducer;
