import axios from "axios";
import { env } from "../configs/env";

function getUTCDate(date) {
  if (date)
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
}

export function create(party) {
  return {
    type: "PARTY_CREATE",
    payload: axios(env.SERVER_ENV.url + "/api/party", {
      method: "POST",
      data: JSON.stringify(party),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function edit(party) {
  return {
    type: "PARTY_EDIT",
    payload: axios(env.SERVER_ENV.url + "/api/party", {
      method: "PATCH",
      data: JSON.stringify(party),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function deleteParty(id, moduleId) {
  return {
    type: "PARTY_DELETE",
    payload: axios(env.SERVER_ENV.url + "/api/party/" + id + "/" + moduleId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function get(id) {
  return {
    type: "PARTY_GET",
    payload: axios(env.SERVER_ENV.url + "/api/party/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function getPage(request) {
  return {
    type: "PARTY_GET_PAGE",
    payload: axios(env.SERVER_ENV.url + "/api/party/page", {
      method: "POST",
      data: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function createPartyType(partyType) {
  return {
    type: "PARTY_TYPE_CREATE",
    payload: axios(env.SERVER_ENV.url + "/api/party/type", {
      method: "POST",
      data: JSON.stringify(partyType),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function editPartyType(partyType) {
  return {
    type: "PARTY_TYPE_EDIT",
    payload: axios(env.SERVER_ENV.url + "/api/party/type", {
      method: "PATCH",
      data: JSON.stringify(partyType),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function deletePartyType(id, moduleId) {
  return {
    type: "PARTY_TYPE_DELETE",
    payload: axios(
      env.SERVER_ENV.url + "/api/party/type/" + id + "/" + moduleId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ),
  };
}

export function getPartyTypes(moduleId) {
  return {
    type: "PARTY_TYPE_GET",
    payload: axios(env.SERVER_ENV.url + "/api/party/type/" + moduleId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function resetNew() {
  return {
    type: "PARTY_RESET_NEW",
  };
}

export function getFirmFeeCollections(request) {
  return {
    type: "PARTY_GET_FIRM_FEE_COLLECTIONS",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral/page", {
      method: "POST",
      data: JSON.stringify({
        ...request,
        referralType: "Firm Fee",
        pageSize: 9999,
        page: 1,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function getAttorneyFeeCollections(request) {
  return {
    type: "PARTY_GET_ATTORNEY_FEE_COLLECTIONS",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral/page", {
      method: "POST",
      data: JSON.stringify({
        ...request,
        referralType: "Attorney Fee",
        pageSize: 9999,
        page: 1,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function getBillingFeeCollections(request) {
  return {
    type: "PARTY_GET_BILLING_FEE_COLLECTIONS",
    payload: axios(env.SERVER_ENV.url + "/api/party/referral/page", {
      method: "POST",
      data: JSON.stringify({
        ...request,
        referralType: "Billing Fee",
        pageSize: 9999,
        page: 1,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  };
}

export function addReferral(referral) {
  return {
    type: "PARTY_ADD_REFERRAL",
    payload: referral,
  };
}

export function removeReferral(id) {
  return {
    type: "PARTY_REMOVE_REFERRAL",
    payload: id,
  };
}

export function editReferral(id) {
  return {
    type: "PARTY_EDIT_REFERRAL",
  };
}

export function selectPartyType(id) {
  return {
    type: "PARTY_SELECT_TYPE",
    payload: id,
  };
}

export function selectParent(id) {
  return {
    type: "PARTY_SELECT_PARENT",
    payload: id,
  };
}

export function selectGender(request) {
  return {
    type: "PARTY_SELECT_GENDER",
    payload: request,
  };
}

export function selectDate(request) {
  return {
    type: "PARTY_SELECT_DATE",
    payload: request,
  };
}

export function setState(data) {
  return {
    type: "PARTY_SET_STATE",
    payload: data,
  };
}
