import dollarsToWords from 'dollars-to-words';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import * as statementsGenerator from './statementsGenerator';

export const createChecks = checks => {
	var doc = new jsPDF();

	checks.forEach(c => {
		if (!c.failed) {
			doc.setFont(undefined, 'bold');
			doc.setFontSize(12);
			doc.text(moment(c.checkDate).format('L'), 170, 15);
			doc.setFontSize(12);
			doc.text(c.remittanceName ? c.remittanceName + '' : c.providerName + '' + '', 20, 40);
			doc.text(c.amount.toFixed(2) + '', 180, 40);
			doc.setFontSize(11);
			var dTW = dollarsToWords(c.amount.toFixed(2));
			var textWidth = (doc.getStringUnitWidth(dTW) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
			var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
			doc.text(textOffset - 25, 50, dTW.toUpperCase().trim());
			doc.setFont(undefined, 'normal');
			doc.setFontSize(12);

			doc.text(
				moment(c.checkDate).format('L') +
					' / Check No: ' +
					c.checkNumber +
					' / ' +
					(c.note ? c.note : 'Invoice No: ' + (c.invoiceNos || '')),
				20,
				70
			);

			doc.setFontSize(12);
			doc.text(c.remittanceName ? c.remittanceName + '' : c.providerName + '', 35, 155);
			doc.text(c.providerAddress + '', 35, 160);
			doc.text(c.providerCity + ', ' + c.providerState + ', ' + c.providerZip, 35, 170);
			if (c !== checks[checks.length - 1]) doc.addPage();
		}
	});

	return doc;
};

export const createStatements = checks => {
	var doc = new jsPDF('l', 'mm', [925, 800]);
	var firstPage = true;

	checks.forEach(c => {
		if (!c.failed && [3, 4, 5].includes(c.checkTypeId)) {
			if (firstPage) firstPage = false;
			else doc.addPage();
			statementsGenerator.createStatementPage(doc, c);
		}
	});

	return doc;
};
