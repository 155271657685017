import moment from 'moment';
import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton, ProgressBar } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import XLSX from 'xlsx';

import { FaSpinner } from 'react-icons/fa';

const adjustData = arr => (arr ? arr : []);

const splitCamelCase = str => str.replace(/^[a-z]|[A-Z]/g, (c, i) => (i ? ' ' : '') + c.toUpperCase());

const transformKeys = obj =>
	Object.keys(obj).reduce((o, key) => {
		o[splitCamelCase(key)] = obj[key];
		return o;
	}, {});

const sortObjectProps = (obj, fields) =>
	Object.keys(obj)
		.sort((x, y) => fields.indexOf(x) - fields.indexOf(y))
		.reduce((o, key) => {
			o[key] = obj[key];
			return o;
		}, {});

export const ExportXlsx = props => {
	const [loading, setLoading] = useState(false);
	const { data, fileName, request, isDropdown, collectionName } = props;

	const isDisabled = () => (props.disabled ? props.disabled : data.length === 0);

	const options = {
		variant: props.variant ? props.variant : 'success',
		disabled: isDisabled(),
		style: props.buttonStyle ? props.buttonStyle : {},
		fileName: fileName ? fileName : 'export',
	};

	const getColumnWidth = num => [...Array(num).keys()].map(() => ({ wch: props.colWidth ? props.colWidth : 17 }));

	const formatColumn = (worksheet, col, fmt) => {
		const range = XLSX.utils.decode_range(worksheet['!ref']);

		for (let row = range.s.r + 1; row <= range.e.r; ++row) {
			const ref = XLSX.utils.encode_cell({ r: row, c: col });
			if (worksheet[ref] && worksheet[ref].t === 'n') worksheet[ref].z = fmt;
		}
	};

	const existCurrencyFields = () => props.currencyIndices && props.currencyIndices.length > 0;

	const downloadSheet = data => {
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(data, {
			cellDates: true,
			dateNF: props.showTime ? 'MM/DD/YYYY hh:mm AM/PM' : 'MM/DD/YYYY',
		});

		ws['!cols'] = getColumnWidth(data[0] ? Object.keys(data[0]).length : 0);

		if (existCurrencyFields()) props.currencyIndices.forEach(i => formatColumn(ws, i, '$0.00'));

		XLSX.utils.book_append_sheet(wb, ws);
		XLSX.writeFile(wb, options.fileName + '.xlsx');
	};

	const handleDownload = all => {
		if (all) {
			setLoading(true);
			request()
				.then(res => {
					downloadSheet(prepareData(res.data[collectionName]));
					setLoading(false);
				})
				.catch(err => {
					console.log('Error - ', err);
					setLoading(false);
				});
		} else downloadSheet(prepareData(data));
	};

	const adjustDate = value => {
		if (value === null || /^\s+$/.test(value)) return null;
		return props.showTime ? new Date(moment.utc(value).local()) : new Date(value);
	};

	const prepareData = data => {
		var { dateFields, fields, unwantedFields, fieldsOrder } = props;

		unwantedFields = adjustData(unwantedFields);
		fields = adjustData(fields);
		dateFields = adjustData(dateFields);

		var headers = fields.filter(x => x && !unwantedFields.includes(x));
		headers = adjustData(headers);

		return data
			? data.map(x => {
					var obj = {};

					Object.keys(x).forEach(element => {
						if (headers.includes(element)) {
							if (typeof x[element] === 'string') x[element] = x[element].split('&#x0D;').join(', ');
							obj[element] = dateFields.includes(element) ? adjustDate(x[element]) : x[element];
						}
					});

					if (fieldsOrder) obj = sortObjectProps(obj, fields);
					return transformKeys(obj);
			  })
			: [];
	};

	return isDropdown ? (
		<div>
			<DropdownButton
				size={props.size ? props.size : 'md'}
				variant={options.variant}
				disabled={options.disabled}
				style={options.style}
				className={props.className ? props.className + ' my-button' : 'my-button'}
				as={ButtonGroup}
				title={<div>{loading && <FaSpinner className='mr-1 spinner' />}Izvoz u excel</div>}>
				<Dropdown.Item eventKey='1' onClick={() => handleDownload()}>
					Trenutna stranica
				</Dropdown.Item>
				<Dropdown.Item eventKey='2' onClick={() => handleDownload(true)}>
					Sve stranice
				</Dropdown.Item>
			</DropdownButton>
		</div>
	) : (
		<Button
			variant={options.variant}
			disabled={options.disabled}
			style={options.style}
			onClick={() => handleDownload()}>
			<BsDownload /> Izvoz
		</Button>
	);
};
