import { act } from 'react-dom/test-utils';

const initialState = {
	page: 1,
	pageSize: 25,
	dataSize: 1,
	calendarEvents: [],
	isGeneralModule: false,
	newCalendarEvent: null,
	newCalendarEvents: [],
	loadDynamicTabs: false,
	calendarEvent: {
		id: null,
	},
};

const calendarEventsReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case 'CALENDAR_EVENT_GET_PAGE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				...action.payload.data,
			});
			break;
		case 'CALENDAR_EVENT_GET_FULFILLED':
			return Object.assign({}, state, {
				...state,
				calendarEvent: {
					...state.calendarEvent,
					...action.payload.data,
					userAssignments: action.payload.data.userAssignments.map(x => ({
						...x,
						value: x.id,
						label: x.name,
					})),
					userGroupAssignments: action.payload.data.userGroupAssignments.map(x => ({
						...x,
						value: x.id,
						label: x.shortName,
					})),
				},
			});
			break;
		case 'CALENDAR_EVENT_CREATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				calendarEvents: [action.payload.data, ...state.calendarEvents],
				newCalendarEvent: action.payload.data,
			});
			break;
		case 'CALENDAR_EVENT_CREATE_MULTIPLE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				calendarEvents: [...action.payload.data, ...state.calendarEvents],
				newCalendarEvents: [...action.payload.data],
				loadDynamicTabs: true,
			});
			break;
		case 'CALENDAR_EVENT_UPDATE_STATE':
			return Object.assign({}, state, {
				...state,
				loadDynamicTabs: false,
				newCalendarEvents: [],
			});
			break;
		case 'CALENDAR_EVENT_UPDATE_FULFILLED':
			return Object.assign({}, state, {
				...state,
				calendarEvents: state.calendarEvents.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return action.payload.data;
				}),
			});
			break;
		case 'CALENDAR_EVENT_DELETE_FULFILLED':
			var removeIndex = state.calendarEvents
				.map(function (item) {
					return item.id;
				})
				.indexOf(action.payload.data);
			state.calendarEvents.splice(removeIndex, 1);
			return Object.assign({}, state, {
				...state,
				calendarEvents: [...state.calendarEvents],
			});
			break;
		case 'CALENDAR_EVENT_GET_USER_ASSIGNMENTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				calendarEvents: state.calendarEvents.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userAssignments: action.payload.data.userAssignments.map(x => {
							return { ...x, value: x.id, label: x.shortName };
						}),
					};
				}),
				calendarEvent: {
					...state.calendarEvent,
					userAssignments: action.payload.data.userAssignments,
				},
			});
			break;
		case 'CALENDAR_EVENT_GET_USER_GROUP_ASSIGNMENTS_FULFILLED':
			return Object.assign({}, state, {
				...state,
				calendarEvents: state.calendarEvents.map((item, index) => {
					if (item.id != action.payload.data.id) return item;
					return {
						...item,
						userGroupAssignments: action.payload.data.userGroupAssignments.map(x => {
							return { ...x, value: x.id, label: x.shortName };
						}),
					};
				}),
				calendarEvent: {
					...state.calendarEvent,
					userGroupAssignments: action.payload.data.userGroupAssignments,
				},
			});
			break;
		case 'SET_GENERAL_CALENDAR_EVENTS_MODE':
			return Object.assign({}, state, {
				...state,
				isGeneralModule: true,
			});
			break;
		default:
			return state;
			break;
	}
};

export default calendarEventsReducer;
