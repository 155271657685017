import moment from 'moment';
import React from 'react';
import { Form } from 'react-bootstrap';
import { customFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { MdDelete, MdEdit, MdSearch } from 'react-icons/md';
import { env } from '../../configs/env';
import DateFilter from '../customFilterFields/dateFilter';
import SelectFilter from '../customFilterFields/selctFilter';

export function columnsConfig(props) {
	const { confirmationHandleOpen, handleUpdate } = props;

	const options = [
		{ value: true, label: 'Aktivan' },
		{ value: false, label: 'Neaktivan' },
	];

	var columns = [
		{
			dataField: 'id',
			text: <div className='pb-1 border-bottom'>ID</div>,
			headerStyle: { width: '2vw' },
			filter: textFilter({
				placeholder: 'Id',
				className: 'form-control-sm mt-1',
				delay: 10,
			}),
			formatter: cellContent => {
				return <div className='text-center'>{cellContent}</div>;
			},
		},
		{
			dataField: 'name',
			text: <div className='pb-1 border-bottom'>Naziv</div>,
			isDummyField: true,
			headerStyle: { width: '10vw' },
			filter: textFilter({
				placeholder: 'Name',
				className: 'form-control-sm mt-1',
				delay: 10,
			}),
			formatter: (cellContent, row) => {
				return row.isCompany
					? row.companyName
					: (row.firstName || '') + ' ' + (row.middleName || '') + ' ' + (row.lastName || '');
			},
		},
		/*{
			dataField: 'dateOfBirth',
			text: <div className='pb-1 border-bottom'>Date of Birth</div>,

			filter: customFilter({ type: 'DATE_FROM_TO', delay: 10 }),
			filterRenderer: (onFilter, column) => <DateFilter onFilter={onFilter} column={column} />,
			formatter: (cellContent, row) => {
				if (row.dateOfBirth === null || /^\s+$/.test(row.dateOfBirth)) return '';
				return moment.utc(row.dateOfBirth).local().format('ll');
			},
		},*/
		{
			dataField: 'taxId',
			text: <div className='pb-1 border-bottom'>Tax ID</div>,
			headerStyle: { width: '8vw' },
			filter: textFilter({
				placeholder: 'TaxId',
				className: 'form-control-sm mt-1',
				delay: 10,
			}),
		},
		{
			dataField: 'partyTypeIds',
			text: <div className='mt-0'>Vrsta/Tip</div>,

			filter: customFilter({ type: 'CUSTOM_MULTISELECT', delay: 10 }),
			filterRenderer: (onFilter, column) => (
				<SelectFilter onFilter={onFilter} isMulti={true} dataTable='prtPartyTypes' dataField='name' />
			),
			formatter: (cellContent, row) => row.partyTypeName,
		},
		{
			dataField: 'created',
			text: <div className='pb-1 border-bottom'>Kreiran</div>,

			filter: customFilter({ type: 'DATE_FROM_TO', delay: 10 }),
			filterRenderer: (onFilter, column) => <DateFilter onFilter={onFilter} column={column} />,
			formatter: (cellContent, row) => {
				if (row.created === null || /^\s+$/.test(row.created)) return '';
				return moment.utc(row.created).local().format('L LT') + ' by ' + row.createdByName;
			},
		},
		{
			dataField: 'modified',
			text: <div className='pb-1 border-bottom'>Ažuriran</div>,

			filter: customFilter({ type: 'DATE_FROM_TO', delay: 10 }),
			filterRenderer: (onFilter, column) => <DateFilter onFilter={onFilter} column={column} />,
			formatter: (cellContent, row) => {
				if (row.modified === null || /^\s+$/.test(row.modified)) return '';
				return moment.utc(row.modified).local().format('L LT') + ' by ' + row.modifiedByName;
			},
		},
		{
			dataField: 'isActive',
			text: <div className='pb-1 border-bottom'>Status</div>,
			headerStyle: {
				width: '100px',
				verticalAlign: 'text-top',
			},
			filter: selectFilter({
				options: options,
				placeholder: '...',
				className: 'form-control-sm mt-1',
				style: { color: 'gray' },
				delay: 10,
			}),
			formatter: (cellContent, row) => {
				return (
					<div>
						{!props.update ? (
							row.isActive ? (
								'Aktivan'
							) : (
								'Neaktivan'
							)
						) : (
							<Form.Check
								id={'switch-' + row.id}
								type='switch'
								checked={row.isActive}
								label={row.isActive ? 'Aktivan' : 'Neaktivan'}
								onChange={() => handleUpdate(row)}
								className='switch'
							/>
						)}
					</div>
				);
			},
		},
		{
			dataField: 'actions',
			text: '',
			isDummyField: true,
			headerStyle: { width: '8em' },
			editCellStyle: (cell, row, rowIndex, colIndex) => {
				return { padding: 0 };
			},
			formatter: (cellContent, row) => {
				return (
					<div className='action-buttons'>
						{props.search && (
							<MdSearch
								style={{ fontSize: '16px' }}
								className='text-success '
								onClick={() => window.open(env.BASE_PATH + 'bolnica/' + row.id + '?viewMode=true', '_blank')}
							/>
						)}
						{props.update && (
							<MdEdit
								style={{ fontSize: '16px' }}
								className='text-secondary'
								onClick={() => window.open(env.BASE_PATH + 'bolnica/' + row.id, '_blank')}
							/>
						)}
						{props.delete && (
							<MdDelete
								style={{ fontSize: '16px' }}
								className='text-danger'
								onClick={() => confirmationHandleOpen(row.id)}
							/>
						)}
					</div>
				);
			},
		},
	];

	if (props.isMassUpdate)
		columns.forEach(x => {
			delete x.filter;
			delete x.filterRenderer;
		});

	return columns;
}
