import axios from 'axios';
import { env } from '../configs/env';

export function getReportParameters(reportId) {
	return axios(env.SERVER_ENV.url + '/api/reports/parameters/' + reportId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getReportInfo(reportId) {
	return axios(env.SERVER_ENV.url + '/api/reports/info/' + reportId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getGenericPage(request) {
	return axios(env.SERVER_ENV.url + '/api/reports/generic/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPrintData(request) {
	return axios(env.SERVER_ENV.url + '/api/reports/print', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function create(reports) {
	return axios(env.SERVER_ENV.url + '/api/reports', {
		method: 'POST',
		data: JSON.stringify(reports),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
