import { _escapeString } from '../../helpers/escapeString';

// export function handleOnChecboxChange(e, obj) {
// 	obj.setState({
// 		dynamicFields: {
// 			...obj.state.dynamicFields,
// 			[e.target.name]: e.target.value == 'true' ? false : true,
// 		},
// 		editedFields: {
// 			...obj.state.editedFields,
// 			[e.target.name]: true,
// 		},
// 	});
// }

// export function handleOnSelect(value, { action, removedValue }, name, obj) {
// 	obj.setState({
// 		dynamicFields: { ...obj.state.dynamicFields, [name]: value },
// 		editedFields: {
// 			...obj.state.editedFields,
// 			[name]: true,
// 		},
// 	});
// }

// export function handleOnDateChange(date, name) {
// 	this.setState({
// 		dynamicFields: {
// 			...this.state.dynamicFields,
// 			[name]: date ? new Date(date) : null,
// 		},
// 		editedFields: {
// 			...this.state.editedFields,
// 			[name]: true,
// 		},
// 	});
// }

// export function handleOnCreateOption(newValue, actionMeta, name, tableName, obj) {
// 	obj.setState({ isLoading: { ...obj.state.isLoading, [name]: true } });
// 	createCode({
// 		name: _escapeString(newValue),
// 		tableName: tableName.substring(2),
// 		moduleId: obj.props.currentModule.id,
// 	})
// 		.then(response => {
// 			obj.setState({
// 				isLoading: { ...obj.state.isLoading, [name]: false },
// 				dynamicFields: {
// 					...obj.state.dynamicFields,
// 					[name]: { value: response.data.id, label: response.data.name },
// 				},
// 				editedFields: {
// 					...obj.state.editedFields,
// 					[name]: true,
// 				},
// 			});
// 		})
// 		.catch(err => {
// 			obj.setState({
// 				isLoading: { ...obj.state.isLoading, [name]: false },
// 			});
// 		});
// }

// export function handleOnCurrencyChange(values, name, obj) {
// 	const { formattedValue, value } = values;
// 	obj.setState({
// 		dynamicFields: { ...obj.state.dynamicFields, [name]: value },
// 		editedFields: {
// 			...obj.state.editedFields,
// 			[name]: true,
// 		},
// 	});
// }

export const getOnCheckboxChangeObject = (name, value, state) => {
	return {
		dynamicFields: {
			...state.dynamicFields,
			[name]: value == 'true' ? false : true,
		},
		editedFields: {
			...state.editedFields,
			[name]: true,
		},
	};
};

export const getOnSelectObject = (value, name, state) => {
	return {
		dynamicFields: { ...state.dynamicFields, [name]: value },
		editedFields: {
			...state.editedFields,
			[name]: true,
		},
	};
};
export const getOnCreatableSelectObject = (response, name, state, isMulti) => {
	const added = { value: response.data.id, label: response.data.name };
	return {
		isLoading: { ...state.isLoading, [name]: false },
		dynamicFields: {
			...state.dynamicFields,
			[name]: isMulti ? [...state.dynamicFields[name], added] : added,
		},
		editedFields: {
			...state.editedFields,
			[name]: true,
		},
	};
};

export const getOnDateChangeObject = (date, name, state) => {
	return {
		dynamicFields: {
			...state.dynamicFields,
			[name]: date ? new Date(date) : null,
		},
		editedFields: {
			...state.editedFields,
			[name]: true,
		},
	};
};

export const getOnChangeObject = (name, value, state) => {
	return {
		dynamicFields: {
			...state.dynamicFields,
			[name]: value,
		},
		editedFields: {
			...state.editedFields,
			[name]: true,
		},
	};
};
export const getOnCurrencyChangeObject = (values, name, state) => {
	return {
		dynamicFields: { ...state.dynamicFields, [name]: values.value },
		editedFields: {
			...state.editedFields,
			[name]: true,
		},
	};
};
