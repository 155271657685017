import moment from 'moment';
import * as utility from '../utility';

export const getFromInterestDate = (referentDateFieldId, row) => {
	switch (referentDateFieldId) {
		case 1:
			return row.pomDate30;
		case 2:
			return row.dateIndexPurchased;
		case 3:
			return row.dateInsurerServed;
	}
};

export const getPassedDays = judgement => utility.calculatePassedDays(judgement, getFromInterestDate);

const lookupAmount = {
	1: 'balanceAmount',
	2: 'suitAmount',
};

export const calculate = judgement => {
	let openCases = judgement.judgementGroupCasesOpen.map(x => {
		if (!x.isActive) return x;

		x.judgementAmount = judgement.judgementAmount;
		let judgementAmount = getJudgementAmount(x[lookupAmount[judgement.interestReferentAmountField.value]]);
		if (judgementAmount < 0) judgementAmount = 0;

		let fromInterestDate = getFromInterestDate(judgement.interestReferentDateFieldId, x);
		fromInterestDate = fromInterestDate ? moment(fromInterestDate) : null;

		let daysBetween =
			fromInterestDate && x.toInterestDate && moment(fromInterestDate).isValid()
				? moment(x.toInterestDate).diff(fromInterestDate, 'days')
				: 0;

		let interestAmount = utility.getInterestAmount(judgementAmount, daysBetween);

		return {
			...x,
			attorneyFees: utility.getAF(judgementAmount, interestAmount),
			interestAccrued: interestAmount,
			prAmount: judgementAmount,
			judgementAmount,
			interestReferentDateField: judgement.interestReferentDateField ? judgement.interestReferentDateField.label : null,
			interestReferentDateFieldId: judgement.interestReferentDateField
				? judgement.interestReferentDateField.value
				: null,
			interestReferentDate: fromInterestDate,
			interestReferentAmountField: judgement.interestReferentAmountField
				? judgement.interestReferentAmountField.label
				: null,
			interestReferentAmountFieldId: judgement.interestReferentAmountField
				? judgement.interestReferentAmountField.value
				: null,
			principleReferentAmountField: judgement.principleReferentAmountField
				? judgement.principleReferentAmountField.label
				: null,
			principleReferentAmountFieldId: judgement.principleReferentAmountField
				? judgement.principleReferentAmountField.value
				: null,
			attorneyFeeLimit: judgement.attorneyFeeLimit ? judgement.attorneyFeeLimit.label : '20%',
			attorneyFeeLimitId: judgement.attorneyFeeLimit ? judgement.attorneyFeeLimit.value : null,
			judgementType: judgement.judgementType ? judgement.judgementType.label : null,
			judgementTypeId: judgement.judgementType ? judgement.judgementType.value : null,
			judgementStatusId: judgement.judgementStatus ? judgement.judgementStatus.value : null,
			interestPercentage: 2,
			fromInterestDate,
		};
	});

	if (!judgement.attorneyFeeLimit) return openCases;

	return utility.annealByGroup(openCases, judgement.attorneyFeeLimit);
};

const columnSumFields = [
	'claimAmount',
	'collectedAmount',
	'prAmount',
	'suitAmount',
	'judgementAmount',
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'statuteCost',
	'marshalPoundage',
];

export const rowSumFields = [
	'interestAccrued',
	'attorneyFees',
	'additionalAttorneyFees',
	'filingFees',
	'statuteCost',
	'marshalPoundage',
];

export const generateColumnTotals = (table, isTakenTable = false) =>
	utility.getColumnTotals(table, isTakenTable, columnSumFields);

export const generateRowTotals = row => utility.getRowTotals(row, rowSumFields);

export const getInterestAmount = (judgementAmount, passedDays) =>
	passedDays > 0 ? (0.24 * judgementAmount * passedDays) / 365 : 0;

export const getJudgementAmount = (judgementAmountFromReferent, newPercentage = 100) =>
	judgementAmountFromReferent > 0 ? (judgementAmountFromReferent * newPercentage) / 100 : 0;
