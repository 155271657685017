const initialState = {
    page: 1,
    pageSize: 25,
    dataSize: 1,
    taskSubtypes: [],
};

const taskSubtypeReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case "TASK_SUBTYPE_GET_PAGE_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                ...action.payload.data,
            });
            break;
        case "TASK_SUBTYPE_CREATE_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: [action.payload.data, ...state.taskSubtypes],
            });
            break;
        case "TASK_SUBTYPE_DELETE_FULFILLED":
            var removeIndex = state.taskSubtypes
                .map(function (item) {
                    return item.id;
                })
                .indexOf(action.payload.data);
            state.taskSubtypes.splice(removeIndex, 1);
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: [...state.taskSubtypes],
            });
            break;
        case "TASK_SUBTYPE_EDIT_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return action.payload.data;
                }),
            });
            break;
        case "TASK_SUBTYPE_GET_FIRMS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        firms: action.payload.data.firms.map((x) => {
                            return { ...x, value: x.id, label: x.name };
                        }),
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_UPDATE_FIRMS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        firmNames: action.payload.data.firms.map((x) => {
                            return { firmName: x.name }
                        }).reduce((acc, val) => acc + val.firmName + "&#x0D;", "")
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_GET_MATTERS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        matters: action.payload.data.matters.map((x) => {
                            return { ...x, value: x.id, label: x.name };
                        }),
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_UPDATE_MATTERS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        matterNames: action.payload.data.matters.map((x) => {
                            return { matterName: x.name }
                        }).reduce((acc, val) => acc + val.matterName + "&#x0D;", "")
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_GET_USER_ASSIGNMENTS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        userAssignments: action.payload.data.userAssignments.map((x) => {
                            return { ...x, value: x.id, label: x.shortName };
                        }),
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_UPDATE_USER_ASSIGNMENTS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        userAssignmentNames: action.payload.data.userAssignments.map((x) => {
                            return { UAName: x.name }
                        }).reduce((acc, val) => acc + val.UAName + "&#x0D;", "")
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_GET_USER_GROUP_ASSIGNMENTS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        userGroupAssignments: action.payload.data.userGroupAssignments.map((x) => {
                            return { ...x, value: x.id, label: x.shortName };
                        }),
                    };
                }),
            });
            break;
        case "TASK_SUBTYPE_UPDATE_USER_GROUP_ASSIGNMENTS_FULFILLED":
            return Object.assign({}, state, {
                ...state,
                taskSubtypes: state.taskSubtypes.map((item, index) => {
                    if (item.id != action.payload.data.id) return item;
                    return {
                        ...item,
                        userGroupAssignmentNames: action.payload.data.userGroupAssignments.map((x) => {
                            return { UAName: x.name }
                        }).reduce((acc, val) => acc + val.UAName + "&#x0D;", "")
                    };
                }),
            });
            break;

        default:
            return state;
            break;
    }
};

export default taskSubtypeReducer;
