import React, { useState, useEffect, useImperativeHandle } from 'react';
import ContactsTableConfig from './contactsTableConfig';
import NumberInput from '../checks/utils/NumberInput';
import NumberFormat from 'react-number-format';
import { Button, Form } from 'react-bootstrap';
import { MdContactPhone, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import SelectField from '../dynamicFields/selectField';
import styled from 'styled-components';
import {
	addContact,
	addEditContact,
	create,
	deleteContacts,
	edit,
	getContactTypes,
	getFirmContacts,
	getPartyContacts,
	getStates,
	getUserContacts,
	toggleDeletedContact,
} from '../../actions/contactActions';
import './contactsTable.css'

const Styles = styled.div`
	input:focus {
		width: 16vw;
	}
`;

const ContactsTableNew = (props, ref) => {
	const contactState = useSelector(state => state.contactReducer);
	const pendingState = useSelector(state => state.pendingReducer);
	const currentModule = useSelector(state => state.moduleReducer.currentModule);
	const [data, setData] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [cntStatesOptions, setCntStatesOptions] = useState([]);
	const [columns, setColumns] = useState([]);

	const initialContact = {
		id: -1,
		email: '',
		phone: '',
		mobile: '',
		fax: '',
		address: '',
		city: '',
		zip: '',
		stateName: '',
		contactTypeName: '',

		validationError: '',
		name: '',
	};
	const dispatch = useDispatch();
	const [state, setState] = useState({
		contact: { ...initialContact },
		editedContacts: [],
		deletedContacts: [],
		createdContacts: [],
	});

	useEffect(() => {
		dispatch(getContactTypes(currentModule.id));
		dispatch(getStates());
		if (props.userId) dispatch(getUserContacts(props.userId));
		else if (props.firmId) dispatch(getFirmContacts(props.firmId));
		else if (props.partyId) dispatch(getPartyContacts(props.partyId));
	}, []);

	useEffect(() => {
		let data = props.isMassUpdate
			? [...props.contacts, state.contact]
			: [...contactState.contacts, ...contactState.createdContacts, state.contact];
		setData(data);

		let typeOptions = contactState.contactTypes.map(ct => {
			return { value: ct.name, label: ct.name };
		});

		let contactsTypes = props.isMassUpdate
			? props.contacts.map(x => x.contactTypeName)
			: contactState.contacts.map(x => x.contactTypeName);

		typeOptions = typeOptions.filter(x => !contactsTypes.includes(x.value));
		setTypeOptions(typeOptions);

		setCntStatesOptions(
			contactState.states.map(ct => {
				return { value: ct.name, label: ct.name, id: ct.id };
			})
		);
	}, [contactState, props.isMassUpdate && props]);
	
	useEffect(() => {
		setColumns([
			{
				accessor: 'id',
				text: 'Contact ID',
				width: '4%',
				editable: false,
				Cell: ({ value: initialValue }) => {
					const [value, setValue] = useState(initialValue);
					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return <label className='id'>{value > 0 ? value : null}</label>;
				},
			},
			{
				accessor: 'name',
				text: 'Name',
				width: '8%',
			},
			{
				accessor: 'email',
				text: 'Email',
				width: '8%',
				Cell: ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
					const [value, setValue] = useState(initialValue);

					const onChange = e => {
						setValue(e.target.value);
					};

					const onBlur = () => {
						updateMyData(index, id, value);
					};

					const updateRow = value => {
						updateMyData(index, id, value);
					};

					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return <EmailInput onUpdate={updateRow} disabled={props.isDisabled} value={value} />;
				},
			},
			{
				accessor: 'phone',
				text: 'Phone',
				width: '8%',

				Cell: ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
					const [value, setValue] = useState(initialValue);

					const onChange = e => {
						setValue(e.target.value);
					};

					const onBlur = () => {
						updateMyData(index, id, value);
					};

					const updateRow = value => {
						updateMyData(index, id, value);
					};

					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return props.isDisabled ? (
						<NumberFormat value={value} displayType={'text'} format='(###) ###-####' mask='_' />
					) : (
						<NumberInput onUpdate={updateRow} value={value} format='(###) ###-####' mask='_' />
					);
				},
			},
			{
				accessor: 'mobile',
				text: 'Mobile',
				width: '8%',
				style: {
					overflowWrap: 'break-word',
				},
				Cell: ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
					const [value, setValue] = useState(initialValue);

					const onChange = e => {
						setValue(e.target.value);
					};

					const onBlur = () => {
						updateMyData(index, id, value);
					};

					const updateRow = value => {
						updateMyData(index, id, value);
					};

					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return props.isDisabled ? (
						<NumberFormat value={value} displayType={'text'} format='(###) ###-####' mask='_' />
					) : (
						<NumberInput onUpdate={updateRow} value={value} format='(###) ###-####' mask='_' />
					);
				},
			},
			{
				accessor: 'fax',
				text: 'Fax',
				width: '8%',

				style: {
					overflowWrap: 'break-word',
				},
				Cell: ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
					const [value, setValue] = useState(initialValue);

					const onChange = e => {
						setValue(e.target.value);
					};

					const onBlur = () => {
						updateMyData(index, id, value);
					};

					const updateRow = value => {
						updateMyData(index, id, value);
					};

					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return props.isDisabled ? (
						<NumberFormat value={value} displayType={'text'} format='(###) ###-####' mask='_' />
					) : (
						<NumberInput onUpdate={updateRow} value={value} format='(###) ###-####' mask='_' stringValue={true} />
					);
				},
			},
			{
				accessor: 'address',
				text: 'Address',
				width: '8%',
			},
			{
				accessor: 'city',
				text: 'City',
				width: '8%',
			},
			{
				accessor: 'zip',
				text: 'Zip',
				width: '8%',
			},
			{
				accessor: 'stateName',
				text: 'State',
				width: '8%',

				style: {
					overflowWrap: 'break-word',
				},
				Cell: ({ value: initialValue, row: { index, original }, column: { id }, updateMyData }) => {
					const [value, setValue] = useState(initialValue);

					const onChange = e => {
						setValue(e.target.value);
					};

					const onBlur = () => {
						updateMyData(index, id, value);
					};

					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return props.isDisabled && original.id < 0 && !original.new ? null : (
						<SelectField
							disabled={props.isDisabled}
							isHidden={true}
							value={{ value: value, label: value }}
							loadOptions={(_, callback) => callback(cntStatesOptions)}
							onChange={e => updateMyData(index, id, e.value)}
							viewMode={props.viewMode}
						/>
					);
				},
			},
			{
				accessor: 'contactTypeName',
				text: 'Contact Type',
				width: '8%',

				Cell: ({ value: initialValue, row: { index, original }, column: { id }, updateMyData }) => {
					const [value, setValue] = useState(initialValue);

					const onChange = e => {
						setValue(e.target.value);
					};

					const onBlur = () => {
						updateMyData(index, id, value);
					};

					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);
					return props.isDisabled && original.id < 0 && !original.new ? null : (
						<SelectField
							isHidden={true}
							value={{ value: value, label: value }}
							disabled={props.isDisabled}
							loadOptions={(_, callback) => callback(typeOptions)}
							onChange={e => updateMyData(index, id, e.value)}
							viewMode={props.viewMode}
						/>
					);
				},
			},
			{
				accessor: 'actions',
				text: '',
				isDummyField: true,
				width: props.isDisabled ? '0%' : '2%',
				editable: false,
				Cell: cellProps => {
					const row = cellProps.cell.row.original;
					cellProps.column.editable = false;
					if (!props.isDisabled) {
						if (row.id < 0 && !row.new)
							return (
								<div className='text-center'>
									<Button
										variant='primary'
										size='sm'
										style={{ fontSize: '0.8em' }}
										onClick={() => handleAddContact(row)}>
										<MdContactPhone style={{ fontSize: '1.2em' }} /> Add
									</Button>
								</div>
							);
						return (
							<div className='action-buttons'>
								<MdDelete className='text-danger' onClick={() => handleDeleteContacts(row)} />
							</div>
						);
					}
					return null;
				},
			},
		]);
	}, [props.isDisabled, contactState.deletedContacts, props.isMassUpdate && props, props.isMassUpdate && typeOptions]);
	
	const onUpdate = (rowIndex, columnId, value) => {
		setData(old =>
			old.map((row, index) => {
				if (index === rowIndex) {
					var editedRow = {
						...old[rowIndex],
						[columnId]: value,
					};
					if (editedRow.id >= 0) {
						dispatch(addEditContact(editedRow));
					}
					return editedRow;
				}
				return row;
			})
		);
	};

	const EditableCell = ({ 
		value: initialValue, 
		row: { index }, 
		column: { id }, 
		updateMyData 
	}) => {
		const [value, setValue] = useState(initialValue);

		const onChange = e => {
			setValue(e.target.value);
		};

		const onBlur = () => {
			updateMyData(index, id, value);
		};

		useEffect(() => {
			setValue(initialValue);
		}, [initialValue]);

		return props.isDisabled ? (
			<label>{value}</label>
		) : (
			<input disabled={props.isDisabled} value={value} onChange={onChange} onBlur={onBlur} />
		);
	};

	const defaultColumn = {
		Cell: EditableCell,
	};

	const handleAddContact = newContact => {
		if (newContact.contactTypeName) {
			if (props.isMassUpdate) {
				newContact.id = contactState.createdContacts.length + 1;
			}
			dispatch(addContact(newContact));
			initialContact.id--;
			setState({ contact: { ...initialContact }, validationError: '' });

			if (props.handleAddContact) props.handleAddContact(newContact);
		} else {
			setState({ validationError: 'Contact type must not be empty!' });
		}
	};

	const createContacts = () => {
		var contacts = contactState.createdContacts.map(x => {
			if (!x.deleted) return x;
		});

		contacts.forEach(i => {
			if (i) {
				var contactType = contactState.contactTypes.find(x => {
					return x.name === i.contactTypeName;
				});
				var cntState = contactState.states.find(x => {
					return x.name === i.stateName;
				});
				i.userId = props.userId;
				i.firmId = props.firmId;
				i.partyId = props.partyId;
				i.contactTypeId = contactType ? contactType.id : null;
				i.stateId = cntState ? cntState.id : null;
			}
		});

		dispatch(create(contacts));
	};

	const editContacts = () => {
		contactState.editedContacts.forEach(i => {
			var contactType = contactState.contactTypes.find(x => {
				return x.name === i.contactTypeName;
			});
			var cntState = contactState.states.find(x => {
				return x.name === i.stateName;
			});
			i.contactTypeId = contactType ? contactType.id : null;
			i.stateId = cntState ? cntState.id : null;
		});

		dispatch(edit(contactState.editedContacts));
	};

	const handleDelete = () => {
		var ids = contactState.deletedContacts.map(x => {
			return x.id;
		}).filter(x => x > -1);
		dispatch(deleteContacts(ids));
	};

	const handleDeleteContacts = row => {
		if (!props.isMassUpdate) dispatch(toggleDeletedContact(row));
		else props.handleDeleteContact(row);
	};

	const handleSave = () => {
		if (contactState.createdContacts.length !== 0) createContacts();
		if (contactState.editedContacts.length !== 0) editContacts();
		if (contactState.deletedContacts.length !== 0) handleDelete();
	};

	useImperativeHandle(ref, () => ({
		handleSave: handleSave,
		getContactTypes: () => contactState.contactTypes,
		getStates: () => contactState.states,
	}));

	return (
		<div className='custom-table' style={{ width: props.isMassUpdate ? '90%' : '100%' }}>
			{props.isMassUpdate ? (
				<Styles>
					<ContactsTableConfig columns={columns} data={data} updateMyData={onUpdate} defaultColumn={defaultColumn} />
					<p className='text-danger'>{state.validationError}</p>
				</Styles>
			) : (
				<>
					<ContactsTableConfig columns={columns} data={data} updateMyData={onUpdate} defaultColumn={defaultColumn} />
					<p className='text-danger'>{state.validationError}</p>
				</>
			)}
		</div>
	);
};

export default React.forwardRef(ContactsTableNew);

const EmailInput = (props) => {
	const [value, setValue] = useState('');
	const { onUpdate } = props;

	useEffect(() => {
		setValue(props.value);
	}, []);

	const handleKeyDown = e => {
		const { onEscape } = props;
		if (e.keyCode === 27) {
			// ESC
			props.onUpdate(value);
		} else if (e.keyCode === 13) {
			// ENTER
			props.onUpdate(value);
		}
	};

	return props.disabled
		? [<label>{value}</label>]
		: [
				<Form onSubmit={null}>
					<Form.Control
						type='email'
						size='sm'
						value={value}
						onBlur={() => onUpdate(value)}
						onKeyDown={handleKeyDown}
						onChange={e => setValue(e.target.value)}
						formatErrorTxt='Incorect email format!'
					/>
				</Form>,
		  ];
	
}
