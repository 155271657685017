import axios from 'axios';
import { saveAs } from 'file-saver';
import { env } from '../configs/env';

export function edit(task) {
	return axios(env.SERVER_ENV.url + '/api/task', {
		method: 'PATCH',
		data: JSON.stringify(task),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getTaskSubtypeUsers(id) {
	return axios(env.SERVER_ENV.url + '/api/taskSubtype/userAssignments/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function saveTopLevelFieldsDynMassUpdate(data) {
	return axios(env.SERVER_ENV.url + '/api/task/massUpdateTopLevelFields', {
		method: 'POST',
		data: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getTaskSubtypeUserGroups(id) {
	return axios(env.SERVER_ENV.url + '/api/taskSubtype/userGroupAssignments/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getTaskTypeUserGroups(id) {
	return axios(env.SERVER_ENV.url + '/api/taskType/userGroupAssignments/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getTaskTypeUsers(id) {
	return axios(env.SERVER_ENV.url + '/api/taskType/userAssignments/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getAdequateSubtypes(request) {
	return axios(env.SERVER_ENV.url + '/api/taskSubtype/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export async function uploadFile(file, taskId) {
	let data = new FormData();
	data.append('file', file, file.name);
	data.append('TaskId', taskId);

	return axios.post(env.SERVER_ENV.url + '/api/task/uploadFile', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export async function uploadZipFile(file, taskId) {
	let data = new FormData();
	data.append('file', file, file.name);
	data.append('TaskId', taskId);

	return axios.post(env.SERVER_ENV.url + '/api/task/uploadZipFile', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getTaskDoucumentInfo(taskId, moduleId = 0) {
	return axios(env.SERVER_ENV.url + '/api/task/documents/' + taskId + '/' + moduleId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function downloadTaskFile(id) {
	axios
		.get(env.SERVER_ENV.url + '/api/task/file/' + id, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			responseType: 'blob',
		})
		.then(res => {
			let filename = res.headers['content-disposition']
				.split(';')
				.find(n => n.includes('filename='))
				.replace('filename=', '')
				.replace(/"/g, '')
				.trim();
			let blob = new Blob([res.data]);
			let url = window.URL.createObjectURL(blob);
			saveAs(url, filename);
		});
}

export function getTaskFilesBlobMultiple(ids, blob = []) {
	return new Promise((resolve, reject) => {
		if (ids.length === 0) resolve(blob);
		else
			axios
				.get(env.SERVER_ENV.url + '/api/task/file/' + ids[0], {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
					responseType: 'arraybuffer',
				})
				.then(res => {
					ids.splice(0, 1);
					getTaskFilesBlobMultiple(ids, [...blob, res.data])
						.then(res => resolve(res))
						.catch(err => reject(err));
				})
				.catch(err => reject(err));
	});
}

export async function asyncForEach(array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

export function getDynamicFields(taskSubtypeId) {
	return axios(env.SERVER_ENV.url + '/api/task/fieldDefinition/' + taskSubtypeId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function updateCaseStatus(request) {
	return axios(env.SERVER_ENV.url + '/api/case/status', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export async function uploadTaskDocument(file, fileName, caseId, path) {
	var data = new FormData();
	data.append('file', new Blob([file], { type: 'application/pdf' }), fileName);
	data.append('FileName', fileName.substr(0, fileName.lastIndexOf('.')));
	data.append('NodeForGrid', path ? caseId + '/' + path : caseId);
	data.append('CaseId', caseId);
	data.append('AccessToken', localStorage.getItem('token'));
	data.append('ForceOverwrite', true);
	data.append('UploadNew', true);
	data.append('UserId', localStorage.getItem('userId'));

	try {
		let result = await axios.post(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/fileupload', data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		if (result.status == 200) {
			var search_params = new URLSearchParams();
			search_params.append('UserId', localStorage.getItem('userId'));

			for (var key in result.data) if (key !== 'UserId') search_params.append(key, result.data[key]);

			return await axios.post(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/document', search_params);
		}
	} catch (e) {
		console.log(e);
	}
}

function deleteDocument(params) {
	return axios.delete(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/document' + params, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

function deleteFile(params) {
	return axios.delete(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/file' + params, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

export async function mergeFiles(filePaths, fileName = 'merged', downolad = false) {
	filePaths = filePaths.map(x => x.replaceAll('/', '\\'));
	let data = JSON.stringify({ filePaths });

	try {
		let response = await axios.post(env.SERVER_ENV.url + '/api/task/taskFiles/merge', data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			responseType: 'arraybuffer',
		});

		if (response.status == 200) {
			let blob = new Blob([response.data]);
			if (downolad) {
				let url = window.URL.createObjectURL(blob);
				saveAs(url, fileName + '.pdf');
			}
			return blob;
		}
	} catch (e) {
		return null;
	}
}

export async function deleteTaskDocument(documentId) {
	let token = localStorage.getItem('token'),
		userId = localStorage.getItem('userId');

	return new Promise((resolve, reject) => {
		getDocument(documentId)
			.then(res => {
				let params =
					'?ImageId=' +
					documentId +
					'&AccessToken=' +
					token +
					'&UserId=' +
					userId +
					'&FileName=' +
					res.data.fileName +
					'&CaseId=' +
					res.data.caseId;

				deleteDocument(params);
				params =
					'?ImageId=' + documentId.toString() + +'&UNC=' + encodeURIComponent(res.data.unc) + '&AccessToken=' + token;

				deleteFile(params)
					.then(r => {
						resolve(r);
					})
					.catch(e => reject('File Delete Failed!'));
			})
			.catch(e => reject('Document Get Failed!'));
	});
}

export function updateTaskDocuments(request) {
	return axios(env.SERVER_ENV.url + '/api/task/documents', {
		method: 'PATCH',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getDocument(documentId) {
	return axios(env.SERVER_ENV.url + '/api/Task/document/' + documentId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getPage(request) {
	return axios(env.SERVER_ENV.url + '/api/task/page', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getUsersPage(request) {
	return axios(env.SERVER_ENV.url + '/api/task/generalPage', {
		method: 'POST',
		data: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getSubtypeDetails(id, moduleId) {
	return axios(env.SERVER_ENV.url + '/api/taskSubtype/' + id + '/' + moduleId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export function getTaskTypeDetails(id, moduleId) {
	return axios(env.SERVER_ENV.url + '/api/taskType/' + id + '/' + moduleId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export async function uploadMutlipleTaskDocument(taskDocumentId, fileName, caseIds, path = null) {
	const caseId = caseIds ? caseIds.split(',')[0] : null;

	const doc = new URLSearchParams();
	doc.append('UserId', localStorage.getItem('userId'));
	doc.append('AccessToken', localStorage.getItem('token'));
	doc.append('ImageId', taskDocumentId);
	doc.append('NodeForGrid', path ? caseId + '/' + path : caseId);
	doc.append('FileName', fileName);
	doc.append('CaseIds', caseIds);

	var documentIds = [];
	var DMSaveResult = 200;
	try {
		let result = await axios.post(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/taskfileuploadmany', doc);

		if (result.status == 200) {
			await asyncForEach(result.data, async element => {
				var search_params = new URLSearchParams();
				search_params.append('UserId', localStorage.getItem('userId'));

				for (var key in element) if (key !== 'UserId') search_params.append(key, element[key]);

				var dbSaveResult = await axios.post(env.DOCUMENT_MANAGMENT_API_ENV.url + '/api/document', search_params);

				if (dbSaveResult.status !== 200) DMSaveResult = 501;
				else documentIds.push(dbSaveResult.data);
			});
		}
		return { status: DMSaveResult, documentIds: documentIds.join(',') };
	} catch (e) {
		console.log(e);
	}
}

export const getTaskFile = id => {
	return axios.get(env.SERVER_ENV.url + '/api/task/file/' + id, {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
		responseType: 'arraybuffer',
	});
};

export function getAllowedTaskTypes(isCreate = false, searchTerm = null, fetchSize = null) {
	return axios(env.SERVER_ENV.url + '/api/taskType/userTypes', {
		method: 'POST',
		data: { isCreate, searchTerm, fetchSize },
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}

export async function getMergedFile(filePaths) {
	filePaths = filePaths.map(x => x.replaceAll('/', '\\'));
	let data = JSON.stringify({ filePaths });

	return axios.post(env.SERVER_ENV.url + '/api/task/taskFiles/merge', data, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
		responseType: 'arraybuffer',
	});
}

export function saveMassUpdateRelatedCases(data) {
	return axios(env.SERVER_ENV.url + '/api/task/massUpdateRelatedCases', {
		method: 'POST',
		data: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	});
}
