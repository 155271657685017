import axios from 'axios';
import { env } from '../configs/env';

export function getFieldDefinitions(entityCode) {
	return {
		type: 'FIELD_DEFINITIONS_GET',
		payload: axios(env.SERVER_ENV.url + '/api/utility/fieldDefinitions/' + entityCode, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function saveDynamicDataFields(entityCode, dynamicEntityData, shouldAppend = false) {
	return {
		type: 'DYNAMIC_FIELDS_SAVE',
		payload: axios(env.SERVER_ENV.url + '/api/Utility/dynamicFields', {
			method: 'POST',
			data: {
				entityCode,
				dynamicEntityData,
				shouldAppend,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getModulePermissions(userId, moduleId) {
	return {
		type: 'MODULE_PERMISSIONS_GET',
		payload: axios(env.SERVER_ENV.url + '/api/utility/permissions/' + userId + '/' + moduleId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function saveDynamicDataFieldsMassUpdate(request) {
	return {
		type: 'DYNAMIC_FIELDS_MASS_UPDATE',
		payload: axios(env.SERVER_ENV.url + '/api/utility/dynamicFieldsMassUpdate', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function adjustDateOffset(date) {
	return date ? new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)) : null;
}
