import axios from 'axios';
import { env } from '../configs/env';

export function getPage(request) {
	return {
		type: 'MODULE_GET_PAGE',
		payload: axios(env.SERVER_ENV.url + '/api/module/page', {
			method: 'POST',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getAllModules(userId, moduleId) {
	return {
		type: 'MODULE_GET_ALL',
		payload: axios(env.SERVER_ENV.url + '/api/module/all/' + userId + '/' + moduleId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getUserGroupPermissions(userGroupId) {
	return {
		type: 'MODULE_GET_BY_UG',
		payload: axios(env.SERVER_ENV.url + '/api/module/userGroup/' + userGroupId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getUserPermissions() {
	return {
		type: 'MODULE_GET_BY_USER',
		payload: axios(env.SERVER_ENV.url + '/api/module/user/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function getReportGroups() {
	return {
		type: 'REPORT_GET_GROUPS',
		payload: axios(env.SERVER_ENV.url + '/api/reports/groups', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	};
}

export function setUserPermissions(navModules) {
	return {
		type: 'SET_NAV_MODULES',
		payload: navModules,
	};
}

export function create(module) {
	return {
		type: 'MODULE_CREATE',
		payload: axios(env.SERVER_ENV.url + '/api/module', {
			method: 'POST',
			data: JSON.stringify(module),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function edit(module) {
	return {
		type: 'MODULE_EDIT',
		payload: axios(env.SERVER_ENV.url + '/api/module', {
			method: 'PATCH',
			data: JSON.stringify(module),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function deleteModule(id, userId, moduleId) {
	return {
		type: 'MODULE_DELETE',
		payload: axios(env.SERVER_ENV.url + '/api/module/' + id + '/' + userId + '/' + moduleId, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function updatePermissions(request) {
	return {
		type: 'MODULE_UPDATE_PERMISSIONS',
		payload: axios(env.SERVER_ENV.url + '/api/module/permissions', {
			method: 'PATCH',
			data: JSON.stringify(request),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		}),
	};
}

export function togglePermission(moduleId, permissionType) {
	return {
		type: 'MODULE_TOGGLE_PERMISSION',
		payload: { moduleId, permissionType },
	};
}

export function updateCurrentModule(path) {
	return {
		type: 'CURRENT_MODULE_UPDATE',
		payload: path,
	};
}

export function toggleAllPermission() {
	return {
		type: 'MODULE_TOGGLE_ALL_PERMISSION',
	};
}
