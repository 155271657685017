import moment from 'moment';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { roundToTwo as rnd } from '../utility';

export const getAF = (amount, intAmount, attorneyFeeLimit = '20%') =>
	adjustAF(rnd((rnd(amount) + rnd(intAmount)) * 0.2), attorneyFeeLimit);

export const getAttorneyFees = (amount, intAmount) => rnd((rnd(amount) + rnd(intAmount)) * 0.2);

export const getInterestAmount = (amount, passedDays) =>
	passedDays > 0 ? rnd((0.24 * rnd(amount) * passedDays) / 365) : 0;

export const applyInterestDiscount = (interest, discount) => {
	discount = parseFloat(discount);
	return rnd((rnd(interest) * rnd(discount)) / 100);
};
export const getInterestDiscount = (newInterest, oldInterest) => rnd((100 * rnd(newInterest)) / rnd(oldInterest));

export const calculatePassedDays = (row, getFromInterestDate) => {
	let fromInterestDate = getFromInterestDate(row.interestReferentDateFieldId, row);
	return fromInterestDate && row.toInterestDate ? moment(row.toInterestDate).diff(fromInterestDate, 'days') : 0;
};

export const getEntityAmount = (entityAmountFromReferent, newPercentage = 100) =>
	entityAmountFromReferent > 0 ? rnd((rnd(entityAmountFromReferent) * newPercentage) / 100) : 0;

export const getEntityPercentage = (amount, balanceAmount) =>
	balanceAmount > 0 && amount > 0 ? rnd((rnd(amount) / rnd(balanceAmount)) * 100) : 0;

export const applyEntityPercentage = (percentage, balanceAmount) => (percentage * rnd(balanceAmount)) / 100;

export const adjustAF = (AF, attorneyFeeLimit) => {
	if (attorneyFeeLimit) {
		let AFL = attorneyFeeLimit.label ? attorneyFeeLimit.label : attorneyFeeLimit;
		let limit = parseInt(AFL.match(/\d+/)[0]);

		if (AFL.includes('<=')) return AF > limit ? limit : AF;

		if (AFL.includes('>=')) return AF < limit ? limit : AF;

		if (AFL === '0%') return 0;
	}
	return AF;
};

export const annealRow = (AF, attorneyFeeLimit, cases, patientId, rowId) => {
	if (attorneyFeeLimit) {
		let AFL = attorneyFeeLimit.label ? attorneyFeeLimit.label : attorneyFeeLimit;
		let limit = parseInt(AFL.match(/\d+/)[0]);
		let sum = cases
			.map(x => (x.patientId === patientId && x.caseId !== rowId ? x.attorneyFees : 0))
			.reduce((acc, cur) => acc + cur, 0);
		sum = rnd(sum);

		if (AFL.includes('<=')) return sum + AF > limit ? limit - sum : sum + AF;

		if (AFL.includes('>=')) return sum + AF < limit ? limit - sum : sum + AF;
	}
	return AF;
};

export const groupAnneal = (attorneyFeeLimit, cases, key, groupBy) => {
	let limit = parseInt(attorneyFeeLimit.match(/\d+/)[0]);
	let sum = cases
		.map(x => (getGroupKey(x, groupBy) === key && x.isActive ? x.attorneyFees : 0))
		.reduce((acc, cur) => acc + cur, 0);
	let totalNo = cases.filter(x => getGroupKey(x, groupBy) === key && x.isActive).length;

	sum = rnd(sum);

	if (attorneyFeeLimit.includes('<=')) return sum > limit ? rnd(limit / totalNo) : null;

	if (attorneyFeeLimit.includes('>=')) return sum < limit ? rnd(limit / totalNo) : null;

	return null;
};

const getGroupKey = (x, arr) => arr.map(name => x[name]).join('-');

export const annealByGroup = (arr, attorneyFeeLimit = { label: '20%' }, groupBy = ['patientId']) => {
	let unique = new Set();
	let result = [];
	let temp;

	arr.forEach(x => unique.add(getGroupKey(x, groupBy)));

	for (let id of unique) {
		temp = arr
			.map(x => {
				if (getGroupKey(x, groupBy) === id) {
					let AF = groupAnneal(attorneyFeeLimit.label, arr, id, groupBy);
					return { ...x, attorneyFees: AF ? AF : x.attorneyFees };
				}
			})
			.filter(x => x);

		if (temp.length > 0) result.push(...temp);
	}
	return result;
};

const columnSum = (propName, table, isTakenTable) =>
	table.reduce((acc, cur) => (cur[propName] > 0 && (cur.isActive || isTakenTable) ? acc + rnd(cur[propName]) : acc), 0);

export const getColumnTotals = (table, isTakenTable = false, columnSumFields = []) => {
	let result = { id: 'row-t', isTotal: true, created: null };
	columnSumFields.forEach(element => (result[element] = columnSum(element, table, isTakenTable)));
	return result;
};

export const getRowTotals = (row, rowSumFields = []) =>
	row.isActive ? rowSumFields.reduce((acc, cur) => (row[cur] > 0 ? acc + rnd(row[cur]) : acc), 0) : 0;

export const getMandatoryLabel = name => (
	<OverlayTrigger
		placement={'right'}
		overlay={
			<Popover id={'popover-disabled'}>
				<Popover.Content>Mandatory field!</Popover.Content>
			</Popover>
		}>
		<div>
			{name} <span className='text-danger'>*</span>
		</div>
	</OverlayTrigger>
);

export const numberOfDots = str => {
	const regex = /[.]/g;

	return str.match(regex).length;
};
