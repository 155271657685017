const initialState = {
  dmDefaultFolders: [],
  loading: false,
  checkedAll: false
};

const dmDefaultFolderReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "DM_DEFAULT_FOLDER_GET_ALL_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        dmDefaultFolders: action.payload.data,
      });
      break;
    case "DM_DEFAULT_FOLDERS_GET_BY_UG_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        dmDefaultFolders: state.dmDefaultFolders.map((item) => {
          let value = action.payload.data.find((x) => item.id === x.id);
          if (value)
            return { ...value, read: true };
          else
            return {
              ...item,
              read: false,
              generateCost: false
            };
        }),
      });
      break;
    case "DM_DEFAULT_FOLDER_TOGGLE_PERMISSION":
      return Object.assign({}, state, {
        ...state,
        dmDefaultFolders: state.dmDefaultFolders.map((item) => {
          if (item.id == action.payload.moduleId) {
            if (action.payload.permissionType === "read") {
              let readValue = item["read"];
              return {
                ...item,
                generateCost: readValue ? false : item.generateCost,
                read: !readValue,
              };
            } else
              return {
                ...item,
                read: true,
                generateCost: !item.generateCost
              };
          }
          return item;
        }),
      });
      break;
    case "DM_DEFAULT_FOLDER_UPDATE_PERMISSIONS_PENDING":
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
      break;
    case "DM_DEFAULT_FOLDER_UPDATE_PERMISSIONS_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        loading: false,
      });
      break;
    case "DM_DEFAULT_FOLDER_TOGGLE_ALL_PERMISSION":
      return Object.assign({}, state, {
        ...state,
        dmDefaultFolders: state.dmDefaultFolders.map((item) => {
          return {
            ...item,
            read: !state.checkedAll,
          };
        }),
        checkedAll: !state.checkedAll
      });
      break;
    default:
      return state;
      break;
  }
};

export default dmDefaultFolderReducer;
