const initialState = {
  page: 1,
  pageSize: 25,
  dataSize: 1,
  viewGroups: [],
};

const viewGroupReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "VG_GET_PAGE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data,
        viewGroups: [...action.payload.data.viewGroups],
      });
      break;
    case "VG_CREATE_FULFILLED":
      if (state.viewGroups.length === state.pageSize)
        state.viewGroups.splice(state.viewGroups.length - 1, 1);
      return Object.assign({}, state, {
        ...state,
        viewGroups: [action.payload.data, ...state.viewGroups],
      });
      break;
    case "VG_UPDATE_FULFILLED":
      return Object.assign({}, state, {
        ...state,
        viewGroups: state.viewGroups.map((item, index) => {
          if (item.id != action.payload.data.id) return item;
          return action.payload.data;
        }),
      });
      break;
    default:
      return state;
      break;
  }
};

export default viewGroupReducer;
